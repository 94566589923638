import React from 'react';
import Playlists from './MainViews/Playlists';
import Albums from './MainViews/Albums';

const MainPanel = ({ mainPage = 'Home', setMainPage = () => {}, userSettings = {}, playTrack = async ({}) => {}, addToQueue = () => {}, setTmpQueue = () => {}, 
                        playerState = {}, togglePlayPause = () => {}, nextTrack = () => {}, getPlayerState = () => {} }) => {

    const getComponent = () => {
        switch (mainPage) {
            case 'Playlists':
                return <Playlists playTrack={playTrack} userSettings={userSettings} setTmpQueue={setTmpQueue} playerState={playerState} togglePlayPause={togglePlayPause}
                            addToQueue={addToQueue} nextTrack={nextTrack} setMainPage={setMainPage} getPlayerState={getPlayerState} />
            case 'Albums':
                return <Albums userSettings={userSettings} playTrack={playTrack} playerState={playerState} togglePlayPause={togglePlayPause} 
                            addToQueue={addToQueue} nextTrack={nextTrack} />
        
            default:
                return <p>{mainPage}</p>
        }
    };

    return (
        <React.Fragment>
            <div>
                {getComponent()}
            </div>
        </React.Fragment>
    );
};

export default MainPanel;