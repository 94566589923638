import React, { useState, useCallback, useMemo } from 'react';
import UserContext from './UserContext';
import Cookies from 'js-cookie';

export const UserProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const loadStateFromCookies = () => {
        const stateFromCookies = {
            isLoggedIn: Cookies.get('isLoggedIn') === 'true',
            token: Cookies.get('token') || '',
            username: Cookies.get('username') || '',
            profile_url: Cookies.get('profile_url') || '',
            broadcaster_type: Cookies.get('broadcaster_type') || '',
            isSubscribed: Cookies.get('isSubscribed') === 'true',
            isAdmin: Cookies.get('isAdmin') === 'true',
            theme: Cookies.get('theme') || 'dark',
            editor: Cookies.get('editor') === 'true',
        };
        return stateFromCookies;
    };
    const [user, setUser] = useState(loadStateFromCookies);
    const saveStateToCookies = useCallback((state) => {
        Object.keys(state).forEach(key => {
            Cookies.set(key, state[key]);
        });
    }, []);
    const clearCookies = () => {
        Object.keys(Cookies.get()).forEach(cookieName => {
            Cookies.remove(cookieName);
        });
        localStorage.removeItem('editorPermissions');
        localStorage.removeItem('editingFor');
        localStorage.removeItem('mainViewComponent');
    };

    const checkIfSubscribed = useCallback(async (token, returnDetails = false) => {
        try {
            const response = await fetch(`${apiUrl}/user/subscription/status?token=${token}`);
            const res = await response.json();
            const status = res['status'];
            setUser(currentState => {
                const newState = { ...currentState, isSubscribed: ['active', 'trialing', 'past_due'].includes(status) };
                saveStateToCookies(newState);
                return newState;
            });
            if (returnDetails === true) return res;
            return ['active', 'trialing', 'past_due'].includes(status);
        } catch (e) {
            console.error(e);
            return false;
        }
    }, [apiUrl, saveStateToCookies]);

    const checkIfAdmin = useCallback(async (token) => {
        try {
            const response = await fetch(`${apiUrl}/user/isadmin?token=${token}`);
            const resStatus = response.ok;
            const res = await response.text();
            if (res && resStatus) {
                setUser(currentState => {
                    const newState = { ...currentState, isAdmin: true };
                    saveStateToCookies(newState);
                    return newState;
                });
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }, [apiUrl, saveStateToCookies]);

    const login = useCallback(async (token, username, profile_url, broadcaster_type, editor) => {
        console.log('Logging in UserProvider', editor);
        setUser(currentState => {
            const newState = {...currentState, isLoggedIn: true, token, username, profile_url, broadcaster_type, editor};
            saveStateToCookies(newState);
            return newState;
        });
        await checkIfSubscribed(token);
        await checkIfAdmin(token);
        return true;
    }, [checkIfSubscribed, checkIfAdmin, saveStateToCookies]);

    const logout = useCallback(async () => {
        const { token } = user;
        await fetch(`${apiUrl}/user/logout?token=${token}`);
        setUser({});
        clearCookies();
        window.location.href = '/';
    }, [user, apiUrl]);

    const contextValue = useMemo(() => ({
        ...user, login, logout, checkIfAdmin, checkIfSubscribed
    }), [user, login, logout, checkIfAdmin, checkIfSubscribed]);
  
    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
  };  