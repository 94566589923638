import React from 'react';
import { Typography, Container, Grid, Card, CardContent, styled, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import useIsMobile from '../../utils/useIsMobile';

const StreamersPage = ({streamers}) => {
    const isMobile = useIsMobile();
    const TransparentCard = styled(Card)(() => ({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        boxShadow: 'none',
        transition: 'transform 0.3s, background-color 0.3s',
        cursor: 'pointer',
        '&:hover': {
          transform: 'scale(1.02)',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }
    }));

    const handleCardClick = (streamer) => {
        window.location.href = `https://twitch.tv/${streamer}`;
    };

  return (
    <div style={{ display: 'flex', minHeight: '70vh', marginTop: '24px', justifyContent: 'center' }}>
      <Container maxWidth="xl">
        <Typography variant={isMobile ? "h3" : "h1"} align={isMobile ? 'left' : 'center'}>Live Streamers With Ratbot</Typography>
        <br />
        <Grid container spacing={2} sx={{overflowY: 'auto', maxHeight: '80vh', justifyContent: 'center'}}>
          {Object.keys(streamers).length > 0 &&
            Object.keys(streamers).map((streamer) => (
                <Grid item xs={12} sm={3} sx={{margin: '10px'}} key={streamer}>
                    <TransparentCard onClick={() => handleCardClick(streamer)}>
                        <CardContent>
                            <Typography variant='h3'>{streamer}</Typography>
                            <br />
                            <Typography variant='h4'>{`Title: ${streamers[streamer]['title']}`}</Typography>
                            <Typography>{`Viewers: ${streamers[streamer]['viewer_count']}`}</Typography>
                            <br />
                            <img src={streamers[streamer]['thumbnail_url'].replace('{width}x{height}', '300x168')} />
                            <br />
                            <Typography variant='h5'>{`Category: ${streamers[streamer]['game_name'] === "" ? 'None' : streamers[streamer]['game_name']}`}</Typography>
                            <br />
                            {streamers[streamer]['tags'].length > 0 && streamers[streamer]['tags'].map(tag => (
                                <Chip label={tag} variant='outlined' key={tag} sx={{marginRight: '8px', marginBottom: '4px'}} />
                            ))}
                        </CardContent>
                    </TransparentCard>
                </Grid>
            ))}
          {Object.keys(streamers).length === 0 && (
            <React.Fragment>
                <Typography sx={{marginTop: '16px', textAlign: isMobile ? 'left' : 'center'}}>No Ratbot users currently live - Please check back later!</Typography>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default StreamersPage;