import { Box, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import Aliases from '../dialogs/aliases';
import IgnoredUsers from '../dialogs/ignoredusers';
import StreamStats from '../dialogs/streamstats';
import ChatLogs from '../dialogs/chatlogs';
import SliderDialog from '../dialogs/sliderdialog';
import TextInputDialog from '../dialogs/textinputdialog';
import SystemCommands from '../dialogs/systemcommands';
import CreateSpotifyRewardDialog from '../dialogs/createSpotifyReward';
import SpotifyBlockListDialog from '../dialogs/spotifyblocklist';
import PresetGrid from '../../components/personality_picker/PresetGrid';
import Commands from '../../components/commands';
import TriggerGrid from '../../components/triggers/TriggerGrid';
import Spotify from '../../components/spotify';
import Twitch from '../../components/twitch';
import Admin from '../../components/admin';
import NewTrigger from '../dialogs/newtrigger';
import NewCommand from '../dialogs/newCommand';
import PersonalityRewards from '../dialogs/personalityrewards';
import ReleaseNotes from '../dialogs/releasenotes';
import FAQ from '../dialogs/faq';
import AddTwitchPermission from '../dialogs/addTwitchPermission';
import OBSTTS from '../dialogs/obstts';
import OBSTTSReward from '../dialogs/obsttsreward';
import useLatestStreams from '../../utils/useLatestStreams';
import useAIPresets from '../../utils/useAIPresets';
import Topbar from '../globals/Topbar';
import { useStreamerContext } from '../../store/streamersettings-context';
import useIsMobile from '../../utils/useIsMobile';
import Sidebar from '../globals/Sidebar';
import BottomBar from '../globals/BottomBar';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { useUser } from '../../context/UserContext';

const EditorView = ({backgroundColor = '#5D3B9E'}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userSettings, refresh, update, error, deletekey, selectedStreamer, setSelectedStreamer } = useStreamerContext();
    const queryParams = new URLSearchParams(location.search);
    const selectedTabParam = queryParams.get('p');
    const [selectedTab, setSelectedTab] = useState(selectedTabParam || 'dashboard');
    const { filenames } = useLatestStreams();
    const isMobile = useIsMobile();
    const isMedium = useWindowSizeThreshold(1280, true);
    const presets = useAIPresets();
    const [streamers, setStreamers] = useState([]);
    const [editorPermissions, setEditorPermissions] = useState(JSON.parse(localStorage.getItem('editorPermissions')));
    const [mainViewComponent, setMainViewComponent] = useState(selectedTabParam || localStorage.getItem('mainViewComponent') || 'aipresets');
    const [isBlur, setIsBlur] = useState(false);
    const [mainViewHeader, setMainViewHeader] = useState('AI Presets');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState('');
    const [dialogComponent, setDialogComponent] = useState(<></>);
    const [loading, setLoading] = useState(false);
    const [sidebarHovered, setSidebarHovered] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        box: {
            backgroundColor: colorScheme.background50,
            width: '500px',
            textAlign: 'center',
            padding: '48px',
            borderRadius: '10px',
            border: `5px solid ${colorScheme.background75}`
        },
        container: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        title: {
            fontSize: '3rem',
            fontWeight: 700,
            color: '#FFFFFF',
            textShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            letterSpacing: '0.1em',
            background: '-webkit-linear-gradient(45deg, #9d65ff, #8461e8)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '1rem',
            userSelect: 'none'
        }
    };

    useEffect(() => {
        const fetchCanEditFor = async () => {
            setLoading(true);
            const response = await fetch(`${apiUrl}/user/caneditfor?token=${authCtx.token}`);
            const res = await response.json();
            if (response.ok) {
                setStreamers(res);
            }
            setLoading(false);
        };
        fetchCanEditFor();
        refresh(localStorage.getItem('editingFor'));
    }, [apiUrl, authCtx]);
    useEffect(() => {
        setSelectedTab(mainViewComponent);
        navigate(`/editorsettings?p=${mainViewComponent}`);
        localStorage.setItem('mainViewComponent', mainViewComponent);
        switch (mainViewComponent) {
            case 'aipresets':
                setMainViewHeader('AI Presets');
                break;
            case 'dashboard':
                setMainViewHeader('Dashboard');
                break;
            case 'commands':
                setMainViewHeader('Commands');
                break;
            case 'triggers':
                setMainViewHeader('Triggers');
                break;
            case 'spotify':
                setMainViewHeader('Spotify');
                break;
            case 'twitch':
                setMainViewHeader('Twitch');
                break;
            case 'admin':
                setMainViewHeader('Admin');
                break;
        }
        if (selectedStreamer) {
            fetchPermissions(selectedStreamer);
        }
    }, [mainViewComponent]);

    const fetchPermissions = async (streamer = '') => {
        const response = await fetch(`${apiUrl}/user/editingpermissions?token=${authCtx.token}&streamer=${streamer}`);
        const res = await response.json();
        setEditorPermissions(res);
        return res;
    };
    const getStarted = async () => {
        setLoading(true);
        const permissions = await fetchPermissions(selectedStreamer);
        if (Object.keys(permissions).length > 0) {
            localStorage.setItem('editorPermissions', JSON.stringify(permissions, null, 4));
            localStorage.setItem('editingFor', selectedStreamer);
        }
        await refresh(localStorage.getItem('editingFor'));
        setLoading(false);
    };
    const openDialogComponent = (type, data = {}) => {
        setOpenDialog(true); 
        setIsBlur(true);
        switch (type) {
            case 'aliases':
                setDialogHeader('Aliases');
                setDialogComponent(<Aliases backgroundColor={colorScheme.background25} userSettings={userSettings} update={update} />);
                break;
            case 'ignoredusers':
                setDialogHeader('Ignored Users');
                setDialogComponent(<IgnoredUsers backgroundColor={colorScheme.background25} userSettings={userSettings} update={update} />);
                break;
            case 'streamstats':
                setDialogHeader('Stream Stats');
                setDialogComponent(<StreamStats backgroundColor={colorScheme.background25} filenames={filenames} loading={loading} defaultExpanded={data} />);
                break;
            case 'chatlogs':
                setDialogHeader('Chatlogs');
                setDialogComponent(<ChatLogs backgroundColor={colorScheme.background25} />);
                break;
            case 'buffertimer':
                setDialogHeader('Chat Buffer Timer');
                setDialogComponent(<SliderDialog footer='Set minimum time between bot messages in chat' value={parseInt(userSettings.messageInterval)/1000}
                    setValue={(v) => update({messageInterval: (v * 1000).toString()})} min={5} max={30} step={1} unit=' seconds' backgroundColor={backgroundColor} />);
                break;
            case 'usercontext':
                setDialogHeader('Context-Aware Tuning');
                setDialogComponent(<TextInputDialog footer='Provide additional information that Ratbot should be aware of' backgroundColor={backgroundColor}
                    value={userSettings.openAISettings.customPrompt} setValue={(v) => update({openAISettings: {...userSettings.openAISettings, customPrompt: v}})}
                    maxCharacters={1000} />);
                break;
            case 'exitmessage':
                setDialogHeader('Exit Message');
                setDialogComponent(<TextInputDialog footer='Ratbot will post this message in chat when your stream goes offline' backgroundColor={backgroundColor}
                    value={userSettings.exitMessage} setValue={(v) => update({exitMessage: v})} maxCharacters={500} />);
                break;
            case 'systemcommands':
                setDialogHeader('System Commands');
                setDialogComponent(<SystemCommands backgroundColor={colorScheme.background25} userSettings={userSettings} update={update} />)
                break;
            case 'spotifymintracklength':
                setDialogHeader('Minimum Track Length');
                setDialogComponent(<SliderDialog footer='Set minimum length for tracks requested'
                    value={userSettings.spotify.minLength} setValue={(v) => update({spotify: {...userSettings.spotify, minLength: v}})}
                    min={10} max={600} step={10} useTimeFormatting backgroundColor={backgroundColor} />);
                break;
            case 'spotifymaxtracklength':
                setDialogHeader('Maximum Track Length');
                setDialogComponent(<SliderDialog footer='Set maximum length for tracks requested'
                    value={userSettings.spotify.maxLength} setValue={(v) => update({spotify: {...userSettings.spotify, maxLength: v}})}
                    min={60} max={1800} step={30} useTimeFormatting backgroundColor={backgroundColor} />);
                break;
            case 'spotifyminpopularity':
                setDialogHeader('Minimum Popularity Threshold');
                setDialogComponent(<SliderDialog footer='Set minimum popularity rating for requested songs (0 = all songs)'
                    value={userSettings.spotify.minPopularity} setValue={(v) => update({spotify: {...userSettings.spotify, minPopularity: v}})}
                    min={0} max={100} step={1} backgroundColor={backgroundColor} unit='%' />);
                break;
            case 'createSpotifyReward':
                setDialogHeader('Channel Point Reward for Spotify');
                setDialogComponent(<CreateSpotifyRewardDialog footer='Create a Twitch reward for viewers to redeem song requests' />);
                break;
            case 'spotifyBlockedTracks':
                setDialogHeader('Blocked Tracks');
                setDialogComponent(<SpotifyBlockListDialog userSettings={userSettings} update={update} backgroundColor={backgroundColor} footer='Edit tracks to automatically block from being redeemed' blockList='blockedTracks' />);
                break;
            case 'spotifyBlockedArtists':
                setDialogHeader('Blocked Artists');
                setDialogComponent(<SpotifyBlockListDialog userSettings={userSettings} update={update} backgroundColor={backgroundColor} footer='Edit artists to automatically block from being redeemed' blockList='blockedArtists' />);
                break;
            case 'newtrigger':
                setDialogHeader(Object.keys(data).length === 0 ? 'New Trigger' : 'Edit Trigger');
                setDialogComponent(<NewTrigger userSettings={userSettings} update={update} backgroundColor={backgroundColor} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} editObj={Object.keys(data).length === 0 ? {} : data} />)
                break;
            case 'newcommand':
                setDialogHeader(Object.keys(data).length === 0 ? 'New Command' : 'Edit Command');
                setDialogComponent(<NewCommand backgroundColor={backgroundColor} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} editObj={Object.keys(data).length === 0 ? {} : data} />)
                break;
            case 'twitchpolllength':
                setDialogHeader('Twitch Poll Length');
                setDialogComponent(<SliderDialog footer='Length of Twitch polls created by Ratbot' value={userSettings?.modData?.twitch?.commands?.create_poll?.duration ?? 60}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_poll: {...userSettings['modData']['twitch']['commands']['create_poll'], duration: v}}}}}, false)}
                    min={15} max={600} step={15} backgroundColor={backgroundColor} useTimeFormatting />);
                break;
            case 'twitchpollcommand':
                setDialogHeader('Twitch Poll Command');
                setDialogComponent(<TextInputDialog footer='Command to use for creating polls' backgroundColor={backgroundColor}
                    value={userSettings['modData']['twitch']['commands']['create_poll']['command'] ?? 'poll'} maxCharacters={20} prefix={userSettings['commandDelimiter']}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_poll: {...userSettings['modData']['twitch']['commands']['create_poll'], command: v}}}}})} />);
                break;
            case 'twitchpredictionlength':
                setDialogHeader('Twitch Prediction Window');
                setDialogComponent(<SliderDialog footer='The length of time that the prediction will run for' value={userSettings?.modData?.twitch?.commands?.create_prediction?.prediction_window ?? 300}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_prediction: {...userSettings['modData']['twitch']['commands']['create_prediction'], prediction_window: v}}}}}, false)}
                    min={30} max={1800} step={30} backgroundColor={backgroundColor} useTimeFormatting />);
                break;
            case 'twitchpredictioncommand':
                setDialogHeader('Twitch Prediction Command');
                setDialogComponent(<TextInputDialog footer='Command to use for creating predictions' backgroundColor={backgroundColor}
                    value={userSettings['modData']['twitch']['commands']['create_prediction']['command'] ?? 'predict'} maxCharacters={20} prefix={userSettings['commandDelimiter']}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_prediction: {...userSettings['modData']['twitch']['commands']['create_prediction'], command: v}}}}})} />);
                break;
            case 'twitchendpredictioncommand':
                setDialogHeader('Twitch End Prediction Command');
                setDialogComponent(<TextInputDialog footer='Command to use for ending ongoing predictions' backgroundColor={backgroundColor}
                    value={userSettings['modData']['twitch']['commands']['end_prediction']['command'] ?? 'endpredict'} maxCharacters={20} prefix={userSettings['commandDelimiter']}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], end_prediction: {...userSettings['modData']['twitch']['commands']['end_prediction'], command: v}}}}})} />);
                break;
            case 'twitchpersonalityrewards':
                setDialogHeader('Viewer Rewards: Personalities');
                setDialogComponent(<PersonalityRewards footer='Creater viewer rewards for changing Ratbot personality' backgroundColor={backgroundColor}
                    presets={presets} userSettings={userSettings} update={update} deletekey={deletekey} refresh={refresh} />);
                break;
            case 'releasenotes':
                setDialogHeader('Release Notes');
                setDialogComponent(<ReleaseNotes footer='Update log for Ratbot and the ratbot.store website' />);
                break;
            case 'faq':
                setDialogHeader('FAQ');
                setDialogComponent(<FAQ footer='Frequently Asked Questions' />);
                break;
            case 'addtwitchpermission':
                setDialogHeader('Add Twitch Permission');
                setDialogComponent(<AddTwitchPermission footer='Add additional Twitch permissions for Ratbot' backgroundColor={backgroundColor}
                    scope={data['scope']} />)
                break;
            case 'obstts':
                setDialogHeader('RatbotTTS (Text-to-Speech)');
                setDialogComponent(<OBSTTS footer='Set up the text-to-speech parameters for the RatbotTTS OBS plugin' backgroundColor={backgroundColor}
                    userSettings={userSettings} update={update} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} />);
                break;
            case 'obsttsreward':
                setDialogHeader('Channel Points Reward for RatbotTTS');
                setDialogComponent(<OBSTTSReward footer='Creater viewer rewards for changing Ratbot personality' backgroundColor={backgroundColor}
                    presets={presets} userSettings={userSettings} update={update} deletekey={deletekey} refresh={refresh} />);
                break;
            case 'emotefrequency':
                setDialogHeader('Emote Use Frequency');
                setDialogComponent(<SliderDialog footer='Adjust how often Ratbot adds a 7TV/BTTV emote at the end of chat messages' 
                    value={userSettings?.emoteFrequency ?? 50} setValue={(v) => update({emoteFrequency: v})}
                min={0} max={100} step={5} backgroundColor={backgroundColor} />)
                break;

            default:
                break;
        };
    };
    const hasPermission = (action, target) => {
        let res = false;
        Object.entries(editorPermissions).forEach((entry, index) => {
            const [entryAction, entryTarget] = entry[0].split(':');
            if (entryAction === action && entryTarget === target && entry[1] === true) res = true;
        });
        return res;
    };
    const accessDeniedComponent = () => {
        return (
            <p>{`This page is off-limits because the streamer hasn't granted you access. If you think this is a mistake, please ask the streamer to update your permissions.`}</p>
        );
    };
    const renderMainViewComponent = () => {
        switch(mainViewComponent) {
            case 'aipresets':
                return (hasPermission('read', 'personalities') || hasPermission('write', 'personalities')) ? 
                <PresetGrid backgroundColor={backgroundColor} filter={[]} presets={presets} userSettings={userSettings} update={update} /> : accessDeniedComponent();
            case 'dashboard':
                return <Typography>Dashboard</Typography>;
            case 'commands':
                return (hasPermission('read', 'commands') || hasPermission('write', 'commands')) ? 
                <Commands backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} update={update} deletekey={deletekey} /> : accessDeniedComponent();
            case 'triggers':
                return (hasPermission('read', 'triggers') || hasPermission('write', 'triggers')) ? 
                <TriggerGrid backgroundColor={backgroundColor} userSettings={userSettings} update={update} openDialogComponent={openDialogComponent} /> : accessDeniedComponent();
            case 'spotify':
                return (hasPermission('read', 'spotify') || hasPermission('write', 'spotify')) ? 
                <Spotify backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} update={update} refresh={refresh} /> : accessDeniedComponent();
            case 'twitch':
                return (hasPermission('read', 'twitch') || hasPermission('write', 'twitch')) ? 
                <Twitch backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} update={update} refresh={refresh} /> : accessDeniedComponent();
            case 'admin':
                return <Admin backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} update={update} refresh={refresh} />;
            default:
                return null;
        };
    };

    return (
        <React.Fragment>
            {editorPermissions === null ? (
                <Grid container style={styles.container}>
                    <Box sx={styles.box}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h3'>Welcome to Ratbot Editor mode!</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h5'>Please select below which streamer you are editing for:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Dropdown value={{name: selectedStreamer}} onChange={(e) => setSelectedStreamer(e.value['name'])} options={streamers.map((v, i) => ({ name: v }))} optionLabel='name'
                                    placeholder='Select a streamer' style={{width: '50%'}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button style={{width: '50%'}} size='small' label='Get Started' icon='pi pi-sign-in' disabled={selectedStreamer === null}
                                    iconPos='right' onClick={getStarted} severity='success' />
                            </Grid>
                            <Grid item xs={12}>
                                <Button style={{width: '50%'}} size='small' label='Log Out' icon='pi pi-sign-out' 
                                    iconPos='right' onClick={() => authCtx.logout()} severity='danger' />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ) : Object.keys(userSettings).length > 1 && (
                <React.Fragment>
                    <Dialog header={dialogHeader} visible={openDialog} onHide={() => {setOpenDialog(false); setIsBlur(false);}} resizable={false}
                        headerStyle={{backgroundColor: colorScheme.background50}} draggable={false} contentStyle={{backgroundColor: colorScheme.background50}}>
                        {dialogComponent}
                    </Dialog>
                    {Object.keys(userSettings).length > 1 && (<div style={{position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', filter: isBlur && 'blur(10px) brightness(0.75)', transition: 'filter 0.5s'}}>
                        <Topbar setIsBlur={setIsBlur} openDialogComponent={openDialogComponent} setMainViewComponent={setMainViewComponent} setMainViewHeader={setMainViewHeader}
                            userSettings={userSettings} update={update} />
                    {!isMobile ? (
                        <Sidebar setSidebarHovered={setSidebarHovered} mainViewComponent={mainViewComponent} setMainViewComponent={setMainViewComponent} 
                            mainViewHeader={mainViewHeader} setMainViewHeader={setMainViewHeader} />
                    ) : (
                        <BottomBar backgroundColor={backgroundColor} selectedTextColor='#e0e0e0' openDialogComponent={openDialogComponent}
                            setMainViewComponent={setMainViewComponent} />
                    )}
                    <motion.div animate={!isMobile ? {marginLeft: sidebarHovered ? '8vw' : '7.5vw'} : {marginLeft: '5vw'}} 
                        transition={!isMobile && { duration: 0.25, type: 'spring', stiffness: 100 }}>
                        <Box sx={{width: '90vw', height: isMobile ? '75vh' : '77.5vh', marginTop: isMobile && '12.5vh', 
                            paddingLeft: (isMobile && isMedium) ? '0px' : (!isMobile && isMedium) ? '48px' : '24px'}}>
                            {!isMobile && (<Typography variant='h1' sx={{ ...styles.title, marginTop: '10vh' }}>{`${mainViewHeader} (${selectedStreamer})`}</Typography>)}
                            {renderMainViewComponent()}
                        </Box>
                    </motion.div>
                    </div>)}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default EditorView;