import React from 'react';
import './PlaybackPanel.css';
import { Box, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

// Track and image variants for animation
const trackVariants = {
    firstTrack: {
        initial: { x: 250, opacity: 1, height: '65px' },
        animate: { x: 0, opacity: 1, height: '80px' },
        exit: { x: 0, y: 0, opacity: 0, height: '80px' },
    },
    secondTrack: {
        initial: { x: 225, opacity: 1, height: '50px' },
        animate: { x: 0, opacity: 1, height: '65px' },
        exit: { x: -265, opacity: 1, height: '65px' },
    },
    thirdTrack: {
        initial: { x: 200, opacity: 1, height: '50px' },
        animate: { x: 0, opacity: 1, height: '50px' },
        exit: { x: -240, opacity: 1, height: '50px' },
    },
    fourthTrack: {
        initial: { x: 185, opacity: 1, height: '0px' },
        animate: { x: 0, opacity: 1, height: '0px' },
        exit: { x: -231, opacity: 1, height: '50px' },
    }
};

const imageVariants = {
    firstImage: {
        initial: { height: '65px' },
        animate: { height: '80px' },
        exit: { height: '65px' },
    },
    secondImage: {
        initial: { height: '50px' },
        animate: { height: '65px' },
        exit: { height: '80px' },
    },
    thirdImage: {
        initial: { height: '50px' },
        animate: { height: '50px' },
        exit: { height: '65px' },
    },
    fourthImage: {
        initial: { height: '35px' },
        animate: { height: '35px' },
        exit: { height: '50px' },
    }
};

const transition = { duration: 0.5, ease: "easeInOut" };

const QueueAnimation = ({ queue }) => (
    <Box display="flex" alignItems="center">
        <AnimatePresence initial={false}>
            {[0, 1, 2, 3].map((i) => (
                queue[i] && (
                    <motion.div key={`${i}`} initial="initial" animate="animate" exit="exit" transition={transition}
                        variants={i === 0 ? trackVariants.firstTrack : i === 1 ? trackVariants.secondTrack : i === 2 ? trackVariants.thirdTrack : trackVariants.fourthTrack}
                        style={{ display: 'flex', alignItems: 'center', marginRight: '16px', marginLeft: i === 3 && '16px', minWidth: `${250 - (i * 25)}px` }}>
                        <Box display="flex" alignItems="center" sx={{ marginRight: '8px' }}>
                            <motion.img src={`${queue[i].album.images[0].url}`} alt="Album cover" initial="initial" animate="animate" exit="exit"
                                variants={i === 0 ? imageVariants.firstImage : i === 1 ? imageVariants.secondImage : i === 2 ? imageVariants.thirdImage : imageVariants.fourthImage}
                                transition={transition} style={{ display: 'block' }} />
                        </Box>
                        <Box sx={{ maxWidth: '160px' }}>
                            <Typography noWrap>{queue[i].name}</Typography>
                            <Typography noWrap>{queue[i].artists.map((artist) => artist.name).join(', ')}</Typography>
                        </Box>
                    </motion.div>
                )
            ))}
        </AnimatePresence>
    </Box>
);

export default QueueAnimation;
