import React, { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import useSubscriberList from '../../utils/useSubscriberList';
import Perfumes from './Yellowraven/perfumes';
import { Box, ButtonBase, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import useIsMobile from '../../utils/useIsMobile';

const Yellowraven = ({backgroundColor = '#5D3B9E', openDialogComponent = () => {}, setSelectedComponent = () => {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useContext(AuthContext);
    const subscriberlist = useSubscriberList();
    const isMobile = useIsMobile();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const tools = [
        {
            title: 'Perfumes',
            description: 'Saved perfume recipes',
            img: 'https://i.imgur.com/NyOWU9D.png',
            component: 'perfumes'
        }
    ];
    const [selectedTool, setSelectedTool] = useState('');

    const ToolItem = ({toolObj}) => {
        const itemStyle = {
            width: '100%',
            display: 'flex', // Set display to flex
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
            textAlign: 'center',
            height: '200px',
            border: `5px solid ${colorScheme.background75}`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${toolObj['img']})`,
            borderRadius: isMobile ? '10px' : '20px',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: 'inherit',
                filter: 'brightness(50%)',
                zIndex: 1,
                borderRadius: isMobile ? '10px' : '20px'
            },
            '& > *': {
                position: 'relative',
                zIndex: 2,
                textShadow: '2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black'
            }
        };
        const HoverComponent = (props) => {
            const hoverAnimation = {
                scale: 1.025,
                transition: {
                    duration: 0.4
                }
            };
            return (
                <motion.div whileHover={!isMobile && hoverAnimation}>
                    {props.children}
                </motion.div>
            );
        };
        const handleClick = () => {
            setSelectedComponent(toolObj['component']);
        };
        return (
            <HoverComponent>
                <ButtonBase onClick={handleClick} sx={{ width: '100%', height: '100%', borderRadius: isMobile ? '10px' : '20px' }}>
                    <Box sx={itemStyle}>
                        <Typography variant='h1'>{toolObj['title']}</Typography>
                    </Box>
                </ButtonBase>
            </HoverComponent>
        );
    };

    return (
        <React.Fragment>
            {selectedTool === '' && (
                <Grid container spacing={2}>
                    {tools.map((toolObj, index) => (
                        <Grid item key={index} xs={3}>
                            <ToolItem toolObj={toolObj} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </React.Fragment>
    );
};

export default Yellowraven;