import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Grid } from '@mui/material';
import AnimatedCard from './AnimatedCard';
import { useNavigate, useLocation } from 'react-router-dom';

const AnimatedGrid = ({ items = [{backgroundImage: '', title: '', description: '', id: ''}], itemSize = 2 }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Grid container spacing={2} style={{overflowX: 'hidden'}}>
            {items.map((item, index) => (
                <AnimatedGridItem key={index} index={index} itemSize={itemSize} onClick={() => navigate(`${location.pathname}?id=${item.id}`)}>
                    <AnimatedCard backgroundImage={item['backgroundImage']} title={item['title']} description={item['description']} />
                </AnimatedGridItem>
            ))}
        </Grid>
    );
};

const AnimatedGridItem = ({ children, index, itemSize, onClick }) => {
    const animationProps = useSpring({
        from: { transform: 'translateX(100%)', opacity: 0 },
        to: { transform: 'translateX(0%)', opacity: 1 },
        delay: index * 50,
    });

    return (
        <Grid item xs={itemSize} onClick={onClick}>
            <animated.div style={animationProps}>
                {children}
            </animated.div>
        </Grid>
    );
};

export default AnimatedGrid;