import React, { useState, useEffect, useCallback, useContext } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import useChatlogs from '../../utils/useChatlogs';
import { Box, Button, Grid, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, colors } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ColorModeContext } from '../../theme';
import useIsMobile from '../../utils/useIsMobile';

const ChatLogs = ({ backgroundColor = '#5D3B9E' }) => {
    const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
    const chatlogData = useChatlogs(); // {"user1": ["message1", "message2"], ...}
    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [searchText, setSearchText] = useState('');
    const [messageSearchText, setMessageSearchText] = useState('');
    const [userMessages, setUserMessages] = useState({});
    const [limit, setLimit] = useState(25);
    const rowsPerPageOptions = [25, 50, 75, 100];
    const [offset, setOffset] = useState(0);
    const isMobile = useIsMobile();
    const scrollableContent = {
      maxHeight: '65vh',
      height: '60vh',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
          display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const muiInputStyle = {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.text.primary} !important`
        },
        "& .MuiInputBase-input": {
            color: theme.palette.text.primary
        },
        "& .MuiInputLabel-root": {
            color: theme.palette.text.primary
        }
    };
    const columns = [
        { field: "displayname", headerName: "Display name", flex: 1 },
        { field: "messagestotal", headerName: "Messages in chat", flex: 1 },
        {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => {
            const user = params.row.displayname;
            return (
              <Box sx={{maxWidth: '400px', minWidth: '100px', overflow: 'auto', '& .MuiButtonBase-root': {backgroundColor, color: theme.palette.text.primary}, '& .MuiButtonBase-root:hover': {backgroundColor}}}>
                <Button fullWidth size='small' variant='text' onClick={() => {
                    showUserMessagesHandler(user);
                    setMessageSearchText('');
                    }}>Chat Messages</Button>
              </Box>
            );
          },
        },
    ];

    useEffect(() => {
        const tmpArr = [];
        const tmpMsgObj = {};
        for (let user of Object.keys(chatlogData)) {
          const tmpObj = {};
          tmpMsgObj[user] = chatlogData[user][0];
          tmpObj["id"] = user;
          tmpObj["displayname"] = user;
          tmpObj["messagestotal"] = chatlogData[user][0].length;
          tmpArr.push(tmpObj);
        }
        setUserMessages(tmpMsgObj);
        setUserData(tmpArr);
    }, [chatlogData]);

    const showUserMessagesHandler = (user) => {
        setSelectedUser(user);
    };
    const handleRowsPerPageChange = (value) => {
    setLimit(value);
    setOffset(0);
    };
    const handlePageChange = (value) => {
    setOffset(value);
    };
    const handleSearchTextChange = useCallback((event) => {
        setSearchText(event.target.value);
    }, []);
    const handleMessageSearchTextChange = useCallback((event) => {
        setMessageSearchText(event.target.value);
    }, []);
    const filterData = useCallback((data) => {
        if (searchText === '') {
            return data;
        }
        return data.filter((row) => row.displayname.toLowerCase().includes(searchText.toLowerCase()));
    }, [searchText]);
    const filterMessageData = useCallback((data) => {
        if (messageSearchText === '') {
            return data;
        }
        return data.filter((msg) => msg.toLowerCase().includes(messageSearchText.toLowerCase()));
    }, [messageSearchText]);


    return (
        <DialogComponent footer='View users who have chatted in your stream and their messages' maxHeight='85vh' minWidth='65vw'>
              <Grid container spacing={2} sx={scrollableContent}>
                <Grid item xs={isMobile ? 12 : 6} sx={{height: isMobile ? '50vh' : '60vh'}}>
                  {userData && (
                    <React.Fragment>
                      <TextField sx={muiInputStyle} placeholder='Search Filter' variant="outlined" value={searchText} 
                        onChange={handleSearchTextChange} fullWidth margin="dense" color="primary" />
                      <DataGrid rows={filterData(userData)} columns={columns} onPageChange={(v) => handlePageChange(v)} onPageSizeChange={(v) => handleRowsPerPageChange(v)}
                        pageSize={limit} page={offset} rowCount={userData.length} pagination rowsPerPageOptions={rowsPerPageOptions} density="compact"
                        sx={{maxHeight: isMobile ? '75vh' : '45vh', fontSize: 14, color: theme.palette.text.primary, marginTop: '8px', '& .MuiDataGrid-virtualScroller': {...scrollableContent}}} />
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={isMobile ? 12 : 6} sx={{marginTop: isMobile && '64px'}}>
                  <React.Fragment>
                    <TextField sx={muiInputStyle} placeholder={`Filter messages by ${selectedUser}`} variant="outlined" fullWidth margin="dense"
                        value={messageSearchText} onChange={handleMessageSearchTextChange} />
                      <TableContainer sx={{...scrollableContent, marginTop: '8px', maxHeight: '45vh'}}>
                        <Table sx={{backgroundColor}}>
                          <TableHead>
                            {selectedUser &&
                              filterMessageData(userMessages[selectedUser]).map((msg) => (
                                <TableRow>
                                  <TableCell align="left">
                                    <Typography sx={{color: theme.palette.text.primary}} key={Math.random()}>{msg}</Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableHead>
                        </Table>
                      </TableContainer>
                  </React.Fragment>
                </Grid>
              </Grid>
        </DialogComponent>
    );
};

export default ChatLogs;