import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const CustomSnackbar = ({ open, autoHideDuration, severity, snackText, setSnackAlert }) => {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackAlert({"open":false,"severity":"success","text":"","autoHideDuration":5000});
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {snackText}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;