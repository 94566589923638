import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { InputTextarea } from 'primereact/inputtextarea';
import { Grid, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { useUser } from '../../context/UserContext';

const RedeemCode = ({backgroundColor = '#5D3B9E', footer = '', value = '', prefix = '', setOpenDialog = () => {}}) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [tmpValue, setTmpValue] = useState(value);
    const [error, setError] = useState('');
    const [successText, setSuccessText] = useState('');
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const handleInputChange = (e) => {
        setTmpValue(e.target.value);
    };
    const submitHandler = async () => {
        setError('');
        try {
            const response = await fetch(`${apiUrl}/user/redeemcode?token=${authCtx.token}&code=${tmpValue}`);
            const data = await response.json();
            if (response.ok) {
                setSuccessText('Code redeemed successfully! Please wait a few minutes and refresh the page.');
            } else {
                setError(data.error);
            }
        } catch (error) {
            setError(error.message);
        }
    };

  return (
    <DialogComponent footer={footer} maxHeight='60vh'>
        <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
            <Grid item xs={12}>
                <h2 style={{marginTop: '0px', marginBottom: '0px'}}>Code</h2>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <InputTextarea autoResize style={{width: '100%', backgroundColor: colorScheme.background50}} value={`${prefix}${tmpValue}`} onChange={handleInputChange} />
            </Grid>
            {error !== '' && (
                <Grid item xs={12}>
                    <i style={{color: 'red'}}>{error}</i>
                </Grid>
            )}
            {successText !== '' && (
                <Grid item xs={12}>
                    <i style={{color: 'green'}}>{successText}</i>
                </Grid>
            )}
            <Grid item xs={12}>
                <Button label='Submit' severity='success' size='small' style={{width: '25%'}} onClick={submitHandler} />
            </Grid>
        </Grid>
    </DialogComponent>
  );
};

export default RedeemCode;