import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { spotify } from '../mockData';
import { Box, Grid, Typography } from '@mui/material';
import ViewingAlbum from './ViewingAlbum';

const Albums = ({ userSettings = {}, playTrack = async ({}) => {}, playerState = {}, togglePlayPause = () => {}, 
                    addToQueue = (uri) => {}, nextTrack = () => {} }) => {
    const [loading, setLoading] = useState(false);
    const [viewingAlbum, setViewingAlbum] = useState({});
    const [error, setError] = useState('');
    const devMode = false;
    const mockData = spotify;
    const location = useLocation();
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    useEffect(() => {
        if (id && 'spotify' in userSettings && !devMode) {
            const getAlbum = async () => {
                setLoading(true);
                const albumResponse = await fetch(`${apiUrl}/spotify/getalbum?q=${id}&refreshtoken=${userSettings['spotify']['refreshToken']}`);
                if (albumResponse.ok) {
                    const albumRes = await albumResponse.json();
                    setViewingAlbum(albumRes);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const albumRes = await albumResponse.json();
                    setError(`Error loading playlist: ${albumResponse.statusText} - ${albumRes.error}`);
                }
            };
            getAlbum();
        } else if (id && devMode) {
            setViewingAlbum(mockData.album);
            setLoading(false);
        }
    }, [id, userSettings]);

    return (
        <React.Fragment>
            <Box sx={{ margin: '16px' }}>
                {id && Object.keys(viewingAlbum).length > 0 ? (
                    <ViewingAlbum album={viewingAlbum} loading={loading} setLoading={setLoading} playTrack={playTrack} playerState={playerState}
                        togglePlayPause={togglePlayPause} addToQueue={addToQueue} nextTrack={nextTrack} />
                ) : (
                    <React.Fragment>
                        {error !== '' && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h4'>{error}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Box>
        </React.Fragment>
    );
};

export default Albums;