import { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";

const useLatestStreams = () => {
  const [filenames, setFilenames] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const authCtx = useUser();

  useEffect(() => {
    fetch(`${apiUrl}/user/lateststreams?token=${authCtx.token}`)
      .then((response) => response.json())
      .then((res) => {
        setFilenames(res["filenames"]);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.message || "An error occurred.");
        setFilenames({filenames: {}});
        setLoading(false);
      });
  }, [apiUrl, authCtx.token]);

  return { filenames, loading, error };
};

export default useLatestStreams;