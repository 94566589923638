import React from 'react';
import './PlaybackPanel.css';
import { Box, Divider, Typography } from '@mui/material';

const TrackInfo = ({ name, artists }) => (
    <Box ml={2} sx={{ textAlign: 'left', width: '250px' }}>
        <Typography variant='h6' noWrap>{name}</Typography>
        <Typography variant='caption' noWrap>{artists.map((artist) => artist.name).join(', ')}</Typography>
        <Divider sx={{ marginTop: '8px' }} />
    </Box>
);

export default TrackInfo;