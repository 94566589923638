import React from 'react';
import { Menubar } from 'primereact/menubar';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useUserSettings } from '../../store/usersettings-context';
import { Button, Typography } from '@mui/material';
import 'primeicons/primeicons.css';
import useLatestStreams from '../../utils/useLatestStreams';
import useStreamers from '../../utils/useStreamers';
import useIsSubscribed from '../../utils/useIsSubscribed';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { useUser } from '../../context/UserContext';
import './Topbar.css';

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Format timestamp as a string
};

const Topbar = ({backgroundColor = '#5D3B9E', setIsBlur = () => {}, openDialogComponent = () => {}, setMainViewComponent = () => {}, setMainViewHeader = () => {}, 
    userSettings = {}, update = () => {}}) => {
    const isSmall = useWindowSizeThreshold(540, true);
    const isMedium = useWindowSizeThreshold(1040, true);
    const isMobile = useIsMobile();
    const authCtx = useUser();
    const { filenames, loading } = useLatestStreams();
    const streamers = useStreamers();
    const isSubscribed = useIsSubscribed();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        title: {
            position: 'absolute',
            top: isSmall ? '30px' : '24px',
            left: isSmall ? '35vw' : '25vw',
            fontSize: isSmall ? '1.25rem' : '1.5rem',
            fontWeight: 700,
            color: '#FFFFFF',
            textShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            letterSpacing: '0.1em',
            background: '-webkit-linear-gradient(45deg, #9d65ff, #8461e8)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '1rem',
            userSelect: 'none'
        },
        topbarStyle: {
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 999,
            opacity: '1',
            background: colorScheme.background25,
            '--background50-color': colorScheme.background50,
        },
        afterElementStyle: {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: isMobile ? '0px' : '200px', // Conditional left offset
            width: isMobile ? '100%' : 'calc(100% - 200px)', // Conditional width
            borderBottom: `2px solid var(--background50-color)`,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)', // Box shadow
        }
    };
    const MenuItemIcon = ({icon, onClick = () => {}}) => {
        return (
            <div onClick={onClick} style={{marginTop: '6px', marginRight: '8px'}}>
                {icon}
            </div>
        );
    };
    const getBotRunningStatus = () => {
        if (Object.keys(streamers).includes(authCtx.username)) {
            return 'Online';
        } else {
            return 'Offline';
        }
    };
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const items = [
        // USER
        (authCtx.editor === false) ? {
            label: authCtx.username,
            icon: <MenuItemIcon icon={<AccountCircleOutlinedIcon />} />,
            items: [
                {
                    label: `Subscription: ${isSubscribed ? 'Active' : 'Inactive'}`,
                    icon: `pi pi-fw ${isSubscribed ? 'pi-circle-fill' : 'pi-circle'}`,
                },
                {
                    label: `Bot Status: ${getBotRunningStatus()}`,
                    icon: `pi pi-fw ${getBotRunningStatus() === 'Offline' ? 'pi-circle' : 'pi-circle-fill'}`,
                },
                {
                    label: 'Stream Stats',
                    icon: 'pi pi-fw pi-chart-line',
                    command: () => openDialogComponent('streamstats'),
                    items: filenames ? Object.keys(filenames).map((timestamp) => ({
                        label: formatTimestamp(filenames[timestamp]['startedAt']),
                        command: () => {
                            openDialogComponent('streamstats', formatTimestamp(filenames[timestamp]['startedAt']));
                        }
                    })) : []
                },
                {
                    label: 'Chatlogs',
                    icon: 'pi pi-fw pi-envelope',
                    command: () => openDialogComponent('chatlogs')
                },
                {
                    label: 'Editors',
                    icon: 'pi pi-fw pi-user',
                    command: () => openDialogComponent('editors')
                }
            ]
        } : null,
        // GENERAL
        {
            label: 'General',
            icon: <MenuItemIcon icon={<SettingsOutlinedIcon />} />,
            items: [
                ('botAliases' in userSettings) ? {
                    label: 'Aliases',
                    items: userSettings?.botAliases.length > 0 ? 
                    [
                    ...userSettings.botAliases.map((a) => ({
                        label: a.toUpperCase(),
                        command: () => openDialogComponent('aliases')
                    }))]
                    : [{label: 'No aliases', command: () => openDialogComponent('aliases')}]
                } : null,
                ('blockedUsers' in userSettings) ? {
                    label: 'Ignored Users',
                    items: userSettings?.blockedUsers.length > 0 ? userSettings.blockedUsers.map((b) => ({
                        label: b.toUpperCase(),
                        command: () => openDialogComponent('ignoredusers')
                    })) : [{label: 'No users', command: () => openDialogComponent('ignoredusers')}]
                } : null,
                // Separator between 'Aliases'/'Ignored Users' and 'Exit Message'/'Context-Aware Tuning'
                (('botAliases' in userSettings) || ('blockedUsers' in userSettings)) ? {
                    separator: true
                } : null,
                ('exitMessage' in userSettings) ? {
                    label: 'Exit Message',
                    command: () => openDialogComponent('exitmessage')
                } : null,
                ('openAISettings' in userSettings) ? (
                    ('customPrompt' in userSettings['openAISettings']) ? {
                        label: 'Context-Aware Tuning',
                        command: () => openDialogComponent('usercontext')
                    } : null
                ) : null,
                // Separator between 'Exit Message'/'Context-Aware Tuning' and 'Buffer Timer'
                (('exitMessage' in userSettings) || ('openAISettings' in userSettings && 'customPrompt' in userSettings['openAISettings'])) ? {
                    separator: true
                } : null,
                ('messageInterval' in userSettings) ? {
                    label: `Buffer Timer (${parseInt(userSettings.messageInterval)/1000} sec)`,
                    command: () => openDialogComponent('buffertimer')
                } : null,
                // Separator before 'Emotes'
                ('messageInterval' in userSettings) ? {
                    separator: true
                } : null,
                ('useEmotes' in userSettings || 'emoteFrequency' in userSettings) ? {
                    label: 'Emotes',
                    items: [
                        ('useEmotes' in userSettings) ? {
                            label: 'Use Emotes',
                            command: () => update({useEmotes: !userSettings.useEmotes}),
                            icon: userSettings.useEmotes && 'pi pi-fw pi-check'
                        } : null,
                        ('emoteFrequency' in userSettings) ? {
                            label: 'Adjust Frequency',
                            command: () => openDialogComponent('emotefrequency')
                        } : null
                    ].filter(Boolean)
                } : null,
                // Separator before 'Announce Personality Switch'/'Short Replies'/'User Profiling'
                ('openAISettings' in userSettings) ? {
                    separator: true
                } : null,
                ('openAISettings' in userSettings) && (
                    ('announceSwitch' in userSettings['openAISettings']) ? {
                        label: 'Announce Personality Switch',
                        icon: userSettings.openAISettings.announceSwitch && 'pi pi-fw pi-check',
                        command: () => update({openAISettings: {...userSettings.openAISettings, announceSwitch: !userSettings.openAISettings.announceSwitch}})
                } : null),
                ('openAISettings' in userSettings) && (
                    ('shortReplies' in userSettings['openAISettings']) ? {
                        label: 'Short Replies',
                        icon: userSettings.openAISettings.shortReplies && 'pi pi-fw pi-check',
                        command: () => update({openAISettings: {...userSettings.openAISettings, shortReplies: !userSettings.openAISettings.shortReplies}})
                } : null),
                ('openAISettings' in userSettings) && (
                    ('profiling' in userSettings['openAISettings']) ? {
                        label: 'User Profiling',
                        icon: userSettings.openAISettings.profiling && 'pi pi-fw pi-check',
                        command: () => update({openAISettings: {...userSettings.openAISettings, profiling: !userSettings.openAISettings.profiling}})
                } : null)
            ].filter(Boolean)
        },
        // COMMANDS
        {
            label: 'Commands',
            icon: <MenuItemIcon icon={<CodeOutlinedIcon />} />,
            items: [
                ('commandDelimiter' in userSettings) ? {
                    label: 'Command Identifier',
                    items: [
                        {
                            label: '!command',
                            icon: userSettings.commandDelimiter === '!' && 'pi pi-fw pi-check',
                            command: () => update({commandDelimiter: '!'})
                        },
                        {
                            label: '#command',
                            icon: userSettings.commandDelimiter === '#' && 'pi pi-fw pi-check',
                            command: () => update({commandDelimiter: '#'})
                        },
                        {
                            label: '$command',
                            icon: userSettings.commandDelimiter === '$' && 'pi pi-fw pi-check',
                            command: () => update({commandDelimiter: '$'})
                        }
                    ]
                } : null,
                ('adminLevel' in userSettings) ? {
                    label: 'Mod Commands',
                    items: [
                        {
                            label: 'Broadcaster',
                            icon: userSettings.adminLevel === 'broadcaster' && 'pi pi-fw pi-check',
                            command: () => update({adminLevel: 'broadcaster'})
                        },
                        {
                            label: 'Broadcaster and moderators',
                            icon: userSettings.adminLevel === 'moderator' && 'pi pi-fw pi-check',
                            command: () => update({adminLevel: 'moderator'})
                        },
                        {
                            label: 'Broadcaster, moderators and VIPs',
                            icon: userSettings.adminLevel === 'vip' && 'pi pi-fw pi-check',
                            command: () => update({adminLevel: 'vip'})
                        }
                    ]
                } : null,
                ('disabledCommands' in userSettings) ? {
                    label: 'System Commands',
                    command: () => openDialogComponent('systemcommands')
                } : null
            ].filter(Boolean)
        },
        // INTEGRATIONS
        {
            label: 'Integrations',
            icon: <MenuItemIcon icon={<IntegrationInstructionsIcon />} />,
            items: [
                ('modData' in userSettings) ? (
                    ('twitch' in userSettings['modData']) ? {
                        label: 'Twitch',
                        icon: <MenuItemIcon icon={<LiveTvOutlinedIcon />} />,
                        items: ((userSettings['modData'] && userSettings['modData']['twitch'] && userSettings['modData']['twitch']['refresh_token']) || authCtx.editor) ? [
                            {
                                label: 'Viewer Rewards',
                                items: [
                                    {
                                        label: 'Personality Redeems',
                                        command: () => openDialogComponent('twitchpersonalityrewards')
                                    }
                                ]
                            },
                            {
                                label: 'Polls',
                                items: [
                                    {
                                        label: `Poll Length: ${userSettings?.modData?.twitch?.commands?.create_poll?.duration !== undefined ? formatTime(userSettings.modData.twitch.commands.create_poll.duration) : formatTime(60)}`,
                                        command: () => openDialogComponent('twitchpolllength')
                                    },
                                    {
                                        label: 'Poll Chat Command',
                                        command: () => openDialogComponent('twitchpollcommand')
                                    }
                                ]
                            },
                            {
                                label: 'Predictions',
                                items: [
                                    {
                                        label: `Prediction Window: ${userSettings?.modData?.twitch?.commands?.create_prediction?.prediction_window !== undefined ? formatTime(userSettings.modData.twitch.commands.create_prediction.prediction_window) : formatTime(60)}`,
                                        command: () => openDialogComponent('twitchpredictionlength')
                                    },
                                    {
                                        label: 'Prediction Chat Command',
                                        command: () => openDialogComponent('twitchpredictioncommand')
                                    },
                                    {
                                        label: 'End Prediction Chat Command',
                                        command: () => openDialogComponent('twitchendpredictioncommand')
                                    }
                                ]
                            }
                        ] : [
                            {
                                label: 'Authorization needed'
                            }
                        ]
                    } : null
                ) : null,
                ('spotify' in userSettings) ? {
                    label: 'Spotify',
                    icon: <MenuItemIcon icon={<MusicNoteOutlinedIcon />} />,
                    items: ((userSettings['spotify'] && userSettings['spotify']['refreshToken']) || authCtx.editor) ? [
                        {
                            label: 'Announce New Song',
                            items: [
                                {
                                    label: 'Requested Songs',
                                    icon: userSettings.spotify.nowPlayingRequested && 'pi pi-fw pi-check',
                                    command: () => update({spotify: {...userSettings.spotify, nowPlayingRequested: !userSettings.spotify.nowPlayingRequested}})
                                },
                                {
                                    label: 'All Songs',
                                    icon: userSettings.spotify.nowPlayingAll && 'pi pi-fw pi-check',
                                    command: () => update({spotify: {...userSettings.spotify, nowPlayingAll: !userSettings.spotify.nowPlayingAll}})
                                }
                            ]
                        },
                        {
                            label: 'Restrictions',
                            items: [
                                {
                                    label: 'Allow Explicit Lyrics',
                                    icon: userSettings.spotify.allowExplicit && 'pi pi-fw pi-check',
                                    command: () => update({spotify: {...userSettings.spotify, allowExplicit: !userSettings.spotify.allowExplicit}})
                                },
                                {
                                    label: 'Track Length',
                                    items: [
                                        {
                                            label: 'Minimum Length',
                                            command: () => openDialogComponent('spotifymintracklength')
                                        },
                                        {
                                            label: 'Maximum Length',
                                            command: () => openDialogComponent('spotifymaxtracklength')
                                        }
                                    ]
                                },
                                {
                                    label: 'Blocked Tracks',
                                    items: userSettings.spotify.blockedTracks.length > 0 ? (userSettings.spotify.blockedTracks.map((o) => (
                                        {
                                            label: `${o['name']} by ${o['artist']}`,
                                            command: () => openDialogComponent('spotifyBlockedTracks')
                                        }
                                    ))) : [
                                        {
                                            label: 'No blocked tracks',
                                            command: () => openDialogComponent('spotifyBlockedTracks')
                                        }
                                    ]
                                },
                                {
                                    label: 'Blocked Artists',
                                    items: userSettings.spotify.blockedArtists.length > 0 ? (userSettings.spotify.blockedArtists.map((o) => (
                                        {
                                            label: `${o['name']}`,
                                            command: () => openDialogComponent('spotifyBlockedArtists')
                                        }
                                    ))) : [
                                        {
                                            label: 'No blocked artists',
                                            command: () => openDialogComponent('spotifyBlockedArtists')
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Viewer Rewards',
                            items: !!userSettings.spotify.rewards.songRequest.id !== '' ? [
                                {
                                    label: 'Song request reward enabled',
                                    icon: 'pi pi-fw pi-check'
                                }
                            ] : [
                                {
                                    label: 'Song request reward not enabled'
                                }
                            ]
                        },
                        {
                            separator: true
                        },
                        {
                            label: 'Start Playing On Redeem',
                            // Check if `autoStart` exists before evaluating its truthiness
                            icon: userSettings.spotify?.autoStart ? 'pi pi-fw pi-check' : '',
                            command: () => update({spotify: {...userSettings.spotify, autoStart: !userSettings.spotify.autoStart}})
                        },
                        {
                            label: 'Song Analysis',
                            // Check if `analysis` exists in `modData` before evaluating its truthiness
                            icon: userSettings.spotify?.analysis ? 'pi pi-fw pi-check' : '',
                            command: () => update({spotify: {...userSettings.spotify, analysis: !userSettings.spotify.analysis}})
                        },
                        {
                            label: 'GameGroove',
                            // Check if `gameGroove` exists before evaluating its truthiness
                            icon: userSettings.spotify?.gameGroove ? 'pi pi-fw pi-check' : '',
                            command: () => update({spotify: {...userSettings.spotify, gameGroove: !userSettings.spotify.gameGroove}})
                        },
                        {
                            separator: true
                        },
                        {
                            label: 'Track Added Message',
                            command: () => openDialogComponent('spotifytrackconfirmationmsg')
                        }
                    ] : [
                        {
                            label: 'Authorization needed'
                        }
                    ]
                } : null
            ].filter(Boolean)
        },
        // ABOUT
        {
            label: 'About',
            icon: <MenuItemIcon icon={<InfoOutlinedIcon />} />,
            items: [
                {
                    label: 'Release Notes',
                    command: () => openDialogComponent('releasenotes')
                },
                {
                    label: 'FAQ',
                    command: () => openDialogComponent('faq')
                },
                {
                    label: 'Discord',
                    command: () => window.open('https://discord.gg/HJv5d7bEea', '_blank')
                },
                {
                    label: 'Redeem Code',
                    command: () => openDialogComponent('redeemcode')
                }
            ].filter(Boolean)
        }
    ].filter(Boolean);

    const start = (
        <div onClick={() => setMainViewComponent('dashboard')} style={{ cursor: 'pointer' }}>
            <img alt="logo" src={authCtx.profile_url} height="50" width="50" style={{ marginTop: '6px', marginLeft: '16px', marginRight: '16px', borderRadius: '25px' }} />
        </div>
    );

    const end = (
        <React.Fragment>
            {authCtx.isAdmin && !isMobile && (
                <Button variant='text' size='large' color='info' endIcon={<LockOpenIcon />} sx={{marginRight: '8px'}} onClick={() => {
                    setMainViewComponent('admin');
                    setMainViewHeader('Admin');
                }}>Admin</Button>
            )}
            <Button endIcon={<MenuItemIcon icon={<ExitToAppOutlinedIcon />} />} 
                variant='text' size='large' sx={{color: '#eeeeee'}} onClick={authCtx.logout}>{isMedium ? '' : 'Log Out'}</Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Menubar model={items} start={start} end={end} style={styles.topbarStyle} />
            {isMobile && <Typography sx={styles.title}>{authCtx.username}</Typography>}
        </React.Fragment>
    );
};

export default Topbar;