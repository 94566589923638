import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { InputTextarea } from 'primereact/inputtextarea';
import { Grid, Typography } from '@mui/material';

const TextInputDialog = ({backgroundColor = '#5D3B9E', footer = '', value = '', setValue = () => {}, maxCharacters = 1000, prefix = '', placeholder = ''}) => {
    const [tmpValue, setTmpValue] = useState(value);
    const debounceDelay = 500;
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setValue(tmpValue);
        }, debounceDelay);
        return () => clearTimeout(handler);
    }, [tmpValue, setValue, debounceDelay]);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= maxCharacters) {
            setTmpValue(inputValue.replace(prefix, ''));
        }
    };

  return (
    <DialogComponent footer={footer} maxHeight='60vh'>
        <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <InputTextarea autoResize style={{width: '100%', backgroundColor: colorScheme.background50}} value={`${prefix}${tmpValue}`} onChange={handleInputChange}
                    placeholder={placeholder} />
            </Grid>
            <Grid item xs={12}>
                <Typography textAlign='right'>{`${tmpValue.length}/${maxCharacters}`}</Typography>
            </Grid>
        </Grid>
    </DialogComponent>
  );
};

export default TextInputDialog;