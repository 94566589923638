import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Left from './left';
import useIsMobile from '../../utils/useIsMobile';

const twitchTheme = createTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});
const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(https://images.unsplash.com/photo-1619190324856-af3f6eb55601?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  filter: 'blur(10px) brightness(0.2)', // Add brightness filter with value between 0 and 1
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: -1,
}));

const Landing = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <React.Fragment>
      <BackgroundContainer />
      <Container component="main" maxWidth="l">
        <ThemeProvider theme={twitchTheme}>
          {!isMobile ? (
            <Left isMobile={isMobile} />
          ) : (
            <Left isMobile={isMobile} />
          )}
        </ThemeProvider>
      </Container>
    </React.Fragment>
  );
};

export default Landing;