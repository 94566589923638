import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import DialogComponent from '../../../beta/layouts/DialogComponent';
import useIngredientTags from '../utils/useIngredientTags';

const ViewPerfumeRecipe = ({backgroundColor = '#5D3B9E', footer = '', setOpenDialog = () => {}, setIsBlur = () => {}, recipe = {}}) => {
    const tags = useIngredientTags();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    return (
        <DialogComponent footer={footer} minHeight='70vh' maxHeight='80vh' minWidth='540px'>
            <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>{recipe['title']}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{recipe['description']}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>Top Notes</Typography>
                </Grid>
                {recipe['topnotes'].map((noteObj) => (
                    <React.Fragment>
                        <Grid item xs={4}>
                            <Typography>{noteObj['title']}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{noteObj['amount']}</Typography>
                        </Grid>
                    </React.Fragment>
                ))}
                <Grid item xs={12}>
                    <Typography variant='h5'>Middle Notes</Typography>
                </Grid>
                {recipe['middlenotes'].map((noteObj) => (
                    <React.Fragment>
                        <Grid item xs={4}>
                            <Typography>{noteObj['title']}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{noteObj['amount']}</Typography>
                        </Grid>
                    </React.Fragment>
                ))}
                <Grid item xs={12}>
                    <Typography variant='h5'>Base Notes</Typography>
                </Grid>
                {recipe['basenotes'].map((noteObj) => (
                    <React.Fragment>
                        <Grid item xs={4}>
                            <Typography>{noteObj['title']}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{noteObj['amount']}</Typography>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </DialogComponent>
    );
};

export default ViewPerfumeRecipe;