import React, { useContext } from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { ColorModeContext } from '../theme';

const Header = ({title, subtitle, ...props}) => {
    const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.between('xs', 'lg'));

    return (
        <Box mb={isMobile ? "5px" : "30px"} sx={{backgroundColor: props.backgroundColor}}>
            <Typography variant={isMobile ? "h4" : "h2"} color={theme.palette.text.primary} fontWeight="bold"
                sx={{mb: isMobile ? "0" : "5px", display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>{title}</Typography>
            <Typography variant={isMobile ? "h6" : "h5"} color={theme.palette.text.primary} 
                sx={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>{subtitle}</Typography>
        </Box>
    );
};

export default Header;