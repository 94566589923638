import { useContext } from "react";
import { ColorModeContext } from "../theme";
import { Box } from "@mui/material";

const OverrideStyles = ({children}) => {
    const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);

    const muiOverrideStyles = {
        "& .MuiSlider-root": {
            color: theme.palette.secondary.main
        },
        "& .MuiSlider-thumb, .MuiSwitch-thumb": {
            color: theme.palette.secondary.light
        },
        "& .MuiSwitch-track, .MuiButton-containedSecondary": {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: theme.palette.secondary.text
        },
        "& .MuiButton-containedSuccess": {
            color: theme.palette.secondary.text
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.secondary.main} !important`
        },
        "& .MuiOutlinedInput-root:hover": {
            borderColor: `${theme.palette.secondary.light} !important`
        },
        "& .MuiInputBase-input": {
            color: theme.palette.text.primary
        },
        "& .MuiInputLabel-root": {
            color: theme.palette.text.primary
        }
    };

    return (
        <Box sx={muiOverrideStyles}>{children}</Box>
    );
};

export default OverrideStyles;