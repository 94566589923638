import { Box, Grid } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useRef, useState } from 'react';
import './startNewGame.css';
import useThemeSuggestions from '../../../utils/useThemeSuggestions';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import useAllStories from '../../../utils/useAllStories';

const StartNewGame = ({backgroundColor = '#5D3B9E', setStoryObject = () => {}, initializeStory = ({title, description}) => {}}) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { suggestions, refreshSuggestions } = useThemeSuggestions(true);
    const { stories, refreshStories } = useAllStories(true);
    const storyRefs = useRef([]);

    const handleTitleChange = (e) => {
        if (e.target.value.length <= 50) {
            setTitle(e.target.value);
        }
    };

    const handleDescriptionChange = (e) => {
        if (e.target.value.length <= 1000) {
            setDescription(e.target.value);
        }
    };

    const handleSuggestionClick = (item) => {
        initializeStory({title: item.title, description: item.description});
    };

    const isLoading = () => {
        if (suggestions && stories) {
            return suggestions.length === 0;
        } else {
            return true;
        }
    };

    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const styles = {
        text: {
            backgroundColor: colorScheme.background50,
            width: '100%'
        },
        panelView: {
            '--panels-background': colorScheme.background25,
            '--panel-highlighted': colorScheme.background50,
            '--panel-tab': colorScheme.background25,
            '--panel-tab-hover': colorScheme.background50,
        },
        suggestionItem: {
            backgroundColor: colorScheme.background25,
            marginTop: '16px',
            borderRadius: '10px',
            border: `2px solid ${colorScheme.background50}`,
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            padding: '16px'
        },
        suggestionItemHover: {
            backgroundColor: colorScheme.background50
        },
        suggestionContainer: {
        },
        suggestionText: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    };

    const titlePlaceholder = 'Your story title';
    const descriptionPlaceholder = 'Describe your desired story.';

    // Function to handle mouse hover effect to scroll the text to the bottom with smooth transition
    const handleMouseOver = (index, event) => {
        if (storyRefs.current[index]) {
            const element = storyRefs.current[index];
            element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' }); // Scroll to bottom smoothly
        }
        // Change the background color on hover
        event.currentTarget.style.backgroundColor = colorScheme.background50;
    };

    // Function to handle mouse out event to reset scroll smoothly and revert background color
    const handleMouseOut = (index, event) => {
        if (storyRefs.current[index]) {
            const element = storyRefs.current[index];
            element.scrollTo({ top: 0, behavior: 'smooth' }); // Reset scroll to top smoothly
        }
        // Revert the background color on mouse out
        event.currentTarget.style.backgroundColor = colorScheme.background25;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <h1>Start New Story</h1>
            </Grid>
            <Grid item xs={12}>
                <TabView style={styles.panelView}>
                    <TabPanel header="Create Your Own">
                        <Box sx={{height: '55vh'}}>
                            <Grid item xs={12}>
                                <h3>Title (Optional)</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <InputText style={styles.text} value={title} onChange={handleTitleChange} placeholder={titlePlaceholder} maxLength={50} />
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Description (Required)</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <InputTextarea autoResize style={{...styles.text, minHeight: '25vh'}} value={description} onChange={handleDescriptionChange} placeholder={descriptionPlaceholder} maxLength={1000} />
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: '32px'}}>
                                <Button style={{width: '25%'}} size='small' label='Generate Story' severity='success' disabled={description.length === 0 || description.length > 1000}
                                    onClick={() => initializeStory({title, description})} />
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel header="Today's Stories">
                        <Box sx={{height: '57vh', overflowY: 'auto'}}>
                            {isLoading() ? (
                                <Box>
                                    <h2 style={{textAlign: 'center', width: '100%'}}>Loading today's stories...</h2>
                                </Box>
                            ) : (
                                <Grid container sx={styles.suggestionContainer}>
                                    {suggestions.map((item, index) => (
                                        <Grid key={index} item xs={12}>
                                            <Box sx={styles.suggestionItem} onClick={() => handleSuggestionClick(item)} onMouseOver={(e) => e.currentTarget.style.backgroundColor = colorScheme.background50}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorScheme.background25}>
                                                <h3 style={styles.suggestionText}>{item.title}</h3>
                                                <p style={styles.suggestionText}>{item.description}</p>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel header="Browse">
                        <Box sx={{height: '57vh', overflowY: 'auto'}}>
                            {isLoading() ? (
                                <Box>
                                    <h2 style={{textAlign: 'center', width: '100%'}}>Loading stories...</h2>
                                </Box>
                            ) : (
                                <Grid container sx={styles.suggestionContainer} spacing={4}>
                                    {stories.map((item, index) => (
                                        <Grid key={index} item xs={6}>
                                            <Box ref={(el) => storyRefs.current[index] = el} // Store a reference to each story item
                                                sx={{ ...styles.suggestionItem, height: '300px', overflowY: 'hidden' }}
                                                onMouseOver={(event) => handleMouseOver(index, event)}
                                                onMouseOut={(event) => handleMouseOut(index, event)}
                                                onClick={() => handleSuggestionClick(item)}>
                                                <h3 style={{ ...styles.suggestionText, marginTop: '0px', marginBottom: '0px' }}>{item.title}</h3>
                                                <i style={styles.suggestionText}>{item.genre}</i>
                                                <p style={styles.suggestionText}>{item.description}</p>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    </TabPanel>
                </TabView>
            </Grid>
        </Grid>
    );
};

export default StartNewGame;