import React, { useEffect, useState } from 'react';
import { useUserSettings } from '../store/usersettings-context';
import './index.css';
import MainLayout from './MainViews/MainLayout';

const SpotifyPlayer = () => {
    const [settingsReady, setSettingsReady] = useState(false);
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchAccessToken = async (refreshToken) => {
        const accessTokenResponse = await fetch(`${apiUrl}/spotify/getaccesstoken?refreshtoken=${refreshToken}`);
        const accessTokenRes = await accessTokenResponse.json();
        console.log(accessTokenRes);
        if (accessTokenResponse.ok) {
            return accessTokenRes.accessToken;
        }
    };

    useEffect(() => {
        if (userSettings['spotify'] && !settingsReady) {
            if (userSettings['spotify']['refreshToken']) {
                if (userSettings['spotify']['refreshToken'] !== '') {
                    fetchAccessToken(userSettings['spotify']['refreshToken']).then((accessToken) => {
                        setSettingsReady(true);
                        update({spotify: {...userSettings['spotify'], accessToken}});
                    });
                }
            }
        }
    }, [userSettings]);

    return (
        <React.Fragment>
            {settingsReady ? (
                <MainLayout userSettings={userSettings} />
            ) : (
                <p>Loading layout...</p>
            )}
        </React.Fragment>
    );
};

export default SpotifyPlayer;