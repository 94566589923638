import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const Subscriptions = () => {
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [subscriptionsList, setSubscriptionsList] = useState([]);
    const boundingBoxStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        paddingBottom: '32px'
    };

    const Subscription = ({subscription}) => {
        const [connectedCustomer, setConnectedCustomer] = useState({});
        const [connectedPlan, setConnectedPlan] = useState({});
        const [isLoading, setIsLoading] = useState(true);
        const convertTimestampToDateFormat = (timestamp) => {
            const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
          
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
          
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        };

        useEffect(() => {
            if (subscription.customer !== '' && Object.keys(connectedCustomer).length === 0) {
                fetch(`${apiUrl}/stripe/customer/${subscription.customer}?token=${authCtx.token}`).then(response => response.json()).then(res => {
                    setConnectedCustomer(res);
                }).catch(e => console.log(e));
            }
        }, []);
        useEffect(() => {
            if (subscription.plan.id !== '' && Object.keys(connectedPlan).length === 0) {
                fetch(`${apiUrl}/stripe/price/${subscription.plan.id}?token=${authCtx.token}`).then(response => response.json()).then(res => {
                    setConnectedPlan(res);
                    setIsLoading(false);
                }).catch(e => console.log(e));
            }
        }, []);
        
        
        return (
            <Grid item sx={{margin: '16px'}}>
                <motion.div whileHover={{scale: 1.075}}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                                    <Typography variant="h3">Customer data</Typography>
                                    {Object.keys(connectedCustomer).length > 0 && (
                                        <React.Fragment>
                                            <Typography>{`Name: ${connectedCustomer.name}`}</Typography>
                                            <Typography>{`Email: ${connectedCustomer.email}`}</Typography>
                                            <Typography>{`ID: ${connectedCustomer.id}`}</Typography>
                                        </React.Fragment>
                                    )}
                                    <br />
                                    <Typography variant="h3">Subscription</Typography>
                                    <Typography>{`Status: ${subscription.status}`}</Typography>
                                    <Typography>{`Currency: ${subscription.currency}`}</Typography>
                                    <Typography>{`Start date: ${convertTimestampToDateFormat(subscription.start_date)}`}</Typography>
                                    <Typography>{`Current period start: ${convertTimestampToDateFormat(subscription.current_period_start)}`}</Typography>
                                    <Typography>{`Current period end: ${convertTimestampToDateFormat(subscription.current_period_end)}`}</Typography>
                                    <br />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h3">Plan</Typography>
                                    {Object.keys(connectedPlan).length > 0 && (
                                        <React.Fragment>
                                            <Typography>{`Currency: ${connectedPlan.currency}`}</Typography>
                                            <Typography>{`Amount: ${connectedPlan.unit_amount/100}`}</Typography>
                                            <Typography>{`Active: ${connectedPlan.active}`}</Typography>
                                            <Typography>{`Created: ${convertTimestampToDateFormat(connectedPlan.created)}`}</Typography>
                                            <Typography>{`Type: ${connectedPlan.type}`}</Typography>
                                            {Object.keys(connectedPlan.recurring).length > 0 && (
                                                <React.Fragment>
                                                    <Typography>{`Interval: ${connectedPlan.recurring.interval}`}</Typography>
                                                    <Typography>{`Interval count: ${connectedPlan.recurring.interval_count}`}</Typography>
                                                    <Typography>{`Trial period days: ${connectedPlan.recurring.trial_period_days}`}</Typography>
                                                    <Typography>{`Usage: ${connectedPlan.recurring.usage_type}`}</Typography>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>
                            {Object.keys(subscription.metadata).length > 0 && (<Typography>{`Other: ${JSON.stringify(subscription.metadata)}`}</Typography>)}
                        </CardContent>
                    </Card>
                </motion.div>
            </Grid>
        );
    };

    useEffect(() => {
        fetch(`${apiUrl}/stripe/subscriptions?token=${authCtx.token}`).then(response => response.json()).then(res => {
            setSubscriptionsList(res['data']);
        }).catch(e => console.log(e));
    }, []);
    

  return (
    <React.Fragment>
        <Typography variant="h2">Subscriptions</Typography>
        <br />
        {Object.keys(subscriptionsList).length > 0 ? (
            <Grid container sx={boundingBoxStyle}>
                {subscriptionsList.map((s, i) => (
                    <motion.div key={s.id} animate={{x: 0}} initial={{x: '80vw'}} transition={{delay: i * 0.1}}><Subscription subscription={s} /></motion.div>
                ))}
            </Grid>
        ) : (
            <Typography variant="h3">Loading subscriptions...</Typography>
        )}
    </React.Fragment>
  )
};

export default Subscriptions;