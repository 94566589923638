import React from 'react';
import './PlaybackPanel.css';
import { Box, Typography } from '@mui/material';
import { Slider } from 'primereact/slider';

const msToTime = (duration) => {
    const minutes = Math.floor(duration / 60000);
    const seconds = Math.floor((duration % 60000) / 1000);
    
    // Add leading zero to seconds if needed
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    
    return `${minutes}:${formattedSeconds}`;
};

const msToSeconds = (duration) => {
    return Math.floor(duration / 1000);
};

const TrackProgressBar = ({ playerState }) => {
    return (
        <Box mt={2} display="flex" alignItems="center">
            <Typography>{playerState?.position ? msToTime(playerState.position) : '0:00'}</Typography>
            <Box ml={2} mr={2} sx={{ flex: 1 }}>
                <Slider value={playerState?.position ? msToSeconds(playerState.position) : 0} max={playerState.duration ? msToSeconds(playerState.duration) : 100} />
            </Box>
            <Typography>{playerState?.duration ? msToTime(playerState.duration) : '0:00'}</Typography>
        </Box>
    );
};

export default TrackProgressBar;