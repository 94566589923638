import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion } from 'framer-motion';

const StreamStats = ({ backgroundColor = '#5D3B9E', defaultExpanded = '', filenames = [], loading = false }) => {
  const scrollableContent = {
    maxHeight: '50vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };
  const convertToUnixTimestamp = (dateString) => {
    return new Date(dateString).getTime();
  }

  const refs = useRef(Object.keys(filenames).reduce((acc, timestamp) => {
    acc[timestamp] = React.createRef();
    return acc;
  }, {}));
  
  useLayoutEffect(() => {
    if (defaultExpanded && refs.current[convertToUnixTimestamp(defaultExpanded)]) {
        refs.current[convertToUnixTimestamp(defaultExpanded)].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [defaultExpanded]);

  const StreamItem = ({ timestamp, index }) => {
    const [expanded, setExpanded] = useState(defaultExpanded === formatTimestamp(filenames[timestamp]['startedAt']));

    const handleExpand = () => {
      setExpanded(!expanded);
    };

    const streamData = filenames[timestamp];

    return (
      <motion.div initial={{ y: '100%' }} animate={{ y: 0 }} transition={{ type: 'spring', stiffness: 50, delay: index * 0.01 }} ref={refs.current[timestamp]}>
        <Accordion expanded={expanded} onChange={handleExpand} sx={{ backgroundColor }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{`${streamData.streamTitle} ${formatTimestamp(streamData.startedAt)}`}</Typography>
            </AccordionSummary>
          <AccordionDetails style={{ overflow: 'hidden' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{formatTimestamp(streamData.endedAt)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Accordion sx={{backgroundColor}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Chat Info</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ overflow: 'hidden' }}>
                    {Object.entries(streamData.chatInfo).map(([username, messages]) => (
                      <div key={username} style={{maxHeight: '25vh', overflowY: 'auto'}}>
                        <Typography>{`${username}: ${messages}`}</Typography>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Typography>Peak Viewers: {streamData.peakViewers}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Average Viewers: {streamData.averageViewers}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Game Name: {streamData.game_name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Stream Title: {streamData.streamTitle}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Total Bot Messages: {streamData.numTotalBotMessages}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Total Conversation Messages: {streamData.numTotalConversationMessages}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Total Commands Issued: {streamData.numTotalCommandsIssued}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </motion.div>
    );
  };

  return (
    <DialogComponent footer='Click on any of the dates to see stats from that streaming session.' maxHeight='65vh' minWidth='500px' maxWidth='750px'>
        {loading ? (
            <React.Fragment>
                <Typography variant='h3' sx={{textAlign: 'center'}}>Loading stream data...</Typography>
            </React.Fragment>
        ) : (
        <Grid container spacing={2} sx={scrollableContent}>
            {Object.keys(filenames).map((timestamp, index) => (
                <Grid item xs={12} key={timestamp}>
                  <StreamItem timestamp={timestamp} index={index} />
                </Grid>
            ))}
        </Grid>
        )}
    </DialogComponent>
  );
};

export default StreamStats;
