import React, { useCallback, useState } from 'react';

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    username: '',
    profile_url: '',
    broadcaster_type: '',
    isSubscribed: false,
    isAdmin: false,
    theme: 'dark',
    editor: false,
    login: (token) => {},
    logout: () => {},
    setIsSubscribed: (value) => {},
    checkIfAdmin: (token) => {}
});

const retrieveStoredData = () => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    const storedProfile_Url = localStorage.getItem('profile_url');
    const storedBroadcaster_Type = localStorage.getItem('broadcaster_type');
    const storedIsSubscribed = localStorage.getItem('isSubscribed');
    const storedIsAdmin = localStorage.getItem('isAdmin');
    const storedTheme = localStorage.getItem('theme');
    const storedEditor = localStorage.getItem('editor');

    return {
        token: storedToken,
        username: storedUsername,
        profile_url: storedProfile_Url,
        broadcaster_type: storedBroadcaster_Type,
        isSubscribed: storedIsSubscribed,
        isAdmin: storedIsAdmin,
        theme: storedTheme,
        editor: storedEditor,
    };
};

export const AuthContextProvider = (props) => {
    const storedData = retrieveStoredData();
    let initialToken; let initialUsername; let initialProfile_Url = 'https://i.imgur.com/TVeZvrh.png'; let initialBroadcaster_Type; 
    let initialIsAdmin; let initialIsSubscribed; let initialEditor;
    if (storedData) {
        initialToken = storedData.token;
        initialUsername = storedData.username;
        initialProfile_Url = storedData.profile_url;
        initialBroadcaster_Type = storedData.broadcaster_type;
        initialIsSubscribed = storedData.isSubscribed;
        initialIsAdmin = storedData.isAdmin;
        initialEditor = false;
    }
    const [token, setToken] = useState(initialToken);
    const [username, setUsername] = useState(initialUsername);
    const [profile_url, setProfile_Url] = useState(initialProfile_Url);
    const [broadcaster_type, setBroadcaster_Type] = useState(initialBroadcaster_Type);
    const [isSubscribed, setIsSubscribed] = useState(initialIsSubscribed);
    const [isAdmin, setIsAdmin] = useState(initialIsAdmin);
    const [editor, setEditor] = useState(initialEditor);
    const apiUrl = process.env.REACT_APP_API_URL;

    const userIsLoggedIn = !!token;

    const logoutHandler = useCallback(() => {
        fetch(`${apiUrl}/user/logout?token=${token}`);
        setToken(null); setBroadcaster_Type(null); setIsAdmin(null); setIsSubscribed(null); setUsername(null); setProfile_Url(null); setEditor(null);
        localStorage.removeItem('token'); localStorage.removeItem('username'); localStorage.removeItem('profile_url'); localStorage.removeItem('broadcaster_type'); 
        localStorage.removeItem('isSubscribed'); localStorage.removeItem('isAdmin'); localStorage.removeItem('editor'); localStorage.removeItem('editorPermissions');
        localStorage.removeItem('editingFor');
    }, [apiUrl, token]);

    const checkIfAdminHandler = async (token) => {
        const response = await fetch(`${apiUrl}/user/isadmin?token=${token}`);
        const resStatus = response.ok;
        const res = await response.text();
        if (res && resStatus === true) {
            console.log('Admin check OK');
        } else {
            //logoutHandler();
        }
    };

    const loginHandler = async (token, username = '', profile_url = '', broadcaster_type = '', editor = false) => {
        console.log(`Auth success ${token}`);
        setToken(token); setUsername(username); setProfile_Url(profile_url); setBroadcaster_Type(broadcaster_type); setIsAdmin(false); setEditor(editor);
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        localStorage.setItem('profile_url', profile_url);
        localStorage.setItem('broadcaster_type', broadcaster_type || 'normal');
        localStorage.setItem('isAdmin', false);
        localStorage.setItem('editor', editor);
        const bodyObj = {}; bodyObj['token'] = token.toString();
        try {
            const response = await fetch(`${apiUrl}/user/isadmin?token=${token}`);
            const res = await response.text();
            const resStatus = response.ok;
            if (res && resStatus === true) {
                setIsAdmin(true);
                localStorage.setItem('isAdmin', true);
            }
        } catch (error) {
            console.log(`Admin check error: ${error}`);
        };
        try {
            const response = await fetch(`${apiUrl}/user/subscription/status?token=${token}`);
            const res = await response.json();
            const status = res['status'];
            if (status === 'active' || status === 'trialing' || status === 'past_due') {
                setIsSubscribed(true);
                window.location.reload(false);
                localStorage.setItem('isSubscribed', true);
            } else {
                console.log(response.status);
                setIsSubscribed(false);
                localStorage.setItem('isSubscribed', false);
            }
        } catch(error) {
            setIsSubscribed(false);
            localStorage.setItem('isSubscribed', false);
        };
    };

    const subscribeHandler = (value) => {
        setIsSubscribed(value);
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        username: username,
        profile_url: profile_url,
        broadcaster_type: broadcaster_type,
        isSubscribed: isSubscribed,
        isAdmin: isAdmin,
        editor: editor,
        login: loginHandler,
        logout: logoutHandler,
        setIsSubscribed: subscribeHandler,
        checkIfAdmin: checkIfAdminHandler,
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;