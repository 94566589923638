import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { spotify } from '../mockData';
import { Box } from '@mui/material';
import './PlaybackPanel.css';
import AlbumInfo from './AlbumInfo';
import VolumeControl from './VolumeControl';
import TrackInfo from './TrackInfo';
import PlaybackControls from './PlaybackControls';
import TrackProgressBar from './TrackProgressBar';
import QueueAnimation from './QueueAnimation';

const PlaybackPanel = ({playerState = {}, player = {}, togglePlayPause = () => {}, setVolume = (volume) => {}, getVolume = () => {}, nextTrack = () => {}, previousTrack = () => {}}) => {
    const [offset, setOffset] = useState(0);
    const [mockTrack, setMockTrack] = useState(spotify.track);
    const [mockCurrentlyPlaying, setMockCurrentlyPlayer] = useState(spotify.currently_playing);
    const [tmpVolume, setTmpVolume] = useState(50);
    const [lastVolume, setLastVolume] = useState(50);
    const [isMuted, setIsMuted] = useState(false);
    const [tmpAlbum, setTmpAlbum] = useState({});
    const [tmpTrackName, setTmpTrackName] = useState('');
    const [tmpArtists, setTmpArtists] = useState([]);
    const [tmpNextTracks, setTmpNextTracks] = useState([]);

    const handleVolumeChange = (e) => {
        setTmpVolume(e.value);
        setLastVolume(e.value);
        if (isMuted) setIsMuted(false);
    };
    const toggleMute = () => {
        if (isMuted) {
            setVolume(lastVolume);
        } else {
            setLastVolume(tmpVolume);
            setVolume(0);
        }
        setIsMuted(!isMuted);
    };
    const nextTrackHandler = () => {
        setMockTrack(mockCurrentlyPlaying.queue[offset]);
        setOffset((prev) => (prev + 1) % mockCurrentlyPlaying.queue.length);
    };
    useEffect(() => {
        setVolume(tmpVolume);
    }, [tmpVolume]);
    useEffect(() => {
        if (playerState) {
            if (playerState.track_window) {
                if (playerState.track_window.current_track) {
                    if (playerState.track_window.current_track.album.images.length > 0) {
                        setTmpAlbum(playerState.track_window.current_track.album);
                    }
                    if (playerState.track_window.current_track.name) {
                        setTmpTrackName(playerState.track_window.current_track.name);
                    }
                    if (playerState.track_window.current_track.artists) {
                        setTmpArtists(playerState.track_window.current_track.artists);
                    }
                    if (playerState.track_window.next_tracks) {
                        setTmpNextTracks(playerState.track_window.next_tracks);
                    }
                }
            }
        }
    }, [playerState]);

    return (
        <React.Fragment>
            <Card style={{ width: '100%', height: '100%' }}>
                <Box display="flex" alignItems="center" sx={{ height: '100%' }}>
                    <AlbumInfo album={tmpAlbum} />
                    <Box>
                        <TrackInfo name={tmpTrackName} artists={tmpArtists} />
                        <VolumeControl isMuted={isMuted} volume={tmpVolume} onMuteToggle={toggleMute} onVolumeChange={handleVolumeChange} />
                    </Box>
                    <Box sx={{ flex: 1 }} ml={2}>
                        <PlaybackControls playerState={playerState} onNextTrack={nextTrack} onPreviousTrack={previousTrack} togglePlayPause={togglePlayPause} />
                        <TrackProgressBar playerState={playerState} />
                    </Box>
                    <Box ml={4} mr={4} sx={{ minWidth: '475px', maxWidth: '475px' }}>
                        <QueueAnimation queue={tmpNextTracks} />
                    </Box>
                </Box>
            </Card>
        </React.Fragment>
    );
};

export default PlaybackPanel;
