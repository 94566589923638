import { useState } from 'react';

function useFAQ() {
  const [faq, setFaq] = useState([
    {
        q: 'I have an idea for a new personality or feature for Ratbot, can you implement it?',
        a: 'Probably! Join the Discord server and send a message with your request.'
    },
    {
        q: 'Why is the bot not in my chat?',
        a: 'The bot will only be in your chat while you are streaming. If you see the bot in your chatter list but it is not responding, it could be due to a followers-only chat restriction. Making the bot a mod will override this.'
    }
  ]);

  return [faq, setFaq];
}

export default useFAQ;