import { Box, Button, ButtonGroup, Chip, Dialog, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useIsMobile from '../../utils/useIsMobile';
import { ColorModeContext } from '../../theme';
import PresetGrid from '../../components/personality_picker/PresetGrid';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import useAICategories from '../../utils/useAICategories';

const LiveChat = ({personality = ''}) => {};

const PersonalitySetup = ({setStepperValue}) => {
    const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
    const [filter, setFilter] = useState([]);
    const [selected, setSelected] = useState('');
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const isLarge = useWindowSizeThreshold(1440, true);
    const isMobile = useIsMobile();
    const categories = useAICategories();
    const [removedCategories, setRemovedCategories] = useState([]);
    const customStyles = {
        dialog: {
            backgroundColor: '#6441A4', // Dark purple
            width: isMobile ? '90vw' : '40vw',
            height: '75vh'
        },
        chip: {
            margin: '5px',
            '&.MuiChip-filled': {
                background: 'linear-gradient(45deg, #7a42d1 30%, #5f1ca3 90%)', // Brighter purple 5f1ca3
                color: 'white',
                '&:hover': {
                    backgroundColor: '#a65fff', // A slightly lighter purple for hover
                    // Optionally add a scale transform or a shadow for more interactivity
                    transform: 'scale(1.05)',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s', // Smooth transition for hover effects
                }
            },
            '&.MuiChip-outlined': {
                borderColor: '#9147FF',
                color: '#a65fff',
                '&:hover': {
                    borderColor: '#a65fff', // A slightly lighter purple for hover
                    color: '#a65fff',
                    // Optionally add a scale transform or a shadow for more interactivity
                    transform: 'scale(1.05)',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s, box-shadow 0.3s, border-color 0.3s, color 0.3s', // Smooth transition for hover effects
                }
            }
        },
        buttonGroup: {
            '& .MuiButton-contained': {
                background: 'linear-gradient(45deg, #7a42d1 30%, #5f1ca3 90%)', // Darker gradient purple
                color: 'white'
            }
        },        
        typographyH1: {
            color: 'white',
            fontFamily: '"Roobert", "Helvetica Neue", sans-serif'
        },
        typographyH3: {
            color: 'white',
            fontFamily: '"Roobert", "Helvetica Neue", sans-serif'
        },
        title: {
            fontSize: isMobile ? '2rem' : '3rem', // Adjust to your preference
            fontWeight: 700, // Heavier font weight
            color: '#FFFFFF',
            textShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            letterSpacing: '0.1em', // Adjust to your preference
            background: '-webkit-linear-gradient(45deg, #9d65ff, #8461e8)', // Lighter purple gradient
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '1rem', // Space from the title to content below
            '&:hover': {
                transform: 'scale(1.05)', // Slightly increase size on hover
                transition: 'transform 0.3s', // Smooth transition for the transform
            },
            textAlign: 'center', // Center the title if it's not already
            userSelect: 'none', // Prevent the user from selecting the text
        },
        button: {
            width: (isLarge && isMobile) ? '40vw' : isLarge ? '20vw' : '15vw', // Adjust as necessary
            borderRadius: 2,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', // Your original shadow
            transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s', // Smooth transition for hover effects
            '&:hover': {
                backgroundColor: '#a65fff', // A slightly lighter purple for hover
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.35)', // More pronounced shadow on hover
                transform: 'translateY(-2px)', // Button moves up slightly
            }
        }
    };

    const filterChangeHandler = (category) => {
        if (removedCategories.includes(category)) {
            const _removedCategories = [...removedCategories].filter((c) => c !== category);
            setRemovedCategories(_removedCategories);
        } else {
            setRemovedCategories((prev) => ([...prev, category]));
        }
    };

    useEffect(() => {
        const toAdd = [];
        for (let index in removedCategories) {
            for (let preset of categories[removedCategories[index]]) {
                toAdd.push(preset);
            }
        }
        setFilter(toAdd);
    }, [removedCategories]);
    
    
    const ChatDialog = () => {
        const dialogStyle = openChatDialog ? {} : { display: 'none' };
        return (
            <Dialog open={true} onClose={() => setOpenChatDialog(false)}
                PaperProps={{style: {...dialogStyle, backgroundColor: theme.palette.primary.dark, width: '25vw', height: '75vh'}}}>
                <LiveChat personality={selected} />
            </Dialog>
        );
    };

  return (
    <React.Fragment>
        {openChatDialog && <ChatDialog />}
        <Grid container sx={{marginTop: '2.5vh'}}>
            <Grid item xs={12} textAlign='center'>
                <Typography variant='h1' sx={customStyles.title}>Ratbot Personalities</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{width: '80vw', marginLeft: '10vw', height: '75vh'}}>
                    <PresetGrid filter={filter} selected={selected} setSelected={setSelected} categories={categories} />
                </Box>
            </Grid>
        </Grid>
        <Grid container flex alignItems='center' flexDirection='column' marginTop='16px'>
            <Grid item xs={12}>
                {Object.keys(categories).map((c) => (
                    <Chip sx={customStyles.chip} key={c} label={c} onClick={() => filterChangeHandler(c)} variant={removedCategories.includes(c) ? 'outlined' : 'filled'} />
                ))}
            </Grid>
        </Grid>
        {selected !== '' && (
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                    <ButtonGroup variant='contained' aria-label="chat and next buttons group" sx={customStyles.buttonGroup}>
                        <Button sx={customStyles.button} startIcon={<ChatIcon />} onClick={() => setOpenChatDialog(true)}>{isMobile ? 'Chat' : `Chat with ${selected}`}</Button>
                        <Button sx={customStyles.button} endIcon={<ArrowForwardIcon />} onClick={() => console.log(filter)}>Next</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        )}
    </React.Fragment>
  )
};

export default PersonalitySetup;