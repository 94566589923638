import React, { useEffect, useState } from 'react';
import { Paper, BottomNavigation, BottomNavigationAction, Typography, ListItemButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { motion, AnimatePresence } from 'framer-motion';

const BottomBar = ({backgroundColor = '#5D3B9E', selectedTextColor = '#FFFFFF', openDialogComponent = () => {}, setMainViewComponent = () => {}}) => {
    const [value, setValue] = useState(null);
    const [selected, setSelected] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [bottomPadding, setBottomPadding] = useState(0); 
    const menus = [
        { primary: "AI", secondaries: ["My Presets", "Create New"] },
        { primary: "Automation", secondaries: ["Commands", "Triggers", "Macros"] },
        { primary: "Integrations", secondaries: ["Spotify", "Twitch"] },
    ];
    const bottomBarStyle = {
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        backgroundColor, 
        zIndex: 1000
    };

    useEffect(() => {
        const updatePaddingForAndroid = () => {
            // Example calculation, adjust based on your app's needs
            const navBarHeight = window.innerHeight * 0.01;
            setBottomPadding(navBarHeight);
        };
        window.addEventListener('resize', updatePaddingForAndroid);
        updatePaddingForAndroid();

        return () => window.removeEventListener('resize', updatePaddingForAndroid);
    }, []);

    const handleClick = (index) => {
        if (selected === index) {
            setSelected(null);
            setIsExpanded(false);
        } else {
            setSelected(index);
            setValue(index);
            setIsExpanded(true);
        }
    };

    const handleSecondaryClick = (page) => {
        setSelected(page);
        setIsExpanded(false);
        switch (page) {
            case 'My Presets':
                setMainViewComponent('aipresets');
                break;
            case 'Create New':
                setMainViewComponent('personalitybuilder');
                break;
            case 'Commands':
                setMainViewComponent('commands');
                break;
            case 'Triggers':
                setMainViewComponent('triggers');
                break;
            case 'Macros':
                setMainViewComponent('macros');
                break;
            case 'Spotify':
                setMainViewComponent('spotify');
                break;
            case 'Twitch':
                setMainViewComponent('twitch');
                break;
        }
    };

    const spring = {
        type: "linear",
        stiffness: 700,
        damping: 30
    };

    return (
        <Paper sx={bottomBarStyle} elevation={3}>
            <BottomNavigation value={value} showLabels>
                {menus.map((menu, index) => (
                    <BottomNavigationAction 
                        key={menu.primary} 
                        label={<Typography color={selected === index ? selectedTextColor : 'inherit'}>{menu.primary}</Typography>}
                        icon={selected === index ? <ExpandLessIcon style={{ color: selectedTextColor }} /> : <ExpandMoreIcon />}
                        onClick={() => handleClick(index)} 
                        sx={{'&.Mui-selected': {color: selectedTextColor, borderTop: isExpanded && `5px solid rgba(0, 200, 0, 0.25)`}}} 
                    />
                ))}
            </BottomNavigation>
            <AnimatePresence>
                {isExpanded && (
                    <motion.div 
                        initial={{ opacity: 0, height: 0 }} 
                        animate={{ opacity: 1, height: 'auto' }} 
                        exit={{ opacity: 0, height: 0 }} 
                        transition={spring}>
                        <List sx={{backgroundColor}}>
                            {menus[selected]?.secondaries.map((secondary, index) => (
                                <ListItemButton key={index} onClick={() => handleSecondaryClick(secondary)}>
                                    <ListItemText primary={<Typography>{secondary}</Typography>} />
                                </ListItemButton>
                            ))}
                        </List>
                    </motion.div>
                )}
            </AnimatePresence>
        </Paper>
    );
};

export default BottomBar;