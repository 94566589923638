import { useState } from "react";

const useTriggerPresets = () => {
    const [triggerPresets, setTriggerPresets] = useState({
        "Welcome New User": {
            "notes": "Welcome message",
            "description": "Welcome message",
            "trigger": "first_time_message",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Welcome ${username} to the stream"
                }
            ]
        },
        "Vibe Check": {
            "notes": "Chat inactivity",
            "description": "Vibe check",
            "chat_inactivity": 900,
            "trigger": "chat_inactivity",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Ask an open question to the chatters"
                }
            ]
        },
        "Spotify Song Announcement": {
            "notes": "Song announcement",
            "description": "Song announcement",
            "trigger": "spotify_new_song",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that ${song} is playing"
                }
            ]
        },
        "New Follower": {
            "notes": "New follower",
            "description": "New follower",
            "trigger": "twitch_channel_follow",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for following the channel"
                }
            ]
        },
        "New Subscriber": {
            "notes": "New subscriber",
            "description": "New subscriber",
            "trigger": "twitch_channel_subscribe",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for subscribing to the channel (tier ${tier})"
                }
            ]
        },
        "Resubscription": {
            "notes": "Resubscriber",
            "description": "Resubscriber",
            "trigger": "twitch_channel_subscription_message",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for subscribing to the channel at tier ${tier} for ${cumulative} months (${streak} months streak). User message: \"${message}\""
                }
            ]
        },
        "Subscription Gift": {
            "notes": "Gifter",
            "description": "Gifter",
            "trigger": "twitch_channel_subscription_gift",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for donating ${total} tier ${tier} subscriptions to channel members. They have gifted ${cumulative} subscriptions in total on the channel"
                }
            ]
        },
        "Ad Break": {
            "notes": "Ad break",
            "description": "Midroll ad start",
            "trigger": "twitch_ad_break_begin",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Ad is now playing, remind users that they can go ad-free if they subscribe"
                }
            ]
        },
        "Raid From Other Channel": {
            "notes": "Raid from other channel",
            "description": "Raid From Other Channel",
            "trigger": "twitch_raid_from",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that streamer ${raid_from} has raided the channel with ${raid_viewers} viewers"
                }
            ]
        },
        "Raid To Other Channel": {
            "notes": "Raid to other channel",
            "description": "Raid To Other Channel",
            "trigger": "twitch_raid_to",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that you are raiding ${raid_to} with ${raid_viewers} viewers, remind everyone to say hi to ${raid_to}"
                }
            ]
        },
        "Shoutout Created": {
            "notes": "Shoutout created",
            "description": "Shoutout Create",
            "trigger": "twitch_shoutout_create",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that we are recognizing streamer @${username}"
                }
            ]
        },
        "Shoutout Received": {
            "notes": "Shoutout received",
            "description": "Shoutout Receive",
            "trigger": "twitch_shoutout_receive",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Streamer @${username} has given a shoutout to this channel"
                }
            ]
        }
    });

      return [triggerPresets, setTriggerPresets];
};

export default useTriggerPresets;