import { useState, useEffect } from 'react';

// This is a custom hook for managing ingredient tags
const useIngredientTags = () => {
    // Example static data for tags
    const initialTags = [
        { label: 'Floral', value: 'Floral' },
        { label: 'Fresh', value: 'Fresh' },
        { label: 'Citrus', value: 'Citrus' },
        { label: 'Fruity', value: 'Fruity' },
        { label: 'Woody', value: 'Woody' },
        { label: 'Spicy', value: 'Spicy' },
        { label: 'Sweet', value: 'Sweet' },
        { label: 'Earthy', value: 'Earthy' },
        { label: 'Musky', value: 'Musky' },
        { label: 'Aquatic', value: 'Aquatic' },
        { label: 'Green', value: 'Green' },
        { label: 'Herbal', value: 'Herbal' },
        { label: 'Oriental', value: 'Oriental' },
        { label: 'Gourmand', value: 'Gourmand' },
        { label: 'Powdery', value: 'Powdery' },
        { label: 'Balsamic', value: 'Balsamic' },
        { label: 'Vanilla', value: 'Vanilla' },
        { label: 'Amber', value: 'Amber' },
        { label: 'Leathery', value: 'Leathery' },
        { label: 'Smokey', value: 'Smokey' },
        { label: 'Animalic', value: 'Animalic' },
        { label: 'Tropical', value: 'Tropical' },
        { label: 'Minty', value: 'Minty' },
        { label: 'Nutty', value: 'Nutty' },
        { label: 'Coffee', value: 'Coffee' },
        { label: 'Chocolate', value: 'Chocolate' },
        { label: 'Warm', value: 'Warm' },
        { label: 'Medicinal', value: 'Medicinal' },
        // ... add more as needed
    ];    

    const [tags, setTags] = useState([]);

    useEffect(() => {
        setTags(initialTags);
    }, []);

    return tags;
};

export default useIngredientTags;
