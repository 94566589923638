import React from 'react';
import './PlaybackPanel.css';
import { Box } from '@mui/material';
import { Button } from 'primereact/button';

const PlaybackControls = ({ playerState = {}, onPreviousTrack = () => {}, onPlay = () => {}, onNextTrack = () => {}, togglePlayPause = () => {} }) => (
    <Box display="flex" justifyContent="center">
        <Box display="flex" gap={4}>
            <Button icon="pi pi-step-backward" rounded text aria-label="Previous" size='large' onClick={onPreviousTrack} />
            <Button icon={playerState.paused ? "pi pi-play" : "pi pi-pause"} rounded text severity="success" aria-label="Play" size='large' onClick={togglePlayPause} />
            <Button icon="pi pi-step-forward" rounded text aria-label="Next" size='large' onClick={onNextTrack} />
        </Box>
    </Box>
);

export default PlaybackControls;