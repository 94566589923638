import React, { useEffect, useState } from 'react';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import PresetCard from './PresetCard';
import { ToggleButton } from 'primereact/togglebutton';
import { InputText } from 'primereact/inputtext';
import './PresetGrid.css';

const PresetGrid = ({backgroundColor = '#5D3B9E', presets = {}, updatePresets = () => {}, customPresets = [], userSettings = {}, update = () => {},
                        updateCustomPresets = () => {}}) => {
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const isMedium = useWindowSizeThreshold(580);
    const [loading, setLoading] = useState(false);
    const [loadedImages, setLoadedImages] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [viewPresets, setViewPresets] = useState(true);
    const [viewCustomPresets, setViewCustomPresets] = useState(true);
    const [selected, setSelected] = useState(userSettings['openAISettings']['personality']);
    const [searchString, setSearchString] = useState('');
    const [filter, setFilter] = useState(Object.keys(presets));
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        gridContainer: {
          display: 'flex', // Ensure the container is using flexbox
          flexWrap: 'wrap', // Allows items to wrap as needed
          justifyContent: 'center', // Change to 'space-between' or 'space-evenly' as needed
          padding: '16px',
          backgroundColor: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
          height: isMobile ? '100%' : '70vh', // Adjust height to fit the screen
          paddingBottom: '32px',
          width: '100%', // Set width to 80%
          margin: 'auto', // Center container on the screen
          boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.5)', // Inline drop shadow
          borderRadius: '10px',
        },
        scrollableContent: {
          maxHeight: isMobile ? '100%' : '70vh',
          overflowY: 'auto', // Enable vertical scrolling
          '&::-webkit-scrollbar': {
            display: 'none', // Hide vertical scrollbar
          },
          '-ms-overflow-style': 'none', // For Internet Explorer and Edge
          'scrollbar-width': 'none', // For Firefox
        },
        toolbar: {
          display: 'flex',
          alignItems: 'center',
          gap: '16px', // Adds space between toolbar items
        },
        toggleButton: {
            width: '275px', // Fixed width for ToggleButton
            paddingTop: '8px',
            paddingBottom: '8px'
        },
        toggleButtonMobile: {
            width: '100%',
            paddingTop: '6px',
            paddingBottom: '6px'
        },
        inputTextContainer: {
          flexGrow: 1, // Makes InputText grow to fill available space
          display: 'flex' // Flexbox container for input group
        },
        inputText: {
          width: '100%', // Ensure the InputText fills the flex container
          '--background-color50': colorScheme.background50
        }
    };

      // Function to update the filter based on the search string
    useEffect(() => {
        if (searchString.trim() === '') {
        setFilter(Object.keys(presets)); // Show all items if search string is empty
        } else {
        const searchLower = searchString.toLowerCase();
        const newFilter = Object.keys(presets).filter((p) => {
            const presetMatch = p.toLowerCase().includes(searchLower);
            const customPresetMatch = customPresets.includes(p) && p.toLowerCase().includes(searchLower);
            return presetMatch || customPresetMatch;
        });
        setFilter(newFilter);
        }
    }, [searchString, presets, customPresets]);

    const updateAllPresets = () => {
        updatePresets();
        updateCustomPresets();
    };

    const displayPreset = (p) => {
        if (customPresets.includes(p) && viewCustomPresets) return true;
        if (customPresets.includes(p) && !viewCustomPresets) return false;
        if (viewPresets) return true;
        return false;
    };

  return (
    <Box sx={{padding: !isMobile && '16px', marginRight: isMobile && '20px', height: isMobile && '62.5%'}}>
        <Box className='toolbar' sx={styles.toolbar}>
            {isMobile ? (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ToggleButton onIcon="pi pi-check" offIcon="pi pi-times" checked={viewPresets} style={styles.toggleButtonMobile}
                            onChange={(e) => setViewPresets(e.value)} onLabel='Built-in' offLabel='Built-in' />
                    </Grid>
                    <Grid item xs={6}>
                        <ToggleButton onIcon="pi pi-check" offIcon="pi pi-times" checked={viewCustomPresets} style={styles.toggleButtonMobile}
                            onChange={(e) => setViewCustomPresets(e.value)} onLabel='Custom' offLabel='Custom' />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="p-inputgroup" style={styles.inputTextContainer}>
                            <span className="p-inputgroup-addon" style={{'--background-color': backgroundColor}}>
                                <i className="pi pi-search"></i>
                            </span>
                            <InputText placeholder="Filter Presets" onInput={(e) => console.log(e.target.value)} style={styles.inputText}
                                onChange={(e) => setSearchString(e.target.value)} />
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <React.Fragment>
                    <ToggleButton onIcon="pi pi-check" offIcon="pi pi-times" style={styles.toggleButton} checked={viewPresets} 
                        onChange={(e) => setViewPresets(e.value)} onLabel='Built-in Presets' offLabel='Built-in Presets' />
                    <ToggleButton onIcon="pi pi-check" offIcon="pi pi-times" style={styles.toggleButton} checked={viewCustomPresets}
                        onChange={(e) => setViewCustomPresets(e.value)} onLabel='Custom Presets' offLabel='Custom Presets' />
                    <div className="p-inputgroup" style={styles.inputTextContainer}>
                        <span className="p-inputgroup-addon" style={{'--background-color': backgroundColor}}>
                            <i className="pi pi-search"></i>
                        </span>
                        <InputText placeholder="Filter Presets" onInput={(e) => console.log(e.target.value)} style={styles.inputText}
                            onChange={(e) => setSearchString(e.target.value)} />
                    </div>
                </React.Fragment>
            )}
        </Box>
        <Grid container spacing={isMobile ? 1 : 2} sx={{...styles.scrollableContent, ...styles.gridContainer, marginTop: '16px'}}>
            {!loading ? (
                <React.Fragment>
                    {Object.keys(presets).map((p, i) => (
                        <React.Fragment key={p}>
                            {filter.includes(p) && displayPreset(p) && (
                                <Grid item xs={(isMobile && isMedium) ? 6 : (isMobile && !isMedium) ? 4 : (!isMobile && isLarge) ? 3 : 2} >
                                    <PresetCard preset={presets[p]} presetName={p} isSelected={p === selected} update={updateAllPresets}
                                        setSelected={() => {
                                            update({openAISettings: {...userSettings['openAISettings'], personality: p}});
                                            setSelected(p);
                                        }} backgroundColor={backgroundColor} isCustom={customPresets.includes(p)} />
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ) : (
                <Box sx={{position: 'relative', top: '50%', height: '10vh', width: '25vw'}}>
                    {(totalImages > 0) && (
                        <React.Fragment>
                            <LinearProgress color='secondary' variant='determinate' value={Math.floor((loadedImages / totalImages) * 100)} />
                            <Typography sx={{ textAlign: 'center' }}>{`Loading ${Math.floor((loadedImages / totalImages) * 100)}%`}</Typography>
                        </React.Fragment>
                    )}
                </Box>
            )}
        </Grid>
    </Box>
  );
};

export default React.memo(PresetGrid);