import { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';

const useSubscriberList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [subscriberList, setSubscriberList] = useState({});

  useEffect(() => {
    const fetchSubscriberList = async () => {
      try {
        const response = await fetch(`${apiUrl}/admin/subscribers?token=${authCtx.token}`);
        const res = await response.json();
        setSubscriberList(res);
      } catch (e) {
        console.log(e);
      }
    };

    fetchSubscriberList();
  }, []);

  return subscriberList;
};

export default useSubscriberList;