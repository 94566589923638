import { Box, Grid, Typography } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import React, { useState, useRef, useEffect } from 'react';
import './gameView.css';
import { useUser } from '../../../context/UserContext';
import { Divider } from 'primereact/divider';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import { Button } from 'primereact/button';

// Utility function to generate color scheme
const generateColorScheme = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    return {
        background75: `rgba(${r},${g},${b},0.75)`,
        background50: `rgba(${r},${g},${b},0.5)`,
        background25: `rgba(${r},${g},${b},0.25)`
    };
};

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// Helper Function for Mapping Numerical Values to Descriptive Texts
const getRelationshipDescription = (value, type) => {
    const descriptions = {
        trust: {
            high: "They trust you deeply and consider you a close ally.",
            moderate: "They trust you and rely on your judgment.",
            low: "They are hesitant to trust you and remain cautious.",
            negative: "They distrust you and are wary of your intentions."
        },
        respect: {
            high: "They hold you in the highest regard and follow your lead.",
            moderate: "They hold you in high esteem and value your opinions.",
            low: "They question your abilities and are somewhat skeptical.",
            negative: "They have little respect for you and may disregard your suggestions."
        },
        friendliness: {
            high: "They are very friendly, enjoy your company, and are eager to help.",
            moderate: "They are friendly and enjoy your company.",
            low: "They are neutral towards you and keep a polite distance.",
            negative: "They are unfriendly and may be hostile towards you."
        }
    };

    if (value > 10) return descriptions[type].high;
    if (value > 5) return descriptions[type].moderate;
    if (value > -5) return descriptions[type].low;
    return descriptions[type].negative;
};


const GameView = ({ backgroundColor = '#5D3B9E', storyObject = {}, setStoryObject = () => {}, setMainViewHeader = () => {} }) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [playerActionString, setPlayerActionString] = useState('');
    const [loading, setLoading] = useState(false);
    const [expandedItems, setExpandedItems] = useState({}); // Track expanded states
    const colorScheme = generateColorScheme(backgroundColor);
    const conversationHistoryRef = useRef(null);

    const commonStyles = {
        padding: '8px',
        border: `2px solid rgba(255, 255, 255, 0.25)`,
        textShadow: '0px 0px 6px #000000, 0px 0px 5px #000000',
        marginBottom: '16px',
        borderRadius: '15px 15px 15px 0px',
        display: 'inline-block',
        maxWidth: '100%',
        transition: 'max-height 0.3s ease',
        overflow: 'hidden'
    };

    const styles = {
        conversationHistory: {
            outer: {
                backgroundColor: colorScheme.background50,
                height: '100%',
                width: '100%',
                padding: '16px',
                borderRadius: '15px',
                border: `2px solid ${colorScheme.background50}`,
                boxShadow: `inset 3px 3px 15px rgba(0, 0, 0, 0.5)`
            },
            inner: {
                height: '100%',
                width: '100%',
                overflowY: 'auto'
            },
            itemStyles: {
                intro: {
                    ...commonStyles,
                    backgroundColor: 'rgba(69, 209, 158, 0.5)',
                    borderRadius: '5px',
                    cursor: 'pointer'
                },
                action: {
                    ...commonStyles,
                    backgroundColor: 'rgba(45, 156, 207, 0.5)',
                    borderRadius: '15px 15px 0px 15px',
                    float: 'right'
                },
                response: {
                    ...commonStyles,
                    backgroundColor: 'rgba(133, 133, 133, 0.5)'
                },
                newLocation: {
                    ...commonStyles,
                    backgroundColor: 'rgba(176, 196, 77, 0.5)',
                    textAlign: 'center',
                    cursor: 'pointer'
                },
                discoveredCharacter: {
                    ...commonStyles,
                    backgroundColor: 'rgba(222, 176, 51, 0.5)',
                    transition: 'max-height 0.9s ease',
                    cursor: 'pointer'
                },
                rollSuccess: {
                    ...commonStyles,
                    backgroundColor: 'rgba(47, 168, 59, 0.5)',
                    transition: 'max-height 0.9s ease',
                    cursor: 'pointer',
                    paddingRight: '16px'
                },
                rollFailure: {
                    ...commonStyles,
                    backgroundColor: 'rgba(180, 40, 40, 0.5)',
                    transition: 'max-height 0.9s ease',
                    cursor: 'pointer'
                },
                relationshipGood: {
                    ...commonStyles,
                    backgroundColor: 'rgba(47, 168, 59, 0.5)',
                    transition: 'max-height 0.9s ease',
                    cursor: 'pointer',
                    paddingRight: '16px'
                },
                relationshipBad: {
                    ...commonStyles,
                    backgroundColor: 'rgba(180, 40, 40, 0.5)',
                    transition: 'max-height 0.9s ease',
                    cursor: 'pointer'
                }
            },
            titleText: {
                marginTop: '0px',
                marginBottom: '0px',
                textAlign: 'left',
                textShadow: '0px 0px 5px #000000',
            },
            bodyText: {
                marginTop: '8px',
                marginBottom: '8px',
            }
        },
        playerInput: {
            inputText: {
                width: '100%',
                backgroundColor: colorScheme.background25,
                marginTop: '16px',
                border: `2px solid ${colorScheme.background50}`,
                borderRadius: '10px',
                '--input-text-hover-color': colorScheme.background50
            }
        },
        infoPanel: {
            titleText: {

            },
            descriptionText: {

            },
            card: {
                backgroundColor: colorScheme.background50,
                marginBottom: '8px',
                borderRadius: '10px',
                border: `2px solid ${colorScheme.background50}`,
                paddingLeft: '8px',
                paddingRight: '8px',
                overflowY: 'auto',
                boxShadow: `inset 3px 3px 15px rgba(255, 255, 255, 0.15)`
            }
        }
    };

    const handleExpandClick = (index) => {
        setExpandedItems((prevExpandedItems) => ({
            ...prevExpandedItems,
            [index]: !prevExpandedItems[index]
        }));
    };

    const ExpandableHistoryItem = ({ title, content, expandedContent, styleKey, isExpanded, onClick }) => (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    sx={{ ...styles.conversationHistory.itemStyles[styleKey], maxHeight: isExpanded ? '500px' : '80px' }}
                    onClick={onClick}
                >
                    {!isExpanded && <h2 style={styles.conversationHistory.titleText}>{title}</h2>}
                    {isExpanded && expandedContent}
                </Box>
            </Grid>
        </Grid>
    );

    const StaticHistoryItem = ({ content, styleKey }) => (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={styles.conversationHistory.itemStyles[styleKey]}>
                    <p style={styles.conversationHistory.bodyText}>{content}</p>
                </Box>
            </Grid>
        </Grid>
    );

    const renderHistoryItem = (historyObj, index) => {
        const type = Object.keys(historyObj)[0];

        switch (type) {
            case 'intro':
                return (
                    <ExpandableHistoryItem
                        key={index}
                        title="Introduction"
                        content={historyObj.intro}
                        expandedContent={<p style={styles.conversationHistory.bodyText}>{historyObj.intro}</p>}
                        styleKey="intro"
                        isExpanded={!!expandedItems[index]}
                        onClick={() => handleExpandClick(index)}
                    />
                );
            case 'action':
                return (
                    <StaticHistoryItem
                        key={index}
                        content={historyObj.action}
                        styleKey="action"
                    />
                );
            case 'response':
                return (
                    <StaticHistoryItem
                        key={index}
                        content={historyObj.response}
                        styleKey="response"
                    />
                );
            case 'newLocation':
                return;
                return (
                    <ExpandableHistoryItem
                        key={index}
                        title="Location Change"
                        expandedContent={
                        <>
                            <h3 style={styles.conversationHistory.titleText}>New Location</h3>
                            <p style={styles.conversationHistory.bodyText}>{`${historyObj.newLocation}`}</p>
                        </>
                        }
                        styleKey="newLocation"
                        isExpanded={!!expandedItems[index]}
                        onClick={() => handleExpandClick(index)}
                    />
                );
            case 'discoveredCharacter':
                return (
                    <ExpandableHistoryItem
                        key={index}
                        title="New Character"
                        expandedContent={
                            <>
                                <h4 style={styles.conversationHistory.titleText}>{`Name: ${historyObj.discoveredCharacter.name}`}</h4>
                                <p style={styles.conversationHistory.bodyText}>{`Description: ${historyObj.discoveredCharacter.description}`}</p>
                                <p style={styles.conversationHistory.bodyText}>{`Personality: ${historyObj.discoveredCharacter.personality}`}</p>
                                <i style={styles.conversationHistory.bodyText}>{`${historyObj.discoveredCharacter.interactions[0]}`}</i>
                            </>
                        }
                        styleKey="discoveredCharacter"
                        isExpanded={!!expandedItems[index]}
                        onClick={() => handleExpandClick(index)}
                    />
                );
            case 'relationshipChange':
                const trustDescription = getRelationshipDescription(historyObj.relationshipChange.relationships.trust, 'trust');
                const respectDescription = getRelationshipDescription(historyObj.relationshipChange.relationships.respect, 'respect');
                const friendlinessDescription = getRelationshipDescription(historyObj.relationshipChange.relationships.friendliness, 'friendliness');
                return (
                    <ExpandableHistoryItem 
                        key={index}
                        title="Relationship Change"
                        expandedContent={
                            <>
                                <h3 style={styles.conversationHistory.titleText}>Relationship Change</h3>
                                <p style={styles.conversationHistory.bodyText}>{`Name: ${historyObj.relationshipChange.name}`}</p>
                                <li style={{marginBottom: '0px', marginTop: '0px'}}>{`${trustDescription}`}</li>
                                <li style={{marginBottom: '0px', marginTop: '0px'}}>{`${respectDescription}`}</li>
                                <li style={{marginBottom: '8px', marginTop: '0px'}}>{`${friendlinessDescription}`}</li>
                                <i style={styles.conversationHistory.bodyText}>{`${historyObj.relationshipChange.interactions[historyObj.relationshipChange.interactions.length - 1]}`}</i>
                            </>
                        }
                        styleKey={historyObj.relationshipChange.relationships.trust + 
                                    historyObj.relationshipChange.relationships.respect + 
                                    historyObj.relationshipChange.relationships.friendliness < 0 ? 'relationshipBad' : 'relationshipGood'}
                        isExpanded={!!expandedItems[index]}
                        onClick={() => handleExpandClick(index)}
                    />
                );
            case 'roll':
                return (
                    <ExpandableHistoryItem
                        key={index}
                        title="Stat Check"
                        expandedContent={
                            <>
                                <h3 style={styles.conversationHistory.titleText}>{`${capitalizeFirstLetter(historyObj.roll.stat)} Check`}</h3>
                                {/*<p style={styles.conversationHistory.bodyText}>{`Required: ${historyObj.roll.required}`}</p>*/}
                                <p style={styles.conversationHistory.bodyText}>{`Success: ${capitalizeFirstLetter(historyObj.roll.success.toString())}`}</p>
                            </>
                        }
                        styleKey={historyObj.roll.success ? 'rollSuccess' : 'rollFailure'}
                        isExpanded={!!expandedItems[index]}
                        onClick={() => handleExpandClick(index)}
                    />
                );
            case 'gameOver':
                return (
                    <StaticHistoryItem
                        content={
                            <>
                                <p style={styles.conversationHistory.bodyText}>{historyObj.gameOver}</p>
                            </>
                        } styleKey='relationshipBad' />
                )
            default:
                return null;
        }
    };

    const progressStory = async (title, action) => {
        try {
            setLoading(true);
            const progressResponse = await fetch(`${apiUrl}/game/story/progressstory?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({title, action})
            });
            if (progressResponse.ok) {
                const progressData = await progressResponse.json();
                setStoryObject(progressData);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    // Function to handle the key up event
    const submitHandler = (event) => {
        if (event.key === 'Enter' && playerActionString.length > 0 && playerActionString.length < 500) {
            progressStory(storyObject.title, playerActionString);
            setPlayerActionString(''); // Clear input after submission
            setStoryObject((prev) => ({...prev, conversationHistory: [...prev.conversationHistory, {action: playerActionString}]}));
        }
    };

    // Effect to initialize expanded items to all true when storyObject changes
    useEffect(() => {
        /*const initialExpandedItems = {};
        storyObject.conversationHistory.forEach((_, index) => {
            initialExpandedItems[index] = true; // Set all items to expanded by default
        });
        setExpandedItems(initialExpandedItems);*/
    }, [loading]);

    // Effect to scroll to the bottom when loading is finished and data is updated
    useEffect(() => {
        if (conversationHistoryRef.current) {
            conversationHistoryRef.current.scrollTop = conversationHistoryRef.current.scrollHeight;
        }
    }, [loading, storyObject.conversationHistory]);

    return (
        <Box sx={{marginRight: '48px'}}>
            {Object.keys(storyObject).length > 0 && (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {/* LEFT HALF */}
                    <Grid container>
                        <Grid item xs={12} sx={{height: '625px'}}>
                            {/* CONVERSATION HISTORY */}
                            <Box sx={styles.conversationHistory.outer}>
                                <Box sx={styles.conversationHistory.inner} ref={conversationHistoryRef}>
                                    {storyObject.conversationHistory.map((item, index) => (
                                        renderHistoryItem(item, index)
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {/* PLAYER INPUT */}
                                <InputText 
                                    value={loading ? 'Processing...' : playerActionString}
                                    className='player-input-text' 
                                    style={styles.playerInput.inputText}
                                    onChange={(e) => setPlayerActionString(e.target.value)}
                                    onKeyUp={submitHandler}
                                    disabled={loading}
                                    placeholder='Submit your action here...'
                                />
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: '16px'}}>
                            <Button icon='pi pi-arrow-left' label='Back to stories' size='small' onClick={() => {
                                setStoryObject({title: ''});
                                setMainViewHeader('Interactive Story');
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}> {/* RIGHT HALF */}
                    <Grid container sx={{marginLeft: '8px'}}>
                        <Grid item xs={6}> {/* LEFT SIDE */}
                            <Grid container sx={{height: '500px'}}>
                                <Grid item xs={12} sx={styles.infoPanel.card}> {/* CHAPTER TITLE */}
                                    <h2 style={styles.infoPanel.titleText}>{`Chapter ${storyObject.currentChapterIndex + 1}: ${storyObject.currentChapterTitle}`}</h2>
                                    <Divider style={{color: 'white', marginTop: '4px', marginBottom: '4px'}} />
                                    <h3 style={styles.infoPanel.descriptionText}>{`Location: ${storyObject.playerLocation}`}</h3>
                                    <p style={styles.infoPanel.descriptionText}>{storyObject.currentChapterDescription}</p>
                                </Grid>
                                <Grid item xs={12} sx={{...styles.infoPanel.card, maxHeight: '100%'}}> {/* DISCOVERED CHARACTERS */}
                                    <h2 style={styles.infoPanel.titleText}>Discovered Characters</h2>
                                    <Divider style={{color: 'white', marginTop: '4px', marginBottom: '4px'}} />
                                    {storyObject.discoveredCharacters.map((char, index) => (
                                        <Box key={index}>
                                            <h3>{char.name}</h3>
                                            <p>{char.description}</p>
                                            <p>{char.personality}</p>
                                            <i>{char.interactions[char.interactions.length - 1]}</i>
                                            <Divider />
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}> {/* RIGHT SIDE */}
                            <Grid container sx={{marginLeft: '8px', height: '500px'}}>
                                <Grid item xs={12} sx={styles.infoPanel.card}> {/* PLAYER INFO */}
                                    <h2 style={styles.infoPanel.titleText}>Player Character</h2>
                                    <Divider style={{color: 'white', marginTop: '4px', marginBottom: '4px'}} />
                                    <p style={styles.infoPanel.descriptionText}>{`Description: ${storyObject.player.description}`}</p>
                                    <p style={styles.infoPanel.descriptionText}>{`Occupation: ${storyObject.player.occupation}`}</p>
                                    <p style={styles.infoPanel.descriptionText}>{`${storyObject.player.age}, ${storyObject.player.gender}`}</p>
                                </Grid>
                                <Grid item xs={12} sx={{...styles.infoPanel.card, maxHeight: '320px'}}> {/* INVENTORY */}
                                    <h2 style={styles.infoPanel.titleText}>Inventory</h2>
                                    <Divider style={{color: 'white', marginTop: '4px', marginBottom: '4px'}} />
                                    {storyObject.inventory.map((item, index) => (
                                        <Box key={index}>
                                            <h3 style={styles.infoPanel.titleText}>{item.title}</h3>
                                            <p style={styles.infoPanel.descriptionText}>{item.description}</p>
                                            <Divider />
                                        </Box>
                                    ))}
                                </Grid>
                                <Grid item xs={12} sx={styles.infoPanel.card}> {/* HINT */}
                                        <Inplace>
                                            <InplaceDisplay><h2 style={{...styles.infoPanel.titleText, textAlign: 'center'}}>Enable Hints</h2></InplaceDisplay>
                                            <InplaceContent>
                                                <p>{`Hint: ${storyObject.hint === '' ? 'No hint available here!' : storyObject.hint}`}</p>
                                            </InplaceContent>
                                        </Inplace>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>)}
        </Box>
    );
};

export default GameView;
