import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid, Typography } from '@mui/material';
import useFAQ from '../../utils/useFAQ';

const FAQ = ({backgroundColor = '#5D3B9E', footer = ''}) => {
    const [faq, setFaq] = useFAQ();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

  return (
    <DialogComponent footer={footer} maxHeight='60vh'>
        <Grid container style={{height: '100%', marginTop: '8px', marginLeft: '16px', maxHeight: '40vh', overflowY: 'auto'}} spacing={2}>
            {faq.map((o, i) => (
                <Grid item xs={12} sx={{backgroundColor: colorScheme.background50, borderRadius: '5px', marginTop: '8px', border: `2px solid ${colorScheme.background25}`}}>
                    <Typography variant='h5'>{o['q']}</Typography>
                    <Typography sx={{marginTop: '8px', marginBottom: '16px'}}>{o['a']}</Typography>
                </Grid>
            ))}
        </Grid>
    </DialogComponent>
  );
};

export default FAQ;