import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useUserSettings } from '../../store/usersettings-context';
import { AnimatePresence, motion } from 'framer-motion';
import DialogComponent from '../layouts/DialogComponent';
import { Divider } from 'primereact/divider';

const Aliases = ({ backgroundColor = '#5D3B9E', userSettings = {}, update = () => {} }) => {
    const [newAlias, setNewAlias] = useState('');
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none !important' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none !important', // For Internet Explorer and Edge
        'scrollbar-width': 'none !important' // For Firefox
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const addHandler = () => {
        update({botAliases: [...userSettings.botAliases, newAlias]}).then(() => {
            setNewAlias('');
        });
    };

    const deleteHandler = (alias) => {
        const aliases = [...userSettings.botAliases].filter((a) => a !== alias);
        update({botAliases: aliases});
    };

    return (
        <DialogComponent footer='Aliases are nicknames for the bot; when they are used in a chat message, Ratbot will respond.' maxWidth='25vw' minWidth='25vw' maxHeight='75vh'>
        <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '16px', paddingRight: '16px', backgroundColor: colorScheme.background25}}>
            <Grid item xs={2}>
                <motion.div initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.3, delay: 0.1}}>
                    <Button icon='pi pi-plus' text onClick={addHandler} style={{width: '100%'}} />
                </motion.div>
            </Grid>
            <Grid item xs={10}>
                <motion.div initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.3, delay: 0.1}}>
                    <InputText style={{backgroundColor, width: '100%'}} value={newAlias} onChange={(e) => setNewAlias(e.target.value)} />
                </motion.div>
            </Grid>
            <Grid item xs={12} sx={{marginTop: '16px', maxHeight: '50vh', overflowY: 'auto', ...hideScrollbar, backgroundColor: colorScheme.background25}}>
                <AnimatePresence>
                    {userSettings.botAliases.map((a, i) => (
                        <motion.div key={a} initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, x: 20}} transition={{duration: 0.3, delay: 0.1}}>
                            <Grid container spacing={2} paddingBottom='8px'>
                                <Grid item xs={2}>
                                    <Button icon='pi pi-minus' text onClick={() => deleteHandler(a)} style={{width: '100%'}} />
                                </Grid>
                                <Grid item xs={10} sx={{ marginTop: '4px' }}>
                                    <Typography variant='h5' sx={{marginTop: '8px', width: '100%'}}>{a.toUpperCase()}</Typography>
                                </Grid>
                            </Grid>
                            {i !== (userSettings.botAliases.length - 1) && <Divider />}
                        </motion.div>
                    ))}
                </AnimatePresence>
            </Grid>
        </Grid>
        </DialogComponent>
    );
};

export default Aliases;