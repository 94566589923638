import { useState, useEffect, useMemo, useCallback } from 'react';
import { useUser } from '../context/UserContext';

const useAIPresets = () => {
  const authCtx = useUser();
  const [presets, setPresets] = useState({});
  const [fetchTrigger, setFetchTrigger] = useState(false); // New state to trigger fetch
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchData = useCallback(async () => {
    try {
      const presetResponse = await fetch(`${apiUrl}/aipresets?token=${authCtx.token}`);
      const presetData = await presetResponse.json();
      const sortedRes = Object.keys(presetData)
        .sort()
        .reduce((acc, key) => {
          acc[key] = presetData[key];
          return acc;
        }, {});
      setPresets(sortedRes);
    } catch (e) {
      console.log(e);
    }
  }, [apiUrl, authCtx.token]);

  useEffect(() => {
    fetchData();
  }, [apiUrl, fetchTrigger, fetchData]);

  // Memoize the result using useMemo
  const memoizedPresets = useMemo(() => presets, [presets]);

  // Function to trigger re-fetch
  const updatePresets = useCallback(() => {
    setFetchTrigger(prev => !prev);
  }, []);

  return { presets: memoizedPresets, updatePresets };
};

export default useAIPresets;