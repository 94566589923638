import { useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

const useStreamers = () => {
  const [streamers, setStreamers] = useState({});

  useEffect(() => {
    const fetchStreamersData = async () => {
      try {
        const response = await fetch(`${apiUrl}/streamers`);
        const res = await response.json();
        setStreamers(res);
      } catch (e) {
        console.log(e);
        return {};
      }
    };

    fetchStreamersData();
  }, []);

  return streamers;
};

export default useStreamers;
