import React, { useState } from 'react';
import Loading from './Loading';
import TrackTable from './TrackTable';
import useTransformTrackList from '../utils/useTransformTrackList';

const ViewingAlbum = ({ album = {}, loading, setLoading = () => {}, playTrack = async ({}) => {}, playerState = {}, togglePlayPause = () => {}, addToQueue = (uri) => {}, nextTrack = () => {} }) => {
    const tableData = useTransformTrackList(album['tracks']['items'], 'album');

    return (
        <React.Fragment>
            <Loading show={loading} />
            <TrackTable tableData={tableData} playerState={playerState} togglePlayPause={togglePlayPause} context={album} playTrack={playTrack} 
                addToQueue={addToQueue} nextTrack={nextTrack} type='album' />
        </React.Fragment>
    );
};

export default ViewingAlbum;