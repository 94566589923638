import React from 'react';
import Header from '../components/Header';
import HeaderWrapper from './headerwrapper';
import { Box } from '@mui/material';
import useIsMobile from './useIsMobile';

const MainPageChild = ({ children, title, subtitle, ...props }) => {
  const hasTitle = !!title;
  const hasSubtitle = !!subtitle;
  const isMobile = useIsMobile();

  return (
    <Box {...props} sx={{marginLeft: '20px'}}>
        {hasTitle && hasSubtitle && (
            <React.Fragment>
                {isMobile ? (
                    <Header title={title} subtitle={subtitle} />
                ) : (
                    <HeaderWrapper>
                        <Header title={title} subtitle={subtitle} />
                    </HeaderWrapper>
                )}
            </React.Fragment>
        )}
        {React.Children.map(children, (child) => {
            return React.cloneElement(child, { ...props });
        })}
    </Box>
  );
};

export default MainPageChild;