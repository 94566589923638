import { Grid, Typography } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';
import './editCustomVariable.css';
import { Dropdown } from 'primereact/dropdown';

const EditCustomVariable = ({backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, code = '', setOpenDialog = () => {}, setIsBlur = () => {}, deletekey = () => {}}) => {
    const [variable, setVariable] = useState(userSettings['customVariables'][code]);
    const [variableCode, setVariableCode] = useState(code);
    const [selectedUser, setSelectedUser] = useState('');
    const isAlphanumeric = (str) => /^[a-zA-Z0-9 _\-()&+%#/@?!*=']*$/.test(str);
    const datatypeTemplates = {
        number: <InputNumber    value={(variable['unique'] && selectedUser !== '') ? variable['userValues'][selectedUser] : variable['value']}
                                onValueChange={(e) => {
                                    console.log(e);
                                    if (variable['unique'] && selectedUser !== '' && e.value !== null) {
                                        setVariable((prev) => {
                                            const _variable = {...variable};
                                            console.log(selectedUser);
                                            _variable['userValues'][selectedUser] = e.value;
                                            return {..._variable};
                                        });
                                    } else {
                                        setVariable((prev) => ({...prev, value: e.value}));
                                    }
                                }}
                                showButtons
                                buttonLayout='horizontal'
                                step={1}
                                mode='decimal'
                                decrementButtonClassName='p-button-danger'
                                incrementButtonClassName='p-button-success'
                                incrementButtonIcon='pi pi-plus'
                                decrementButtonIcon='pi pi-minus'
                                style={{width: '100%', backgroundColor}}
                                className='custom-input-number'
                />,
        text: <InputText value={variable['value']} onChange={(e) => setVariable((prev) => ({...prev, value: e.target.value}))} style={{width: '100%', backgroundColor}} />,
        boolean: <ToggleButton onLabel="True" offLabel="False" onIcon="pi pi-check" offIcon="pi pi-times" style={{width: '100%'}}
            checked={variable['value']} onChange={(e) => setVariable((prev) => ({...prev, value: e.value}))} />
    };

    const isSaveDisabled = () => {
        if (variableCode.length < 1 || variableCode.length > 50) return true;
        if (variable.title.length < 1 || variable.title.length > 50) return true;
        if (variable.description.length < 1 || variable.description.length > 500) return true;
        if (!isAlphanumeric(variableCode) || !isAlphanumeric(variable.title) || !isAlphanumeric(variable.description)) return true;
        if (variableCode !== code) {
            if (variableCode in userSettings['customVariables']) return true;
        }
        return false;
    };
    const saveHandler = () => {
        const _customVariables = {...userSettings['customVariables']};
        _customVariables[variableCode] = {...variable};
        if (variableCode !== code) {
            deletekey(['customVariables', code]);
            delete _customVariables[code];
        }
        update({customVariables: _customVariables});
        setOpenDialog(false);
        setIsBlur(false);
    };
    const getDropdownOptions = () => {
        console.log(variable);
        if (Object.keys(variable['userValues']).length === 0) {
            return [];
        }
        const returnArr = Object.keys(variable['userValues']).map((u, i) => ({username: u}));
        return returnArr;
    };

    return (
        <DialogComponent footer='Set properties of your custom variable' maxHeight='85vh'>
            <Grid container spacing={2} sx={{marginLeft: '0px'}}>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Code</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputText value={variableCode} onChange={(e) => setVariableCode(e.target.value)} style={{width: '100%', backgroundColor}} />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Title</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputText value={variable.title} onChange={(e) => setVariable({...variable, title: e.target.value})} style={{width: '100%', backgroundColor}} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4'>Description</Typography>
                    <InputText value={variable.description} onChange={(e) => setVariable({...variable, description: e.target.value})} style={{width: '100%', backgroundColor}} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4' sx={{marginBottom: '8px'}}>Current Value</Typography>
                    {variable['unique'] && (<Dropdown style={{backgroundColor, marginBottom: '16px'}} value={{username: selectedUser}} options={getDropdownOptions()} optionLabel='username' 
                        placeholder='Select a user' onChange={(e) => setSelectedUser(e.value.username)} />)}
                    {datatypeTemplates[variable['datatype']]}
                </Grid>
                <Grid item xs={12} sx={{marginTop: '16px'}}>
                    <Button label='Save' severity='success' size='small' style={{width: '25%', float: 'right', marginLeft: '16px'}} disabled={isSaveDisabled()} onClick={saveHandler} />
                    <Button label='Close' severity='danger' size='small' style={{width: '25%', float: 'right'}} onClick={() => {
                        setOpenDialog(false);
                        setIsBlur(false);
                    }} />
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default EditCustomVariable;