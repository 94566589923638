import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import StartNewGame from './startNewGame';
import LoadExistingGame from './loadExistingGame';
import SavedStory from './SavedStory';
import { useUser } from '../../../context/UserContext';
import GameView from './gameView';

const InteractiveStory = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = async () => {}, 
                            refresh = async () => {}, setMainViewHeader = () => {}}) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [storyObject, setStoryObject] = useState(new SavedStory({}));
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // New state to handle loading
    const betaUsers = ['Snowboardrlz'];

    const initializeStory = async (reqObj = {title: '', description: ''}) => {
        try {
            setIsBlur(true);
            setLoading(true);
            const initResponse = await fetch(`${apiUrl}/game/story/initialize?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqObj)
            });
            if (initResponse.ok) {
                setIsBlur(false);
                setLoading(false);
                const initData = await initResponse.json();
                const newStory = new SavedStory(initData);
                setStoryObject(newStory);
            } else {
                setIsBlur(false);
                setLoading(false);
                setError(`Error: ${initResponse.statusText} (${initResponse.status})`);
            }
        } catch (err) {
            console.log(err);
            setIsBlur(false);
            setLoading(false);
            setError('An unexpected error occurred. Please try again later.');
        }
    };

    useEffect(() => {
        if (storyObject.title) {
            setMainViewHeader(storyObject.title);
        }
    }, [storyObject.title])

    return (
        <React.Fragment>
            <BlockUI blocked={loading} fullScreen>
                {(authCtx.isSubscribed || betaUsers.includes(authCtx.username)) ? (
                    <React.Fragment>
                        {storyObject.title === '' ? (
                            <Grid container spacing={4} sx={{paddingRight: '20px'}}>
                                <Grid item xs={6}>
                                    <StartNewGame backgroundColor={backgroundColor} setStoryObject={setStoryObject} initializeStory={initializeStory} />
                                </Grid>
                                <Grid item xs={6}>
                                    <LoadExistingGame backgroundColor={backgroundColor} setStoryObject={setStoryObject} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container>
                                <GameView backgroundColor={backgroundColor} storyObject={storyObject} setStoryObject={setStoryObject}
                                    setMainViewHeader={setMainViewHeader} />
                            </Grid>
                        )}
                    </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h1>This page is accessible to subscribers or beta testers only!</h1>
                        </React.Fragment>
                    )}
            </BlockUI>
        </React.Fragment>
    );
};

export default InteractiveStory;