import React, { useState } from 'react';
import { Button, Card, CardActionArea, CardContent, ListItemText, Typography } from '@mui/material';
import useTriggerTemplates from '../../utils/useTriggerTemplates';
import useIsMobile from '../../utils/useIsMobile';
import { motion } from 'framer-motion';
import { useUserSettings } from '../../store/usersettings-context';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useUser } from '../../context/UserContext';

const TriggerItem = ({triggerObj = {}, backgroundColor = '#5D3B9E', cardStyle = {}, openDialogComponent = () => {}, userSettings = {}, update = () => {}}) => {
    const authCtx = useUser();
    const [triggerTemplates, setTriggerTemplates] = useTriggerTemplates();
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMobile = useIsMobile();
    const template = triggerTemplates[triggerObj['trigger']];
    const permissionDictionary = {
        'first_time_message': [],
        'timer': [],
        'keyword': [],
        'chat_inactivity': [],
        'twitch_raid_from': [],
        'twitch_raid_to': [],
        'spotify_new_song': ['channel:manage:redemptions', 'channel:read:redemptions'],
        'twitch_channel_update': [],
        'twitch_channel_follow': ['moderator:read:followers'],
        'twitch_ad_break_begin': ['channel:read:ads'],
        'twitch_channel_subscribe': ['channel:read:subscriptions'],
        'twitch_channel_subscription_message': ['channel:read:subscriptions'],
        'twitch_channel_subscription_gift': ['channel:read:subscriptions'],
        'twitch_shoutout_create': ['moderator:read:shoutouts', 'moderator:manage:shoutouts'],
        'twitch_shoutout_receive': ['moderator:read:shoutouts', 'moderator:manage:shoutouts']
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const hoverAnimation = {
        scale: [1, 1.025, 1],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.5, 1]
        }
    };
    const clickAnimation = {
        scale: [1, 0.95, 1],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.5, 1]
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };
    const deleteHandler = async (e) => {
        e.stopPropagation();
        const _triggers = [...userSettings['triggers']].filter((tObj) => tObj['id'] !== triggerObj['id']);
        setLoading(true);
        await update({triggers: [..._triggers]});
        setLoading(false);
    };
    const hasPermissionScope = (scope = []) => {
        if ('twitchPermissions' in userSettings === false) return false;
        if (scope.length === 0) return true;
        for (let index in scope) {
            if (userSettings['twitchPermissions'].includes(scope[index])) return true;
        }
        return false;
    };
    const requiresAction = (trigger) => {
        return hasPermissionScope(permissionDictionary[trigger]);
    };
    const addPermission = (e) => {
        e.stopPropagation();
        openDialogComponent('addtwitchpermission', {scope: [...permissionDictionary[triggerObj['trigger']]]});
    };

  return (
    <motion.div whileHover={!isMobile && hoverAnimation} whileTap={!isMobile && clickAnimation} onHoverStart={() => setIsHovered(true)} onHoverEnd={() => setIsHovered(false)}>
        <Card sx={{...cardStyle}}>
            <CardActionArea disableRipple disableTouchRipple sx={{height: cardStyle.height}} onClick={() => openDialogComponent('newtrigger', triggerObj)}>
                {(isHovered || isMobile) && (
                    <CardContent sx={{position: 'absolute', top: -6, right: -6}}>
                        <Button size='medium' variant='text' color='error' onClick={deleteHandler} disabled={loading}>Delete</Button>
                    </CardContent>
                )}
                {!requiresAction(triggerObj['trigger']) && !authCtx.editor && (
                    <CardContent sx={{position: 'absolute', top: -6, left: -6}}>
                        <Button size='medium' variant='text' color='warning' startIcon={<PriorityHighIcon />}
                            onClick={(e) => addPermission(e)}>Action Required</Button>
                    </CardContent>
                )}
                <CardContent sx={{textAlign: 'center'}}>
                    <ListItemText primary={triggerObj['notes']} />
                    {triggerObj['trigger'] === 'timer' && (<Typography>{`Interval: ${formatTime(triggerObj['timer'])}`}</Typography>)}
                    {triggerObj['trigger'] === 'chat_inactivity' && (<Typography>{`Timer: ${formatTime(triggerObj['chat_inactivity'])}`}</Typography>)}
                    {triggerObj['trigger'] === 'keyword' && (<Typography>{`Keyword: ${triggerObj['keyword']}`}</Typography>)}
                    <Typography>{`${triggerObj['actions'].length} actions`}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </motion.div>
  );
};

export default TriggerItem;