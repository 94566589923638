import { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';

const useIsSubscribed = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/subscription/status?token=${authCtx.token}`);
                const res = await response.json();
                const status = res['status'];
                if (status === 'active' || status === 'trialing' || status === 'past_due') {
                    setIsSubscribed(true);
                } else {
                    setIsSubscribed(false);
                }
            } catch (error) {
            }
        };
        checkSubscription();
    }, [apiUrl, authCtx]);

    return isSubscribed;
};

export default useIsSubscribed;