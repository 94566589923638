import React, { useEffect, useState } from 'react';
import { tokens } from '../../../theme';
import AuthContext from '../../../store/auth-context';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import MainPage from '../../../utils/mainpage';
import MainPageWrapper from '../../../utils/mainpagewrapper';
import MainPageChild from '../../../utils/mainpagechild';
import useIsMobile from '../../../utils/useIsMobile';
import Products from './products';
import Prices from './prices';
import Customers from './customers';
import Subscriptions from './subscriptions';

const ManagePlans = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const authCtx = useContext(AuthContext);
    const isMobile = useIsMobile();
    const [backgroundColor, setBackgroundColor] = useState(colors.primary[400]);
    const [selectedTab, setSelectedTab] = useState(0);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (isMobile) {
            setBackgroundColor(colors.primary[600]);
        } else {
            setBackgroundColor(colors.primary[400]);
        }
    }, [isMobile]);

  return (
    <MainPage backgroundColor={backgroundColor}>
        <MainPageWrapper backgroundColor={backgroundColor}>
            <MainPageChild title="Finances and Billing" subtitle="Manage all finance and billing related data">
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs textColor="secondary" indicatorColor="secondary" value={selectedTab} onChange={(e, v) => {setSelectedTab(v)}}>
                        <Tab label="Products" />
                        <Tab label="Prices" />
                        <Tab label="Customers" />
                        <Tab label="Subscriptions" />
                        <Tab label="Pricing Preview" />
                    </Tabs>
                </Box>
                <Box sx={{marginTop: '16px'}}>
                    {selectedTab === 0 && (
                        <Products />
                    )}
                    {selectedTab === 1 && (
                        <Prices />
                    )}
                    {selectedTab === 2 && (
                        <Customers />
                    )}
                    {selectedTab === 3 && (
                        <Subscriptions />
                    )}
                    {selectedTab === 4 && (
                        <></>
                    )}
                </Box>
            </MainPageChild>
        </MainPageWrapper>
    </MainPage>
  )
}

export default ManagePlans;