import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import useSystemVariables from '../../utils/useSystemVariables';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';

const Variables = ({backgroundColor = '#5D3B9E'}) => {
    const [systemVariables, setSystemVariables] = useSystemVariables();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };

    const SystemVariable = ({ variable }) => {
        const maxDescriptionLength = 100;

        const copyToClipboard = (text) => {
            navigator.clipboard.writeText(text);
        };
    
        const truncate = (text, length) => {
            if (text.length <= length) return text;
            return text.substring(0, length) + "...";
        };

        const cardStyle = {
            background: `radial-gradient(circle, ${colorScheme.background75} 0%, ${colorScheme.background25} 100%)`,
            border: `2px solid ${colorScheme.background75}`,
            width: '100%'
        };
    
        return (
            <Grid item xs={(isMobile && isLarge) ? 12 : (!isMobile && isLarge) ? 3 : 2} 
                sx={{...cardStyle, position: 'relative', borderRadius: '5px', margin: '8px', height: '180px', paddingRight: '8px'}}>
                <Typography variant='h5'>{variable['title']}</Typography>
                {variable['description'].length > maxDescriptionLength ? (
                <Tooltip title={<Typography>{variable['description']}</Typography>} placement="top" arrow>
                    <Typography>{truncate(variable['description'], maxDescriptionLength)}</Typography>
                </Tooltip>
                ) : (
                    <Typography>{variable['description']}</Typography>
                )}
                <Button endIcon={<FileCopyIcon />} sx={{ position: 'absolute', bottom: 8, left: 12 }} variant="text" 
                    onClick={() => copyToClipboard(variable['copy'])} color='secondary'>{variable['copy']}</Button>
            </Grid>
        );
    };

    return (
        <Grid container spacing={2} sx={{...hideScrollbar, maxHeight: '80vh', overflowY: 'auto', padding: '16px', paddingTop: isMobile && '0px', paddingRight: isMobile && '0px', paddingBottom: isMobile && '72px', marginTop: '16px'}}>
                {systemVariables.map((vObj, index) => (
                    <React.Fragment key={index}>
                        <SystemVariable variable={vObj} />
                    </React.Fragment>
                ))}
        </Grid>
    );    
};

export default Variables;