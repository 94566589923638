import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const Customers = () => {
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [customersList, setCustomersList] = useState([]);
    const boundingBoxStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        paddingBottom: '32px'
    };

    const Customer = ({customer}) => {
        const [connectedPaymentMethod, setConnectedPaymentMethod] = useState({});
        const convertTimestampToDateFormat = (timestamp) => {
            const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
          
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
          
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        };

        useEffect(() => {
            if (customer.invoice_settings.default_payment_method !== '' && Object.keys(connectedPaymentMethod).length === 0) {
                fetch(`${apiUrl}/stripe/paymentmethod/${customer.invoice_settings.default_payment_method}?token=${authCtx.token}`).then(response => response.json()).then(res => {
                    setConnectedPaymentMethod(res);
                }).catch(e => console.log(e));
            }
        }, []);
        
        return (
            <Grid item sx={{margin: '16px'}}>
                <motion.div whileHover={{scale: 1.1}}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">{customer.name}</Typography>
                            <br />
                            <Typography>{`Email: ${customer.email}`}</Typography>
                            <Typography>{`Created: ${convertTimestampToDateFormat(customer.created)}`}</Typography>
                            <Typography>{`Currency: ${customer.currency}`}</Typography>
                            <Typography>{`Balance: ${customer.balance}`}</Typography>
                            <Typography>{`Discount: ${customer.discount}`}</Typography>
                            <Typography>{`Country: ${customer.address.country}`}</Typography>
                            <Typography>{`Phone: ${customer.phone}`}</Typography>
                            {Object.keys(connectedPaymentMethod).length > 0 && (
                                <React.Fragment>
                                    <br />
                                    <Typography variant="h3">Payment method</Typography>
                                    <Typography>{`Type: ${connectedPaymentMethod.type}`}</Typography>
                                    {connectedPaymentMethod.type === 'card' && (
                                        <React.Fragment>
                                            <Typography>{`Brand: ${connectedPaymentMethod.card.brand}`}</Typography>
                                            <Typography>{`Expires: ${connectedPaymentMethod.card.exp_month}/${connectedPaymentMethod.card.exp_year}`}</Typography>
                                            <Typography>{`Fingerprint: ${connectedPaymentMethod.card.fingerprint}`}</Typography>
                                            <Typography>{`Funding: ${connectedPaymentMethod.card.funding}`}</Typography>
                                            <Typography>{`3D Secure: ${connectedPaymentMethod.card.three_d_secure_usage.supported}`}</Typography>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            {Object.keys(customer.metadata).length > 0 && (<Typography>{`Other: ${JSON.stringify(customer.metadata)}`}</Typography>)}
                        </CardContent>
                    </Card>
                </motion.div>
            </Grid>
        );
    };

    useEffect(() => {
        if (customersList.length === 0) {
            fetch(`${apiUrl}/stripe/customers?token=${authCtx.token}`).then(response => response.json()).then(res => {
                setCustomersList(res['data']);
            }).catch(e => console.log(e));
        }
    }, []);
    

  return (
    <React.Fragment>
        <Typography variant="h2">Customers</Typography>
        <br />
        {Object.keys(customersList).length > 0 ? (
            <Grid container sx={boundingBoxStyle}>
                {customersList.map((c, i) => (
                    <motion.div key={c.id} animate={{x: 0}} initial={{x: '80vw'}} transition={{delay: i * 0.1}}><Customer customer={c} /></motion.div>
                ))}
            </Grid>
        ) : (
            <Typography variant="h3">Loading customers...</Typography>
        )}
    </React.Fragment>
  )
};

export default Customers;