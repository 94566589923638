import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Grid, Typography } from '@mui/material';
import './obstts.css';
import useIsMobile from '../../utils/useIsMobile';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

const OBSTTS = ({backgroundColor = '#5D3B9E', footer = '', userSettings = {}, update = () => {}, setOpenDialog = () => {}, setIsBlur = () => {}}) => {
    const [selectedVoice, setSelectedVoice] = useState(userSettings['modData']?.['obs']?.['voice'] ?? '');
    const [passPhrase, setPassPhrase] = useState(userSettings['modData']?.['obs']?.['pp'] ?? '');
    const [audioSrc, setAudioSrc] = useState('');
    const [tosFilter, setTosFilter] = useState(userSettings['modData']?.['obs']?.['tosFilter'] ?? true);
    const isMobile = useIsMobile();
    const apiUrl = process.env.REACT_APP_API_URL;
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const voiceArr = [
        { name: 'Male 1', code: 0, file: 'sample_1.mp3' },
        { name: 'Male 2', code: 1, file: 'sample_2.mp3' },
        { name: 'Male 3', code: 2, file: 'sample_3.mp3' },
        { name: 'Male 4', code: 3, file: 'sample_4.mp3' },
        { name: 'Female 1', code: 4, file: 'sample_5.mp3' },
        { name: 'Female 2', code: 5, file: 'sample_6.mp3' }
    ];

    const getMP3URL = (filename) => {
        return `https://ratbot.store/obs/ratbotttssamples/${filename}`;
        //return `${apiUrl.split(':')[0]}:${apiUrl.split(':')[1]}/obs/ratbotttssamples/${filename}`;
    };
    const saveHandler = () => {
        update({modData: {...userSettings['modData'], obs: {voice: selectedVoice, pp: passPhrase, tosFilter}}});
        setOpenDialog(false);
        setIsBlur(false);
    };
    const getSaveValidity = () => {
        if (selectedVoice === '') return false;
        if (passPhrase === '') return false;
        return true;
    };

    useEffect(() => {
        if ('obs' in userSettings['modData'] === false) {
            update({modData: {...userSettings['modData'], obs: {
                voice: '',
                pp: '',
                reward: ''
            }}});
        }

        const voice = voiceArr.find(v => v.name === selectedVoice);
        if (voice) {
            setAudioSrc(getMP3URL(voice.file));
        }
    }, [selectedVoice, userSettings, voiceArr]);

    return (
        <DialogComponent footer={footer} minWidth='600px' maxHeight='65vh'>
            <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Voice Selection</Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Dropdown style={{width: '100%', backgroundColor}} placeholder='Voices' options={voiceArr} optionLabel='name'
                              value={voiceArr.find((obj) => obj.name === selectedVoice)} 
                              onChange={(e) => setSelectedVoice(e.value.name)} />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    {/* Audio player */}
                    <audio controls src={audioSrc} style={{width: '100%'}}>
                        Your browser does not support the audio element.
                    </audio>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>OBS Passphrase</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Password value={passPhrase} onChange={(e) => setPassPhrase(e.target.value)} feedback={true} tabIndex={1}
                        style={{width: '100%', backgroundColor: backgroundColor}} toggleMask className='custom-password-input' />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Block non-TOS messages</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputSwitch checked={tosFilter} onChange={(e) => setTosFilter(e.value)} />
                </Grid>
                <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <Button severity='success' disabled={!getSaveValidity()} style={{marginLeft: '16px'}} onClick={saveHandler}>Save</Button>
                    {!isMobile && (<Button severity='danger' onClick={() => {setOpenDialog(false); setIsBlur(false);}}>Close</Button>)}
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default OBSTTS;