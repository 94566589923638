import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { useUser } from '../../context/UserContext';

const AddTwitchPermission = ({backgroundColor = '#5D3B9E', footer = '', scope = []}) => {
    const [elevatedLink, setElevatedLink] = useState('');
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();

    useEffect(() => {
        if (elevatedLink === '') {
            getElevatedTwitchUrl(scope);
        }
    }, []);

    const getElevatedTwitchUrl = async (newScopes) => {
        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}/user/elevatedtwitchurl?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({scope: newScopes, redirect: '/settings?p=twitch'})
            });
            const res = await response.json();
            setElevatedLink(res['status']);
            setLoading(false);
        } catch (e) {
            console.log(e);
            return 'https://ratbot.store/settings';
        }
    };

    return (
        <DialogComponent footer={footer} maxHeight='60vh'>
            <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>{`Request to add permission scope(s): ${scope.join(', ')}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button label='Authorize' disabled={loading} onClick={() => window.location.href = elevatedLink} />
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default AddTwitchPermission;