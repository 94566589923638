import { useState, useEffect } from 'react';

const useWindowSizeThreshold = (threshold, isUnder = true) => {
  const [matches, setMatches] = useState(isUnder ? window.innerWidth <= threshold : window.innerWidth >= threshold);

  useEffect(() => {
    const handleResize = () => {
      if (isUnder) {
        setMatches(window.innerWidth <= threshold);
      } else {
        setMatches(window.innerWidth >= threshold);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold, isUnder]);

  return matches;
};

export default useWindowSizeThreshold;