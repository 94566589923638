import React, { useEffect } from 'react';
import HeaderWrapper from '../utils/headerwrapper';
import MainPage from '../utils/mainpage';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const ErrorPage = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 1000);
  }, []);
  

  return (
    <MainPage>
      <HeaderWrapper>
        <Header title="Error" subtitle={props.msg} />
      </HeaderWrapper>
      <img src={`https://http.cat/${props.status}`} />
    </MainPage>
  )
}

export default ErrorPage;