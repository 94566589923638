import { useEffect, useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

const useReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        const response = await fetch(`${apiUrl}/releasenotes`);
        if (!response.ok) {
          throw new Error('Failed to fetch release notes');
        }
        const data = await response.json();
        setReleaseNotes(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchReleaseNotes();
  }, []);

  const addReleaseNote = async (newEntry, authCtx) => {
    try {
        const response = await fetch(`${apiUrl}/admin/releasenotes?token=${authCtx.token}`, {
            method: 'POST',
            body: JSON.stringify(newEntry),
            headers: {
              'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to add release note');
        }
        const data = await response.json();
        setReleaseNotes(data);
        return true;
    } catch (e) {
        setError(e);
        return false;
    }
  };

  return { releaseNotes, loading, error, addReleaseNote };
};

export default useReleaseNotes;