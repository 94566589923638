import { useState, useEffect, useMemo, useCallback } from 'react';
import { useUser } from '../context/UserContext';

const useAllStories = (devMode = false) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [stories, setStories] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(false);

    // Dummy data for development mode
    const dummyData = [
        {
            "title": "The Timeless Cafe",
            "description": "In a quaint, unassuming cafe, every cup of coffee tells the story of its drinker. Players take on the role of the barista, discovering that by listening carefully, they can alter the course of the customers' lives. Each interaction reveals secrets, past regrets, and future aspirations, leading to heartwarming or heart-wrenching outcomes based on the player’s choices.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Echoes of the Stellar War",
            "description": "In a universe ravaged by a decades-long war between powerful factions, players become a diplomat tasked with forging alliances. As they navigate politics, betrayal, and ancient alien technologies, their choices will determine the fate of civilizations and the very existence of peace among the stars.",
            "genre": "Space Opera"
        },
        {
            "title": "The Forgotten Isle",
            "description": "Stranded on an uncharted island after a storm, players must uncover the mysteries of an ancient civilization while surviving the elements. Every decision about resource management and interaction with the island's peculiar inhabitants shapes their escape and the discovery of the island's long-lost treasures or curses.",
            "genre": "Adventure"
        },
        {
            "title": "Whispers in the Attic",
            "description": "After inheriting an old family home, players discover a hidden attic filled with letters and artifacts from their ancestors. Each letter reveals a mystery or a family secret, prompting players to make choices that could either heal old wounds or awaken new tensions among living relatives.",
            "genre": "Mystery"
        },
        {
            "title": "Glimpse of Tomorrow",
            "description": "In a world where future visions can be seen through dreams, players are gifted—or cursed—with the ability to glimpse possible future events. Their choices will ripple through time, leading to either disastrous outcomes or profound transformations as they decide which visions to act upon or ignore.",
            "genre": "Fantasy"
        },
        {
            "title": "The Last Refuge",
            "description": "Set in a post-apocalyptic landscape, players are part of a community that has been fortified against the ravaging threats from mutated wildlife and hostile survivors. Choices revolve around resource scavenging, forming alliances, or leading expeditions, shaping the future of the community and its beliefs on survival.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "The Office Chronicles",
            "description": "Navigate the highs and lows of a quirky corporate office. Players make decisions that affect team dynamics, relationships with colleagues, and their own career trajectory, revealing humorous and poignant moments in the everyday grind of office life. The goal is to either climb the corporate ladder or just get through the day unscathed.",
            "genre": "Comedy/Drama"
        },
        {
            "title": "Lands of Myth and Magic",
            "description": "Players step into a vibrant fantasy realm where various races and factions vie for power. As a wandering hero, their choices will determine political alliances and influence the balance of magic, forcing them to choose sides in a brewing conflict or seek a path of neutrality that could change the fate of the land.",
            "genre": "Fantasy"
        },
        {
            "title": "The Sound of Silence",
            "description": "In a world afflicted by an inexplicable phenomenon where music has vanished, players are tasked with restoring melody to society. They journey through silent cities, gather diverse cultures, and explore the meaning of sound—every decision spurring either the resurgence of joy or the deepening of despair.",
            "genre": "Horror/Mystery"
        },
        {
            "title": "Cybernetic Shadows",
            "description": "In a futuristic city where reality and virtual existence blur, players take on the role of a hacker uncovering dark corporate secrets. As they delve deeper into the underbelly of the city, decisions about technology, morality, and alliances will forge their path as a rebel hero or a pawn in a larger game.",
            "genre": "Sci-Fi"
        },
        {
            "title": "Small Town Secrets",
            "description": "In the quaint town of Maplewood, a sudden disappearance shakes the community. As a curious local, players dive into the tangled lives of their neighbors, uncovering hidden motives and buried secrets that could change everything they thought they knew about their idyllic home.",
            "genre": "Mystery"
        },
        {
            "title": "The Last Train Home",
            "description": "Set in a world where reality blurs between fantasy and modernity, players experience a single night on a magical train that appears at random intervals. Each passenger has a story to tell, and their fates intertwine, allowing players to decide who to help and which paths to explore before the train reaches its final destination.",
            "genre": "Fantasy"
        },
        {
            "title": "Corporate Climb",
            "description": "Players take on the role of a new employee in a competitive tech company battling against office politics, rivalries, and ethical dilemmas. They must navigate career advancement, ally with colleagues, and make choices that could affect their future and the company's fate.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Starlight Revolution",
            "description": "In a distant galaxy, players become rebels fighting against a tyrannical empire. With the power to build alliances, sabotage operations, and unlock ancient technologies, every choice shapes the outcome of their quest for liberation and the balance of power in the universe.",
            "genre": "Space Opera"
        },
        {
            "title": "Ghosts of the Past",
            "description": "In a long-abandoned mansion rumored to be haunted, players investigate the tragic history of its former residents. As they uncover forgotten tales and confront lingering spirits, the narratives shift based on choices, leading to either revelations or further tragedies.",
            "genre": "Horror"
        },
        {
            "title": "As the World Burns",
            "description": "In a post-apocalyptic wasteland where nature has reclaimed cities, players must navigate survival, scavenging for resources and forming alliances with other survivors. Choices impact trust and conflict, ultimately determining if they can rebuild a semblance of society or if chaos reigns.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "The Timekeeper's Dilemma",
            "description": "Players become a timekeeper, tasked with maintaining the timeline while preventing paradoxes. Each decision affects history in unpredictable ways, leading to outcomes that could alter the fabric of reality. Choices matter deeply as players balance personal desires with the greater good.",
            "genre": "Sci-Fi"
        },
        {
            "title": "The Artisan's Journey",
            "description": "Set in a bustling medieval market, players are aspiring artisans looking to perfect their craft. Through interactions with patrons and mentors, choices influence their reputation and success, leading to the creation of masterpieces or a struggle for survival in the competitive world of trade.",
            "genre": "Fantasy"
        },
        {
            "title": "Beyond the Veil",
            "description": "In a world where dreams can be manipulated by a mysterious organization, players must navigate between the dreamscape and reality. Their choices will impact not just their own dreams but those of others, creating a network of influence that can either liberate or enslave.",
            "genre": "Fantasy/Sci-Fi"
        },
        {
            "title": "The Lost Art of Friendship",
            "description": "In a contemporary setting, players navigate the complexities of adulthood as friends begin to drift apart due to life changes. Each choice leads to different paths of reconnection or conflict, allowing players to explore themes of friendship, loyalty, and personal growth.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Whispers of the Willow Grove",
            "description": "In the heart of a small town lies a mysterious grove rumored to grant wishes. When a series of strange occurrences disrupt the daily life of the townsfolk, players must investigate the connection between the grove's magic and their own desires. Every choice shapes friendships and reveals personal secrets, all while unraveling the grove's enigmatic legacy.",
            "genre": "Mystery"
        },
        {
            "title": "Threads of Fate",
            "description": "A struggling tailor discovers an ancient sewing machine that weaves the fabric of destiny. Each garment created influences the lives of the wearer in startling ways. As players choose how to craft each piece, they must navigate the consequences—transforming relationships, careers, and futures in a world where fashion is anything but frivolous.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Last Light of Humanity",
            "description": "In a post-apocalyptic world where sunlight is scarce and survival hinges on harvesting energy from the remnants of technology, players lead a ragtag group of survivors. Every decision impacts the group’s morale, resources, and safety as they navigate threats both from the environment and rival factions vying for power.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "Galactic Renegades",
            "description": "Join a crew of space outlaws as they traverse the galaxy, dodging bounty hunters and corporate overlords. Players can choose to form alliances, betray comrades, or seek redemption, all while uncovering the hidden truths of a galaxy on the brink of war and their own place within it.",
            "genre": "Space Opera"
        },
        {
            "title": "The Forgotten Heirloom",
            "description": "An ordinary family finds an heirloom that holds the key to a long-buried family secret. As players explore their ancestral home, they encounter puzzling clues, ghostly apparitions, and the shadows of the past. Every choice reveals new layers of family history or risks the bond that ties them together.",
            "genre": "Horror"
        },
        {
            "title": "Recipe for Disaster",
            "description": "Set in a high-stakes cooking competition, players must balance culinary creativity with interpersonal drama. Every dish prepared could secure a spot in the final showdown or lead to shocking eliminations. Choices made in the kitchen and the backroom politics of the competitors shape not only the outcome of the contest but personal growth and friendships.",
            "genre": "Drama"
        },
        {
            "title": "The Timekeeper's Apprentice",
            "description": "After discovering an ancient clock tower controlling time itself, an unsuspecting apprentice is drawn into a battle between factions looking to harness its power. Players must navigate time travel, making choices that affect not just their timeline but also the fabric of history itself.",
            "genre": "Fantasy"
        },
        {
            "title": "Eco-Warriors of Tomorrow",
            "description": "In a near-future world ravaged by climate disasters, a group of activists uncovers a corporate conspiracy poised to finish the planet's destruction. Players must make choices that affect their race against time, resource allocation, and community trust in order to rally support and enact real change before it's too late.",
            "genre": "Sci-Fi"
        },
        {
            "title": "Secrets Beneath the Surface",
            "description": "In a quaint fishing village, peculiar creatures start appearing in the waters, and villagers report strange happenings during the night. As players mingle with locals and investigate underwater phenomena, their choices determine alliances, uncover hidden truths, and seal the fate of the village’s harmony with the sea.",
            "genre": "Adventure"
        },
        {
            "title": "The Last Beacon",
            "description": "With Earth's last bastion of civilization now under siege, players assume the role of a Guardian tasked with defending the beacon of hope. Choices made in strategy, diplomacy, and resource management will determine whether they can hold the line against inevitable collapse or spark a resurgence of humanity’s spirit.",
            "genre": "Epic"
        },
        {
            "title": "Echoes of the Forgotten",
            "description": "In a quiet town where memories seem to fade, you discover that certain places still hold onto echoes from the past. Engage with townsfolk, unravel the secrets of their forgotten memories, and choose whether to help them reclaim their past or let it slip away forever.",
            "genre": "Slice-of-Life Mystery"
        },
        {
            "title": "The Last Starship",
            "description": "As the captain of the last starship in a dying universe, you must navigate through a galaxy of ancient relics and hostile factions. Make strategic decisions that affect your crew's loyalty, the fate of lost civilizations, and the ultimate quest for a new home among the stars.",
            "genre": "Space Opera"
        },
        {
            "title": "Roots of Deceit",
            "description": "Set in a small town with deep-rooted traditions, you've returned after years away to find unsettling changes. Shadowed by the historic facade, uncover the malignant truths behind family legacies and community secrets as your choices determine who to trust and who to expose.",
            "genre": "Mystery/Thriller"
        },
        {
            "title": "Fragments of a World Once Whole",
            "description": "In a post-apocalyptic landscape, society has fractured into nomadic tribes. Choose your alliances, gather resources, and confront moral dilemmas as you search for the last remnants of technology that could reshape the future or plunge it into chaos.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "Whispers in the Dark",
            "description": "A paranormal reality show turns terrifying when the crew uncovers a haunted mansion with a dark history. Players must make choices that influence their survival as they navigate challenges between ghostly entities and the secrets that bind them to the land.",
            "genre": "Horror"
        },
        {
            "title": "The Alchemist's Apprentice",
            "description": "As a young apprentice in a vibrant fantasy city, your choices shape your abilities, relationships, and the very essence of magic itself. Balance ambitions with ethical dilemmas as you uncover conspiracies that threaten the realm or decide to carve your own destiny.",
            "genre": "Fantasy"
        },
        {
            "title": "Corporate Chronicles: The Office Saga",
            "description": "Step into the shoes of an employee navigating the quirky dynamics of a bustling office. Choose how to handle office politics, rivalries, and unexpected adventures with colleagues as you strive for success—or simply to survive the week.",
            "genre": "Comedy/Drama"
        },
        {
            "title": "Chronicles of the Lost Time",
            "description": "After discovering an ancient timepiece, you’re thrust into a world where time travel is a reality. Make choices that affect the timeline, exploring various eras and their consequences, while racing against rival time travelers who seek to rewrite history for their gain.",
            "genre": "Sci-Fi Adventure"
        },
        {
            "title": "Legacies of War",
            "description": "In a world still recovering from a devastating conflict, choose a path as a soldier, peacekeeper, or civilian. Your decisions will shape alliances, rivalries, and the founding of a new society amidst the echoes of war, as idealism clashes with pragmatism.",
            "genre": "Historical Fiction"
        },
        {
            "title": "The Garden of Secrets",
            "description": "As the caretaker of a peculiar garden where every flower holds a secret, your choices will allow you to unveil or conceal the stories of those who visit. Explore themes of grief, love, and growth, while shaping the destinies of others through your interactions.",
            "genre": "Fantasy/Slice-of-Life"
        },
        {
            "title": "Whispers in the Maple Grove",
            "description": "In a small town surrounded by an ancient forest, strange occurrences spark fear and curiosity. Players take on the role of a local journalist uncovering the truth behind the town's folklore, while navigating relationships with the quirky residents and making choices that reveal hidden secrets. Will they expose a long-buried mystery or become part of it?",
            "genre": "Mystery"
        },
        {
            "title": "The Last Greenhouse",
            "description": "In a future where the Earth is scorched and biodiversity is nearly extinct, players are part of a group of botanists tasked with preserving the last known plant species in a hidden greenhouse. Choices determine alliances between factions seeking to control or destroy their work—will they defend their sanctuary or risk everything to venture into the wasteland?",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "Starlight Diplomacy",
            "description": "In a galaxy teetering on the brink of war, players assume the role of an interstellar diplomat. Their mission is to heal rifts between alien species, each with unique cultures and histories, through dialogue and intrigue. Choices impact alliances, and one misstep could spark a cosmic conflict or bring about a peace previously deemed impossible.",
            "genre": "Space Opera"
        },
        {
            "title": "Supernatural Side Hustle",
            "description": "In a bustling city, players take on the role of a regular person who discovers their ability to communicate with supernatural beings. Juggling a nine-to-five job and an unexpected side hustle dealing with ghosts and magical entities, players must make choices that balance their mundane life with extraordinary challenges.",
            "genre": "Urban Fantasy"
        },
        {
            "title": "Forgotten Highways",
            "description": "Travel through a seemingly endless road trip across a surreal, dreamlike landscape where the rules of reality are malleable. Players navigate through the journeys of lost souls and enigmatic beings, making choices that shape their own identity while unraveling the meanings behind their travels and personal regrets.",
            "genre": "Fantasy"
        },
        {
            "title": "Corporate Conspiracy",
            "description": "Set in a near-future mega-corporation, players are employees uncovering a sinister plot that could endanger them and the world. Players must gather evidence, form alliances, and decide whether to expose the truth or protect their jobs. Each choice leads them deeper into a web of corporate deceit and personal moral dilemmas.",
            "genre": "Thriller"
        },
        {
            "title": "The Art of Winter",
            "description": "In a picturesque mountain town enduring an eternal winter, players explore the lives of local artists struggling to find inspiration amid gloom. Choices influence their relationships and creative journeys, unraveling personal backstories while battling the desolation brought on by the unyielding cold. Can they find warmth within each other’s hearts?",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Echoes of the Ancients",
            "description": "Amidst the ruins of a long-lost civilization, players uncover powerful artifacts that resonate with the echoes of the past. As unwitting archaeologists, their choices determine how the knowledge they gain influences the present society, either leading to technological advancement or unleashing ancient horrors upon the world.",
            "genre": "Adventure"
        },
        {
            "title": "Parallel Hearts",
            "description": "In a world where technology allows people to enter alternate realities based on their choices, players navigate through multiple lives they could have lived. Every decision influences their present self, creating a tapestry of interwoven lives that challenges the meaning of happiness and fulfillment. Which path will yield the life they desire?",
            "genre": "Science Fiction"
        },
        {
            "title": "The Haunting of Eldridge Manor",
            "description": "Players inherit an old manor known for mysterious disappearances and ghostly sightings. As they explore its shadowy corridors, they must make crucial decisions on how to face the spirits trapped within. Will they help the apparitions find peace or succumb to the manor’s dark history themselves?",
            "genre": "Horror"
        },
        {
            "title": "The Reluctant Hero of Elmwood",
            "description": "In a seemingly ordinary small town, players navigate the life of a reclusive librarian who discovers a hidden power to communicate with the town’s long-forgotten spirits. As strange occurrences plague Elmwood, players must choose whether to embrace this newfound ability, solve a decades-old mystery, or keep their secret. The choices they make will impact the town's fate and reveal deeper connections to their own past.",
            "genre": "Mystery/Fantasy"
        },
        {
            "title": "Galactic Chef Showdown",
            "description": "In a bustling intergalactic food competition, players take on the role of an aspiring chef who travels to various planets to collect exotic ingredients and learn unique cooking techniques. Choices shape their culinary style, alliances with other competitors, and the outcome of high-stakes cook-offs. Will they uphold their values of authenticity, or will they succumb to the cutthroat pressures of fame and fortune?",
            "genre": "Sci-Fi/Fantasy"
        },
        {
            "title": "Escape from Evernight City",
            "description": "Set in a dystopian future where the sun never shines, players become a member of a resistance group fighting against an oppressive regime that harnesses dark technology to maintain control. As they navigate treacherous alliances and unravel government conspiracies, each decision influences the rebellion's success and the potential for liberation—or total destruction.",
            "genre": "Post-Apocalyptic/Dystopian"
        },
        {
            "title": "Whispers of the Forgotten",
            "description": "In a quaint coastal town, a series of strange occurrences sparks a local tourist’s curiosity. Players investigate an ancient legend of mermaids said to haunt the waters, making choices that lead to discovery or danger. Will they choose to expose the truth, or protect the legacy of the myths that bind the community together?",
            "genre": "Fantasy/Mystery"
        },
        {
            "title": "Corporate Espionage Chronicles",
            "description": "Play as a brilliant but overworked employee in a tech firm who uncovers a corporate conspiracy that puts their life at risk. Each choice can lead players to become a whistleblower, a double agent, or to seek personal gain amidst the chaos. With financial and ethical implications at stake, will they pursue justice or opportunism?",
            "genre": "Thriller/Drama"
        },
        {
            "title": "The Lost Archives of Aetheria",
            "description": "Take on the role of a determined scholar in a quaint kingdom where magic has faded but ancient archives promise forgotten knowledge. Players delve into hidden libraries, face mythical guardians, and make moral choices about the use of magic. Their discoveries could either revive the kingdom’s glory or plunge it into a new crisis.",
            "genre": "Fantasy/Adventure"
        },
        {
            "title": "Time-Loop Chronicles",
            "description": "Players find themselves trapped in a time loop during a seemingly perfect day at a summer festival. Each choice can unravel new layers of the day’s events, revealing hidden secrets about the townsfolk and the player’s own past. Will they seek to escape the loop, change the course of events, or accept their fate?",
            "genre": "Fantasy/Slice-of-Life"
        },
        {
            "title": "Echoes of the Void War",
            "description": "In the aftermath of a devastating war between two alien factions, players must navigate the fractured remnants of their galaxy. Choices impact diplomatic relations, resource management, and strategic alliances as they work to rebuild or dominate. Will they seek peace or revenge in their quest for a brighter future?",
            "genre": "Space Opera/Sci-Fi"
        },
        {
            "title": "The Curious Case of the Clockwork Thief",
            "description": "In a steampunk city, players take on the role of a brilliant inventor whose creation—a sentient clockwork automaton—has gone missing. As they explore sprawling markets and dark alleys, players must choose whether to chase after the thieves, negotiate for its return, or even repurpose its technology for their own gain, leading to surprising outcomes.",
            "genre": "Steampunk/Mystery"
        },
        {
            "title": "Surviving the Last Stand",
            "description": "After a global catastrophe, a small group of survivors must navigate their harsh new reality in a world ruled by brute survivalist clans. Each decision players make affects resource management, community morale, and safety, ultimately leading to various endings where players either rebuild society or fall into chaos.",
            "genre": "Post-Apocalyptic/Survival"
        },
        {
            "title": "Lost in the Small Town",
            "description": "After moving to a sleepy town known for its quirky traditions, players uncover a series of bizarre events that lead to an unmasked secret: the town’s beloved mayor has a hidden past. Explore local lore, interact with eccentric residents, and make choices that will either expose the truth or preserve the town's charm.",
            "genre": "Mystery"
        },
        {
            "title": "The Last Colony",
            "description": "In a distant future where Earth has become uninhabitable, players take on the role of a newly appointed leader of the last human colony on Mars. As tensions rise between factions, players must navigate political intrigue, resource scarcity, and external threats from alien species, making decisions that will determine the fate of humanity.",
            "genre": "Sci-Fi"
        },
        {
            "title": "Fork in the Road",
            "description": "Players step into the shoes of a food truck owner in a bustling city, competing against established restaurants and gaining traction through street food fairs. Engage with customers, create special dishes, and handle challenges like health inspections and rival vendors while forging relationships that could lead to love or betrayal.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Voices from the Abyss",
            "description": "Set in a haunted coastal town, players act as an investigator who can communicate with lost souls. As eerie events unfold, players must choose which spirits to help and which mysteries to solve, uncovering secrets that will either free the town from its tragic past or doom it forever.",
            "genre": "Horror"
        },
        {
            "title": "The Time Traveler’s Dilemma",
            "description": "Players are gifted a mysterious timepiece that allows them to visit pivotal moments in their own life. As they explore these memories, they must decide whether to change the past or accept it, facing emotional repercussions that change their future in unexpected ways.",
            "genre": "Fantasy"
        },
        {
            "title": "Harbor of the Stars",
            "description": "In a universe where space piracy is rampant, players are captains of their own spaceships, navigating asteroid fields and trading with alien civilizations. Players must make choices related to alliances, security, and survival, all while trying to uncover a plot that threatens to ignite a galactic war.",
            "genre": "Space Opera"
        },
        {
            "title": "Echoes of a Silent City",
            "description": "After a sudden global blackout leaves the world without technology for weeks, players must adapt to a new way of life in a city emptied of modern conveniences. Build alliances, scavenge for resources, and face moral dilemmas as society struggles to rebuild or descend into chaos.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "The Great Bake-Off Showdown",
            "description": "Players enter a national baking competition filled with quirky contestants and eccentric judges. They must balance the artistic side of baking with strategic sabotage and friendships that evolve throughout the contest. Choices influence not just the dishes created but relationships and rivalries in the kitchen.",
            "genre": "Drama"
        },
        {
            "title": "Secrets of the Crystal Cavern",
            "description": "As a daring adventurer, players explore a mysterious cavern rumored to house ancient treasures and magical crystals. Along the journey, players confront mythical creatures and puzzles, making choices that will either lead them to great wealth or their doom, while unearthing the caverns' dark history.",
            "genre": "Fantasy"
        },
        {
            "title": "Inheritance of Shadows",
            "description": "Players inherit an old family estate riddled with mysteries and potential secrets. As they renovate the property, they uncover hidden rooms and diary entries, facing family ghosts—both literal and metaphorical. Players must choose how to confront their ancestry, impacting future generations.",
            "genre": "Mystery"
        },
        {
            "title": "Whispers in the Willow",
            "description": "In a sleepy small town, the ancient willow tree by the river holds secrets that have shaped its history. As a newcomer, players must unravel the town’s hushed mysteries, offering choices that affect relationships and community dynamics.",
            "genre": "Mystery"
        },
        {
            "title": "Retail Rumble",
            "description": "Step into the hectic world of a local department store during the holiday rush. Players manage both daily operations and quirky employee dynamics, making choices that impact sales, workplace harmony, and even the store's future.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Starship Odyssey: Echoes of the Cosmos",
            "description": "The universe teeters on the brink of war as factions vie for a powerful artifact lost in time. Players take command of a starship, making choices that can alter the course of intergalactic peace or plunge the galaxy into chaos.",
            "genre": "Space Opera"
        },
        {
            "title": "The Last Garden",
            "description": "In a post-apocalyptic world ravaged by pollution, an oasis of untamed nature appears. Players assume the role of a scavenger who discovers secrets within this garden, navigating choices that affect survival, restoration, and humanity's future.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "The Fabric of Dreams",
            "description": "In a world where dreams can be shaped and shared, players can manipulate their own dreams and the dreams of others. Choices determine the impact on reality, relationships, and whether the dream world becomes a place of solace or chaos.",
            "genre": "Fantasy"
        },
        {
            "title": "Unmasking the Monarch",
            "description": "At a masquerade ball for high society, players choose to manipulate, seduce, or unmask allies and enemies with secrets to gain power. Every choice affects politics, alliances, and uncovering hidden truths in a world filled with intrigue.",
            "genre": "Drama/Thriller"
        },
        {
            "title": "Chronicles of the Forgotten Realm",
            "description": "In a land where lost stories come to life, players embark on quests to restore forgotten fairy tales. Choices lead them through enchanting landscapes, grappling with events that can rewrite the very fabric of the realm.",
            "genre": "Fantasy"
        },
        {
            "title": "Signals from the Abyss",
            "description": "A deep-sea research station intercepts a mysterious signal from the depths of the ocean. Players must make choices that determine whether they unravel a cosmic mystery or face the horrors that lie beneath the waves.",
            "genre": "Horror/Sci-Fi"
        },
        {
            "title": "After the Collapse",
            "description": "In a dystopian society after social breakdown, factions have emerged as survivors struggle to rebuild. Players make critical decisions that shape communities, alliances, and the outcome of human civilization's second chance.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "The Disappearing Ink",
            "description": "As a promising young author, players encounter a series of bizarre occurrences: their words cause changes in reality. They must navigate the literary world and their own past, making choices that affect not just their story, but the stories of others.",
            "genre": "Fantasy/Mystery"
        },
        {
            "title": "Echoes of Willow Creek",
            "description": "In a quaint, seemingly peaceful small town, secrets lie beneath the surface. When a series of bizarre occurrences begin to unravel the town's history, players must navigate the delicate relationships of residents, uncover hidden truths, and choose sides in a struggle that will either heal or destroy their community.",
            "genre": "Mystery"
        },
        {
            "title": "Office Politics: The Game",
            "description": "Step into the chaotic world of a bustling corporate office where ambition blurs the line between friendship and rivalry. Players navigate daily dilemmas, from balancing ethics and ambition to handling office politics, as they climb the corporate ladder while making choices that will impact their careers and relationships.",
            "genre": "Drama"
        },
        {
            "title": "A Symphony of Stars",
            "description": "In a galaxy torn by war, players assume the role of a young musician who discovers they possess the power to influence emotions through music. As they travel across planets, they must make choices that either unite or divide factions, bringing about peace or further chaos through their harmonious journey.",
            "genre": "Sci-fi/Fantasy"
        },
        {
            "title": "Shadows in the Alley",
            "description": "Set in a gritty, dystopian city, a seasoned detective struggles to solidify their reputation while uncovering a web of crime and corruption. Players must choose which leads to follow, who to trust, and how to confront dangerous figures when every choice could mean life or death in the pursuit of justice.",
            "genre": "Noir/Mystery"
        },
        {
            "title": "Survival of the Fittest: After the Collapse",
            "description": "In a world ravaged by a catastrophic event, players lead a group of survivors in a fight for survival. As resources dwindle and factions compete for dominance, every decision from scavenging supplies to forging alliances or making sacrifices determines the fate of their community and their own lives.",
            "genre": "Post-Apocalyptic"
        },
        {
            "title": "The Forgotten Kingdoms",
            "description": "In a realm where magic has faded, players are tasked with navigating a world filled with mythical creatures and ancient prophecies. As they uncover the lost arts of magic and forge alliances with hidden kingdoms, their choices will either restore magic to the world or plunge it deeper into darkness.",
            "genre": "Fantasy"
        },
        {
            "title": "Digital Heist: The Hack of a Lifetime",
            "description": "Players take on the role of elite hackers racing against time to pull off the ultimate heist. Amidst corporate espionage and government surveillance, they must make strategic choices about alliances, tools, and tactics, influencing their escape and the repercussions of their actions in a digital world.",
            "genre": "Cyberpunk"
        },
        {
            "title": "Family Ties",
            "description": "Set in a familial restaurant struggling to keep its doors open, players shape the lives of three generations who face change and challenges. Through personal choices regarding family dynamics, recipes, and relationships, players can strengthen or fracture the family's legacy, learning what truly binds them together.",
            "genre": "Slice-of-Life"
        },
        {
            "title": "Whispers of the Wilderness",
            "description": "When an unsolved mystery draws adventurers to a mystical forest, players must navigate the unforgiving terrain and its magical inhabitants. Their decisions could either restore balance to the land or unleash chaos, as they delve into the lore of nature spirits and ancient guardians that dwell within.",
            "genre": "Fantasy/Adventure"
        },
        {
            "title": "The Celestial Navigation",
            "description": "In a future where humanity has colonized the stars, players become captains of their own space fleet. They must make alliances, wage battles, and explore uncharted territories while contemplating the nature of freedom and the consequences of their choices on intergalactic peace.",
            "genre": "Space Opera"
        },
        {
            "title": "Whispers of Willow Creek",
            "description": "In the quaint town of Willow Creek, a series of strange occurrences shake the peaceful community. As a newcomer, players will unravel the town's secrets, engaging with eccentric locals while facing dilemmas that test loyalties and reveal dark histories. Each choice influences friendships and the truth behind the mysterious happenings.",
            "genre": "Mystery"
        },
        {
            "title": "Echoes of the Past",
            "description": "In a small yet dynamic office environment, tensions rise as a corporate merger looms. Players take on the role of an employee caught in the whirlwind of office politics, uncover hidden agendas, and confront ethical dilemmas as they navigate relationships with coworkers and management. Will you climb the corporate ladder or spark a revolution?",
            "genre": "Slice-of-Life / Drama"
        },
        {
            "title": "Starlit Odyssey",
            "description": "In a distant future, players are members of an intergalactic salvage crew, exploring derelict spaceships and abandoned planets. Uncover the fate of lost civilizations and navigate moral choices between profit and the greater good. Friendships may bloom or shatter amid the vast loneliness of space. Will you risk it all for a treasure or a lost soul?",
            "genre": "Sci-Fi / Space Opera"
        },
        {
            "title": "Elysium's Fall",
            "description": "A thriving metropolis stands between two realms: the human world and a hidden dimension teeming with mythical creatures. Players embody a guardian tasked with maintaining the balance, but a power-hungry entity threatens to tip the scales. Choices impact alliances, battles, and the very fabric of reality while exploring themes of duty and sacrifice.",
            "genre": "Fantasy / Adventure"
        },
        {
            "title": "Forgotten Futures",
            "description": "Set in a post-apocalyptic world where society is fragmented, players are survivors in a scavenger’s paradise, battling not just the environment but each other. Decisions on resource management, alliance formations, and moral choices on humanity versus survival will lead players to one of several stark endings: redemption or despair.",
            "genre": "Post-Apocalyptic / Survival"
        },
        {
            "title": "The Haunting of Moira Manor",
            "description": "As a group of friends embarks on a weekend getaway, they find themselves trapped in a haunted mansion filled with cryptic puzzles and malevolent spirits. Players must solve the mysteries of Moira Manor while making choices that can save them or lead them deeper into the grasp of the supernatural. Which secrets will they unveil, and who will survive?",
            "genre": "Horror / Mystery"
        },
        {
            "title": "Chronicles of the Hidden Realms",
            "description": "Players are powerful mages in a magical kingdom, where ancient laws govern the use of magic. Tensions arise as secrets of forbidden spells surface, and players can choose to uphold tradition or embrace change. Their decisions will determine the fate of magic, friendships, and the very essence of their society. Will they lead a revolution or restore peace?",
            "genre": "Fantasy / Political Intrigue"
        },
        {
            "title": "Arcane Investigations",
            "description": "Set in a bustling metropol filled with supernatural beings, players take on the role of detectives solving magical crimes. Balancing their own mystical abilities with practical sleuthing, players will navigate through a web of deceit, powerful adversaries, and moral dilemmas, forcing them to choose between the law and their conscience.",
            "genre": "Urban Fantasy / Mystery"
        },
        {
            "title": "Distant Echoes",
            "description": "In a village isolated from the rest of the world, an ancient prophecy hints at the return of a long-forgotten evil. Players are villagers tasked with gathering resources, forging alliances, and uncovering the truth behind the legend. Choices made will either unite the village against the impending doom or unravel it from within, leading to numerous possible outcomes.",
            "genre": "Fantasy / Adventure"
        },
        {
            "title": "Celestial Drift",
            "description": "As ships drift through uncharted space after a catastrophic event, players assume the role of diverse crew members aboard a last-resort starship. Navigate conflicts, supply runs, and the threat of piracy while making crucial decisions that affect the ship's survival and the dynamic of your crew. Every decision shapes their fate among the stars.",
            "genre": "Sci-Fi / Space Opera"
        }
    ];

    const fetchData = useCallback(async () => {
        if (devMode) {
            // If in devMode, set the dummy data
            console.log('Set Stories');
            setStories(dummyData);
        } else {
            try {
                const response = await fetch(`${apiUrl}/api/v1/game/story/allstories?token=${authCtx.token}`);
                const data = await response.json();
                setStories(data['stories']);
            } catch (e) {
                console.error(e);
            }
        }
    }, [apiUrl, authCtx.token, devMode]);

    useEffect(() => {
        fetchData();
    }, [fetchData, fetchTrigger]);

    // Memoize the result using useMemo
    const memoizedStories = useMemo(() => stories, [stories]);

    // Function to trigger re-fetch
    const refreshStories = useCallback(() => {
        setFetchTrigger(prev => !prev);
    }, []);

    return { stories: memoizedStories, refreshStories };
};

export default useAllStories;