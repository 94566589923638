import { useState, useEffect, useMemo, useCallback } from 'react';
import { useUser } from '../context/UserContext';

const useThemeSuggestions = (devMode = false) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [suggestions, setSuggestions] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(false);

    // Dummy data for development mode
    const dummyData = [
        {
            "title": "Echoes of Solitude",
            "description": "In a dystopian world where memories can be bought and sold, players assume the role of a memory dealer navigating a city shrouded in fog and forgotten pasts. Each choice to share or erase a memory alters the fabric of their life and those around them, leading to unexpected alliances and haunting consequences."
        },
        {
            "title": "Whispers in the Library",
            "description": "Players step into a magical library where each book is a portal to another world. As aspiring librarians, they must protect the stories from sinister forces trying to rewrite history, leading to adventures filled with whimsical characters and moral dilemmas that could reshape reality."
        },
        {
            "title": "Café Conundrum",
            "description": "Set in a quirky café famous for its ability to fix relationship problems, players manage daily interactions with eccentric customers whose life choices influence the café's magic. Each decision impacts the customers' lives—and potentially their own—leading to heartwarming or disastrous results."
        },
        {
            "title": "Stars of the Forgotten",
            "description": "In a universe where planets are run by different factions, players become starship captains fighting to unite a galaxy on the brink of war. Choices will determine alliances, species' fates, and whether peace can ever rein over chaos, with each star system presenting unique challenges and narratives."
        },
        {
            "title": "The Last Carnival",
            "description": "As the last carnival on earth prepares for its final show, players must uncover a long-buried secret connected to the carnival's magical performances. Balancing between fantastical acts and real-world struggles, players decide whether to save the carnival or embrace the change of a new era."
        },
        {
            "title": "Haunted High School Reunion",
            "description": "Years after graduating, players return to their old high school for a reunion, only to discover supernatural forces at play. Choices will shape whether they confront their past, uncover dark secrets about their former classmates, or help restless spirits find peace."
        },
        {
            "title": "Code of Shadows",
            "description": "In a cyberpunk city dominated by technology and corporate espionage, players are hackers trying to dismantle an oppressive regime. They must make ethical decisions about the extent of their infiltration and whether to protect their loved ones or sacrifice their safety for freedom."
        },
        {
            "title": "Wanderers of the Wasteland",
            "description": "In a post-apocalyptic world where resources are scarce, players form a caravan of survivors, facing the harsh realities of survival and the moral gray areas of humanity. Their choices affect the formation of their society, alliances with other groups, and the direction of their quest for a new home."
        },
        {
            "title": "The Village of Lost Dreams",
            "description": "In a sleepy town where locals are cursed to forget their dreams, players take on the role of a new arrival determined to revive the village’s hopes. Each decision made while interacting with townsfolk can restore or shatter fragile aspirations, leading to a richly layered narrative."
        },
        {
            "title": "Trapped in Reverie",
            "description": "After an accident sends your consciousness into a dream realm, players must navigate surreal environments that reflect their fears, desires, and regrets. Choices impact the state of the real world as players learn to confront and overcome their innermost struggles to return home."
        }
    ];

    const fetchData = useCallback(async () => {
        if (devMode) {
            // If in devMode, set the dummy data
            console.log('Set Suggestions');
            setSuggestions(dummyData);
        } else {
            try {
                const suggestionsResponse = await fetch(`${apiUrl}/game/story/themesuggestions?token=${authCtx.token}`);
                const suggestionsData = await suggestionsResponse.json();
                setSuggestions(suggestionsData['themeSuggestions']);
            } catch (e) {
                console.log(e);
            }
        }
    }, [apiUrl, authCtx.token, devMode]);

    useEffect(() => {
        fetchData();
    }, [apiUrl, fetchTrigger, fetchData]);

    // Memoize the result using useMemo
    const memoizedSuggestions = useMemo(() => suggestions, [suggestions]);

    // Function to trigger re-fetch
    const refreshSuggestions = useCallback(() => {
        setFetchTrigger(prev => !prev);
    }, []);

    return { suggestions: memoizedSuggestions, refreshSuggestions };
};

export default useThemeSuggestions;