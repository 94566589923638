import React, { useState } from 'react';
import { Dock } from 'primereact/dock';
import { Tooltip } from 'primereact/tooltip';
import { motion } from 'framer-motion';
import IconImageDashboard from '../../assets/Sidebar Icons/dashboard_1.png';
import { Box } from '@mui/material';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import useIsMobile from '../../utils/useIsMobile';

const Sidebar = ({setSidebarHovered = () => {}, mainViewComponent = <></>, setMainViewComponent = () => {}, mainViewHeader = '', setMainViewHeader = () => {}, backgroundColor = '#5D3B9E'}) => {
    const [position, setPosition] = useState('left');
    const isMedium = useWindowSizeThreshold(1280, true);
    const isMobile = useIsMobile();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.25)`
    };
    const DockItem = ({img, label}) => {
        const tooltipId = `tooltip-${label.replace(/\s+/g, '-')}`;
        const imageStyle = {
            borderRadius: '15px',
            filter: `brightness(100%) invert(${label === mainViewHeader ? '100%' : '0%'})`
        };

        const hexToRGBA = (hex, alpha) => {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            const returnStr = `rgba(${r},${g},${b},${alpha})`;
            return returnStr;
        };

        return (
            <React.Fragment>
                <img alt={label} src={img} width='100%' style={imageStyle} />
                <Box id={tooltipId} sx={{position: 'absolute', borderRadius: '15px', width: '100%', height: '100%', border: `1px solid rgba(0,0,0,0.25)`,
                    backgroundImage: `linear-gradient(45deg, ${hexToRGBA(backgroundColor, 0.5)}, ${hexToRGBA(backgroundColor, 0.75)})`}} />
                <Tooltip target={`#${tooltipId}`} content={label} showDelay={150} position='right' />
            </React.Fragment>
        );
    };
    const menuItems = [
        {
            label: 'AI Presets',
            image: 'https://i.imgur.com/7J9vfTn.png',
            command: () => setMainViewComponent('aipresets')
        },
        {
            label: 'Commands',
            image: 'https://i.imgur.com/vGgq67W.png',
            command: () => setMainViewComponent('commands')
        },
        {
            label: 'Triggers',
            image: 'https://i.imgur.com/Oe7MlJs.png',
            command: () => setMainViewComponent('triggers')
        },
        {
            label: 'Spotify',
            image: 'https://i.imgur.com/uU7GNC5.png',
            command: () => setMainViewComponent('spotify')
        },
        {
            label: 'Twitch',
            image: 'https://i.imgur.com/aqDSIw2.png',
            command: () => setMainViewComponent('twitch')
        }
    ];
    const generateDockItems = () => {
        return menuItems.map((menuObj, index) => ({
            label: menuObj['label'],
            icon: <DockItem key={index} img={menuObj['image']} label={menuObj['label']} />,
            command: menuObj.command
        }));
    };

    return (
        <motion.div onHoverStart={() => setSidebarHovered(true)} onHoverEnd={() => setSidebarHovered(false)}>
            <style>{`.p-dock-list {background: transparent !important; border: none !important;}`}</style>
            <Dock model={generateDockItems()} position={position} />
        </motion.div>
    );
};

export default Sidebar;