import React, { useContext, useState, useEffect } from 'react';
import Header from '../../../components/Header';
import AuthContext from '../../../store/auth-context';
import HeaderWrapper from '../../../utils/headerwrapper';
import MainPage from '../../../utils/mainpage';
import ServerMetricsChart from './metricschart';

const ServerMetrics = () => {
    const [metricsData, setMetricsData] = useState();
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(`${apiUrl}/api/servermetrics?token=${authCtx.token}`).then(response => response.json()).then(res => {
            setMetricsData(res);
        }).catch(error => console.log(error));
    }, []);
    

  return (
    <MainPage>
        <HeaderWrapper>
            <Header title="Server Metrics" subtitle="For monitoring server health over time" />
        </HeaderWrapper>
        {metricsData && <ServerMetricsChart data={metricsData} />}
    </MainPage>
  )
};

export default ServerMetrics;