import React, { useEffect, useState, useRef } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Slider } from 'primereact/slider';
import { Grid, colors } from '@mui/material';
import { InputText } from 'primereact/inputtext';

const SliderDialog = ({backgroundColor = '#5D3B9E', value = 0, setValue = () => {}, footer = '', step = 1, min = 0, max = 100, unit = '', useTimeFormatting = false}) => {
  const [tmpValue, setTmpValue] = useState(value);
  const debounceDelay = 500;
  const sliderRef = useRef(null);
  const colorScheme = {
    background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
    background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
    background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(tmpValue);
    }, debounceDelay);

    if (sliderRef.current) {
      const sliderElement = sliderRef.current;
      const sliderBar = sliderElement.querySelector('.p-slider');
      const sliderHandle = sliderElement.querySelector('.p-slider-handle');
      if (sliderBar) {
        sliderBar.style.background = backgroundColor;
      }
      if (sliderHandle) {
        sliderHandle.style.background = backgroundColor;
      }
    }

    return () => clearTimeout(handler);
  }, [tmpValue, setValue, debounceDelay, backgroundColor]);

  return (
    <DialogComponent footer={footer} minHeight='20vh'>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingLeft: '16px'}}>
          <div style={{width: '100%'}}>
            <InputText style={{width: '100%', backgroundColor: colorScheme.background25}} value={useTimeFormatting ? formatTime(tmpValue) : `${tmpValue} ${unit}`} onChange={(e) => {}} />
            <div ref={sliderRef}>
              <Slider value={tmpValue} onChange={(e) => setTmpValue(e.value)} step={step} min={min} max={max} />
            </div>
          </div>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default SliderDialog;