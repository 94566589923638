import React, { useEffect, useState } from 'react';
import Topbar from './topbar';
import { useLocation } from 'react-router-dom';
import AboutPage from './about';
import { Box, styled } from '@mui/material';
import PersonalitiesPage from './personalities';
import ModInfoPage from './modinfo';
import StreamersPage from './streamers';
import useIsMobile from '../../utils/useIsMobile';

const Public = () => {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;
  const queryParams = new URLSearchParams(location.search);
  const isMobile = useIsMobile();
  const [page, setPage] = useState(queryParams.get('p') || 'about');
  const [presets, setPresets] = useState({});
  const [streamers, setStreamers] = useState({});

  const BackgroundContainer = styled(Box)(({ theme }) => ({
    backgroundImage: `url(https://images.unsplash.com/photo-1619190324856-af3f6eb55601?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(10px) brightness(0.2)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: -1,
  }));

  useEffect(() => {
    const fetchPresetData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/aipresets`);
        const res = await response.json();
        const sortedRes = Object.keys(res)
          .sort()
          .reduce((acc, key) => {
            acc[key] = res[key];
            return acc;
          }, {});
        setPresets(sortedRes);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchStreamersData = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/streamers`);
            const res = await response.json();
            setStreamers(res);
        } catch (e) {
            console.log(e);
        }
    };

    fetchPresetData();
    fetchStreamersData();
  }, []);

  return (
    <React.Fragment>
      <BackgroundContainer />
      <Topbar page={page} setPage={setPage} />
      {page === 'about' && (
        <React.Fragment>
            <AboutPage />
        </React.Fragment>
      )}
      {(page === 'personalities' || page === 'personalities.') && (
        <React.Fragment>
            <PersonalitiesPage presets={presets} />
        </React.Fragment>
      )}
      {(page === 'streamers' || page === 'streamers.') && (
        <React.Fragment>
            <StreamersPage streamers={streamers} />
        </React.Fragment>
      )}
      {(page === 'modinfo' || page === 'modinfo.') && (
        <React.Fragment>
            <ModInfoPage />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Public;