import React, { useState } from 'react';
import { useUserSettings } from '../../store/usersettings-context';
import "../../../node_modules/primereact/resources/themes/lara-dark-purple/theme.css";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import EditActions from '../../beta/dialogs/editActions';
import { Button } from 'primereact/button';
import useIsMobile from '../../utils/useIsMobile';

const Macros = ({ backgroundColor = '#5D3B9E', openDialogComponent = () => {}, userSettings = {}, update = () => {}, deletekey = () => {} }) => {
    const isMobile = useIsMobile();
    const [hoveredMacro, setHoveredMacro] = useState(null);
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`,
        background10: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.1)`
    };

    return (
        <React.Fragment>
            <Grid container sx={{...hideScrollbar, maxHeight: '80vh', overflowY: 'auto', padding: !isMobile && '16px', paddingTop: '0px', paddingRight: isMobile ? '20px' : '32px'}}>
                <Button label='Add New' icon='pi pi-plus' size='small' style={{ marginBottom: 16, width: isMobile && '100%' }} onClick={() => openDialogComponent('newmacro')} />
                {Object.keys(userSettings['macros']).map((macroName, index) => (
                    <Grid item xs={12} key={index}>
                        <Accordion sx={{ backgroundColor: colorScheme.background10 }} onMouseEnter={() => {!isMobile && setHoveredMacro(macroName)}} 
                            onMouseLeave={() => {!isMobile && setHoveredMacro(null)}}>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: colorScheme.background50, position: 'relative' }}>
                                <h3>{`${macroName} (${userSettings['macros'][macroName]['actions'].length} actions)`}</h3>
                                {hoveredMacro === macroName && (
                                    <div style={{ position: 'absolute', right: 64, top: 20, display: 'flex', gap: '8px' }}>
                                        <Button label='Edit' icon='pi pi-pencil' size='small' style={{ marginRight: 8 }} onClick={(e) => {
                                            e.stopPropagation();
                                            openDialogComponent('newmacro', userSettings['macros'][macroName]);
                                        }} />
                                        <Button label='Delete' icon='pi pi-trash' size='small' className='p-button-danger' onClick={(e) => {
                                            e.stopPropagation();
                                            deletekey(['macros', macroName]);
                                        }} />
                                    </div>
                                )}
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: isMobile ? 2 : 4 }}>
                                {isMobile && (
                                    <div style={{marginBottom: '16px', width: '100%'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button label='Edit' icon='pi pi-pencil' size='small' style={{marginRight: 8, width: '100%'}} onClick={(e) => {
                                                    e.stopPropagation();
                                                    openDialogComponent('newmacro', userSettings['macros'][macroName]);
                                                }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button label='Delete' icon='pi pi-trash' size='small' style={{width: '100%'}} className='p-button-danger' onClick={(e) => {
                                                    e.stopPropagation();
                                                    deletekey(['macros', macroName]);
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                <EditActions 
                                    actionsArr={userSettings['macros'][macroName]['actions']} 
                                    save={(newActionsArr) => {
                                        update({ macros: {...userSettings['macros'], [macroName]: {...userSettings['macros'][macroName], actions: [...newActionsArr]} }});
                                    }} 
                                    backgroundColor={colorScheme.background75} 
                                    textColor='#e0e0e0' 
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default Macros;