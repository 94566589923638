import React from 'react';
import { Typography, Container, Grid, Card, CardContent, styled } from '@mui/material';
import useIsMobile from '../../utils/useIsMobile';

const ModInfoPage = () => {
    const isMobile = useIsMobile();
    const TransparentCard = styled(Card)(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        boxShadow: 'none',
    }));

      const InfoBox = ({title, children}) => {
        return (
            <Grid item xs={12} sm={3}>
                <div style={{ margin: isMobile ? '0px' : '10px' }}>
                    <TransparentCard>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>{title}</Typography>
                            {!isMobile && <br />}
                            {children}
                        </CardContent>
                    </TransparentCard>
                </div>
            </Grid>
        );
      };

      const CommandsBox = ({command, description, example}) => {
        return (
            <React.Fragment>
                <Typography variant="body1">
                    <Typography variant="h5"><strong>{command}</strong></Typography>
                    <Typography variant="body2">
                        {description}
                    </Typography>
                    <Typography sx={{marginTop: '8px'}} variant="body2">
                        {example}
                    </Typography>
                </Typography>
            </React.Fragment>
        );
      };

      return (
        <div style={{ display: 'flex', marginTop: '24px', justifyContent: 'center' }}>
          <Container maxWidth="xl">
            <Typography variant={isMobile ? "h3" : "h1"} align={isMobile ? 'left' : 'center'}>Moderator commands for Ratbot</Typography>
            <Typography align={isMobile ? 'left' : 'center'} sx={{marginTop: '8px'}}>Please note that the command delimiter '!' is the defaulted setting, but can be changed by the streamer</Typography>
            <br />
            <Grid container spacing={2} sx={{overflowY: 'auto', maxHeight: '70vh', justifyContent: 'center'}}>
                <InfoBox title="General">
                    <CommandsBox 
                        command={`!personality {name}`} 
                        description={`Changes personality preset of Ratbot. Please see "Personalities" page for available presets, note that this command is case-sensitive. Use without the name variable to see current setting.`} 
                        example={`Example: '!personality Uplifting'`} />
                    <br />
                    <CommandsBox 
                        command={`!shortreplies {boolean}`} 
                        description={`Sets the short replies mode for Ratbot. If enabled, Ratbot will attempt to use shorter replies to convey its messages. Use without the boolean variable to see current setting.`} 
                        example={`Example: '!shortreplies true'`} />
                </InfoBox>
                <InfoBox title="Spotify">
                    <CommandsBox 
                        command={`!skip`} 
                        description={`Skips to the next track in queue (will automatically start playing is paused).`} 
                        example={`Example: '!skip'`} 
                    />
                </InfoBox>
                <InfoBox title="Administrative">
                    <CommandsBox 
                        command={`!ignore`} 
                        description={`Adds a user to Ratbot's block list - Ratbot will then ignore any messages or commands from that user.`} 
                        example={`Example: '!ignore @StreamElements'`} 
                    />
                    <br />
                    <CommandsBox 
                        command={`!unignore`} 
                        description={`Removes a user from Ratbot's block list.`} 
                        example={`Example: '!unignore @StreamElements'`} 
                    />
                </InfoBox>
            </Grid>
          </Container>
        </div>
      );
};

export default ModInfoPage;