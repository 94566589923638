import { useEffect, useState } from 'react';

const useIsLarge = () => {
  const [isLarge, setIsLarge] = useState(false);
  useEffect(() => {
    const handleResize = () => {
        setIsLarge(window.innerWidth <= 1600); // Adjust the breakpoint as needed
    };
    handleResize(); // Check initial window size
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return isLarge;
};

export default useIsLarge;