/**
 * Class representing a saved story.
 */
class SavedStory {
    /**
     * Creates an instance of SavedStory.
     * @param {Object} data - The data to initialize the saved story.
     * @param {string} data.title - The title of the story.
     * @param {string} data.theme - The theme or setting of the story.
     * @param {Object} data.player - The character object or main protagonist of the story.
     * @param {string} data.player.description - Description of the main protagonist. 
     * @param {string} data.player.occupation - Occupation of the main protagonist. 
     * @param {string} data.player.gender - Gender of the main protagonist. 
     * @param {number} data.player.age - Age of the main protagonist. 
     * @param {Object} data.player.stats - Stats of the main protagonist. 
     * @param {number} data.player.stats.strength - Strength stat of the main protagonist. 
     * @param {number} data.player.stats.dexterity - Dexterity stat of the main protagonist. 
     * @param {number} data.player.stats.intelligence - Intelligence stat of the main protagonist. 
     * @param {number} data.player.stats.charisma - Charisma stat of the main protagonist. 
     * @param {number} data.currentChapterIndex - The index of the current chapter.
     * @param {string} data.currentChapterTitle - The title of the current chapter.
     * @param {number} data.currentChapterPlotPoints - The total plot points in the current chapter.
     * @param {number} data.currentChapterResolvedPlotPoints - The number of resolved plot points in the current chapter.
     * @param {string} data.currentChapterDescription - Description of current chapter without spoilers.
     * @param {boolean} data.storyFinished - Whether the story is finished.
     * @param {string} data.playerLocation - The current location of the player.
     * @param {string} data.timestamp - The timestamp when the story was last saved.
     * @param {Array<Object>} data.conversationHistory - The history of conversations in the story.
     * @param {number} data.numChapters - The total number of chapters in the story.
     * @param {Array<Object>} data.discoveredCharacters - The list of characters discovered in the story.
     * @param {Array<Object>} data.inventory - The inventory items in the story.
     * @param {string} data.hint - A hint on what to do to progress the story.
     */
    constructor(data) {
        this.title = data.title || "";
        this.theme = data.theme || "";
        this.player = data.player || {};
        this.currentChapterIndex = data.currentChapterIndex || 0;
        this.currentChapterTitle = data.currentChapterTitle || "";
        this.currentChapterPlotPoints = data.currentChapterPlotPoints || 0;
        this.currentChapterResolvedPlotPoints = data.currentChapterResolvedPlotPoints || 0;
        this.currentChapterDescription = data.currentChapterDescription || "";
        this.storyFinished = data.storyFinished || false;
        this.playerLocation = data.playerLocation || "";
        this.timestamp = data.timestamp ? new Date(data.timestamp) : new Date();
        this.conversationHistory = data.conversationHistory || [];
        this.numChapters = data.numChapters || 1;
        this.discoveredCharacters = data.discoveredCharacters || [];
        this.inventory = data.inventory || [];
        this.hint = data.hint || "";
    }
}

export default SavedStory;