import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const ServerMetricsChart = (props) => {
  const textColor = '#ffffff';
  const { data } = props;
  const formattedData = [];
  for (let i = 0; i < data['entries'].length; i++) {
    const tmpObj = {};
    tmpObj['x'] = new Date(parseInt(Object.keys(data['entries'][i])[0]));
    tmpObj['cpu'] = data['entries'][i][Object.keys(data['entries'][i])[0]]['cpu'];
    tmpObj['memory'] = parseFloat(data['entries'][i][Object.keys(data['entries'][i])[0]]['memory']);
    tmpObj['processes'] = data['entries'][i][Object.keys(data['entries'][i])[0]]['processes'];
    formattedData.push(tmpObj);
  }

  const chartData = [
    {
      id: 'CPU',
      "color": "hsl(44, 70%, 50%)",
      data: formattedData.map(({ x, cpu }) => ({ x, y: cpu })),
    },
    {
      id: 'Memory',
      data: formattedData.map(({ x, memory }) => ({ x, y: memory })),
    },
    {
      id: 'Processes',
      data: formattedData.map(({ x, processes }) => ({ x, y: processes })),
    },
  ];  

  console.log(data);
  console.log(chartData);

  return (
    <div style={{ height: '400px' }}>
        <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
            xScale={{ type: 'linear' }}
            yScale={{ type: 'linear', stacked: true, min: 0, max: 2500 }}
            yFormat=" >-.2f"
            curve="monotoneX"
            axisTop={null}
            axisRight={{
                tickValues: [
                    0,
                    500,
                    1000,
                    1500,
                    2000,
                    2500
                ],
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: '.2s',
                legend: '',
                legendOffset: 0,
                style: { text: { fill: textColor } }
            }}
            axisBottom={{
                scale: 'time',
                tickValues: formattedData.map((d) => d.x.getTime()),
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickFormat: (value) => {
                  const date = new Date(value);
                  return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                },
                legend: 'Time',
                legendOffset: 36,
                legendPosition: 'middle',
                style: { text: { fill: textColor } }
              }}                      
            axisLeft={{
                tickValues: [
                    0,
                    500,
                    1000,
                    1500,
                    2000,
                    2500
                ],
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: '.2s',
                legend: 'volume',
                legendOffset: -40,
                legendPosition: 'middle',
                style: { text: { fill: textColor } }
            }}
            enableGridX={false}
            colors={{ scheme: 'paired' }}
            lineWidth={1}
            pointSize={4}
            pointColor={textColor}
            pointBorderWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 140,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 12,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ],
                    style: { text: { fill: textColor } }
                }
            ]}
        />
    </div>
    );
};

export default ServerMetricsChart;