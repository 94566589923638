import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import AdminToolGrid from './AdminToolGrid';
import SubscriberList from './SubscriberList';
import Yellowraven from './Yellowraven';
import Perfumes from './Yellowraven/perfumes';
import { useUser } from '../../context/UserContext';

const Admin = ({backgroundColor = '#5D3B9E', openDialogComponent = () => {}}) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState('');
    const [viewedComponent, setViewComponent] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const toolComponent = {
        'subscriberlist': <SubscriberList backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setSelectedComponent={setSelectedComponent} />,
        'yellowraven': <Yellowraven backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setSelectedComponent={setSelectedComponent} />,
        'perfumes': <Perfumes backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setSelectedComponent={setSelectedComponent} />
    }
    const [tools, setTools] = useState([
        {title: 'Subscriber List', component: 'subscriberlist', img: 'https://i.imgur.com/dWA81CA.png'},
        {title: 'Yellowravens Corner', component: 'yellowraven', img: 'https://i.imgur.com/8xthoha.png'}
    ]);

    const checkIfAdmin = async (token) => {
        const response = await fetch(`${apiUrl}/user/isadmin?token=${token}`);
        const res = await response.json();
        console.log(res);
        if (res['status'] === true && response.ok) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    };

    useEffect(() => {
        setIsAdmin(false);
        checkIfAdmin(authCtx.token);
    }, [authCtx]);
    useEffect(() => {
        setViewComponent(toolComponent[selectedComponent]);
    }, [selectedComponent]);

    return (
        <React.Fragment>
            {isAdmin ? (
                <React.Fragment>
                    {selectedComponent !== '' ? viewedComponent : (
                        <AdminToolGrid tools={tools} setSelectedComponent={setSelectedComponent} />
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography variant='h3'>You do not have permission to access this page</Typography>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Admin;