import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useIsMobile from '../../utils/useIsMobile';
import { motion } from 'framer-motion';
import { Button } from 'primereact/button';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { useUser } from '../../context/UserContext';

const Twitch = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = async () => {}, refresh = async () => {}}) => {
    const [fullAccessUrl, setFullAccessUrl] = useState('#');
    const [isReady, setIsReady] = useState(false);
    const [hasCopied, setHasCopied] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.25)`
    };
    const fullAccessArr = ['bits:read', 'channel:manage:broadcast', 'channel:read:hype_train', 'channel:read:polls', 'channel:manage:polls', 'channel:read:predictions', 
    'channel:manage:predictions', 'channel:read:redemptions', 'channel:manage:redemptions', 'channel:read:subscriptions', 'channel:read:vips', 'clips:edit', 
    'moderation:read', 'moderator:manage:announcements', 'moderator:manage:automod', 'moderator:read:blocked_terms', 'moderator:read:chat_settings', 
    'moderator:manage:blocked_terms', 'moderator:read:followers', 'channel:read:ads', 'user:read:email', 'user:read:blocked_users'];
    const scrollableContent = {
        maxHeight: '75vh',
        overflowY: 'auto',
        paddingLeft: '16px',
        paddingBottom: isMobile && '5vh',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    useEffect(() => {
        console.log(userSettings);
        formatModData();
        getElevatedTwitchUrl(fullAccessArr, true).then(url => {
            setFullAccessUrl(url);
        }).catch(e => console.log(e));
    }, []);

    const formatModData = async () => {
        if ('twitchPermissions' in userSettings === false) {
            update({'twitchPermissions': ['user:read:email']});
        }
        if ('twitch' in userSettings['modData'] === false) {
            await update({modData: {...userSettings['modData'], twitch: {}}})
        }
        if ('commands' in userSettings['modData']['twitch'] === false) {
            await update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {}}}});
        }
        if ('create_poll' in userSettings['modData']['twitch']['commands'] === false) {
            await update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_poll: {command: 'poll', duration: 60}}}}});
        }
        if ('create_prediction' in userSettings['modData']['twitch']['commands'] === false) {
            await update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_prediction: {command: 'predict', prediction_window: 300}}}}});
        }
        if ('end_prediction' in userSettings['modData']['twitch']['commands'] === false) {
            await update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], end_prediction: {command: 'endpredict'}}}}});
        }
        if ('rewards' in userSettings['modData']['twitch'] === false) {
            await update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], rewards: {}}}})
        }
        setIsReady(true);
    };
    const getElevatedTwitchUrl = async (newScopes, forceVerify = false) => {
        try {
            const response = await fetch(`${apiUrl}/user/elevatedtwitchurl?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({scope: newScopes, forceVerify, redirect: '/settings?p=twitch'})
            });
            const res = await response.json();
            return res['status'];
        } catch (e) {
            console.log(e);
            return 'https://ratbot.store/settings';
        }
    };
    const authorizeTwitch = async (newScopes) => {
        try {
            const elevatedLink = await getElevatedTwitchUrl(newScopes);
            console.log(elevatedLink);
            window.location.href = elevatedLink;
        } catch (e) {
            console.log(e);
        }
    };
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };
    const validModData = (command = 'create_poll') => {
        if ('twitch' in userSettings['modData'] === false) return false;
        if ('refresh_token' in userSettings['modData']['twitch'] === false && !authCtx.editor) return false;
        if ('commands' in userSettings['modData']['twitch'] === false) return false;
        if (command in userSettings['modData']['twitch']['commands'] === false) return false;
        return true;
    };
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setHasCopied(true);
    };

    const SectionBackground = ({children}) => {
        const style = {
            backgroundColor: colorScheme['background25'],
            borderRadius: '15px',
            padding: '16px',
            marginRight: '16px',
            border: `2px solid rgba(255,255,255,0.025)`,
            height: '100%'
        };
        return (
            <HoverAnimation>
                <Box sx={style}>
                    {children}
                </Box>
            </HoverAnimation>
        );
    };
    const HoverAnimation = ({children}) => {
        const hoverAnimation = {
            scale: [1, 1.0125, 1],
            transition: {
                duration: 0.4,
                ease: "easeInOut",
                times: [0, 0.5, 1]
            }
        };

        return (
            <motion.div whileHover={!isMobile && hoverAnimation} style={{height: '100%', width: '100%'}}>
                {children}
            </motion.div>
        );
    };

    return (
        <React.Fragment>
            {(!isReady && !authCtx.editor) ? (
                <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <SectionBackground>
                            <Typography variant='h3'>Authorize Twitch</Typography>
                            <Typography sx={{marginTop: '8px'}}>Ratbot doesn't have a refresh token from Twitch, please use below button.</Typography>
                            <Button size='small' style={{marginTop: '16px', width: isMobile && '100%'}} 
                                onClick={() => authorizeTwitch(['channel:bot'])}>Elevate Twitch Access</Button>
                        </SectionBackground>
                    </Grid>
                </Grid>
            ) : (
                <Box sx={scrollableContent}>
                    <Grid container spacing={(isLarge && !isMobile) ? 0 : 2} sx={{marginTop: isMobile && '8px'}}>
                        {/* POLLS */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <SectionBackground>
                                <Typography variant='h3'>Polls</Typography>
                                {(!isLarge) && (<Typography sx={{marginTop: '8px'}}>Allow Ratbot to automatically create polls.</Typography>)}
                                <React.Fragment>
                                    {validModData('create_poll') ? (
                                        <React.Fragment>
                                            {userSettings['twitchPermissions'].includes('channel:manage:polls') ? (
                                                <React.Fragment>
                                                    <Grid container spacing={2} sx={{marginTop: '0px'}}>
                                                        <Grid item xs={isLarge ? 12 : 6}>
                                                            <Button size='small' style={{width: isLarge && '100%'}}
                                                                onClick={() => openDialogComponent('twitchpolllength')}>{`Poll length: ${formatTime(userSettings['modData']['twitch']['commands']['create_poll']['duration'] ?? 60)}`}</Button>
                                                        </Grid>
                                                        <Grid item xs={isLarge ? 12 : 6}>
                                                            <Button size='small' style={{width: isLarge && '100%'}}
                                                                onClick={() => openDialogComponent('twitchpollcommand')}>Chat Command</Button>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Typography>Ratbot currently does not have your permission to manage polls, use below button to give permission</Typography>
                                                    <Button size='small' style={{marginTop: '16px', width: isLarge && '100%'}} 
                                                        onClick={() => authorizeTwitch(['channel:manage:polls'])}>Elevate Twitch Access</Button>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Typography>Ratbot currently does not have your permission to manage polls, use below button to give permission</Typography>
                                            <Button size='small' style={{marginTop: '16px', width: isMobile && '100%'}} 
                                                onClick={() => authorizeTwitch(['channel:manage:polls'])}>Elevate Twitch Access</Button>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </SectionBackground>
                        </Grid>
                        {/* REWARDS */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <SectionBackground>
                                <Typography variant='h3'>Rewards</Typography>
                                {(!isLarge) && (<Typography sx={{marginTop: '8px'}}>Create viewer rewards for interaction with Ratbot.</Typography>)}
                                <React.Fragment>
                                    {userSettings['twitchPermissions'].includes('channel:manage:redemptions') ? (
                                        <React.Fragment>
                                            <Grid container sx={{marginTop: '0px'}} spacing={2}>
                                                <Grid item xs={isLarge ? 12 : 6}>
                                                    <Button style={{width: isLarge && '100%'}} size='small' onClick={() => openDialogComponent('twitchpersonalityrewards')}>Personality Redeems</Button>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Typography>Ratbot currently does not have your permission to manage channel point rewards, use below button to give permission</Typography>
                                            <Button size='small' style={{marginTop: '16px', width: isLarge && '100%'}} 
                                                onClick={() => authorizeTwitch(['channel:manage:redemptions', 'channel:read:redemptions'])}>Elevate Twitch Access</Button>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </SectionBackground>
                        </Grid>
                    </Grid>
                    <Grid container sx={{marginTop: !isMobile && '16px'}} spacing={(isLarge && !isMobile) ? 0 : 2}>
                        {/* PREDICTIONS */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <SectionBackground>
                                <Typography variant='h3'>Predictions</Typography>
                                {(!isLarge) && (<Typography sx={{marginTop: '8px'}}>Allow Ratbot to automatically create predictions.</Typography>)}
                                <React.Fragment>
                                    {validModData('create_prediction') ? (
                                        <React.Fragment>
                                            {userSettings['twitchPermissions'].includes('channel:manage:predictions') ? (
                                                <React.Fragment>
                                                    <Grid container sx={{marginTop: '0px'}} spacing={2}>
                                                        <Grid item xs={isLarge ? 12 : 6}>
                                                            <Button size='small' style={{width: isLarge && '100%'}}
                                                                onClick={() => openDialogComponent('twitchpredictionlength')}>{`Prediction window: ${formatTime(userSettings['modData']['twitch']['commands']['create_prediction']['prediction_window'] ?? 60)}`}</Button>
                                                        </Grid>
                                                        <Grid item xs={isLarge ? 12 : 6}>
                                                            <Button size='small' style={{width: isLarge && '100%'}}
                                                                onClick={() => openDialogComponent('twitchpredictioncommand')}>Prediction Command</Button>
                                                        </Grid>
                                                        <Grid item xs={isLarge ? 12 : 6}>
                                                            <Button size='small' style={{width: isLarge && '100%'}}
                                                                onClick={() => openDialogComponent('twitchendpredictioncommand')}>End Prediction Command</Button>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Typography>Ratbot currently does not have your permission to manage predictions, use below button to give permission</Typography>
                                                    <Button size='small' style={{marginTop: '16px', width: isMobile && '100%'}} 
                                                        onClick={() => authorizeTwitch(['channel:manage:predictions'])}>Elevate Twitch Access</Button>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Typography>Ratbot currently does not have your permission to manage predictions, use below button to give permission</Typography>
                                            <Button size='small' style={{marginTop: '16px', width: isMobile && '100%'}} 
                                                onClick={() => authorizeTwitch(['channel:manage:predictions'])}>Elevate Twitch Access</Button>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </SectionBackground>
                        </Grid>
                        {/* AUTHORIZATION */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <SectionBackground>
                                <Typography variant='h3'>Authorization</Typography>
                                {(!isLarge) && (<Typography sx={{marginTop: '8px'}}>Use this button to immediately grant full Twitch access for Ratbot.</Typography>)}
                                <Grid container sx={{marginTop: '0px'}} spacing={2}>
                                    <Grid item xs={isLarge ? 12 : 6}>
                                        <Button size='small' style={{width: isLarge && '100%'}} disabled={authCtx.editor}
                                            onClick={() => window.location.href = fullAccessUrl}>Authorize Full Access</Button>
                                    </Grid>
                                </Grid>
                            </SectionBackground>
                        </Grid>
                    </Grid>
                    <Grid container sx={{marginTop: !isMobile && '16px'}} spacing={(isLarge && !isMobile) ? 0 : 2}>
                        {/* TTS */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <SectionBackground>
                                <Typography variant='h3'>OBS Plugins</Typography>
                                {(!isLarge) && (<Typography sx={{marginTop: '8px'}}>Set up the plugin sources to use with OBS.</Typography>)}
                                <Grid container sx={{marginTop: '0px'}} spacing={2}>
                                    <Grid item xs={isMobile ? 12 : 12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h4'>RatbotTTS</Typography>
                                            </Grid>
                                            <Grid item xs={isLarge ? 12 : 6}>
                                                <Button size='small' style={{width: '100%'}} disabled={authCtx.editor}
                                                        onClick={() => openDialogComponent('obstts')}>Settings</Button>
                                            </Grid>
                                            <Grid item xs={isLarge ? 12 : 6}>
                                                <Button size='small' style={{width: '100%'}} disabled={!('obs' in userSettings['modData'] && userSettings['modData']['obs']['pp'] !== '' && userSettings['modData']['obs']['voice'] !== '')}
                                                        onClick={() => openDialogComponent('obsttsreward')}>Channel Points Reward</Button>
                                            </Grid>
                                            <Grid item xs={isLarge ? 12 : 6}>
                                                <Button size='small' style={{width: '100%'}} disabled={!('obs' in userSettings['modData'] && userSettings['modData']['obs']['pp'] !== '')}
                                                        onClick={() => copyToClipboard(`https://ratbot.store/obs/tts?channel=${authCtx.username}&pp=${userSettings['modData']?.['obs']?.['pp']}`)}>Copy OBS Browser Source</Button>
                                            </Grid>
                                            {hasCopied && (
                                                <Grid item xs={12}>
                                                    <Typography variant='subtitle2' onClick={() => setHasCopied(false)}>Browser source copied - Keep this hidden! Create a new Browser source in OBS and paste this link in the URL field.</Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SectionBackground>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </React.Fragment>
    );
};

export default Twitch;