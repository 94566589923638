import React, { useState } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaHome, FaAndroid, FaTerminal, FaBolt, FaSlidersH, FaThLarge, FaSitemap, FaRobot, FaCog, FaPencilAlt, FaWrench, FaMusic, FaVideo, FaGamepad, FaBook } from 'react-icons/fa';
import 'react-pro-sidebar/dist/css/styles.css';
import './SidebarMenu.css';

const SidebarMenu = ({ backgroundColor = '#5D3B9E', setIsBlur = () => {}, openDialogComponent = () => {}, setMainViewComponent = () => {}, setMainViewHeader = () => {} }) => {
    const [openSubMenu, setOpenSubMenu] = useState(null);

    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const iconStyle = {
        color: '#ffffff'
    };

    const handleSubMenuClick = (menuName) => {
        setOpenSubMenu(openSubMenu === menuName ? null : menuName);
    };

    const handleMenuItemClick = (event, componentName) => {
        event.stopPropagation();  // Prevents the SubMenu's onClick from firing
        setMainViewComponent(componentName);
    };

    return (
        <ProSidebar breakPoint="sm" className="custom-sidebar" width="200px" style={{'--icon-background-color': colorScheme.background75}}>
            <Menu iconShape="circle" style={{ backgroundColor: colorScheme.background25, height: '100vh', borderRight: `2px solid ${colorScheme.background50}` }}>
                <MenuItem onClick={() => setMainViewComponent('dashboard')} icon={<FaHome style={iconStyle} />}><p>Dashboard</p></MenuItem>
                
                <SubMenu title="Personalities" icon={<FaAndroid style={iconStyle} />}
                         open={openSubMenu === 'personalities'} onClick={() => handleSubMenuClick('personalities')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'aipresets')} icon={<FaRobot style={iconStyle} />}>My Presets</MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'personalitybuilder')} icon={<FaWrench style={iconStyle} />}>Create New</MenuItem>
                </SubMenu>
                
                <SubMenu title="Commands" icon={<FaTerminal style={iconStyle} />}
                         open={openSubMenu === 'commands'} onClick={() => handleSubMenuClick('commands')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'systemcommands')} icon={<FaCog style={iconStyle} />}>Built-in</MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'customcommands')} icon={<FaPencilAlt style={iconStyle} />}>My Commands</MenuItem>
                </SubMenu>
                
                <MenuItem onClick={() => setMainViewComponent('triggers')} icon={<FaBolt style={iconStyle} />}>Triggers</MenuItem>
                <MenuItem onClick={() => setMainViewComponent('macros')} icon={<FaSlidersH style={iconStyle} />}>Macros</MenuItem>
                
                <SubMenu title="Variables" icon={<FaThLarge style={iconStyle} />}
                         open={openSubMenu === 'variables'} onClick={() => handleSubMenuClick('variables')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'systemvariables')} icon={<FaCog style={iconStyle} />}>System</MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'customvariables')} icon={<FaPencilAlt style={iconStyle} />}>My Variables</MenuItem>
                </SubMenu>
                
                <SubMenu title="Integrations" icon={<FaSitemap style={iconStyle} />}
                         open={openSubMenu === 'integrations'} onClick={() => handleSubMenuClick('integrations')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'spotify')} icon={<FaMusic style={iconStyle} />}>Spotify</MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'twitch')} icon={<FaVideo style={iconStyle} />}>Twitch</MenuItem>
                </SubMenu>

                <SubMenu title="Games" icon={<FaGamepad style={iconStyle} />}
                         open={openSubMenu === 'games'} onClick={() => handleSubMenuClick('games')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'story')} icon={<FaBook style={iconStyle} />}>Story</MenuItem>
                </SubMenu>
            </Menu>
        </ProSidebar>
    );
};

export default SidebarMenu;