import React, { useContext } from 'react';
import { Box } from '@mui/material';
import useIsMobile from './useIsMobile';
import MainPage from './mainpage';
import { ColorModeContext } from '../theme';
import './loading.css';

const Loading = ({size = 12}) => {
  const isMobile = useIsMobile();
  const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
  const additionalStyles = {
    '& .wheel-and-hamster': {
      fontSize: `${size}px`
    }, 
    overflow: 'hidden'
  };

  return (
    <Box sx={additionalStyles}>
      <div aria-label="Grey rat running in a wheel" role="img" className="wheel-and-hamster">
        <div className="wheel"></div>
        <div className="rat">
          <div className="rat__body">
            <div className="rat__head">
              <div className="rat__ear"></div>
              <div className="rat__eye"></div>
              <div className="rat__nose"></div>
            </div>
            <div className="rat__limb rat__limb--fr"></div>
            <div className="rat__limb rat__limb--fl"></div>
            <div className="rat__limb rat__limb--br"></div>
            <div className="rat__limb rat__limb--bl"></div>
            <div className="rat__tail"></div>
          </div>
        </div>
        <div className="spoke"></div>
      </div>
    </Box>
  )
}

export default Loading;
