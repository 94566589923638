import React, { useEffect, useState } from 'react';
import useSubscriberList from '../../utils/useSubscriberList';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const SubscriberList = ({backgroundColor = '#5D3B9E', openDialogComponent = () => {}, setSelectedComponent = () => {}}) => {
    const [tableItems, setTableItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
        }
    });
    const subscriberlist = useSubscriberList();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        table: {
            maxHeight: '75vh',
            overflowY: 'auto',
            backgroundColor: backgroundColor,
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none'
        }
    };
    const templates = {
        customerTemplate: (customer) => {
            return <Button label='Customer Stripe Data' size='small' onClick={() => openDialogComponent('subscribercustomer', {customer})} />;
        },
        subscriptionTemplate: (customer) => {
            return <Button label='Subscription Stripe Data' size='small' onClick={() => openDialogComponent('subscribersubscription', {customer})} />;
        },
        invoicesTemplate: (customer) => {
            return <Button label='Invoices' size='small' onClick={() => openDialogComponent('subscriberinvoices', {customer})} />;
        }
    };

    useEffect(() => {
        const _tableItems = [];
        Object.keys(subscriberlist).map((username, index) => {
            const insertObj = {};
            insertObj['id'] = index;
            insertObj['username'] = username;
            insertObj['email'] = subscriberlist[username]['email'];
            insertObj['payment_status'] = subscriberlist[username]['payment_status'];
            insertObj['payment_link'] = subscriberlist[username]['payment_link'];
            insertObj['subscription'] = subscriberlist[username]['subscription'];
            insertObj['customer'] = subscriberlist[username]['customer'];
            insertObj['status'] = subscriberlist[username]['status'];
            _tableItems.push(insertObj);
        });
        setTableItems(_tableItems);
    }, [subscriberlist]);

    const renderHeader = () => {
        const onGlobalFilterChange = (e) => {
            const value = e.target.value;
            let _filters = { ...filters };
            _filters['global'].value = value;
            setFilters(_filters);
            setGlobalFilterValue(value);
        };

        return (
            <div className="flex justify-content-end">
                <span style={{marginRight: '16px'}}>
                    <Button icon='pi pi-arrow-left' style={{width: '110px'}} label='Back' onClick={() => setSelectedComponent('')} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText style={{width: '100%'}} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    return (
        <React.Fragment>
            <DataTable value={tableItems} style={styles.table} size='small' stripedRows showGridlines filters={filters} header={renderHeader()}
                paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]} sortField='username' sortOrder={1}>
                <Column sortable field='username' header='Username'></Column>
                <Column sortable field='email' header='Email'></Column>
                <Column sortable field='status' header='Status'></Column>
                <Column sortable field='payment_status' header='Payment Status'></Column>
                <Column style={{width: '250px'}} sortable field='customer' header='Customer' body={templates.customerTemplate}></Column>
                <Column style={{width: '250px'}} sortable field='subscription' header='Subscription' body={templates.subscriptionTemplate}></Column>
                <Column style={{width: '150px'}} sortable field='customer' header='Invoices' body={templates.invoicesTemplate}></Column>
            </DataTable>
        </React.Fragment>
    );
};

export default SubscriberList;