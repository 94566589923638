import { useState, useEffect, useMemo, useCallback } from 'react';
import { useUser } from '../context/UserContext';

const useCustomAIPresets = () => {
  const authCtx = useUser();
  const [presets, setPresets] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false); // New state to trigger fetch
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchData = useCallback(async () => {
    try {
      const presetResponse = await fetch(`${apiUrl}/user/custompersonalities?token=${authCtx.token}`);
      const presetData = await presetResponse.json();
      const sortedRes = presetData['personalities'].sort();
      setPresets(sortedRes);
    } catch (e) {
      console.log(e);
    }
  }, [apiUrl, authCtx.token]);

  useEffect(() => {
    fetchData();
  }, [apiUrl, fetchTrigger, fetchData]);

  // Memoize the result using useMemo
  const memoizedPresets = useMemo(() => presets, [presets]);

  // Function to trigger re-fetch
  const updateCustomPresets = useCallback(() => {
    setFetchTrigger(prev => !prev);
  }, []);

  return { customPresets: memoizedPresets, updateCustomPresets };
};

export default useCustomAIPresets;