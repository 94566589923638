import React from 'react';
import { Grid } from '@mui/material';
import useIsMobile from './useIsMobile';

const MainPageWrapper = ({ backgroundColor, children }) => {
  const childArray = React.Children.toArray(children);
  const numChildren = childArray.length;
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={0} sx={{backgroundColor}}>
      {childArray.map((child, index) => {
        if (isMobile) {
          return (
            <Grid item xs={12} key={index}>
              {child}
            </Grid>
          );
        } else if (numChildren === 1) {
          return (
            <Grid item xs={12} key={index}>
              {child}
            </Grid>
          );
        } else {
          return (
            <Grid item xs={6} key={index}>
              <Grid container>
                <Grid item xs={12}>
                  {child}
                </Grid>
              </Grid>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default MainPageWrapper;