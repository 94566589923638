import React, { useEffect } from 'react';
import { ColorModeProvider, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useLocation  } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import Users from './scenes/admin/users';
import Invoices from './scenes/admin/invoices';
import Landing from './scenes/landing';
import AuthSuccess from './store/authsuccess';
import ManagePlans from './scenes/admin/plans';
import AdminRoute from './routes/AdminRoute';
import BotManager from './scenes/admin/botmanager';
import ServerMetrics from './scenes/admin/servermetrics';
import { useUser } from './context/UserContext';
import KeyWords from './scenes/admin/keywords';
import Emotes from './scenes/admin/emotes';
import Public from './scenes/public';
import Spotify from './scenes/admin/spotify';
import Loading from './utils/loading';
import ChangeLog from './scenes/admin/changelog';
import Onboarding from './scenes/onboarding';
import StandardView from './beta/layouts/StandardView';
import TTS from './obs/tts';
import StreamerSettingsProvider from './store/streamersettings-context';
import EditorView from './beta/layouts/EditorView';
import UserSettingsProvider from './store/usersettings-context';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../node_modules/primereact/resources/themes/lara-dark-purple/theme.css";
import SpotifyPlayer from './spotifyplayer';
import StripeCheckout from './utils/stripecheckout';

function App() {
  const [theme, colorMode] = useMode();
  const authCtx = useUser();
  const location = useLocation();
  const sidebarExlusions = ['/about', '/onboarding', '/settings', '/'];
  const hideSidebar = true;

  // Anti-tampering measure
  useEffect(() => {
    const handleStorageChange = () => {
      authCtx.checkIfAdmin(authCtx.token);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
  <ColorModeProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <PrimeReactProvider>
          <div className="app" style={{overflowX: 'hidden', overflowY: 'hidden'}}>
              <main className="content">
                <Routes>
                  {(authCtx.isAdmin === "true") && (
                    <React.Fragment>
                      <Route path="/admin/users" element={<AdminRoute><Users /></AdminRoute>} />
                      <Route path="/admin/invoices" element={<AdminRoute><Invoices /></AdminRoute>} />
                      <Route path="/admin/plans" element={<AdminRoute><ManagePlans /></AdminRoute>} />
                      <Route path="/admin/botmanager" element={<AdminRoute><BotManager /></AdminRoute>} />
                      <Route path="/admin/servermetrics" element={<AdminRoute><ServerMetrics /></AdminRoute>} />
                      <Route path="/admin/keywords" element={<AdminRoute><KeyWords /></AdminRoute>} />
                      <Route path="/admin/emotes" element={<AdminRoute><Emotes /></AdminRoute>} />
                      <Route path="/admin/spotify" element={<AdminRoute><Spotify /></AdminRoute>} />
                      <Route path="/admin/changelog" element={<AdminRoute><ChangeLog /></AdminRoute>} />
                      <Route path="/onboarding" element={<Onboarding />} />
                      <Route path="/loading" element={<Loading />} />
                    </React.Fragment>
                  )}
                  {(authCtx.isLoggedIn === true) ? (
                    <React.Fragment>
                      {authCtx.editor === false ? (
                        <React.Fragment>
                            <React.Fragment>
                              <Route path="/" element={<UserSettingsProvider><StandardView /></UserSettingsProvider>} />
                              <Route path="/spotifyplayer/*" element={<UserSettingsProvider><SpotifyPlayer /></UserSettingsProvider>} />
                              <Route path="/stripecheckout" element={<StripeCheckout />} />
                            </React.Fragment>
                          <Route path="/settings" element={<UserSettingsProvider><StandardView /></UserSettingsProvider>} />
                        </React.Fragment>
                      ) : (
                          <Route path="/editorsettings" element={<StreamerSettingsProvider><EditorView /></StreamerSettingsProvider>} />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Route path="/" element={<Landing />} />
                    </React.Fragment>
                  )}
                  {/*<Route path='*' element={<ErrorPage msg="Page not found" status="404" />} />*/}
                  <Route path="/authsuccess" element={<AuthSuccess />} />
                  <Route path="/about" element={<Public />} />
                  <Route path="/obs/tts" element={<TTS />} />
                </Routes>
              </main>
          </div>
        </PrimeReactProvider>
    </ThemeProvider>
  </ColorModeProvider>
  );
}

export default App;
