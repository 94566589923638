import React from 'react';
import { Typography, Container, Grid, Card, CardContent, styled } from '@mui/material';
import useIsMobile from '../../utils/useIsMobile';

const PersonalitiesPage = ({presets}) => {
    const isMobile = useIsMobile();
    const TransparentCard = styled(Card)(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        boxShadow: 'none',
    }));

  return (
    <div style={{ display: 'flex', minHeight: '70vh', marginTop: '24px', justifyContent: !isMobile && 'center' }}>
      <Container maxWidth="xl">
        <Typography variant={isMobile ? "h3" : "h1"} align={isMobile ? 'left' : 'center'}>Available personalities for Ratbot</Typography>
        <br />
        <Grid container spacing={isMobile ? 0 : 2} sx={{overflowY: 'auto', maxHeight: '75vh'}}>
          {Object.keys(presets).length > 0 &&
            Object.keys(presets).map((preset) => (
              <Grid item xs={12} sm={3} key={preset}>
                <div style={{ margin: '10px' }}>
                  <TransparentCard>
                    <CardContent>
                      <Typography variant="h3" gutterBottom>
                        {preset}
                      </Typography>
                      <Typography variant="body1">{presets[preset].description}</Typography>
                    </CardContent>
                  </TransparentCard>
                </div>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default PersonalitiesPage;