import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';

//color design tokens
export const tokens = (mode) => ({...(mode === 'dark' ? 
    {
        grey: {
            100: "#e0e0e0",
            200: "#c2c2c2",
            300: "#a3a3a3",
            400: "#858585",
            500: "#666666",
            600: "#525252",
            700: "#3d3d3d",
            800: "#292929",
            900: "#141414"
        },
        primary: {
            100: "#d0d1d5",
            200: "#a1a4ab",
            300: "#727681",
            400: "#1F2A40",
            500: "#141b2d",
            600: "#101624",
            700: "#0c101b",
            800: "#080b12",
            900: "#040509"
        },
        greenAccent: {
            100: "#dbf5ee",
            200: "#b7ebde",
            300: "#94e2cd",
            400: "#70d8bd",
            500: "#4cceac",
            600: "#3da58a",
            700: "#2e7c67",
            800: "#1e5245",
            900: "#0f2922"
        },
        redAccent: {
            100: "#f8dcdb",
            200: "#f1b9b7",
            300: "#e99592",
            400: "#e2726e",
            500: "#db4f4a",
            600: "#af3f3b",
            700: "#832f2c",
            800: "#58201e",
            900: "#2c100f"
        },
        blueAccent: {
            100: "#e1e2fe",
            200: "#c3c6fd",
            300: "#a4a9fc",
            400: "#868dfb",
            500: "#6870fa",
            600: "#535ac8",
            700: "#3e4396",
            800: "#2a2d64",
            900: "#151632"
        },
        purpleAccent: {
            100: "#4d2674",
            200: "#572a85",
            300: "#622e96",
            400: "#6c33a6",
            500: "#7637b7",
            600: "#823bc8",
            700: "#8c40d8",
            800: "#9a56e5",
            900: "#a86ce3",
        },
    } : {
        grey: {
            100: "#141414",
            200: "#292929",
            300: "#3d3d3d",
            400: "#525252",
            500: "#666666",
            600: "#858585",
            700: "#a3a3a3",
            800: "#c2c2c2",
            900: "#e0e0e0",
        },
        primary: {
            100: "#040509",
            200: "#080b12",
            300: "#0c101b",
            400: "#f2f0f0",
            500: "#141b2d",
            600: "#434957",
            700: "#727681",
            800: "#a1a4ab",
            900: "#d0d1d5",
        },
        greenAccent: {
            100: "#0f2922",
            200: "#1e5245",
            300: "#2e7c67",
            400: "#3da58a",
            500: "#4cceac",
            600: "#70d8bd",
            700: "#94e2cd",
            800: "#b7ebde",
            900: "#dbf5ee",
        },
        redAccent: {
            100: "#2c100f",
            200: "#58201e",
            300: "#832f2c",
            400: "#af3f3b",
            500: "#db4f4a",
            600: "#e2726e",
            700: "#e99592",
            800: "#f1b9b7",
            900: "#f8dcdb",
        },
        blueAccent: {
            100: "#151632",
            200: "#2a2d64",
            300: "#3e4396",
            400: "#535ac8",
            500: "#6870fa",
            600: "#868dfb",
            700: "#a4a9fc",
            800: "#c3c6fd",
            900: "#e1e2fe",
        },
        purpleAccent: {
            100: "#4d2674",
            200: "#572a85",
            300: "#622e96",
            400: "#6c33a6",
            500: "#7637b7",
            600: "#823bc8",
            700: "#8c40d8",
            800: "#9a56e5",
            900: "#a86ce3",
        },
    }
)});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);
    const commons = {
        red: {
            ...colors.redAccent
        },
        purple: {
            ...colors.purpleAccent
        },
        grey: {
            100: "#141414",
            200: "#292929",
            300: "#3d3d3d",
            400: "#525252",
            500: "#666666",
            600: "#858585",
            700: "#a3a3a3",
            800: "#c2c2c2",
            900: "#e0e0e0",
        },
        green: {
            100: "#dbf5ee",
            200: "#b7ebde",
            300: "#94e2cd",
            400: "#70d8bd",
            500: "#4cceac",
            600: "#3da58a",
            700: "#2e7c67",
            800: "#1e5245",
            900: "#0f2922"
        }
    };
    const fonts = {
        sourceSansPro: {
            fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 14,
            }
        }
    };
    const themes = {
        dark: {
            palette: {
                mode: mode,
                commons: {...commons},
                primary: {
                    darker: colors.primary[700],
                    dark: colors.primary[600],
                    main: colors.primary[500],
                    light: colors.primary[400],
                    lighter: colors.primary[300]
                },
                secondary: {
                    darker: colors.greenAccent[700],
                    dark: colors.greenAccent[600],
                    main: colors.greenAccent[500],
                    light: colors.greenAccent[400],
                    text: colors.grey[900]
                },
                neutral: {
                    lightest: colors.grey[400],
                    light: colors.grey[500],
                    main: colors.grey[600],
                    dark: colors.grey[700],
                    darker: colors.grey[800],
                    darkest: colors.grey[900],
                },
                background: {
                    default: mode === 'dark' ? colors.primary[500] : "#fcfcfc",
                },
                text: {
                    primary: colors.grey[100],
                    secondary: colors.grey[100],
                }
            },
            typography: {
                ...fonts.sourceSansPro
            }
        },
        light: {
            palette: {
                mode: mode,
                commons: {...commons},
                primary: {
                    darker: colors.primary[700],
                    dark: colors.primary[900],
                    main: colors.primary[400],
                    light: colors.primary[900],
                    lighter: colors.primary[800]
                },
                secondary: {
                    darker: colors.greenAccent[100],
                    dark: colors.greenAccent[200],
                    main: colors.greenAccent[300],
                    light: colors.greenAccent[400],
                    text: colors.grey[900]
                },
                neutral: {
                    lightest: colors.grey[900],
                    light: colors.grey[800],
                    main: colors.grey[700],
                    dark: colors.grey[600],
                    darker: colors.grey[500],
                    darkest: colors.grey[300]
                },
                background: {
                    default: "#fcfcfc",
                },
                text: {
                    primary: colors.grey[100],
                    secondary: colors.grey[900],
                }
            },
            typography: {
                ...fonts.sourceSansPro
            }
        },
        ocean: {
            palette: {
                mode: mode,
                commons: {...commons},
                primary: {
                    darker: "#004d73",
                    dark: "#006e9a",
                    main: "#008fcb",
                    light: "#4ca8e0",
                    lighter: "#a3c7f1"
                },
                secondary: {
                    darker: "#005839",
                    dark: "#00704f",
                    main: "#3deddf",
                    light: "#30b08f",
                    text: commons.grey[100]
                },
                neutral: {
                    lightest: "#f2f4f5",
                    light: "#d9dde0",
                    main: "#bfc5c9",
                    dark: "#a7afb5",
                    darker: "#8e979f",
                    darkest: "#757e88"
                },
                background: {
                    default: mode === 'dark' ? "#008fcb" : "#fcfcfc",
                },
                text: {
                    primary: "#f2f4f5",
                    secondary: "#f2f4f5",
                }
            },
            typography: {
                ...fonts.sourceSansPro
            }
        },
        vibrant: {
            palette: {
                mode: mode,
                commons: {...commons},
                primary: {
                    darker: "#4a006e",
                    dark: "#7c009f",
                    main: "#ad00d1",
                    light: "#d83df2",
                    lighter: "#ef82f7"
                },
                secondary: {
                    darker: "#5f3700",
                    dark: "#945200",
                    main: "#ffff00",
                    light: "#ffb640",
                    text: commons.grey[100]
                },
                neutral: {
                    lightest: "#f9f2db",
                    light: "#f2e0b2",
                    main: "#e8cd86",
                    dark: "#ddad58",
                    darker: "#d08b2b",
                    darkest: "#c26900"
                },
                background: {
                    default: mode === 'dark' ? "#ad00d1" : "#fcfcfc",
                },
                text: {
                    primary: "#f9f2db",
                    secondary: "#f9f2db",
                }
            },
            typography: {
                ...fonts.sourceSansPro
            }
        }
    };
    return themes[mode];
};

export const ColorModeContext = createContext({
    themeMode: 'dark',
    toggleThemeMode: () => {},
    changeTheme: (newTheme) => {},
    theme: {}
});

export const ColorModeProvider = ({ children }) => {
    const [themeMode, setThemeMode] = useState(Cookies.get('themeMode') || 'dark');

    const toggleThemeMode = () => {
        setThemeMode((prev) => (prev === "light" ? "dark" : "light"));
    };

    const changeTheme = (newTheme) => {
        setThemeMode(newTheme);
        Cookies.set('themeMode', newTheme, { expires: 365 });
    };

    const theme = useMemo(() => {
        return createTheme(themeSettings(themeMode));
    }, [themeMode]);

    return (
        <ColorModeContext.Provider value={{ themeMode, changeTheme, toggleThemeMode, theme }}>
            {children}
        </ColorModeContext.Provider>
    );
};

export const useMode = () => {
    const [themeMode, setThemeMode] = useState("dark");
    const colorMode = useMemo(() => ({
        themeMode,
        toggleThemeMode: () => setThemeMode((prev) => (prev === "light" ? "dark" : "light")),
        setTheme: (newTheme) => setThemeMode(newTheme),
    }), []);
    const theme = useMemo(() => createTheme(themeSettings(themeMode)), [themeMode]);
    return [theme, colorMode];
};