import { useState, useEffect, useContext } from 'react';
import AuthContext from '../store/auth-context';
import { useUser } from '../context/UserContext';

const useChatlogs = () => {
  const [chatlogs, setChatlogs] = useState([]);
  const authCtx = useUser();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchChatlogs = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/chatlogs?token=${authCtx.token}`);
        const data = await response.json();
        setChatlogs(data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchChatlogs();
  }, [apiUrl, authCtx.token]);

  return chatlogs;
};

export default useChatlogs;