import { useEffect, useState, useRef } from 'react';

const useSpotifyPlayer = (token) => {
  const [player, setPlayer] = useState(null);
  const [playerState, setPlayerState] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [deviceId, setDeviceId] = useState(localStorage.getItem('spotify_device_id') || null);
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef(null);
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (!scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      script.async = true;

      script.onload = () => {
        scriptLoaded.current = true;
        if (window.onSpotifyWebPlaybackSDKReady) {
          window.onSpotifyWebPlaybackSDKReady();
        }
      };

      script.onerror = () => {
        console.error('Spotify SDK failed to load.');
      };

      document.body.appendChild(script);
    } else {
      initializeSpotifyPlayer();
    }

    window.onSpotifyWebPlaybackSDKReady = () => {
      initializeSpotifyPlayer();
    };

    return () => {
      if (playerRef.current) {
        playerRef.current.disconnect();
      }
    };
  }, [token]);

  const initializeSpotifyPlayer = () => {
    if (playerRef.current) {
      console.log('Spotify Player already initialized');
      return;
    }

    const spotifyPlayer = new window.Spotify.Player({
      name: 'Ratbot Spotify Player 2',
      getOAuthToken: (cb) => {
        cb(token);
      },
      volume: 0.5,
    });

    spotifyPlayer.addListener('ready', ({ device_id }) => {
      console.log('Spotify Player is ready with device ID:', device_id);
      localStorage.setItem('spotify_device_id', device_id);
      setDeviceId(device_id);
      setIsReady(true);
      fetchPlayerState(spotifyPlayer); // Fetch player state when ready
    });

    spotifyPlayer.addListener('player_state_changed', (state) => {
      if (state) {
        console.log(state);
        setPlayerState(state);
        setCurrentTrack(state.track_window.current_track);
      }
    });

    spotifyPlayer.addListener('initialization_error', ({ message }) => {
      console.error('Initialization Error:', message);
    });

    spotifyPlayer.addListener('authentication_error', ({ message }) => {
      console.error('Authentication Error:', message);
    });

    spotifyPlayer.addListener('account_error', ({ message }) => {
      console.error('Account Error:', message);
    });

    spotifyPlayer.addListener('playback_error', ({ message }) => {
      console.error('Playback Error:', message);
    });

    spotifyPlayer.connect().then(success => {
      if (success) {
        console.log('Spotify Player connected successfully');
        setPlayer(spotifyPlayer);
        playerRef.current = spotifyPlayer;
      } else {
        console.error('Spotify Player connection failed');
      }
    });
  };

  const fetchPlayerState = (spotifyPlayer) => {
    spotifyPlayer.getCurrentState().then(state => {
      if (state) {
        setPlayerState(state);
        setCurrentTrack(state.track_window.current_track);
      }
    }).catch(err => {
      console.error('Failed to fetch player state', err);
    });
  };

  const getPlayerState = () => {
      return playerState;
  };

  const play = () => {
    if (playerRef.current) {
      playerRef.current.resume().catch(err => console.error('Failed to resume playback', err));
    }
  };

  const pause = () => {
    if (playerRef.current) {
      playerRef.current.pause().catch(err => console.error('Failed to pause playback', err));
    }
  };

  const nextTrack = () => {
    if (playerRef.current) {
      playerRef.current.nextTrack().catch(err => console.error('Failed to skip to next track', err));
    }
  };

  const previousTrack = () => {
    if (playerRef.current) {
      playerRef.current.previousTrack().catch(err => console.error('Failed to go to previous track', err));
    }
  };

  const togglePlayPause = () => {
    if (playerRef.current) {
      playerRef.current.togglePlay().catch(err => console.error('Failed to toggle play/pause', err));
    }
  };

  const setVolume = (volume) => {
    if (playerRef.current) {
      playerRef.current.setVolume(volume / 100).catch(err => console.error('Failed to set volume', err));
    }
  };

  const getVolume = () => {
    if (playerRef.current) {
      return playerRef.current.getVolume().then(volume => {
        return volume * 100;
      }).catch(err => console.error('Failed to get volume', err));
    }
  };

  const addToQueue = async (uri) => {
    try {
      await fetch(`https://api.spotify.com/v1/me/player/queue?uri=${uri}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.error('Failed to add to queue', err);
    }
  };

  const playTrack = async ({ uri, uris, offset, positionMs }) => {
    const body = {};

    if (Array.isArray(uris) && uris.length > 0) {
      body.uris = uris;
    } else if (typeof uri === 'string') {
      if (uri.startsWith('spotify:track:')) {
        body.uris = [uri];
      } else {
        body.context_uri = uri;
        if (offset) body.offset = offset;
      }
    }

    if (positionMs !== undefined) body.position_ms = positionMs;

    try {
      await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.error('Failed to start playback', err);
    }
  };

  const transferPlayback = async (device_id) => {
    try {
      await fetch('https://api.spotify.com/v1/me/player', {
        method: 'PUT',
        body: JSON.stringify({
          device_ids: [device_id],
          play: false, // Change to true if you want playback to start
        }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setDeviceId(device_id);
    } catch (err) {
      console.error('Failed to transfer playback', err);
    }
  };

  return {
    player,
    playerState,
    currentTrack,
    deviceId,
    isReady,
    play,
    pause,
    nextTrack,
    previousTrack,
    togglePlayPause,
    setVolume,
    getVolume,
    addToQueue,
    playTrack,
    transferPlayback,
    getPlayerState
  };
};

export default useSpotifyPlayer;
