import { Box, Grid } from '@mui/material';
import React from 'react';
import useLoadStories from '../../../utils/useLoadStories';
import SavedStory from './SavedStory';

const LoadExistingGame = ({backgroundColor = '#5D3B9E', setStoryObject = () => {}}) => {
    const { savedStories, refreshSavedStories } = useLoadStories();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const styles = {
        outerBox: {
            backgroundColor: colorScheme.background25,
            height: '67vh',
            padding: '16px',
            borderRadius: '15px',
            overflowY: 'auto',
            borderTop: `1px solid ${colorScheme.background50}`
        },
        storyItem: {
            backgroundColor: colorScheme.background25,
            borderRadius: '15px',
            border: `2px solid ${colorScheme.background50}`,
            padding: '16px',
            marginBottom: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            maxHeight: '250px',
            overflow: 'hidden',  // Ensures content that overflows the maxHeight is hidden
            '&:hover': {
                backgroundColor: colorScheme.background50
            }
        },
        storyTitle: {
            marginBottom: '0px',
            marginTop: '0px'
        },
        storyDetails: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        detailBlock: {
            
        },
        detailTitle: {
            fontWeight: 'bold',
            margin: '0px',
            marginRight: '4px'
        },
        detailText: {
            margin: '0px'
        }
    };

    const handleStoryClick = (story) => {
        const loadedStory = new SavedStory(story);
        setStoryObject(loadedStory);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <h1>Load Story</h1>
            </Grid>
            <Grid item xs={12}>
                <Grid container sx={styles.outerBox}>
                    {savedStories.map((save, index) => (
                        <Box key={index} sx={styles.storyItem} onClick={() => handleStoryClick(save)} onMouseOver={(e) => e.currentTarget.style.backgroundColor = colorScheme.background50}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorScheme.background25}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <h3 style={styles.storyTitle}>{save.title}</h3>
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 style={styles.storyTitle}>Details</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sx={styles.detailBlock}>
                                            <p style={styles.detailTitle}>Theme:</p>
                                            <p style={styles.detailText}>{save.theme}</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sx={styles.detailBlock}>
                                                    <p style={styles.detailTitle}>Current Chapter:</p>
                                                    <p style={styles.detailText}>{save.currentChapterTitle} (Chapter {save.currentChapterIndex + 1})</p>
                                                </Grid>
                                                <Grid item xs={6} sx={styles.detailBlock}>
                                                    <p style={styles.detailTitle}>Plot Points Resolved:</p>
                                                    <p style={styles.detailText}>{save.currentChapterResolvedPlotPoints}/{save.currentChapterPlotPoints}</p>
                                                </Grid>
                                                <Grid item xs={6} sx={styles.detailBlock}>
                                                    <p style={styles.detailTitle}>Player Location:</p>
                                                    <p style={styles.detailText}>{save.playerLocation}</p>
                                                </Grid>
                                                <Grid item xs={6} sx={styles.detailBlock}>
                                                    <p style={styles.detailTitle}>Last Played:</p>
                                                    <p style={styles.detailText}>{new Date(save.timestamp).toLocaleString()}</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LoadExistingGame;
