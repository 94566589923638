import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Button, Grid, Typography } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Slider } from 'primereact/slider';
import { useUserSettings } from '../../store/usersettings-context';
import useIsMobile from '../../utils/useIsMobile';
import { useUser } from '../../context/UserContext';

const PersonalityRewards = ({backgroundColor = '#5D3B9E', footer = '', presets = {}, userSettings = {}, update = () => {}}) => {
    const [presetOptions, setPresetOptions] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState('');
    const [newRewardCost, setNewRewardCost] = useState(1000);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const gridStyle = {
        marginTop: '8px', 
        backgroundColor: colorScheme.background50, 
        paddingLeft: '24px', 
        paddingTop: '8px', 
        paddingBottom: '8px', 
        borderRadius: '5px', 
        border: `2px solid ${colorScheme.background25}`,
        maxHeight: '25vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };
    const scrollableContent = {
        maxHeight: '65vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    const preparePresetOptions = () => {
        const tmpArr = [{name: 'Random'}];
        Object.keys(presets).map((name, index) => {
            const tmpObj = {
                name
            };
            tmpArr.push(tmpObj);
        });
        setPresetOptions(tmpArr.filter((obj, i) => Object.keys(userSettings['modData']['twitch']['rewards']).includes(obj['name']) === false));
    };

    useEffect(() => {
        preparePresetOptions();
    }, [userSettings]);

    const deleteReward = async (name) => {
        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}/user/deletecustomreward?token=${authCtx.token}&type=personality&rewardname=${name}`);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };
    const createReward = async () => {
        try {
            const randomByte = () => {
                const byte = Math.floor(Math.random() * 256);
                return byte.toString(16).toUpperCase().padStart(2, '0');
            };
            const background_color = {
                r: randomByte(),
                g: randomByte(),
                b: randomByte()
            };
            setLoading(true);
            setErrorMessage('');
            const response = await fetch(`${apiUrl}/user/createcustomreward?token=${authCtx.token}&type=personality`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: `Ratbot: ${selectedPreset} personality`, 
                    cost: newRewardCost,
                    background_color, 
                    rewardName: selectedPreset
                })
            });
            if (!response.ok) {
                switch (response.status) {
                    case 400:
                        setErrorMessage(`Error creating reward: Please check if you already have a reward titled "Ratbot: ${selectedPreset} personality"`);
                        break;
                    case 401:
                        setErrorMessage('Error creating reward: Token is invalid, try to authorize full access and try again');
                    case 403:
                        setErrorMessage('Error creating reward: Viewer rewards are only available to affiliate or partner accounts');
                    case 500:
                        setErrorMessage('Error creating reward: Twitch server error, please try again later');
                
                    default:
                        break;
                }
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

  return (
    <DialogComponent footer={footer} maxHeight='65vh'>
        <Grid container sx={{...scrollableContent, marginLeft: '0px', padding: '8px'}} spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h3'>Add New Reward</Typography>
            </Grid>
            <Grid item xs={12}>
                <Dropdown style={{width: '100%', backgroundColor}} placeholder='Personality' options={presetOptions} optionLabel='name'
                    value={{name: selectedPreset}} onChange={(e) => setSelectedPreset(e.value.name)} />
            </Grid>
            <Grid item xs={12}>
                <Typography>{`Channel point cost: ${newRewardCost}`}</Typography>
                <Slider style={{marginTop: '12px'}} value={newRewardCost} onChange={(e) => setNewRewardCost(e.value)} step={100} min={100} max={10000} />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={createReward} disabled={selectedPreset === '' || loading} 
                    sx={{float: 'right', width: isMobile ? '100%' : '50%', marginTop: '16px'}} variant='contained' color='success'>Create Reward</Button>
            </Grid>
            {errorMessage !== '' && (
                <Grid item xs={12}>
                    <Typography variant='h5' color='red'>{errorMessage}</Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4'>Added Rewards</Typography>
                {Object.keys(userSettings['modData']['twitch']['rewards']).length > 0 ? (
                <Grid container sx={gridStyle}>
                    {Object.keys(userSettings['modData']['twitch']['rewards']).map((name, index) => (
                        <React.Fragment key={name}>
                            <Grid item xs={4} sx={{marginTop: '4px'}}>
                                <Typography>{name}</Typography>
                            </Grid>
                            {!isMobile && (<Grid item xs={6} sx={{marginTop: '4px'}}>
                                <Typography>{userSettings['modData']['twitch']['rewards'][name]['id']}</Typography>
                            </Grid>)}
                            <Grid item xs={isMobile ? 8 : 2}>
                                <Button style={{float: isMobile && 'right'}} disabled={loading} variant='text' color='error' size='small' onClick={() => deleteReward(name)}>Delete</Button>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                ) : (
                    <Typography sx={{marginTop: '8px'}}>No rewards added yet!</Typography>
                )}
            </Grid>
        </Grid>
    </DialogComponent>
  );
};

export default PersonalityRewards;