import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { motion } from 'framer-motion';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { useUser } from '../../context/UserContext';

const Spotify = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, refresh = () => {}}) => {
    const defaultScope = ['user:read:email'];
    const elevatedScope = ['channel:read:redemptions', 'channel:manage:redemptions', 'user:read:email'];
    const [elevatedLink, setElevatedLink] = useState('#');
    const [spotifyAuthUrl, setSpotifyAuthUrl] = useState('#');
    const [needsAuthorization, setNeedsAuthorization] = useState(true);
    const [showSpotifyDeleteConfirm, setShowSpotifyDeleteConfirm] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.25)`
    };
    const scrollableContent = {
        maxHeight: '75vh',
        overflowY: 'auto',
        paddingLeft: '16px',
        paddingBottom: isMobile && '5vh',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    const getElevatedTwitchUrl = async (newScopes) => {
        try {
            const response = await fetch(`${apiUrl}/user/elevatedtwitchurl?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({scope: newScopes, redirect: '/settings?p=spotify'})
            });
            const res = await response.json();
            setElevatedLink(res['status']);
        } catch (e) {
            console.log(e);
        }
    };
    const getSpotifyAuthUrl = async () => {
        try {
            const url = await fetch(`${apiUrl}/spotify/authurl?state=${userSettings.channel.substring(1, userSettings.channel.length)}`);
            const data = await url.json();
            setSpotifyAuthUrl(data['status']);
        } catch (e) {
            console.log(e);
        }
    };
    const deleteSpotifyData = async () => {
        try {
            const response = await fetch(`${apiUrl}/spotify/deletedata?token=${authCtx.token}`);
            if (response.ok) {
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    };
    const removeSongRequestReward = async () => {
        try {
            const removeRewardResponse = await fetch(`${apiUrl}/user/deletecustomreward?token=${authCtx.token}&type=spotify&rewardname=songRequest`);
            refresh();
        } catch (e) {
            console.log(e);
        }
    };
    const hasNewSpotifyModule = () => { return 'spotify' in userSettings };
    const hasCreatedReward = () => { return userSettings['spotify']['rewards']['songRequest']['id'] !== '' || userSettings['spotify']['commands']['songRequest'] !== '' };

    useEffect(() => {
        if ('twitchPermissions' in userSettings || authCtx.editor) {
            if (authCtx.editor) {
                setNeedsAuthorization(false);
            } else {
                const currentPermissions = userSettings['twitchPermissions'];
                const isAuthorized = elevatedScope.every(scope => currentPermissions.includes(scope));
                setNeedsAuthorization(!isAuthorized);
            }
            getElevatedTwitchUrl(elevatedScope);
            getSpotifyAuthUrl();
        } else {
            //Does not exist
            update({'twitchPermissions': defaultScope});
        }
    }, []);

    const NeedsAuthorizationSection = () => {
        const handleButtonClick = () => {
            window.location.href = elevatedLink;
        };

        return (
            <React.Fragment>
                <Typography variant='h3'>Welcome to the new Spotify module!</Typography>
                <Typography sx={{marginTop: '8px'}}>This module helps you accept song requests from viewers, gives Ratbot access to alert which song is playing, and more.</Typography>
                {!!userSettings?.modData?.spotify?.rewards?.songRequest && (
                    <Typography>
                        <strong>Note:</strong> You are using the outdated version of Spotify module, remember to remove or disable the viewer reward you already created, as Ratbot will create one for you in this setup!
                    </Typography>
                )}
                <Typography variant='h5' sx={{marginTop: '16px'}}>Get started by authorizing Ratbot access to manage channel point rewards</Typography>
                <Button size='small' style={{marginTop: '16px'}} onClick={handleButtonClick}>Elevate Twitch Access</Button>
            </React.Fragment>
        );
    };

    const SectionBackground = ({children}) => {
        const style = {
            backgroundColor: colorScheme['background25'],
            borderRadius: '15px',
            padding: '16px',
            marginRight: '16px',
            border: `2px solid rgba(255,255,255,0.025)`,
            height: '100%'
        };
        return (
            <HoverAnimation>
                <Box sx={style}>
                    {children}
                </Box>
            </HoverAnimation>
        );
    };
    const HoverAnimation = ({children}) => {
        const hoverAnimation = {
            scale: [1, 1.0125, 1],
            transition: {
                duration: 0.4,
                ease: "easeInOut",
                times: [0, 0.5, 1]
            }
        };

        return (
            <motion.div whileHover={!isMobile && hoverAnimation} style={{height: '100%', width: '100%'}}>
                {children}
            </motion.div>
        );
    };

    const ModuleInformationSecion = () => {
        return (
            <SectionBackground>
                <Typography variant='h3'>{`Spotify is connected`}</Typography>
                {userSettings['spotify']['commands']['songRequest'] !== '' && (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant='h5' sx={{marginTop: '12px'}}>{`Chat command: "${userSettings['commandDelimiter']}${userSettings['spotify']['commands']['songRequest']} <Spotify link / Search text>"`}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button size='small' text severity='danger' onClick={() => update({spotify: {...userSettings.spotify, commands: {...userSettings.spotify.commands, songRequest: ''}}})}>Remove Command</Button>
                            </Grid>
                        </Grid>
                        {userSettings['spotify']['rewards']['songRequest']['id'] === '' && (<Button variant='contained' color='secondary' sx={{marginTop: '8px'}} 
                            onClick={() => openDialogComponent('createSpotifyReward')}>Create channel point reward</Button>)}
                    </React.Fragment>
                )}
                {userSettings['spotify']['rewards']['songRequest']['id'] !== '' && (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant='h5' sx={{marginTop: '12px'}}>{`Custom reward ID: ${userSettings['spotify']['rewards']['songRequest']['id'] !== '' && 'Connected'}`}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button size='small' text severity='danger' onClick={removeSongRequestReward}>Remove Reward</Button>
                            </Grid>
                        </Grid>
                        {userSettings['spotify']['commands']['songRequest'] === '' && (
                            <Button variant='contained' color='secondary' onClick={() => update({spotify: {...userSettings.spotify, commands: {...userSettings.spotify.commands, songRequest: 'spotify'}}})}>Add Chat Command</Button>
                        )}
                    </React.Fragment>
                )}
            </SectionBackground>
        );
    };
    const TroubleshootingSection = () => {
        return (
            <SectionBackground>
                <Typography variant='h3'>Having issues?</Typography>
                <Typography variant='h5' sx={{marginTop: '4px'}}>Use below button to delete your Spotify data</Typography>
                <Button size='small' severity='danger' style={{marginTop: '12px'}} disabled={authCtx.editor}
                    onClick={() => { setShowSpotifyDeleteConfirm(true); setIsBlur(true); }}>Restart Spotify setup</Button>
            </SectionBackground>
        );
    };
    const FiltersSection = () => {
        return (
            <SectionBackground>
                <Typography variant='h3'>Filters</Typography>
                <Typography>Set up rules for which song requests to accept</Typography>
                <br />
                <Grid container>
                    <Grid item xs={isLarge ? 12 : 3}>
                        <Button style={{width: '100%'}} size='small'
                            onClick={() => openDialogComponent('spotifymintracklength')}>{!isLarge ? `${userSettings.spotify.minLength} seconds` : 'Minimum Track Length'}</Button>
                    </Grid>
                    {!isLarge && (
                        <Grid item xs={isLarge ? 12 : 8} sx={{marginTop: '8px', marginLeft: '16px'}}>
                            <Typography>Set up minimum accepted length for requested songs</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container sx={{marginTop: '16px'}}>
                    <Grid item xs={isLarge ? 12 : 3}>
                        <Button style={{width: '100%'}} size='small'
                            onClick={() => openDialogComponent('spotifymaxtracklength')}>{!isLarge ? `${userSettings.spotify.maxLength} seconds` : 'Maximum Track Length'}</Button>
                    </Grid>
                    {!isLarge && (
                        <Grid item xs={isLarge ? 12 : 8} sx={{marginTop: '8px', marginLeft: '16px'}}>
                            <Typography>Set up maximum accepted length for requested songs</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container sx={{marginTop: '16px'}}>
                    <Grid item xs={isLarge ? 12 : 3}>
                        <Button style={{width: '100%'}} size='small'
                            onClick={() => openDialogComponent('spotifyminpopularity')}>{!isLarge ? `${userSettings.spotify.minPopularity}%` : 'Minimum Popularity'}</Button>
                    </Grid>
                    {!isLarge && (
                        <Grid item xs={isLarge ? 12 : 8} sx={{marginTop: '8px', marginLeft: '16px'}}>
                            <Typography>Minimum track popularity threshold</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container sx={{marginTop: '16px'}}>
                    <Grid item xs={isLarge ? 12 : 3}>
                        <Button style={{width: '100%'}} size='small'
                            onClick={() => openDialogComponent('spotifyBlockedTracks')}>Blocked Tracks</Button>
                    </Grid>
                    {!isLarge && (
                        <Grid item xs={isLarge ? 12 : 8} sx={{marginTop: '8px', marginLeft: '16px'}}>
                            <Typography>Your blocked tracks</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container sx={{marginTop: '16px'}}>
                    <Grid item xs={isLarge ? 12 : 3}>
                        <Button style={{width: '100%'}} size='small'
                            onClick={() => openDialogComponent('spotifyBlockedArtists')}>Blocked Artists</Button>
                    </Grid>
                    {!isLarge && (
                        <Grid item xs={isLarge ? 12 : 8} sx={{marginTop: '8px', marginLeft: '16px'}}>
                            <Typography>Your blocked artists</Typography>
                        </Grid>
                    )}
                </Grid>
                <FormControlLabel sx={{marginTop: '16px', marginBottom: '8px'}} control={
                    <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, allowExplicit: e.checked}})}
                        checked={userSettings.spotify.allowExplicit} style={{marginRight: '16px', marginLeft: '12px'}} />
                } label="Allow tracks containing explicit lyrics" />
            </SectionBackground>
        );
    };
    const AdvancedSection = () => {
        return (
            <SectionBackground>
                <Typography variant='h3'>Advanced</Typography>
                <Typography>Additional settings for the Spotify module</Typography>
                <br />
                <FormControlLabel control={
                    <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, autoStart: e.checked}})}
                        checked={userSettings.spotify.autoStart} style={{marginRight: '16px', marginLeft: '12px', marginTop: '8px', marginBottom: '8px'}} />
                } label="Start playing requested songs even when Spotify is paused" />
                <FormControlLabel control={
                    <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, nowPlayingRequested: e.checked}})}
                        checked={userSettings.spotify.nowPlayingRequested} style={{marginRight: '16px', marginLeft: '12px', marginTop: '8px', marginBottom: '8px'}} />
                } label="Announce when requested songs are playing in chat" />
                <FormControlLabel control={
                    <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, nowPlayingAll: e.checked}})}
                        checked={userSettings.spotify.nowPlayingAll} style={{marginRight: '16px', marginLeft: '12px', marginTop: '8px', marginBottom: '8px'}} />
                } label="Announce when non-requested songs are playing in chat" />
                <FormControlLabel control={
                    <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, analysis: e.checked}})}
                        checked={userSettings.spotify.analysis} style={{marginRight: '16px', marginLeft: '12px', marginTop: '8px', marginBottom: '8px'}} />
                } label="Allow Ratbot to analyze the song for key, bpm, danceability etc." />
                <FormControlLabel control={
                    <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, gameGroove: e.checked}})}
                        checked={userSettings.spotify.gameGroove} style={{marginRight: '16px', marginLeft: '12px', marginTop: '8px', marginBottom: '8px'}} />
                } label="GameGroove (let Ratbot select which song to play next when there are no requests)" />
            </SectionBackground>
        );
    };
    const ChannelPointsSection = () => {
        return (
            <SectionBackground>
                <Typography variant='h3'>Channel Points</Typography>
                <Typography>Handling of fulfilling/cancelling redemptions</Typography>
                <br />
                <Grid container>
                    <Typography variant='h4'>Automatic fulfillment</Typography>
                    <Grid item xs={12} sx={{marginTop: '8px'}}>
                        <RadioButton inputId='onQueue' name='fulfillment' value='onQueue' checked={userSettings.spotify.fulfillment === 'onQueue'}
                            onChange={(e) => update({spotify: {...userSettings.spotify, fulfillment: e.value}})} />
                        <label style={{marginLeft: '8px'}} htmlFor='onQueue'>When requested track is added to play queue</label>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '8px'}}>
                        <RadioButton inputId='onPlay' name='fulfillment' value='onPlay' checked={userSettings.spotify.fulfillment === 'onPlay'}
                            onChange={(e) => update({spotify: {...userSettings.spotify, fulfillment: e.value}})} />
                        <label style={{marginLeft: '8px'}} htmlFor='onPlay'>When requested track is played</label>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '8px'}}>
                        <RadioButton inputId='none' name='fulfillment' value='none' checked={userSettings.spotify.fulfillment === 'none'}
                            onChange={(e) => update({spotify: {...userSettings.spotify, fulfillment: e.value}})} />
                        <label style={{marginLeft: '8px'}} htmlFor='none'>No redemptions are fulfilled by Ratbot</label>
                    </Grid>
                </Grid>
                <Grid container sx={{marginTop: '16px'}}>
                    <Typography variant='h4'>Refunding</Typography>
                    <Grid item xs={12}>
                        <FormControlLabel control={
                            <Checkbox onChange={(e) => update({spotify: {...userSettings.spotify, refunding: e.checked}})}
                                checked={userSettings.spotify.refunding} style={{marginRight: '16px', marginLeft: '12px', marginTop: '8px'}} />
                        } label='Automatically refund points when requested track cannot be added' />
                    </Grid>
                </Grid>
            </SectionBackground>
        );
    };

    return (
        <React.Fragment>
            <ConfirmDialog visible={showSpotifyDeleteConfirm} onHide={() => { setShowSpotifyDeleteConfirm(false); setIsBlur(false); }} 
                message="Are you sure you want to delete your Spotify data?" position='center' draggable={false}
                header="Spotify Data Deletion" icon="pi pi-times" accept={deleteSpotifyData} reject={() => { setShowSpotifyDeleteConfirm(false); setIsBlur(false); }} />
            {needsAuthorization ? (
                <NeedsAuthorizationSection />
            ) : (
                <React.Fragment>
                    {hasNewSpotifyModule() ? (
                        <React.Fragment>
                            {hasCreatedReward() ? (
                                <Box sx={scrollableContent}>
                                    <Grid container spacing={2} sx={{marginTop: isMobile && '8px'}}>
                                        <Grid item xs={isMobile ? 12 : 5}>
                                            <ModuleInformationSecion />
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 7}>
                                            <TroubleshootingSection />
                                        </Grid>
                                    </Grid>
                                    {/* MAIN CONTENT BELOW */}
                                    <Grid container sx={{marginTop: !isMobile && '16px'}} spacing={2}>
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <FiltersSection />
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <AdvancedSection />
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <ChannelPointsSection />
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : (
                                <React.Fragment>
                                    <Typography variant='h3'>Welcome to the new Spotify module!</Typography>
                                    <Typography sx={{marginTop: '16px'}}>You've granted Ratbot access to Twitch channel point rewards and Spotify.</Typography>
                                    <Typography sx={{marginTop: '16px'}}>How do you want to accept song requests?</Typography>
                                    <Grid container sx={{maxWidth: '350px', marginTop: '16px'}}>
                                        <Grid item xs={6}>
                                            <Button size='small' onClick={() => openDialogComponent('createSpotifyReward')}>Channel Points</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button size='small' onClick={() => update({spotify: {...userSettings.spotify, commands: {...userSettings.spotify.commands, songRequest: 'spotify'}}})}>Chat Commands</Button>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography variant='h3'>Welcome to the new Spotify module!</Typography>
                            <Typography sx={{marginTop: '16px'}}>You've granted Ratbot access to manage channel point rewards; this will let Ratbot create the song request reward and manage redemptions.</Typography>
                            <Typography variant='h5' sx={{marginTop: '16px'}}>You're almost done!</Typography>
                            <Typography sx={{marginTop: '8px'}}>Next, you need to grant Ratbot access to your Spotify account:</Typography>
                            <Button size='small' style={{marginTop: '16px'}} onClick={() => window.location.href = spotifyAuthUrl}>Authorize Spotify Access</Button>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            {/*<Typography sx={{marginTop: '32px'}}>{JSON.stringify(userSettings['twitchPermissions'])}</Typography>
            <Typography>{elevatedLink}</Typography>*/}
        </React.Fragment>
    );
};

export default Spotify;