import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const TTS = ({ delayBetweenAudios = 5000, pollingInterval = 3000 }) => {
    const [playQueue, setPlayQueue] = useState([]);
    const [isReady, setIsReady] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const channel = queryParams.get('channel');
    const passphrase = queryParams.get('pp');
    const audioRef = useRef(null);
    const isPlayingRef = useRef(false);
    const pollingRef = useRef(null);

    const fetchTTSQueue = async () => {
        const response = await fetch(`${apiUrl.split(':')[0]}:${apiUrl.split(':')[1]}/ttsqueue?channel=${channel}&pp=${passphrase}`);
        const res = await response.json();
        const _queue = [...playQueue];
        for (let i in res['queue']) {
            const insertItem = `${apiUrl.split(':')[0]}:${apiUrl.split(':')[1]}/obs/${channel}/${res['queue'][i]}`;
            _queue.push(insertItem);
        }
        setPlayQueue(_queue);
    };

    useEffect(() => {
        if (isReady && !pollingRef.current) {
            pollingRef.current = setInterval(fetchTTSQueue, pollingInterval);
        }
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, [isReady, pollingInterval]);

    useEffect(() => {
        console.log(playQueue, isReady);
        if (playQueue.length > 0 && !isPlayingRef.current && isReady) {
          isPlayingRef.current = true;
          audioRef.current.src = playQueue[0];
          audioRef.current.play();
        }
    }, [playQueue, isReady]);

    const handleAudioEnd = () => {
        setPlayQueue((prevQueue) => prevQueue.slice(1));
        isPlayingRef.current = false;
        if (playQueue.length > 1) {
            setTimeout(() => {
                if (!isPlayingRef.current && playQueue.length > 1) {
                isPlayingRef.current = true;
                audioRef.current.src = playQueue[1];
                audioRef.current.play();
                }
            }, delayBetweenAudios);
        }
    };

  return (
    <div>
        {!isReady && (
            <Grid container sx={{backgroundColor: 'black', padding: '16px'}}>
                <Grid item xs={12}>
                    <Typography variant='h1'>RatbotTTS is being blocked from playing audio</Typography>
                    <Typography variant='h1'>1. Right-click this source</Typography>
                    <Typography variant='h1'>2. Click "Interact"</Typography>
                    <Typography variant='h1'>3. Click below button</Typography>
                    <Button sx={{width: '50%', marginTop: '32px'}} size='large' variant='contained' color='success' onClick={() => setIsReady(true)}>Activate TTS</Button>
                </Grid>
            </Grid>
        )}
        <audio ref={audioRef} onEnded={handleAudioEnd} />
    </div>
  );
};

export default TTS;