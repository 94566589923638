import { Grid, Typography } from '@mui/material';
import { Divider } from 'primereact/divider';
import React from 'react';
import useIsMobile from '../../utils/useIsMobile';

const DialogComponent = ({maxWidth = '30vw', minWidth = '30vw', maxHeight = '30vh', minHeight = '30vh', children, footer}) => {
  const isMobile = useIsMobile();
  const containerStyle = {
    maxWidth: isMobile ? '100%' : maxWidth, 
    minWidth: isMobile ? '100%' : minWidth, 
    maxHeight: isMobile ? '100%' : maxHeight, 
    minHeight: isMobile ? '100%' : minHeight
  };

  return (
    <Grid container spacing={isMobile ? 0 : 2} sx={containerStyle}>
        <Divider />
        {children}
        <Grid item xs={12} sx={{textAlign: 'right', marginTop: '16px', maxHeight: '100px'}}>
            <Divider />
            <Typography>{footer}</Typography>
        </Grid>
    </Grid>
  );
};

export default DialogComponent;