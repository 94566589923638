import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const TrackTable = ({ tableData, playerState, togglePlayPause, context, playTrack, addToQueue, nextTrack, setMainPage = () => {}, type = 'playlist' }) => {
    const [hoveredRow, setHoveredRow] = useState(null);
    const navigate = useNavigate();
    
    const imageBodyTemplate = (item) => {
        return <img src={item.image} alt={item.image} width={50} />;
    };

    const artistTemplate = (item) => {
        return <p>{item.artists.map((artist) => artist.name).join(', ')}</p>;
    };

    const albumTemplate = (item) => {
        return (
            <p style={{ cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'transparent', transition: 'text-decoration-color 0.2s' }}
                onMouseEnter={(e) => e.target.style.textDecorationColor = 'inherit'} onMouseLeave={(e) => e.target.style.textDecorationColor = 'transparent'}
                onClick={() => navigate(`/spotifyplayer/albums?id=${item.albumUri.split(':')[2]}`)}>
                {item.albumName}
            </p>
        );
    };

    const idBodyTemplate = (rowData) => {
        if (playerState?.track_window?.current_track?.uri === rowData.uri) {
            return (
                <Button rounded severity='success' style={{width: '100%'}} text icon={playerState?.paused ? "pi pi-play" : "pi pi-pause"}
                    onClick={togglePlayPause} />
            );
        }
        return hoveredRow === rowData.id ? (
            <Button rounded severity='success' style={{width: '100%'}} text icon="pi pi-play"
                onClick={() => {
                    if (playerState?.context?.uri === context.uri) {
                        addToQueue(rowData.uri).then(() => {
                            nextTrack();
                        });
                    } else {
                        playTrack({
                            uri: context['uri'],
                            offset: { position: rowData.id - 1 }
                        });
                    }
                }} />
        ) : (
            <p style={{textAlign: 'center'}}>{rowData.id}</p>
        );
    };

    return (
        <DataTable value={tableData} size='small' stripedRows removableSort selectionMode='single' 
            onRowMouseEnter={(e) => setHoveredRow(e.data.id)} onRowMouseLeave={() => setHoveredRow(null)}>
            <Column field='id' header='#' body={idBodyTemplate} bodyStyle={{ width: '70px' }} sortable></Column>
            <Column header='Image' body={imageBodyTemplate} bodyStyle={{ width: '60px' }}></Column>
            <Column field='trackName' header='Title' sortable></Column>
            <Column header='Artist' body={artistTemplate} sortable></Column>
            {type !== 'album' && <Column header='Album' body={albumTemplate} sortable></Column>}
            <Column field='duration' header='Length' sortable></Column>
        </DataTable>
    );
};

export default TrackTable;