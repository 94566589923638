import React, { useEffect, useState } from 'react';
import { spotify } from '../mockData';
import AnimatedGrid from './AnimatedGrid';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import ViewingPlaylist from './ViewingPlaylist';
import Loading from './Loading';

const Playlists = ({ userSettings = {}, playTrack = async ({}) => {}, setTmpQueue = () => {}, playerState = {}, togglePlayPause = () => {}, addToQueue = () => {},
                        nextTrack = () => {}, setMainPage = () => {}, getPlayerState = () => {} }) => {
    const [viewingPlaylist, setViewingPlaylist] = useState({});
    const [loading, setLoading] = useState(false);
    const [playlistItems, setPlaylistItems] = useState([]);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState('');
    const devMode = false;
    const authCtx = useUser();
    const mockData = spotify;
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const limit = 48;

    const getPlaylistItem = (id) => {
        const playlistItem = spotify.playlists.items.find((item) => item.id === id);
        return playlistItem;
    };

    const getUserPlaylists = async (limit, offset) => {
        setLoading(true);
        if (!devMode) {
            const playlistResponse = await fetch(`${apiUrl}/spotify/getuserplaylists?limit=${limit}&offset=${offset}&refreshtoken=${userSettings['spotify']['refreshToken']}`);
            const playlistRes = await playlistResponse.json();
            setPlaylistItems(playlistRes['items']);
            setTotal(playlistRes['total']);
            setLoading(false);
            setError('');
        } else if (!devMode) {
            setLoading(false);
            //setError(`Error loading playlists: ${playlistResponse.statusText} - ${playlistRes.error}`);
        } else {
            setLoading(false);
            setPlaylistItems(mockData.playlists.items);
        }
    };

    const mapPlaylistData = (playlistItemsArr = []) => {
        return playlistItemsArr.map((item) => ({
            backgroundImage: item['images'] !== null ? item['images'][0]['url'] : 'https://i.imgur.com/uijqYCJ.png',
            title: item['name'],
            description: item['description'],
            id: item['id']
        }));
    };

    useEffect(() => {
        if (id && 'spotify' in userSettings && !devMode) {
            const getPlaylist = async () => {
                setLoading(true);
                const playlistResponse = await fetch(`${apiUrl}/spotify/getplaylist?q=${id}&refreshtoken=${userSettings['spotify']['refreshToken']}`);
                if (playlistResponse.ok) {
                    const playlistRes = await playlistResponse.json();
                    setViewingPlaylist(playlistRes);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const playlistRes = await playlistResponse.json();
                    setError(`Error loading playlist: ${playlistResponse.statusText} - ${playlistRes.error}`);
                }
            };
            getPlaylist();
        } else if (id && devMode) {
            setViewingPlaylist(mockData.playlist);
            setLoading(false);
        } else {
            getUserPlaylists(limit, offset);
        }
    }, [id, userSettings, offset]);

    const handleNextPage = () => {
        setOffset((prevOffset) => prevOffset + limit);
    };

    const handlePreviousPage = () => {
        setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
    };

    return (
        <React.Fragment>
            <Box sx={{ margin: '16px' }}>
                {id && Object.keys(viewingPlaylist).length > 0 ? (
                    <ViewingPlaylist playlist={viewingPlaylist} loading={loading} setLoading={setLoading} playTrack={playTrack} setTmpQueue={setTmpQueue} getPlayerState={getPlayerState}
                        playerState={playerState} togglePlayPause={togglePlayPause} addToQueue={addToQueue} nextTrack={nextTrack} setMainPage={setMainPage} />
                ) : (
                    <React.Fragment>
                        <Loading show={loading} />
                        {playlistItems.length > 0 ? (
                            <React.Fragment>
                                <AnimatedGrid items={mapPlaylistData(playlistItems)} itemSize={2} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                    <Button variant="contained" onClick={handlePreviousPage} disabled={offset === 0}>
                                        Previous
                                    </Button>
                                    <Button variant="contained" onClick={handleNextPage} disabled={offset + limit >= total}>
                                        Next
                                    </Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {error !== '' && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant='h4'>{error}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginTop: '8px'}}>
                                            <Button variant='contained' color='info' onClick={() => getUserPlaylists(limit, offset)}>Try Again</Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Box>
        </React.Fragment>
    );
};

export default Playlists;