import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { motion } from 'framer-motion';
import './AnimatedCard.css';
import { Typography } from '@mui/material';

const AnimatedCard = ({ backgroundImage, title, description }) => {
    const [hovered, setHovered] = React.useState(false);
    const { transform, opacity, filter, blur } = useSpring({
        transform: hovered ? 'translateY(25%)' : 'translateY(75%)',
        opacity: hovered ? 1 : 0,
        filter: hovered ? 'brightness(0.5)' : 'brightness(1)',
        blur: hovered ? 'blur(15px)' : 'blur(0px)'
    });
    const { scale } = useSpring({
        scale: hovered ? 1.05 : 1,
    });

    return (
        <div className="card-container" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <animated.div className="background-image" style={{ backgroundImage: `url(${backgroundImage})`, transform: scale.to(s => `scale(${s})`) }} />
            <animated.div className="overlay" style={{ transform, background: 'rgba(0, 0, 0, 0.75)' }}>
                <div className="description" style={{overflowY: 'hidden'}}>
                    <Typography variant='h5' sx={{marginTop: '8px', marginBottom: '24px'}}>{title}</Typography>
                    <p>{description}</p>
                </div>
            </animated.div>
            <animated.div className="title" style={{ opacity, filter, background: 'rgba(0, 0, 0, 0.75)' }} />
        </div>
    );
};

export default AnimatedCard;