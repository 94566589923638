import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import AuthContext from '../store/auth-context';
import ErrorPage from '../components/ErrorPage';

const AdminRoute = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(`${apiUrl}/isadmin?token=${authCtx.token}`).then(response => {
            if (response.ok) {
                setIsLoading(false);
                setIsAdmin(true);
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
        });
    }, []);
    

  return (
    <React.Fragment>
        {isLoading ? <Box m='20px'><Typography>Loading...</Typography></Box> : isAdmin ? props.children : <ErrorPage msg="You do not have access to this page!" status="401" />}
    </React.Fragment>
  )
}

export default AdminRoute;