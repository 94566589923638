import React, { useEffect, useState, useRef } from 'react';
import useIsMobile from '../../utils/useIsMobile';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import CommandGrid from './CommandGrid';
import SystemCommands from './SystemCommands';
import Variables from './Variables';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import CustomVariables from './CustomVariables';
import Macros from './Macros';

const Commands = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, deletekey = () => {}}) => {
    const isMobile = useIsMobile();
    const [selectedTab, setSelectedTab] = useState(0);
    const swiperRef = useRef(null);
    const tabItems = [
        {title: 'System Commands', value: 'systemCommands'},
        {title: 'Custom Commands', value: 'customCommands'},
        {title: 'System Variables', value: 'variables'},
        {title: 'Custom Variables', value: 'customvariables'},
        {title: 'Macros', value: 'macros'},
    ];
    const styles = {
        tabs: {
            marginBottom: '0px',
        },
        scrollableContent: {
            maxHeight: '70%', // Adjust the 100px based on your header/footer size
            paddingRight: '16px',
            paddingBottom: '72px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none' // Hide vertical scrollbar
            },
            '-ms-overflow-style': 'none', // For Internet Explorer and Edge
            'scrollbar-width': 'none' // For Firefox
        },
    };

    const handleSwiperSlideChange = (swiper) => {
        setSelectedTab(swiper.activeIndex);
    };
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(newValue);
        }
    };

    // Transform older scheme into new with actions array
    useEffect(() => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        Object.keys(userSettings['customCommands']).map((commandName) => {
            const cmdObj = {...userSettings['customCommands'][commandName]};
            const isNewType = 'actions' in cmdObj;
            if (isNewType === false) {
                const newCmdObj = {
                    actions: [
                        {
                            type: capitalizeFirstLetter(cmdObj['type']),
                            message: cmdObj['value']
                        }
                    ],
                    title: commandName
                };
                update({customCommands: {...userSettings['customCommands'], [commandName]: newCmdObj}});
            }
        });
    }, []);

    return (
        <React.Fragment>
            <Box sx={styles.tabs}>
                <Tabs textColor='secondary' indicatorColor='secondary' value={selectedTab} onChange={handleTabChange}>
                    {tabItems.map((item, index) => (
                        <Tab key={index} label={<Typography variant='h5'>{item.title}</Typography>} />
                    ))}
                </Tabs>
            </Box>
            {isMobile ? (
                <Swiper style={{zIndex: '10'}} ref={swiperRef} onSlideChange={handleSwiperSlideChange} initialSlide={selectedTab} autoHeight={true}>
                    <SwiperSlide>
                        <Box sx={styles.scrollableContent}>
                            <SystemCommands backgroundColor={backgroundColor} userSettings={userSettings} update={update} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={styles.scrollableContent}>
                            <CommandGrid backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} userSettings={userSettings} update={update} deletekey={deletekey} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={styles.scrollableContent}>
                            <Variables backgroundColor={backgroundColor} userSettings={userSettings} update={update} />
                        </Box>
                    </SwiperSlide>
                    {/*<SwiperSlide>
                        <Box sx={styles.scrollableContent}>
                            <CustomVariables openDialogComponent={openDialogComponent} deletekey={deletekey} backgroundColor={backgroundColor} 
                                userSettings={userSettings} update={update} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={styles.scrollableContent}>
                            <Macros openDialogComponent={openDialogComponent} deletekey={deletekey} backgroundColor={backgroundColor} 
                                userSettings={userSettings} update={update} />
                        </Box>
                    </SwiperSlide>*/}
                </Swiper>
            ) : (
                <Box sx={styles.scrollableContent}>
                    {selectedTab === 0 && (<SystemCommands backgroundColor={backgroundColor} userSettings={userSettings} update={update} />)}
                    {selectedTab === 1 && (<CommandGrid backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} 
                                                userSettings={userSettings} update={update} deletekey={deletekey} />)}
                    {selectedTab === 2 && (<Variables backgroundColor={backgroundColor} userSettings={userSettings} update={update} />)}
                    {selectedTab === 3 && (<CustomVariables backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} 
                                                deletekey={deletekey} userSettings={userSettings} update={update} />)}
                    {selectedTab === 4 && (<Macros backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} update={update}
                                                userSettings={userSettings} deletekey={deletekey} />)}
                </Box>
            )}
        </React.Fragment>
    );
};

export default Commands;