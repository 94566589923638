import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import TrackTable from './TrackTable';
import useTransformTrackList from '../utils/useTransformTrackList';
import { Button } from 'primereact/button';

const ViewingPlaylist = ({ playlist = {}, loading, setLoading = () => {}, playTrack = async ({}) => {}, setTmpQueue = () => {}, playerState = {}, 
                            togglePlayPause = () => {}, addToQueue = (uri) => {}, nextTrack = () => {}, setMainPage = () => {}, getPlayerState = () => {} }) => {
    const tableData = useTransformTrackList(playlist['tracks']['items'], 'playlist');

    return (
        <React.Fragment>
            <Loading show={loading} />
            <TrackTable tableData={tableData} playerState={playerState} togglePlayPause={togglePlayPause} setMainPage={setMainPage}
                context={playlist} playTrack={playTrack} addToQueue={addToQueue} nextTrack={nextTrack} type='playlist' />
            <Button style={{marginTop: '16px'}} label='Dev Test' onClick={() => console.log(playerState)} />
        </React.Fragment>
    );
};

export default ViewingPlaylist;