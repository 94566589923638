import React, { useContext, useEffect, useState } from "react";
import { useTheme, Typography, Box, TextField, Grid, Button, IconButton } from "@mui/material";
import useIsMobile from "../../../utils/useIsMobile";
import { tokens } from "../../../theme";
import MainPage from "../../../utils/mainpage";
import MainPageWrapper from "../../../utils/mainpagewrapper";
import MainPageChild from "../../../utils/mainpagechild";
import AuthContext from "../../../store/auth-context";
import ClearIcon from '@mui/icons-material/Clear';
import CustomSnackbar from "../../../utils/customsnackbar";

const KeyWords = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const [backgroundColor, setBackgroundColor] = useState(colors.primary[400]);
  const [keywords, setKeywords] = useState({});
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const [snackAlert, setSnackAlert] = useState({"open":false,"severity":"success","text":"","autoHideDuration":5000});
  const authCtx = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/api/admin/keywords?token=${authCtx.token}`)
      .then((response) => response.json())
      .then((res) => {
        setKeywords(res.additionalKeywords);
      })
      .catch((e) => {
        const alertText = e.message || 'An error occurred.';
        setSnackAlert((prev) => ({...prev, severity: "error", text: alertText, open: true}));
      });
  }, []);

  useEffect(() => {
    setBackgroundColor(isMobile ? colors.primary[600] : colors.primary[400]);
  }, [isMobile]);

  const saveHandler = () => {
    fetch(`${apiUrl}/api/admin/keywords?token=${authCtx.token}`, {
        method: 'POST',
        body: JSON.stringify(keywords),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => response.text()).then((res) => {
        setSnackAlert((prev) => ({...prev, severity: "success", text: res, open: true}));
    }).catch((e) => {
        const alertText = e.message || 'An error occurred.';
        setSnackAlert((prev) => ({...prev, severity: "error", text: alertText, open: true}));
    });
  };

  const handleValueChange = (event, key) => {
    const value = event.target.value;
    setKeywords((prevKeywords) => ({
      ...prevKeywords,
      [key]: value,
    }));
  };

  const handleDelete = (event, key) => {
    const newKeywords = { ...keywords };
    delete newKeywords[key];
    setKeywords(newKeywords);
  };

  return (
    <MainPage backgroundColor={backgroundColor}>
        <CustomSnackbar open={snackAlert.open} autoHideDuration={snackAlert.autoHideDuration} severity={snackAlert.severity} 
            setSnackAlert={setSnackAlert} snackText={snackAlert.text} />
        <MainPageWrapper backgroundColor={backgroundColor} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <MainPageChild title="Keywords" subtitle="Edit bot keyword-based context">
                <Box sx={{ overflow: "auto", height: "70vh", width: "100%" }}>
                    {Object.keys(keywords).map((key) => (
                    <Grid container key={key}>
                        <Grid item xs={3}>
                            <Typography variant="h5" sx={{ display: "flex", justifyContent: "right", alignItems: "center", height: "100%", paddingRight: '8px' }}>{key}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField onBlur={saveHandler} color="secondary" sx={{ width: "100%" }} value={keywords[key]} onChange={(e) => handleValueChange(e, key)} />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={(e) => handleDelete(e, key)} sx={{ padding: '0px', marginTop: '12px', marginLeft: '8px' }}>
                                <ClearIcon sx={{width: '30px', height: '30px'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    ))}
                </Box>
                <br />
                <Grid container sx={{ width: "100%" }}>
                    <Grid sx={{ marginRight: '8px' }} item xs={3}>
                        <TextField fullWidth placeholder="Keyword" color="secondary" value={newKey} onChange={(e) => setNewKey(e.target.value)} />
                    </Grid>
                    <Grid sx={{ marginRight: '8px' }} item xs={4}>
                        <TextField fullWidth placeholder="Context" color="secondary" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                    </Grid>
                    <Grid sx={{ marginRight: '8px' }} item xs={2}>
                        <Button fullWidth sx={{ marginTop: '4px' }} size="large" variant="contained" color="secondary" onClick={() => {
                            const tmpKeywords = { ...keywords };
                            tmpKeywords[newKey.toLowerCase()] = newValue;
                            setKeywords(tmpKeywords);
                            setNewKey(''); setNewValue('');
                        }}>+ Add New</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button fullWidth sx={{ marginTop: '4px' }} size="large" variant="contained" color="secondary" onClick={saveHandler}>Save</Button>
                    </Grid>
                </Grid>
            </MainPageChild>
            <MainPageChild title="Documentation" subtitle="How keywords work">
                <Typography variant="h3">Adding Keywords</Typography>
                <br />
                <Typography variant="body">To add keywords and their corresponding values or actions, follow these steps:</Typography>
                <li>In the provided text fields, enter the keyword in one field and its description or associated value in the context field.</li>
                <li>Click the "+ Add New" button to submit the keyword-context pair.</li>
                <li>Repeat the above steps to add more keywords as needed.</li>
                <br />
                <Typography variant="h3">Space Separation</Typography>
                <br />
                <Typography variant="body">Space separation allows you to match a keyword when all parts of the keyword are present in the user's input, regardless of their order or position. For example:</Typography>
                <li>Keyword: "weather report"</li>
                <li>User input: "Can you provide a report on the weather today?"</li>
                <li>Result: The keyword "weather report" will match because both "weather" and "report" are present in the user's input.</li>
                <br />
                <Typography variant="h3">Comma Separation</Typography>
                <br />
                <Typography variant="body">Comma separation enables you to create OR-type keyword matching, where multiple parts of a keyword can be matched independently. For example:</Typography>
                <li>Keyword: "spencer song, spencer jingle"</li>
                <li>User input: "I heard a song by Spencer yesterday."</li>
                <li>Result: The keyword "spencer song, spencer jingle" will match because the user input includes both "spencer" and "song."</li>
                <br />
                <Typography variant="h3">Exact Matching with Quotation Marks</Typography>
                <br />
                <Typography variant="body">Exact matching with quotation marks allows you to match a keyword precisely when it appears within the user's input. The keyword must be enclosed in quotation marks and should not have any leading or trailing alphanumeric characters. For example:</Typography>
                <li>Keyword: ""ice""</li>
                <li>User input: "Let's get some ice!"</li>
                <li>Result: The keyword ""ice"" will match because the user input exactly matches the keyword enclosed in quotation marks.</li>
                <br />
                <Typography variant="body">However, if the user input includes additional characters, such as "icecream" or "iced," the keyword will not match.</Typography>
                <br />
                <br />
                <Typography variant="h3">Don't Forget To Save</Typography>
            </MainPageChild>
        </MainPageWrapper>
    </MainPage>
  );
  
};

export default KeyWords;
