import { Box, Grid, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import Loading from '../../utils/loading';
import useIsMobile from '../../utils/useIsMobile';

const PersonalityBuilder = ({backgroundColor}) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const isMobile = useIsMobile();
    const [loading, setLoading] = useState(false);
    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [hasGeneratedNew, setHasGeneratedNew] = useState(false);
    const [error, setError] = useState('');
    const [generatedPersonality, setGeneratedPersonality] = useState({
        "image": "",
        "image_selected": "",
        "trait1": "",
        "trait2": "",
        "trait3": "",
        "objective": "",
        "name": "",
        "occupation": "",
        "addedInstruction": "",
        "associations": "",
        "description": "",
        "pronouns": ""
    });

    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        panel: {
            backgroundColor: colorScheme.background50,
            height: isMobile ? '75vh' : '80vh',
            borderRadius: '20px',
            border: `2px solid ${colorScheme.background50}`,
            padding: '16px'
        }
    };
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };
    const namePlaceholder = 'Old MacRatty';
    const descPlaceholder = `Old MacRatty is a humble and hardworking farmer who has spent his entire life tending to his fields and livestock. He's unfamiliar with modern technology and the internet, preferring the simple life of agriculture. With a kind heart and a wealth of practical knowledge about farming, Old MacRatty approaches everything with a sense of patience and a down-to-earth attitude.`;

    const getSubmitValidity = () => {
        if (loading) return true;
        if (newName.length === 0 || newName.length > 50) return true;
        if (newDescription.length === 0 || newDescription.length > 1000) return true;
        return false;
    };

    const submitHandler = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await fetch(`${apiUrl}/user/generatepersonality?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newName,
                    description: newDescription
                })
            });
            const res = await response.json();
            if (response.ok) {
                setGeneratedPersonality(res[Object.keys(res)[0]]);
            } else {
                setError(res['error']);
            }
            setHasGeneratedNew(true);
            setLoading(false);
        } catch (err) {
            setError(`An error was encountered: ${err}`);
            setLoading(false);
        }
    };

    const deleteHandler = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await fetch(`${apiUrl}/user/personality/${encodeURIComponent(newName)}?token=${authCtx.token}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setHasGeneratedNew(false);
                setGeneratedPersonality({
                    "image": "",
                    "image_selected": "",
                    "trait1": "",
                    "trait2": "",
                    "trait3": "",
                    "objective": "",
                    "name": "",
                    "occupation": "",
                    "addedInstruction": "",
                    "associations": "",
                    "description": "",
                    "pronouns": ""
                });
                setLoading(false);
            } else {
                const res = await response.json();
                setError(res['error']);
                setLoading(false);
            }
        } catch (err) {
            setError(`An error was encountered: ${err}`);
            setLoading(false);
        }
    };

    return (
        <Box sx={{...hideScrollbar, maxHeight: isMobile ? '75vh' : '80vh', overflowY: isMobile ? 'none' : 'auto', padding: '16px', marginTop: '16px', paddingRight: isMobile && '4px'}}>
            <Grid container spacing={2} sx={{width: isMobile ? '100%' : '90%'}}>
                {(!isMobile || (isMobile && !hasGeneratedNew)) && <Grid item xs={isMobile ? 12 : 5} sx={styles.panel}>
                    <Box sx={{height: '100%', padding: '16px'}}>
                        {(!isMobile || (isMobile && !loading)) && (<Typography variant={isMobile ? 'h3' : 'h2'}>New Personality</Typography>)}
                        {(!isMobile || (isMobile && !loading)) && (<Typography variant='h4' sx={{marginTop: isMobile ? '16px' : '32px'}}>Name</Typography>)}
                        {(!isMobile || (isMobile && !loading)) && (<InputText value={newName} onChange={(e) => setNewName(e.target.value)} placeholder={namePlaceholder} 
                            style={{backgroundColor: colorScheme.background50, width: '100%', marginTop: '8px'}} />)}
                        {(!isMobile || (isMobile && !loading)) && (<Typography variant='h4' sx={{marginTop: '16px'}}>Description</Typography>)}
                        {(!isMobile || (isMobile && !loading)) && (<InputTextarea value={newDescription} onChange={(e) => setNewDescription(e.target.value)} placeholder={descPlaceholder} 
                            style={{backgroundColor: colorScheme.background50, width: '100%', height: isMobile ? '50%' : '25%', marginTop: '8px'}} />)}
                        {(!isMobile || (isMobile && !loading)) && (<Button disabled={getSubmitValidity()} label='Submit' severity='success' style={{width: '50%', marginTop: isMobile ? '8px' : '16px'}}
                            onClick={submitHandler} />)}
                        {((!isMobile && loading) || (isMobile && loading)) && (
                            <Box sx={{ marginTop: '16px' }}>
                                {!isMobile ? (
                                    <React.Fragment>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Loading size={4} />
                                            <Typography sx={{ marginLeft: '16px', textAlign: 'left' }} variant='h4'>
                                                Ratbot is building your personality, please wait...
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <img style={{marginTop: '16px', borderRadius: '20px', border: `2px solid ${colorScheme.background75}`}}
                                                src='https://i.imgur.com/WptbaJW.png' height={150} />
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Typography sx={{ marginLeft: '16px', textAlign: 'left' }} variant='h4'>
                                            Ratbot is building your personality, please wait...
                                        </Typography>
                                        <div style={{marginLeft: '25%', marginTop: '50%', display: 'flex', alignItems: 'center'}}>
                                            <Loading size={12} />
                                        </div>
                                    </React.Fragment>
                                )}
                            </Box>
                        )}
                    </Box>
                </Grid>}
                {!isMobile && (<Grid item xs={1}>
                    {/* SPACING */}
                </Grid>)}
                {(!isMobile || (isMobile && hasGeneratedNew)) && <Grid item xs={isMobile ? 12 : 6} sx={{...styles.panel, maxHeight: isMobile ? '75vh' : '80vh', overflowY: 'auto'}}>
                    <Box sx={{height: '100%', padding: '16px'}}>
                        <Typography variant={isMobile ? 'h3' : 'h2'}>Generated Personality</Typography>
                        <Grid container sx={{marginTop: isMobile ? '16px' : '32px'}} spacing={isMobile && 2}>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <img style={{border: `2px solid ${colorScheme.background75}`, borderRadius: '20px'}} width='75%'
                                    src={hasGeneratedNew ? `${apiUrl}/user/personalityimage/${encodeURIComponent(generatedPersonality.name)}?token=${authCtx.token}` : 'https://i.imgur.com/bu2dVoQ.png'} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Typography variant='h4'>Name:</Typography>
                                <Typography variant='h5'>{generatedPersonality.name}</Typography>
                                
                                <Typography variant='h4' sx={{ marginTop: isMobile ? '12px' : '24px' }}>Traits:</Typography>
                                <Typography variant='h5'>{hasGeneratedNew ? `${generatedPersonality.trait1}, ${generatedPersonality.trait2} and ${generatedPersonality.trait3}` : ''}</Typography>
                                
                                <Typography variant='h4' sx={{ marginTop: isMobile ? '12px' : '24px' }}>Pronouns:</Typography>
                                <Typography variant='h5'>{generatedPersonality.pronouns}</Typography>
                                
                                <Typography variant='h4' sx={{ marginTop: isMobile ? '12px' : '24px' }}>Occupation:</Typography>
                                <Typography variant='h5'>{generatedPersonality.occupation}</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='h4' sx={{ marginTop: '8px' }}>Description:</Typography>
                        <Typography sx={{marginTop: '8px'}} component='p'>{generatedPersonality.description}</Typography>

                        <Button onClick={deleteHandler} disabled={!hasGeneratedNew || loading} style={{marginBottom: isMobile && '32px', marginTop: isMobile ? '16px' : '32px', width: isMobile ? '100%' : '50%'}} 
                            label='Delete Personality' severity='danger' />
                    </Box>
                </Grid>}
            </Grid>
        </Box>
    );
};

export default PersonalityBuilder;