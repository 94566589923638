import { Box, ButtonBase, Grid, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import useIsMobile from '../../../utils/useIsMobile';
import { useUser } from '../../../context/UserContext';

const Perfumes = ({backgroundColor = '#5D3B9E', openDialogComponent = () => {}, setSelectedComponent = () => {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [ingredients, setIngredients] = useState({});
    const [recipes, setRecipes] = useState({});
    const isMobile = useIsMobile();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const scrollableContent = {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      };

    const fetchIngredients = async () => {
        const response = await fetch(`${apiUrl}/yellowraven/perfumeingredients?token=${authCtx.token}`);
        const res = await response.json();
        setIngredients(res);
    };
    const fetchRecipes = async () => {
        const response = await fetch(`${apiUrl}/yellowraven/perfumerecipes?token=${authCtx.token}`);
        const res = await response.json();
        setRecipes(res);
    };

    useEffect(() => {
        fetchIngredients();
        fetchRecipes();
    }, [apiUrl, authCtx]);

    const IngredientItem = ({ingredientObj}) => {
        const itemStyle = {
            width: '100%',
            display: 'flex', // Set display to flex
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
            textAlign: 'center',
            height: '150px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${ingredientObj['image']})`,
            borderRadius: isMobile ? '5px' : '10px',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: 'inherit',
                filter: 'brightness(50%)',
                zIndex: 1,
                borderRadius: isMobile ? '5px' : '10px'
            },
            '& > *': {
                position: 'relative',
                zIndex: 2,
                textShadow: '2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black'
            }
        };
        const HoverComponent = (props) => {
            const hoverAnimation = {
                scale: 1.025,
                transition: {
                    duration: 0.4
                }
            };
            return (
                <motion.div whileHover={!isMobile && hoverAnimation}>
                    {props.children}
                </motion.div>
            );
        };
        const handleClick = () => {
            console.log(ingredientObj);
        };
        return (
            <HoverComponent>
                <ButtonBase onClick={handleClick} sx={{ width: '100%', height: '100%', borderRadius: isMobile ? '5px' : '10px' }}>
                    <Box sx={itemStyle}>
                        <Typography variant='h3'>{ingredientObj['title']}</Typography>
                    </Box>
                </ButtonBase>
            </HoverComponent>
        );
    };
    const RecipeItem = ({recipeObj}) => {
        const itemStyle = {
            width: '100%',
            display: 'flex', // Set display to flex
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
            textAlign: 'center',
            height: '150px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${recipeObj['image']})`,
            borderRadius: isMobile ? '5px' : '10px',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: 'inherit',
                filter: 'brightness(50%)',
                zIndex: 1,
                borderRadius: isMobile ? '5px' : '10px'
            },
            '& > *': {
                position: 'relative',
                zIndex: 2,
                textShadow: '2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black'
            }
        };
        const HoverComponent = (props) => {
            const hoverAnimation = {
                scale: 1.025,
                transition: {
                    duration: 0.4
                }
            };
            return (
                <motion.div whileHover={!isMobile && hoverAnimation}>
                    {props.children}
                </motion.div>
            );
        };
        const handleClick = () => {
            openDialogComponent('viewperfumerecipe', recipeObj);
        };
        return (
            <HoverComponent>
                <ButtonBase onClick={handleClick} sx={{ width: '100%', height: '100%', borderRadius: isMobile ? '5px' : '10px' }}>
                    <Box sx={itemStyle}>
                        <Typography variant='h3'>{recipeObj['title']}</Typography>
                    </Box>
                </ButtonBase>
            </HoverComponent>
        );
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>{`Recipes (${Object.keys(recipes).length})`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size='small' label='Create New Recipe' onClick={() => openDialogComponent('createperfumerecipe', ingredients)} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button size='small' label='Refresh' onClick={fetchRecipes} />
                        </Grid>
                        <Grid item xs={12} sx={{...scrollableContent, maxHeight: '65vh', marginTop: '16px'}}>
                            <Grid container spacing={2}>
                                {Object.keys(recipes).map((title, index) => (
                                    <Grid item xs={3} key={index}>
                                        <RecipeItem recipeObj={recipes[title]} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>{`Ingredients (${Object.keys(ingredients).length})`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size='small' label='Create New Ingredient' onClick={() => openDialogComponent('createperfumeingredient')} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button size='small' label='Refresh' onClick={fetchIngredients} />
                        </Grid>
                        <Grid item xs={12} sx={{...scrollableContent, maxHeight: '65vh', marginTop: '16px'}}>
                            <Grid container spacing={2}>
                                {Object.keys(ingredients).map((title, index) => (
                                    <Grid item xs={3} key={index}>
                                        <IngredientItem ingredientObj={ingredients[title]} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Perfumes;