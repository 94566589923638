import React, { useContext, useState, useEffect, useRef } from 'react';
import { Box, Button, Grid, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import Header from '../../../components/Header';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AuthContext from '../../../store/auth-context';
import MainPage from '../../../utils/mainpage';
import HeaderWrapper from '../../../utils/headerwrapper';
import MainPageWrapper from '../../../utils/mainpagewrapper';
import MainPageChild from '../../../utils/mainpagechild';

const BotManager = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [processList, setProcessList] = useState();
    const [processData, setProcessData] = useState();
    const [forcedUpdate, setForcedUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const updateIntervalRef = useRef(null);
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const isScreenMobile = useMediaQuery((theme) => theme.breakpoints.between('xs', 'lg'));
    const [isMobile, setIsMobile] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(colors.primary[400]);
    const columns = [
        { field: "id", headerName: "#", flex: 0 },
        { field: "processName", headerName: "Process", flex: 1 },
        { field: "status", headerName: "Status", flex: 0 },
        { field: "uptime", headerName: "Uptime", flex: 0 },
        { field: "cpu", headerName: "CPU", flex: 0 },
        { field: "memory", headerName: "Memory", flex: 0 },
        { field: "restarts", headerName: "Restarts", flex: 0 },
        { field: "actions", headerName: "Actions", flex: 1, renderCell: ({row: { access }}) => { return (
                <Box sx={{maxWidth: '300px', overflow: 'auto'}}>
                  <Tooltip title="Restart">
                    <Button size='small' variant='contained' color='warning' onClick={restartProcess} sx={{marginRight: '10px'}}>↺</Button>
                  </Tooltip>
                  <Tooltip title="Stop">
                    <Button size='small' variant='contained' color='info' onClick={stopProcess} sx={{marginRight: '10px'}}>⏸️</Button>
                  </Tooltip>
                  <Tooltip title="Start">
                    <Button size='small' variant='contained' color='success' onClick={startProcess} sx={{marginRight: '10px'}}>▶</Button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button size='small' variant='contained' color='error' onClick={killProcess}>❌</Button>
                  </Tooltip>
                </Box>
              );}
        },
        { field: "placeholder", headerName: " ", flex: 0}    
    ];

    const stopProcess = (event) => {
        const processName = event.target.parentNode.parentNode.parentNode.querySelector('[data-field="processName"]').innerText;
        fetch (`${apiUrl}/api/stopprocess?token=${authCtx.token}&processname=${processName}`).then(response => {
            if (response.ok) {
                console.log(response.status);
                setForcedUpdate(!forcedUpdate);
            }
        }).catch(err => console.log(err));
    };

    const killProcess = (event) => {
        const processName = event.target.parentNode.parentNode.parentNode.querySelector('[data-field="processName"]').innerText;
        fetch (`${apiUrl}/api/killprocess?token=${authCtx.token}&processname=${processName}`).then(response => {
            if (response.ok) {
                console.log(response.status);
                setForcedUpdate(!forcedUpdate);
            }
        }).catch(err => console.log(err));
    };

    const startProcess = (event) => {
        const processName = event.target.parentNode.parentNode.parentNode.querySelector('[data-field="processName"]').innerText;
        fetch (`${apiUrl}/api/startprocess?token=${authCtx.token}&processname=${processName}`).then(response => {
            if (response.ok) {
                console.log(response.status);
                setForcedUpdate(!forcedUpdate);
            }
        }).catch(err => console.log(err));
    };

    const restartProcess = (event) => {
        const processName = event.target.parentNode.parentNode.parentNode.querySelector('[data-field="processName"]').innerText;
        fetch (`${apiUrl}/api/restartprocess?token=${authCtx.token}&processname=${processName}`).then(response => {
            if (response.ok) {
                console.log(response.status);
                setForcedUpdate(!forcedUpdate);
            }
        }).catch(err => console.log(err));
    };

    useEffect(() => {
    updateIntervalRef.current = setInterval(() => {
        setForcedUpdate(prev => !prev);
    }, 5000);
    return () => {
        clearInterval(updateIntervalRef.current);
    };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/processlist?token=${authCtx.token}`);
                if (response.ok) {
                    const res = await response.json();
                    setProcessList(res);
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [forcedUpdate]);

    useEffect(() => {
        async function fetchProcessData() {
            if (processList) {
                const tmpProcessData = [];
                for (let i in processList['processList']) {
                    const tmpObj = {};
                    console.log(processList['processList'][i]);
                    tmpObj['id'] = processList['processList'][i]['id'];
                    tmpObj['processName'] = processList['processList'][i]['processName'];
                    tmpObj['status'] = processList['processList'][i]['status'];
                    tmpObj['uptime'] = processList['processList'][i]['uptime'];
                    tmpObj['cpu'] = processList['processList'][i]['cpu'];
                    tmpObj['memory'] = `${((parseInt(processList['processList'][i]['memory']) / 1024) / 1024).toFixed(2)} MB`;
                    tmpObj['restarts'] = processList['processList'][i]['restarts'];
                    tmpObj['actions'] = '#';
                    tmpProcessData.push(tmpObj);
                }
                setProcessData(tmpProcessData);
            }
        }
        fetchProcessData();
    }, [processList]);

    useEffect(() => {
        if (processData) {
            setIsLoading(false);
            console.log(processData); 
        }
    }, [processData]);

    useEffect(() => {
        if (isScreenMobile) {
            setIsMobile(true);
            setBackgroundColor(colors.primary[600]);
        } else if (!isScreenMobile) {
            setIsMobile(false);
            setBackgroundColor(colors.primary[400]);
        }
    }, [isScreenMobile]);

    return (
        <MainPage backgroundColor={backgroundColor} isMobile={isMobile}>
            <MainPageWrapper isMobile={isMobile}>
                <MainPageChild title="Processes" subtitle="Processes running on server" isMobile={isMobile}>
                    {!isLoading ?
                    <Box m="0" height="75vh" sx={{
                        "& .MuiDataGrid-root": {
                            border: "none"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none"
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300]
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none"
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400]
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700]
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`
                        },
                        width: '97%'
                    }}>
                        {processData && <DataGrid rows={processData} columns={columns} components={{Toolbar: GridToolbar}} sx={isMobile ? {fontSize: 12} : {fontSize: 14}} 
                                density="compact" />}
                    </Box> : (
                        <Box m="0" width="97%">
                            <Skeleton animation="wave" variant="rectangular" height="75vh" />
                        </Box>)}
                </MainPageChild>
            </MainPageWrapper>
        </MainPage>
    );
};

export default BotManager;