import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useUser } from '../context/UserContext';

const apiUrl = process.env.REACT_APP_API_URL;

const UserSettingsContext = React.createContext(null);

const UserSettingsProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useState({});
  const [error, setError] = useState(null);
  const authCtx = useUser();

  const refreshCb = useCallback(async () => {
  if (authCtx.token) {
    try {
      const response = await fetch(`${apiUrl}/user/settings?token=${authCtx.token}`);
      console.log(response.status, response.statusText);
      if (!response.ok) {
        authCtx.logout();
      }
      const data = await response.json();
      setUserSettings(data);
    } catch (e) {
      setError('Error fetching user settings.');
    }
  }
  }, [authCtx]);

  useEffect(() => {
    refreshCb();
  }, [refreshCb, authCtx.isLoggedIn]);

  //const memoizedFetchSettings = useMemo(() => refreshCb, [refreshCb]);

  const refresh = useCallback(async () => {
  if (authCtx.token) {
    try {
      const response = await fetch(`${apiUrl}/user/settings?token=${authCtx.token}`);
      const data = await response.json();
      if (response.ok) {
        setUserSettings(data);
        return data;
      } else {
        setError('Error updating user settings.');
        authCtx.logout();
      }
    } catch (e) {
      setError('Error updating user settings.');
      authCtx.logout();
    }
  }
  }, [authCtx]);

  const update = useCallback(async (data, destructively = true) => {
    if (data) {
      try {
        const response = await fetch(`${apiUrl}/user/updatesettings?token=${authCtx.token}&destructively=${destructively}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        const res = await response.text();
        refresh();
        return res;
      } catch (e) {
        console.log(e);
        const alertText = e.message || 'An error occurred.';
        setError(alertText);
      }
    }
  }, [authCtx, refresh]);

  const deletekey = useCallback(async (keypath = []) => {
    try {
      if (keypath.length > 0) {
        const response = await fetch(`${apiUrl}/user/removekey?token=${authCtx.token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({keyPath: [...keypath]})
        });
        const res = await response.text();
        refresh();
        return res;
      }
    } catch (e) {
      console.log(e);
      const alertText = e.message || 'An error occurred.';
      setError(alertText);
    }
  }, [authCtx, refresh]);

  const memoizedValue = useMemo(() => ({ userSettings, refresh, update, error, deletekey }), [userSettings, refresh, update, error, deletekey]);

  return <UserSettingsContext.Provider value={memoizedValue}>{children}</UserSettingsContext.Provider>;
};

export const useUserSettings = () => {
  const userSettingsCtx = useContext(UserSettingsContext);
  if (!userSettingsCtx) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }
  return userSettingsCtx;
};

export default UserSettingsProvider;
