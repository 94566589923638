import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import SidePanel from '../SidePanel';
import MainPanel from '../MainPanel';
import PlaybackPanel from '../PlaybackPanel/PlaybackPanel';
import useSpotifyPlayer from '../utils/useSpotifyPlayer';

const MainLayout = ({userSettings = {}}) => {
    const location = useLocation();
    const [mainPage, setMainPage] = useState('Home');
    const [tmpQueue, setTmpQueue] = useState([]);
    const [tmpCurrentlyPlaying, setTmpCurrentlyPlaying] = useState({});
    const [playerState, setPlayerState] = useState({});
    const tempToken = 'BQAJ629vLLJQYFsFyV4jbY7QOgYx5bR1UoTC9eZsfmQ8G9gnZnbqi95forueUM3yhZTxFPmm9SBOCO_5ZKYLobMr-zazVgA_inBx-GVqqG4y1hn-cqtuDj9P5AkBViixNdOqdtX_yNFVmYJ0tJmc1VOa0EhFPASvvw8PX0lWH1lU5k27ntYlHvkv0Z9PRqt5Ru4S1Q';
    const { player, currentTrack, isReady, play, pause, nextTrack, previousTrack, togglePlayPause, setVolume, getVolume, 
        addToQueue, playTrack, getPlayerState } = useSpotifyPlayer(userSettings['spotify']['accessToken']);

    useEffect(() => {
        const path = location.pathname.substring(1).split('/')[1];
        if (path) {
            setMainPage(path.charAt(0).toUpperCase() + path.slice(1));
        }
    }, [location]);

    useEffect(() => {
        const updateProgress = () => {
            if (player) {
                player.getCurrentState().then(state => {
                    if (state) {
                        console.log(state);
                        setPlayerState(state);
                    }
                });
            }
        };
        //const interval = setInterval(updateProgress, 1000);
        //return () => clearInterval(interval);
    }, [player]);

    return (
        <div style={{height: '100vh', overflow: 'hidden'}}>
            <Splitter style={{height: '100%', width: '100%', overflow: 'hidden'}}>
                <SplitterPanel size={15} minSize={10} style={{overflow: 'hidden'}}>
                    <SidePanel mainPage={mainPage} setMainPage={setMainPage} />
                </SplitterPanel>
                <SplitterPanel size={85} minSize={75} style={{overflow: 'hidden'}}>
                    <Splitter layout='vertical' style={{height: '100%', overflow: 'hidden'}}>
                        <SplitterPanel size={85} minSize={85} style={{overflow: 'auto'}}>
                            {isReady && (<MainPanel setTmpQueue={setTmpQueue} addToQueue={addToQueue} playTrack={playTrack} mainPage={mainPage} 
                                setMainPage={setMainPage} userSettings={userSettings} playerState={playerState} togglePlayPause={togglePlayPause}
                                nextTrack={nextTrack} getPlayerState={getPlayerState} />)}
                        </SplitterPanel>
                        <SplitterPanel size={15} minSize={15} style={{overflow: 'hidden'}}>
                            {isReady && (<PlaybackPanel playerState={playerState} player={player} togglePlayPause={togglePlayPause} setVolume={setVolume} getVolume={getVolume}
                                            nextTrack={nextTrack} previousTrack={previousTrack} />)}
                        </SplitterPanel>
                    </Splitter>
                </SplitterPanel>
            </Splitter>
        </div>
    );
};

export default MainLayout;