import { useEffect, useState } from 'react';

const useTransformTrackList = (items = [], context) => {
    const [tableData, setTableData] = useState([]);

    const msToMinutesAndSeconds = (ms) => {
        const minutes = Math.floor(ms / 60000);
        const seconds = ((ms % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    };

    useEffect(() => {
        if (items.length > 0) {
            let _tableData;
            if (context === 'playlist') {
                _tableData = items.map((item, index) => ({
                    id: index + 1,
                    trackName: item['track']['name'],
                    artists: item['track']['artists'],
                    albumName: item['track']['album']['name'],
                    albumUri: item['track']['album']['uri'],
                    image: item['track']['album']['images'][0]['url'],
                    duration: msToMinutesAndSeconds(item['track']['duration_ms']),
                    uri: item['track']['uri']
                }));
            } else if (context === 'album') {
                _tableData = items.map((item, index) => ({
                    id: index + 1,
                    trackName: item['name'],
                    artists: item['artists'],
                    duration: msToMinutesAndSeconds(item['duration_ms']),
                    uri: item['uri']
                }));
            }
            setTableData(_tableData);
        }
    }, [items, context]);

    return tableData;
};

export default useTransformTrackList;