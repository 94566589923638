import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const Products = () => {
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [productList, setProductList] = useState([]);
    const boundingBoxStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        paddingBottom: '32px'
    };

    const Product = ({product}) => {
        const convertTimestampToDateFormat = (timestamp) => {
            const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
          
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
          
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        };
        
        return (
            <Grid item sx={{margin: '16px'}}>
                <motion.div whileHover={{scale: 1.1}}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">{product.name}</Typography>
                            <br />
                            {product.images.length > 0 && (<Box sx={{width: '140px'}}><CardMedia component="img" image={product.images[0]} /><br /></Box>)}
                            <Typography>{`Description: ${product.description}`}</Typography>
                            <Typography>{`Active: ${product.active}`}</Typography>
                            <Typography>{`Created: ${convertTimestampToDateFormat(product.created)}`}</Typography>
                            <Typography>{`Last updated: ${convertTimestampToDateFormat(product.updated)}`}</Typography>
                            {Object.keys(product.metadata).length > 0 && (<Typography>{`Other: ${JSON.stringify(product.metadata)}`}</Typography>)}
                        </CardContent>
                    </Card>
                </motion.div>
            </Grid>
        );
    };

    useEffect(() => {
        fetch(`${apiUrl}/stripe/products?token=${authCtx.token}`).then(response => response.json()).then(res => {
            setProductList(res['data']);
        }).catch(e => console.log(e));
    }, []);
    

  return (
    <React.Fragment>
        <Typography variant="h2">Products</Typography>
        <br />
        {Object.keys(productList).length > 0 ? (
            <Grid container sx={boundingBoxStyle}>
                {productList.map((p, i) => (
                    <motion.div key={p.id} animate={{x: 0}} initial={{x: '80vw'}} transition={{delay: i * 0.1}}><Product product={p} /></motion.div>
                ))}
            </Grid>
        ) : (
            <Typography variant="h3">Loading products...</Typography>
        )}
    </React.Fragment>
  )
};

export default Products;