import { useState, useEffect } from 'react';

const useAICategories = () => {
  const [categories, setCategories] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categoriesResponse = await fetch(`${apiUrl}/api/aicategories`);
        if (categoriesResponse.ok) {
          const data = await categoriesResponse.json();
          const _data = { ...data };
          delete _data['Other'];
          setCategories(_data);
        } else {
          console.log(`Non-ok result returned from API: ${categoriesResponse.status} - ${categoriesResponse.statusText}`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (Object.keys(categories).length === 0) {
      loadCategories();
    }
  }, [apiUrl, categories]);

  return categories;
};

export default useAICategories;
