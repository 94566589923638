import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Grid, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Header from '../../../components/Header';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AuthContext from '../../../store/auth-context';
import MainPage from '../../../utils/mainpage';
import HeaderWrapper from '../../../utils/headerwrapper';
import MainPageWrapper from '../../../utils/mainpagewrapper';
import MainPageChild from '../../../utils/mainpagechild';

const Users = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [channels, setChannels] = useState();
    const [channelData, setChannelData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const isScreenMobile = useMediaQuery((theme) => theme.breakpoints.between('xs', 'lg'));
    const [isMobile, setIsMobile] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(colors.primary[400]);
    const columns = [
        { field: "displayname", headerName: "Display Name", flex: 1 }, //String
        { field: "email", headerName: "Email", flex: 1, renderCell: ({row:{email}}) => {
            return (
                <React.Fragment>{isMobile ? (
                    <Tooltip title={email} placement="left" disableInteractive arrow><p>{email}</p></Tooltip>
                ) : (
                    <>{email}</>
                )}</React.Fragment>
            )
        }},
        { field: "chatters", headerName: "Chatters", flex: 0 }, //String
        { field: "subscribed", headerName: "Subscribed", flex: 0 }, //Boolean
        { field: "subscribedsince", headerName: "Since", flex: 0 }, //String
        { field: "subscriptiontier", headerName: "Tier", flex: 0 }, //Integer
        { field: "access", headerName: "User Type", flex: 1, renderCell: ({row:{access}}) => { //String "Normal", "Affiliate", "Partner"
            return (
                <Box
                  width="100%"
                  m="0 auto"
                  p="5px"
                  display="flex"
                  justifyContent="center"
                  backgroundColor={
                    access === "Affiliate" || access === "Partner"
                      ? colors.greenAccent[600]
                      : colors.greenAccent[700]
                  }
                  borderRadius="4px"
                >
                  {isMobile ? (
                    <Tooltip title={"TEST"} arrow>
                      <React.Fragment>
                        {access === "Normal" && (
                          <Tooltip
                            title={"Normal"}
                            placement="top"
                            disableInteractive
                            arrow
                          >
                            <LockOpenOutlinedIcon />
                          </Tooltip>
                        )}
                        {access === "Admin" && (
                          <Tooltip
                            title={"Admin"}
                            placement="top"
                            disableInteractive
                            arrow
                          >
                            <AdminPanelSettingsOutlinedIcon />
                          </Tooltip>
                        )}
                        {isMobile === false && <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                          {access}
                        </Typography>}
                      </React.Fragment>
                    </Tooltip>
                  ) : (
                    <React.Fragment>
                      {access === "Normal" && <LockOpenOutlinedIcon />}
                      {access === "Admin" && <AdminPanelSettingsOutlinedIcon />}
                      <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                        {access}
                      </Typography>
                    </React.Fragment>
                  )}
                </Box>
              );              
        }},
    ];

    useEffect(() => {
        if (isScreenMobile) {
            setIsMobile(true);
            setBackgroundColor(colors.primary[600]);
        } else if (!isScreenMobile) {
            setIsMobile(false);
            setBackgroundColor(colors.primary[400]);
        }
    }, [isScreenMobile]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/channels?token=${authCtx.token}`);
                if (response.ok) {
                    const res = await response.json();
                    setChannels(res);
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);    

    useEffect(() => {
        async function fetchChannelsData() {
            if (channels) {
                console.log(channels);
                const tmpChannelData = [];
                for (let i in channels['channels']) {
                    console.log(channels['channels'][i]);
                    const tmpObj = {};
                    tmpObj['id'] = channels['channels'][i]['id'];
                    tmpObj['displayname'] = channels['channels'][i]['displayname'];
                    tmpObj['email'] = channels['channels'][i]['email'];
                    tmpObj['chatters'] = channels['channels'][i]['chatters'];
                    tmpObj['subscribed'] = true;
                    tmpObj['subscribedsince'] = "0";
                    tmpObj['subscriptiontier'] = channels['channels'][i]['tier'];
                    tmpObj['access'] = "Normal";
                    const adminsResponse = await fetch(`${apiUrl}/api/admins?token=${authCtx.token}`);
                    const adminsRes = adminsResponse.ok ? await adminsResponse.json() : null;
                    if (adminsRes) {
                        for (let x in adminsRes['admins']) {
                            if (adminsRes['admins'][x]['name'] === channels['channels'][i]['displayname']) {
                                tmpObj['access'] = "Admin";
                            }
                        }
                    }
                    tmpChannelData.push(tmpObj);
                }
                setChannelData(tmpChannelData);
                setIsLoading(false);
            }
        }
        fetchChannelsData();
    }, [channels]);

    return (
        <MainPage backgroundColor={backgroundColor} isMobile={isMobile}>
            <MainPageWrapper isMobile={isMobile} backgroundColor={backgroundColor}>
                <MainPageChild title="Users" subtitle="Registered users" isMobile={isMobile}>
                    {!isLoading ?
                    <Box m="0" height="75vh" sx={{
                        "& .MuiDataGrid-root": {
                            border: "none"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none"
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300]
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none"
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400]
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700]
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`
                        },
                        "& .MuiSwitch-track": {
                            backgroundColor: colors.primary[100]
                        },
                        "& .MuiSwitch-switchBase": {
                            color: colors.primary[100]
                        },
                        width: '97%'
                    }}>
                        {channelData && (
                            <React.Fragment>
                                <DataGrid rows={channelData} theme={theme} columns={columns} components={{Toolbar: props => (<GridToolbar {...props} theme={theme} />)}} 
                                    sx={isMobile ? {fontSize: 12, color: colors.primary[100]} : {fontSize: 14, color: colors.primary[100]}} density="compact" />
                            </React.Fragment>
                        )}
                    </Box>
                    : (
                        <Box m="0" width="97%">
                            <Skeleton animation="wave" variant="rectangular" height="75vh" />
                        </Box>)}
                </MainPageChild>
            </MainPageWrapper>
        </MainPage>
    );
};

export default Users;