import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const Prices = () => {
    const authCtx = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [priceList, setPriceList] = useState([]);
    const boundingBoxStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        paddingBottom: '32px'
    };

    const Price = ({price}) => {
        const [connectedProduct, setConnectedProduct] = useState({});
        const convertTimestampToDateFormat = (timestamp) => {
            const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
          
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
          
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        };

        useEffect(() => {
            if (price.product !== '' && Object.keys(connectedProduct).length === 0) {
                fetch(`${apiUrl}/stripe/product/${price.product}?token=${authCtx.token}`).then(response => response.json()).then(res => {
                    setConnectedProduct(res);
                }).catch(e => console.log(e));
            }
        }, []);
        
        
        return (
            <Grid item sx={{margin: '16px'}}>
                <motion.div whileHover={{scale: 1.1}}>
                    <Card>
                        <CardContent>
                            <Typography>{`Currency: ${price.currency}`}</Typography>
                            <Typography>{`Amount: ${price.unit_amount/100}`}</Typography>
                            <Typography>{`Active: ${price.active}`}</Typography>
                            <Typography>{`Created: ${convertTimestampToDateFormat(price.created)}`}</Typography>
                            <Typography>{`Type: ${price.type}`}</Typography>
                            {Object.keys(price.recurring).length > 0 && (
                                <React.Fragment>
                                    <Typography>{`Interval: ${price.recurring.interval}`}</Typography>
                                    <Typography>{`Interval count: ${price.recurring.interval_count}`}</Typography>
                                    <Typography>{`Trial period days: ${price.recurring.trial_period_days}`}</Typography>
                                    <Typography>{`Usage: ${price.recurring.usage_type}`}</Typography>
                                </React.Fragment>
                            )}
                            {Object.keys(connectedProduct).length > 0 && (
                                <React.Fragment>
                                    <Typography>{`Connected product: ${connectedProduct.name}`}</Typography>
                                </React.Fragment>
                            )}
                            {Object.keys(price.metadata).length > 0 && (<Typography>{`Other: ${JSON.stringify(price.metadata)}`}</Typography>)}
                        </CardContent>
                    </Card>
                </motion.div>
            </Grid>
        );
    };

    useEffect(() => {
        fetch(`${apiUrl}/stripe/prices?token=${authCtx.token}`).then(response => response.json()).then(res => {
            setPriceList(res['data']);
        }).catch(e => console.log(e));
    }, []);
    

  return (
    <React.Fragment>
        <Typography variant="h2">Prices</Typography>
        <br />
        {Object.keys(priceList).length > 0 ? (
            <Grid container sx={boundingBoxStyle}>
                {priceList.map((p, i) => (
                    <motion.div key={p.id} animate={{x: 0}} initial={{x: '80vw'}} transition={{delay: i * 0.1}}><Price price={p} /></motion.div>
                ))}
            </Grid>
        ) : (
            <Typography variant="h3">Loading prices...</Typography>
        )}
    </React.Fragment>
  )
};

export default Prices;