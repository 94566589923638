import { Box, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { ColorModeContext, tokens } from '../theme';
import useIsMobile from './useIsMobile';

const MainPage = ({children, useBackgroundColor = true, useBoundingBox = true}) => {
    const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
    const isMobile = useIsMobile();
    const boundingBoxStyle = {
      marginLeft: `${isMobile ? (50 + 20) : (225 + 20)}px`,
      height: '100%',
      width: '100%',
      overflow: 'auto',
      backgroundColor: theme.palette.primary.main
    };

    return (
        <Box sx={{height: '100%', width: '100%', backgroundColor: useBackgroundColor && theme.palette.primary.dark}}>
          <Box sx={useBoundingBox && boundingBoxStyle}>
            <Box sx={useBoundingBox && {marginRight: !isMobile ? '265px' : '80px'}}>
                {children}
            </Box>
          </Box>
        </Box>
    );
};

export default MainPage;