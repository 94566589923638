import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DialogComponent from '../../../beta/layouts/DialogComponent';
import { PickList } from 'primereact/picklist';
import useIngredientTags from '../utils/useIngredientTags';
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useUser } from '../../../context/UserContext';

const CreatePerfumeRecipe = ({backgroundColor = '#5D3B9E', footer = '', setOpenDialog = () => {}, setIsBlur = () => {}, ingredients = {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const tags = useIngredientTags();
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const [newRecipe, setNewRecipe] = useState({
        title: '',
        description: '',
        image: '',
        topnotes: [],
        middlenotes: [],
        basenotes: []
    });
    const styles = {
        base: {
            width: '100%'
        }
    };

    useEffect(() => {
        const _source = [];
        Object.keys(ingredients).map((ingredient, index) => {
            _source.push({...ingredients[ingredient], search: JSON.stringify(ingredients[ingredient], null, 4)});
        });
        setSource(_source);
    }, [ingredients]);
    useEffect(() => {
        const _topnotes = []; const _middlenotes = []; const _basenotes = [];
        target.map((ingredientObj, index) => {
            const insertObj = {title: ingredientObj['title'], amount: 5};
            switch (ingredientObj['note']) {
                case 'Top':
                    _topnotes.push(insertObj);
                    break;
                case 'Middle':
                    _middlenotes.push(insertObj);
                    break;
                case 'Base':
                    _basenotes.push(insertObj);
                    break;
            }
        });
        setNewRecipe((prev) => ({...prev, topnotes: _topnotes, middlenotes: _middlenotes, basenotes: _basenotes}));
    }, [target]);

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    };
    const onSliderChange = (noteType, index, newValue) => {
        setNewRecipe(prev => {
            const updatedNotes = [...prev[noteType]];
            updatedNotes[index].amount = newValue;
            return { ...prev, [noteType]: updatedNotes };
        });
    };
    const calculateTotal = noteType => {
        return newRecipe[noteType].reduce((total, note) => total + note.amount, 0);
    };
    const itemTemplate = (item) => {
        const backgroundImageStyle = {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            filter: 'brightness(50%)',
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };
    
        const textStyle = {
            position: 'relative',
            zIndex: 1,
            color: 'white',
            textAlign: 'center',
            textShadow: '2px 2px 2px black',
        };
    
        return (
            <Grid container style={{position: 'relative', height: '100px'}}>
                <Box style={backgroundImageStyle} />
                <Grid item xs={12} style={{ ...textStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Box>
                        <Typography variant="h5">{item.title}</Typography>
                        <Typography>{item.note}</Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    };
    const submitHandler = async () => {
        const url = `${apiUrl}/yellowraven/newperfumerecipe?token=${authCtx.token}`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newRecipe)
        };
        const response = await fetch(url, options);
        const res = await response.json();
        if (response.ok) {
            setOpenDialog(false);
            setIsBlur(false);
        }
    };
    const areAllFieldsFilled = () => {
        if (!newRecipe.title || !newRecipe.description || !newRecipe.image) {
            return false;
        }
        return true;
    };

    return (
        <DialogComponent footer={footer} minHeight='70vh' maxHeight='80vh' minWidth='960px'>
            <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3' sx={{textAlign: 'center'}}>Pick Ingredients</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PickList dataKey='title' source={source} target={target} onChange={onChange} itemTemplate={itemTemplate} breakpoint='1280px' style={{width: '100%'}}
                        sourceHeader='Ingredients' targetHeader='Selected' sourceStyle={{height: '24rem'}} targetStyle={{height: '24rem'}}
                        filter filterBy='search' />
                </Grid>
                <Grid item xs={6} sx={{marginTop: '16px'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>Proportions</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Top Notes (Total: {calculateTotal('topnotes')}%)</Typography>
                            {newRecipe['topnotes'].map((ingredientObj, index) => (
                                <Grid container key={`top-${index}`} spacing={2} sx={{marginTop: '8px'}}>
                                    <Grid item xs={12}>
                                        <Typography>{`${ingredientObj.title} (${ingredientObj.amount}%)`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Slider value={ingredientObj.amount} onChange={(e) => onSliderChange('topnotes', index, e.value)}
                                            max={100} valueLabelDisplay='auto' />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Middle Notes (Total: {calculateTotal('middlenotes')}%)</Typography>
                            {newRecipe['middlenotes'].map((ingredientObj, index) => (
                                <Grid container key={`top-${index}`} spacing={2} sx={{marginTop: '8px'}}>
                                    <Grid item xs={12}>
                                        <Typography>{`${ingredientObj.title} (${ingredientObj.amount}%)`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Slider value={ingredientObj.amount} onChange={(e) => onSliderChange('middlenotes', index, e.value)}
                                            max={100} valueLabelDisplay='auto' />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Base Notes (Total: {calculateTotal('basenotes')}%)</Typography>
                            {newRecipe['basenotes'].map((ingredientObj, index) => (
                                <Grid container key={`top-${index}`} spacing={2} sx={{marginTop: '8px'}}>
                                    <Grid item xs={12}>
                                        <Typography>{`${ingredientObj.title} (${ingredientObj.amount}%)`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Slider value={ingredientObj.amount} onChange={(e) => onSliderChange('basenotes', index, e.value)}
                                            max={100} valueLabelDisplay='auto' />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sx={{marginTop: '16px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>About</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText style={{...styles.base}} value={newRecipe.title} onChange={(e) => setNewRecipe((prev) => ({...prev, title: e.target.value}))} 
                                placeholder='Title' name='title' />
                        </Grid>
                        <Grid item xs={12}>
                            <InputTextarea style={styles.base} value={newRecipe.description} onChange={(e) => setNewRecipe((prev) => ({...prev, description: e.target.value}))} 
                                rows={3} placeholder='Description' autoResize />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText style={{...styles.base}} value={newRecipe.image} onChange={(e) => setNewRecipe((prev) => ({...prev, image: e.target.value}))} 
                                placeholder='Image URL' name='image' />
                        </Grid>
                        <Grid item xs={12}>
                            <Button style={{width: '100%'}} disabled={!areAllFieldsFilled()} size='small' label='Submit' severity='success' onClick={submitHandler} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default CreatePerfumeRecipe;