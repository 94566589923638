import React, { useContext, useEffect, useState } from "react";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography, useTheme } from "@mui/material";
import useIsMobile from "../../../utils/useIsMobile";
import { tokens } from "../../../theme";
import MainPage from "../../../utils/mainpage";
import MainPageWrapper from "../../../utils/mainpagewrapper";
import MainPageChild from "../../../utils/mainpagechild";
import AuthContext from "../../../store/auth-context";
import CustomSnackbar from "../../../utils/customsnackbar";

const emotionSet = [
  'admiration', 'amusement', 'approval', 'caring', 'desire', 'excitement', 'gratitude', 'joy', 'love', 'optimism',
  'pride', 'relief', 'anger', 'annoyance', 'disappointment', 'disapproval', 'disgust', 'embarrassment', 'fear', 'grief',
  'nervousness', 'remorse', 'sadness', 'confusion', 'curiosity', 'realization', 'surprise'
];

const Emotes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const [backgroundColor, setBackgroundColor] = useState(colors.primary[400]);
  const [snackAlert, setSnackAlert] = useState({
    open: false,
    severity: "success",
    text: "",
    autoHideDuration: 5000,
  });
  const [emotes, setEmotes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (Object.keys(emotes).length === 0) {
        fetch(`${apiUrl}/api/admin/emotes?token=${authCtx.token}`)
        .then((response) => response.json())
        .then((res) => {
            setEmotes(res["emotes"]);
            setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  useEffect(() => {
    setBackgroundColor(isMobile ? colors.primary[600] : colors.primary[400]);
  }, [isMobile]);

  useEffect(() => {
    if (!isLoading) {
      fetch(`${apiUrl}/api/admin/emotes?token=${authCtx.token}`, {
        method: 'POST',
        body: JSON.stringify(emotes),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => response.text()).then((res) => {
        setSnackAlert((prev) => ({...prev, severity: "success", text: res, open: true}));
    }).catch((e) => {
        const alertText = e.message || 'An error occurred.';
        setSnackAlert((prev) => ({...prev, severity: "error", text: alertText, open: true}));
    });
    }
  }, [emotes]);
  
  const truncateKey = (key) => {
    if (key.length > 70) {
      return key.substring(0, 70) + "...";
    }
    return key;
  };

  const Documentation = () => {
    return (
      <div>
      <Typography variant="body1" gutterBottom>
        Welcome to the Emote Assignment page for Ratbot! This interface allows you to assign sentiments or intents to specific emotes, enabling Ratbot to better understand and interpret the emotional context of user messages. The assigned sentiments will be used by the chatbot to analyze both incoming and outgoing messages.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <ol>
          <li>
            <strong>Emote List:</strong> On this page, you will find a comprehensive list of emotes available for assignment. Each emote represents a 7TV or BTTV emote that conveys a particular feeling or intent.
          </li>
          <li>
            <strong>Dropdown Selection:</strong> For each emote in the list, there is a corresponding dropdown selection menu.
            <ul>
              <li>
                By clicking on the dropdown menu next to an emote, you can choose a sentiment or intent to associate with that emote.
              </li>
            </ul>
          </li>
          <li>
            <strong>Assigning Sentiments:</strong> To assign a sentiment to an emote, simply select the desired sentiment from the dropdown menu next to the corresponding emote. The assignment is automatically saved upon selection, so you don't need to worry about saving manually.
          </li>
          <li>
            <strong>Incoming User Messages:</strong> When users send messages containing emotes, Ratbot will analyze the associated sentiments of those emotes to better understand the emotional context of the user's message. This analysis helps the chatbot to respond more appropriately and accurately to user queries, taking into account the expressed emotions or intents.
          </li>
          <li>
            <strong>Outgoing Messages:</strong> In addition to understanding user messages, Ratbot can also utilize emotes in its own outgoing messages.
            <ul>
              <li>
                Ratbot will select an appropriate emote based on the intended sentiment or emotion it wants to convey.
              </li>
              <li>
                This enhances Ratbot's ability to communicate effectively and empathetically with users.
              </li>
            </ul>
          </li>
        </ol>
      </Typography>
      <Typography variant="body1">
        Note: It's important to regularly review and update the emote assignments as needed to ensure they accurately reflect the intended sentiments. Emote assignment customization can greatly enhance Ratbot's ability to understand and respond to user messages in a more human-like manner.
      </Typography>
    </div>
    );
  };

  return (
    <MainPage backgroundColor={backgroundColor}>
      <CustomSnackbar open={snackAlert.open} autoHideDuration={snackAlert.autoHideDuration} severity={snackAlert.severity}
        setSnackAlert={setSnackAlert} snackText={snackAlert.text} />
      <MainPageWrapper backgroundColor={backgroundColor} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <MainPageChild title="Emotes" subtitle="Edit bot emote assignment">
          <Box>
            {Object.keys(emotes).length > 0 && (
              <Box sx={{maxHeight: "80vh", overflowY: "auto"}}>
                {Object.keys(emotes).map((key, index) => (
                  <Box key={key} sx={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", 
                    backgroundColor: index % 2 === 0 ? colors.grey[700] : colors.grey[800]}}>
                    <Tooltip title={key.length > 30 && <Typography>{key}</Typography>}>
                      <Typography sx={{ marginLeft: '16px' }} variant="h5">{truncateKey(key)}</Typography>
                    </Tooltip>
                    <FormControl>
                      <Select value={emotes[key]} onChange={(e) => {
                            const updatedEmotes = { ...emotes };
                            updatedEmotes[key] = e.target.value;
                            setEmotes(updatedEmotes);
                          }}>
                        {emotionSet.map((e) => (
                          <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </MainPageChild>
        <MainPageChild title="How It Works" subtitle="Emote Assignment Documentation">
          <Documentation />
        </MainPageChild>
      </MainPageWrapper>
    </MainPage>
  );
};

export default Emotes;
