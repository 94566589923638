import React, { useContext, useState } from 'react';
import MainPage from '../../../utils/mainpage';
import MainPageWrapper from '../../../utils/mainpagewrapper';
import MainPageChild from '../../../utils/mainpagechild';
import OverrideStyles from '../../../utils/styles';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import useReleaseNotes from '../../../utils/useReleaseNotes';
import { ColorModeContext } from '../../../theme';
import AuthContext from '../../../store/auth-context';
import ReleaseNotes from '../../dashboard/releasenotes';

const ChangeLog = () => {
  const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
  const { releaseNotes, loading, addReleaseNote } = useReleaseNotes();
  const [showNewEntry, setShowNewEntry] = useState(false);
  const generateRandomAlphanumericString = (length) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  };
  const [entry, setEntry] = useState({
    title: "Title",
    timestamp: new Date().getTime(),
    id: generateRandomAlphanumericString(10),
    entries: [
      {
        type: 'summary',
        content: ''
      },
      {
        type: 'list',
        content: [
        ]
      },
      {
        type: 'image',
        url: '',
        altText: 'An image',
        width: 150,
        height: 150
      }
    ]
  });

  const NewEntry = ({showNewEntry, setShowNewEntry, entry, setEntry}) => {
    const [newTitle, setNewTitle] = useState(entry['title']);
    const [newSummary, setNewsummary] = useState(entry['entries'].find(item => item.type === 'summary')?.content);
    const [newList, setNewList] = useState(entry['entries'].find(item => item.type === 'list')?.content);
    const [newImage, setNewImage] = useState(entry['entries'].find(item => item.type === 'image'));
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useContext(AuthContext);

    const renderEntry = (entry, index) => {
      switch (entry['type']) {
        case 'summary':
          return (
            <Box key={index}>
              <Typography variant='h4' sx={{marginTop: '8px'}}>Summary</Typography>
              <TextField fullWidth color='secondary' value={newSummary} onChange={(e) => setNewsummary(e.target.value)} />
            </Box>
          );
        case 'list':
          return (
            <Box key={index}>
              <Grid container sx={{marginTop: '16px'}}>
                <Grid item xs={1}>
                  <Typography variant='h4' sx={{marginTop: '4px'}}>List</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button variant='text' color='success' size='large' onClick={() => setNewList((prev) => [...prev, `${prev.length+1}`])}>+ Add</Button>
                </Grid>
              </Grid>
              {newList.map((liItem, i) => (
                <Box sx={{marginBottom: '8px'}} key={i}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography sx={{marginTop: '8px'}}>{`List Item ${i+1}`}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button variant='text' color='error' sx={{marginBottom: '8px'}} onClick={() => {
                        const tmpArr = [...newList];
                        tmpArr.splice(i, 1);
                        setNewList(tmpArr);
                      }}>Remove</Button>
                    </Grid>
                  </Grid>
                  <TextField fullWidth color='secondary' value={liItem} onChange={(e) => {
                    const tmpArr = [...newList];
                    tmpArr[i] = e.target.value;
                    setNewList(tmpArr);
                  }} />
                </Box>
              ))}
            </Box>
          );
        case 'image':
          return (
            <React.Fragment>
              <Typography variant='h4' sx={{marginTop: '8px'}}>Image</Typography>
              <Typography sx={{marginTop: '8px'}}>URL</Typography>
              <TextField fullWidth color='secondary' value={newImage['url']} onChange={(e) => setNewImage((prev) => ({...prev, url: e.target.value}))} />
              <Typography sx={{marginTop: '8px'}}>Alt. text</Typography>
              <TextField fullWidth color='secondary' value={newImage['altText']} onChange={(e) => setNewImage((prev) => ({...prev, altText: e.target.value}))} />
              <Typography sx={{marginTop: '8px'}}>Width</Typography>
              <TextField fullWidth color='secondary' value={newImage['width']} onChange={(e) => setNewImage((prev) => ({...prev, width: e.target.value}))} />
              <Typography sx={{marginTop: '8px'}}>Height</Typography>
              <TextField fullWidth color='secondary' value={newImage['height']} onChange={(e) => setNewImage((prev) => ({...prev, height: e.target.value}))} />
            </React.Fragment>
          );
      
        default:
          break;
      }
    };

    const saveEntry = async () => {
      const tmpObj = {
        title: newTitle,
        timestamp: new Date().getTime(),
        id: entry['id'],
        entries: [
          {type: 'summary', content: newSummary},
          {type: 'list', content: [...newList]},
          {type: 'image', ...newImage}
        ]
      };
      const updateSuccess = await addReleaseNote(tmpObj, authCtx);
      setShowNewEntry(!updateSuccess);
    };

    return (
      <Dialog open={showNewEntry} onClose={() => setShowNewEntry(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle variant='h3'>Create New Entry</DialogTitle>
        <DialogContent>
          <Typography variant='h4'>Title</Typography>
          <TextField fullWidth color='secondary' value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
          <React.Fragment>
            {entry['entries'].map((entryItem, index) => (
              <React.Fragment>
                {renderEntry(entryItem, index)}
              </React.Fragment>
            ))}
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='info' onClick={() => console.log(entry)}>Log</Button>
          <Button variant='contained' color='error' onClick={() => setShowNewEntry(false)}>Close</Button>
          <Button variant='contained' color='success' onClick={saveEntry}>Upload</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <MainPage>
      <MainPageWrapper>
        <MainPageChild title="Changelog" subtitle="Add and update release log entries">
          <OverrideStyles>
            <NewEntry showNewEntry={showNewEntry} setShowNewEntry={setShowNewEntry} entry={entry} setEntry={setEntry} />
            <Button variant='contained' color='secondary' sx={{marginBottom: '16px'}} onClick={() => setShowNewEntry(true)}>Create new entry</Button>
            {loading ? (<Typography>Fetching release notes...</Typography>) : (<ReleaseNotes releaseNotes={releaseNotes} />)}
          </OverrideStyles>
        </MainPageChild>
      </MainPageWrapper>
    </MainPage>
  )
};

export default ChangeLog;