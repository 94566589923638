import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { ColorModeContext } from '../theme';

const HeaderWrapper = ({children, useBackgroundColor = true}) => {
  const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);

  return (
    <Grid ml={0}>
        <Grid item xs={12} mt={3} mr={3} sx={{backgroundColor: `${useBackgroundColor ? theme.palette.primary.main : null}`}}>
            {children}
        </Grid>
    </Grid>
  );
};

export default HeaderWrapper;