import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import DialogComponent from '../../../beta/layouts/DialogComponent';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useUser } from '../../../context/UserContext';

const SubscriberInvoices = ({backgroundColor = '#5D3B9E', footer = '', customerObj = {}}) => {
    const [stripeInfo, setStripeInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    useEffect(() => {
        const fetchCustomerData = async () => {
            const response = await fetch(`${apiUrl}/stripe/invoices/${customerObj['customer']}?token=${authCtx.token}`);
            const res = await response.json();
            setStripeInfo(res);
            setLoading(false);
        };
        fetchCustomerData();
    }, [customerObj]);

  return (
    <DialogComponent footer={footer} maxHeight='60vh'>
        <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
            {loading ? (
                <Typography>Loading customer data...</Typography>
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Accordion>
                            {stripeInfo['data'].map((invoiceObj, index) => (
                                <AccordionTab header={new Date(invoiceObj['effective_at'] * 1000).toLocaleString()}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography>{`Amount Due: ${invoiceObj['amount_due'] / 100} ${invoiceObj['currency'].toUpperCase()}`}</Typography>
                                            <Typography>{`Amount Paid: ${invoiceObj['amount_paid'] / 100} ${invoiceObj['currency'].toUpperCase()}`}</Typography>
                                            <Typography>{`Amount Remaining: ${invoiceObj['amount_remaining'] / 100} ${invoiceObj['currency'].toUpperCase()}`}</Typography>
                                            <Typography>{`Attempt Count: ${invoiceObj['attempt_count']}`}</Typography>
                                            <Typography>{`Next Payment Attempt: ${invoiceObj['next_payment_attempt']}`}</Typography>
                                            <Typography>{`Period: ${new Date(invoiceObj['period_start'] * 1000).toLocaleDateString()} to ${new Date(invoiceObj['period_end'] * 1000).toLocaleDateString()}`}</Typography>
                                            <Typography>{<a href={invoiceObj['hosted_invoice_url']} target='_blank'>Invoice PDF</a>}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    </DialogComponent>
  );
};

export default SubscriberInvoices;