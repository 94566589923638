import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Box, Grid, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { Collapse } from '@nextui-org/react';
import useReleaseNotes from '../../utils/useReleaseNotes';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const ReleaseNotes = ({backgroundColor = '#5D3B9E', footer = ''}) => {
    const { releaseNotes, loading } = useReleaseNotes();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const textStyle = {
        color: '#e0e0e0'
    };
    const gridStyle = {
        height: '100%', 
        marginTop: '8px', 
        marginLeft: '16px',
        backgroundColor: colorScheme.background50,
        borderRadius: '5px',
        border: `2px solid ${colorScheme.background25}`,
        maxHeight: '50vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    const renderEntry = (entry) => {
        switch (entry['type']) {
            case 'summary':
                return (
                    <Typography sx={{...textStyle, marginBottom: '8px'}} variant='h5'>{entry['content']}</Typography>
                );
            case 'list':
                return (
                    <List sx={{marginBottom: '8px'}}>
                        {entry['content'].map(txt => (
                            <ListItem>
                                <ListItemIcon><RadioButtonUncheckedIcon sx={{width: '10px', height: '10px'}} /></ListItemIcon>
                                <Typography variant='h4' sx={textStyle}>{txt}</Typography>
                            </ListItem>
                        ))}
                    </List>
                );
            case 'image':
                return (
                    <img src={entry['url']} alt={entry['altText']} width={entry['width']} height={entry['height']} />
                )
        
            default:
                return (
                    <Typography sx={textStyle}>{JSON.stringify(entry)}</Typography>
                );
        }
    };
    const formatTimestamp = (timestamp) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(Number(timestamp)).toLocaleString('en-US', options);
    };

  return (
    <DialogComponent footer={footer} maxHeight='65vh'>
        <Grid container style={gridStyle} spacing={2}>
            <Grid item xs={12}>
                <React.Fragment>
                    {releaseNotes.length > 0 && (
                        <Collapse.Group>
                            {releaseNotes.map(entry => (
                                <Collapse key={entry['id']} title={<Typography sx={textStyle} variant='h4'>{`${formatTimestamp(entry['timestamp'])}`}</Typography>}>
                                    <Box sx={{backgroundColor: colorScheme.background50, padding: '8px', marginRight: '16px', borderRadius: '5px'}}>
                                    <Typography sx={{...textStyle, marginBottom: '8px'}} variant='h3'>{entry['title']}</Typography>
                                    {entry['entries'].map(entryObj => (
                                        <React.Fragment>
                                            {
                                                renderEntry(entryObj)
                                            }
                                        </React.Fragment>
                                    ))}
                                    </Box>
                                </Collapse>
                            ))}
                        </Collapse.Group>
                    )}
                </React.Fragment>
            </Grid>
        </Grid>
    </DialogComponent>
  );
};

export default ReleaseNotes;