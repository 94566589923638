import React from 'react';
import { Typography, Container, Grid, Card, CardContent, styled } from '@mui/material';
import useIsMobile from '../../utils/useIsMobile';

const AboutPage = () => {
    const isMobile = useIsMobile();
    const TransparentCard = styled(Card)(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        boxShadow: 'none',
    }));

  return (
    <div style={{ display: 'flex', minHeight: '70vh', marginTop: '24px', overflowY: 'auto', maxHeight: '85vh' }}>
        <Container maxWidth="md">
            <Typography variant={isMobile ? "h3" : "h1"} align={isMobile ? 'left' : 'center'} gutterBottom>
                Welcome to Ratbot!
            </Typography>
            <Typography variant="body1" align={isMobile ? 'left' : 'center'} gutterBottom>
                The Ultimate Chatbot for Your Twitch Channel
            </Typography>
            {!isMobile && <br />}
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6}>
                    <TransparentCard>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>
                                Spotify Integration
                            </Typography>
                            <Typography variant="body1">
                                Ratbot seamlessly integrates with Spotify, allowing your viewers to submit song requests via Twitch channel point redemptions. No manual work needed! You can also ask Ratbot advanced questions about the current song playing, such as its key, tempo, and more. With GameGroove, Ratbot can even selects songs automatically based on the mood of the game you're playing.
                            </Typography>
                        </CardContent>
                    </TransparentCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TransparentCard>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>
                                Customized Context
                            </Typography>
                            <Typography variant="body1">
                                With Ratbot, you can provide the bot with context-specific information to enhance its responses. For example, let Ratbot know your stream schedule, and it will answer questions about your streaming days and times. This feature adds a personal touch and makes Ratbot more engaging for your viewers.
                            </Typography>
                        </CardContent>
                    </TransparentCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TransparentCard>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>
                                Mod Support
                            </Typography>
                            <Typography variant="body1">
                                Ratbot offers full customization through mod support. You can write your own third-party modules in JavaScript or Python to integrate with the Ratbot API. The mod store ensures that everyone can benefit from the created mods, enhancing the functionality and versatility of Ratbot for your Twitch channel.
                            </Typography>
                        </CardContent>
                    </TransparentCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TransparentCard>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>
                                Game-Specific Presets
                            </Typography>
                            <Typography variant="body1">
                                Save time and effort with Ratbot's game-specific presets. It can automatically switch personalities depending on the game you're playing. With a single setup, Ratbot adapts to the game environment and provides relevant responses, creating a more immersive experience for your viewers.
                            </Typography>
                        </CardContent>
                    </TransparentCard>
                </Grid>

                <Grid item xs={12}>
                    <TransparentCard>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>
                                7TV/BTTV Emotes
                            </Typography>
                            <Typography variant="body1">
                                Ratbot continuously learns to use 7TV/BTTV emotes in messages in a way that makes sense. Using GoEmotion sentiment analysis, Ratbot brings more emotions and expressions to the chat, making interactions livelier and more engaging for your viewers.
                            </Typography>
                        </CardContent>
                    </TransparentCard>
                </Grid>
            </Grid>
        </Container>
    </div>
  );
};

export default AboutPage;