import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loading.css';

const Loading = ({show = false}) => {

    return (
        <React.Fragment>
            {show && (<div className="loadingoverlay">
                <ProgressSpinner />
            </div>)}
        </React.Fragment>
    );
};

export default Loading;