import React from 'react';
import './PlaybackPanel.css';
import { Box } from '@mui/material';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';

const VolumeControl = ({ isMuted, volume, onMuteToggle, onVolumeChange }) => (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}>
        <Button icon={isMuted ? "pi pi-volume-off" : "pi pi-volume-up"} onClick={onMuteToggle} className="p-button-rounded p-button-text" aria-label="Mute" />
        <Slider value={isMuted ? 0 : volume} onChange={onVolumeChange} max={100} id='volume-slider' style={{ marginLeft: '10px', flexGrow: 1 }} />
    </Box>
);

export default VolumeControl;