import { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';

/**
 * Custom hook to fetch and return subscription information for the current user.
 * @returns {Object} The subscription information.
 *
 * @example
 * const subscriptionInfo = useSubscriptionInfo();
 * console.log(subscriptionInfo);
 */
const useSubscriptionInfo = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [subId, setSubId] = useState('');

    useEffect(() => {
        /**
         * Fetches the subscription status of the user.
         * @returns {Promise<void>}
         */
        const fetchSubStatus = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/subscription/status?token=${authCtx.token}`);
                if (response.ok) {
                    const res = await response.json();
                    if ('subscription' in res) {
                        setSubId(res['subscription']);
                    } else if ('type' in res) {
                        setSubscriptionInfo(res);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };

        /**
         * Fetches the subscription information of the user.
         * @returns {Promise<void>}
         */
        const fetchSubscription = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/subscription/${subId}?token=${authCtx.token}`);
                const res = await response.json();
                setSubscriptionInfo(res);
            } catch (e) {
                console.log(e);
            }
        };

        if (subId === '') {
            fetchSubStatus();
        } else if (subId) {
            fetchSubscription();
        }
    }, [subId, apiUrl, authCtx]);

    return subscriptionInfo;
};

export default useSubscriptionInfo;

/* RESPONSE EXAMPLE */
/*
{
    "id": "sub_1NsWQpFBpKaruH1EEuLQFjEE",
    "object": "subscription",
    "application": null,
    "application_fee_percent": null,
    "automatic_tax": {
        "enabled": true,
        "liability": {
            "type": "self"
        }
    },
    "billing_cycle_anchor": 1695239491,
    "billing_cycle_anchor_config": null,
    "billing_thresholds": null,
    "cancel_at": null,
    "cancel_at_period_end": false,
    "canceled_at": null,
    "cancellation_details": {
        "comment": null,
        "feedback": null,
        "reason": null
    },
    "collection_method": "charge_automatically",
    "created": 1695239491,
    "currency": "usd",
    "current_period_end": 1721505091,
    "current_period_start": 1718913091,
    "customer": "cus_OfsBVqAqbrCnEk",
    "days_until_due": null,
    "default_payment_method": "pm_1NsWQoFBpKaruH1Et18ndJTU",
    "default_source": null,
    "default_tax_rates": [],
    "description": null,
    "discount": null,
    "discounts": [],
    "ended_at": null,
    "invoice_settings": {
        "account_tax_ids": null,
        "issuer": {
            "type": "self"
        }
    },
    "items": {
        "object": "list",
        "data": [
            {
                "id": "si_OfsBNm5JAQ9fNt",
                "object": "subscription_item",
                "billing_thresholds": null,
                "created": 1695239492,
                "discounts": [],
                "metadata": {},
                "plan": {
                    "id": "price_1NawCFFBpKaruH1EOudMlasR",
                    "object": "plan",
                    "active": true,
                    "aggregate_usage": null,
                    "amount": 500,
                    "amount_decimal": "500",
                    "billing_scheme": "per_unit",
                    "created": 1691048627,
                    "currency": "usd",
                    "interval": "month",
                    "interval_count": 1,
                    "livemode": true,
                    "metadata": {},
                    "meter": null,
                    "nickname": null,
                    "product": "prod_ONhbOnIEF6AXUJ",
                    "tiers_mode": null,
                    "transform_usage": null,
                    "trial_period_days": null,
                    "usage_type": "licensed"
                },
                "price": {
                    "id": "price_1NawCFFBpKaruH1EOudMlasR",
                    "object": "price",
                    "active": true,
                    "billing_scheme": "per_unit",
                    "created": 1691048627,
                    "currency": "usd",
                    "custom_unit_amount": null,
                    "livemode": true,
                    "lookup_key": null,
                    "metadata": {},
                    "nickname": null,
                    "product": "prod_ONhbOnIEF6AXUJ",
                    "recurring": {
                        "aggregate_usage": null,
                        "interval": "month",
                        "interval_count": 1,
                        "meter": null,
                        "trial_period_days": null,
                        "usage_type": "licensed"
                    },
                    "tax_behavior": "inclusive",
                    "tiers_mode": null,
                    "transform_quantity": null,
                    "type": "recurring",
                    "unit_amount": 500,
                    "unit_amount_decimal": "500"
                },
                "quantity": 1,
                "subscription": "sub_1NsWQpFBpKaruH1EEuLQFjEE",
                "tax_rates": []
            }
        ],
        "has_more": false,
        "total_count": 1,
        "url": "/v1/subscription_items?subscription=sub_1NsWQpFBpKaruH1EEuLQFjEE"
    },
    "latest_invoice": "in_1PTr1cFBpKaruH1ETZR0716h",
    "livemode": true,
    "metadata": {},
    "next_pending_invoice_item_invoice": null,
    "on_behalf_of": null,
    "pause_collection": null,
    "payment_settings": {
        "payment_method_options": null,
        "payment_method_types": null,
        "save_default_payment_method": "off"
    },
    "pending_invoice_item_interval": null,
    "pending_setup_intent": null,
    "pending_update": null,
    "plan": {
        "id": "price_1NawCFFBpKaruH1EOudMlasR",
        "object": "plan",
        "active": true,
        "aggregate_usage": null,
        "amount": 500,
        "amount_decimal": "500",
        "billing_scheme": "per_unit",
        "created": 1691048627,
        "currency": "usd",
        "interval": "month",
        "interval_count": 1,
        "livemode": true,
        "metadata": {},
        "meter": null,
        "nickname": null,
        "product": "prod_ONhbOnIEF6AXUJ",
        "tiers_mode": null,
        "transform_usage": null,
        "trial_period_days": null,
        "usage_type": "licensed"
    },
    "quantity": 1,
    "schedule": null,
    "start_date": 1695239491,
    "status": "active",
    "test_clock": null,
    "transfer_data": null,
    "trial_end": null,
    "trial_settings": {
        "end_behavior": {
            "missing_payment_method": "create_invoice"
        }
    },
    "trial_start": null
}

*/