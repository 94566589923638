import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import useIsMobile from '../../utils/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { TwitchChat } from 'react-twitch-embed';
import useBotUptime from '../../utils/useBotUptime';
import useBotJobs from '../../utils/useBotJobs';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import useSubscriptionInfo from '../../utils/useSubscriptionInfo';
import useAIPresets from '../../utils/useAIPresets';

const Dashboard = ({ openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, refresh = () => {} }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const botUptime = useBotUptime();
    const { presets, updatePresets } = useAIPresets();
    const { botJobs, setBotJobs, postJob, clearJobs } = useBotJobs();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedSimulations, setSelectedSimulations] = useState({label: 'Triggers'});
    const [selectedPersonality, setSelectedPersonality] = useState({label: userSettings['openAISettings']['personality']});
    const subscriptionInfo = useSubscriptionInfo();
    console.log(subscriptionInfo);

    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        botOnlineStatus: {
            backgroundColor: Object.keys(botUptime).length > 0 ? 'rgba(0, 125, 0, 0.25)' : 'rgba(125, 0, 0, 0.25)',
            borderRadius: '8px',
            border: '2px solid rgba(0, 0, 0, 0.25)',
            paddingLeft: 4, 
            paddingRight: 4, 
            paddingBottom: 4
        },
        borderBackground: { 
            position: 'relative', 
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            padding: 4, 
            borderRadius: 4, 
            border: '2px solid rgba(0, 0, 0, 0.125)' 
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getPercentageProgress = (value, max) => {
        const factor = parseFloat(value / max);
        const percentage = parseInt(factor * 100);
        if (percentage < 0) return 0;
        if (percentage > 100) return 100;
        return percentage;
    };

    const getFullJobsLength = () => {
        let total = 0;
        for (let i = 0; i < botJobs.length; i++) {
            total = total + botJobs[i]['length'];
        }
        return total;
    };

    const secondsToHMS = (seconds) => {
        const pad = (num) => String(num).padStart(2, '0');
    
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
    
        return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    };

    const getJobsStatus = () => {
        try {
            if (botJobs.length === 0) {
                if (Object.keys(botUptime).length > 0) {
                    return 'Running in live mode';
                }
            } else if (botJobs[0] && 'running' in botJobs[0] && botJobs[0]['running']) {
                return `Running as job in ${botJobs[0]['type']} mode`;
            }
            return '';
        } catch (error) {
            return '';
        }
    };

    const handleButtonClick = (action) => {
        setIsButtonDisabled(true);
        action();
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 5000);
    };

    const postSimulation = async (simObj) => {
        try {
            const response = await fetch(`${apiUrl}/user/simulations?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(simObj, null, 4)
            });
        } catch (error) {
            console.log(error);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp*1000);
        if (isNaN(date.getTime())) {
            throw new Error("Invalid timestamp");
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const cancelSubscription = async () => {
        try {
            const statusResponse = await fetch(`${apiUrl}/user/subscription/status?token=${authCtx.token}`);
            const statusRes = await statusResponse.json();
            const subId = statusRes['subscription'];
            const cancelResponse = await fetch(`${apiUrl}/user/cancelsubscription/${subId}?token=${authCtx.token}`);
            const cancelRes = await cancelResponse.json();
            if (cancelResponse.ok) {
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    };
    const resumeSubscription = async () => {
        try {
            const statusResponse = await fetch(`${apiUrl}/user/subscription/status?token=${authCtx.token}`);
            const statusRes = await statusResponse.json();
            const subId = statusRes['subscription'];
            const resumeResponse = await fetch(`${apiUrl}/user/resumesubscription/${subId}?token=${authCtx.token}`);
            const resumeRes = await resumeResponse.json();
            if (resumeResponse.ok) {
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const cancelHandler = () => {
        confirmDialog({
            message: `Do you want to cancel your RatbotTTV subscription? It will stay active until ${formatDate(subscriptionInfo['current_period_end'])}.`,
            header: 'Cancel RatbotTTV Subscription',
            icon: 'pi pi-exclamation-triangle',
            accept: cancelSubscription,
            reject: () => {}
        });
    };
    const resumeHandler = () => {
        confirmDialog({
            message: `Do you want to resume your RatbotTTV subscription? Please note that this can take a few minutes to take effect.`,
            header: 'Resume RatbotTTV Subscription',
            icon: 'pi pi-exclamation-circle',
            accept: resumeSubscription,
            reject: () => {}
        });
    };

    const personalityChangeHandler = (pObj) => {
        setSelectedPersonality(pObj);
        update({openAISettings: {...userSettings['openAISettings'], personality: pObj.label}});
    };

    return (
        <React.Fragment>
            <ConfirmDialog />
            <Grid container sx={{ height: isMobile ? '100%' : '75vh', maxHeight: isMobile && '80vh', overflowY: isMobile && 'auto' }}>
                <Grid item xs={isMobile ? 12 : 4}> {/* PROFILE, SUBSCRIPTION, JOBS AND QUICK ACTIONS */}
                    <Grid container>
                        <Grid item xs={isMobile ? 12 : 5}> {/* PROFILE AND SUBSCRIPTION */}
                            <Grid container sx={{height: isMobile ? '100%' : '75vh'}}>
                                <Grid item xs={12} sx={{...styles.borderBackground, marginRight: isMobile && '20px'}}>
                                    <img alt="logo" src={authCtx.profile_url} style={{ 
                                            width: isMobile ? 'auto' : '100%',
                                            height: isMobile ? '150px' : 'auto',
                                            borderRadius: 128, 
                                            border: '2px solid rgba(0, 0, 0, 0.125)',
                                            display: isMobile && 'block',
                                            marginLeft: isMobile && 'auto',
                                            marginRight: isMobile && 'auto'
                                        }}
                                    />
                                    <div style={{ position: 'relative', top: '0%', left: '0%', color: 'white', textAlign: 'center' }}>
                                        <h2 style={{ margin: 0 }}>{authCtx.username}</h2>
                                        <h3 style={{ margin: 0 }}>{capitalizeFirstLetter(authCtx.broadcaster_type)}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sx={{...styles.borderBackground, marginTop: isMobile ? 2 : 4, marginRight: isMobile && '20px'}}>
                                    {Object.keys(subscriptionInfo).length > 0 ? (
                                        <React.Fragment>
                                            {('type' in subscriptionInfo === false) ? (
                                                <Box>
                                                    <Typography variant='h3'>Subscription</Typography>
                                                    <Typography sx={{marginTop: 2}}>{`${capitalizeFirstLetter(subscriptionInfo['status'])} ${subscriptionInfo['plan']['amount']/100} ${subscriptionInfo['plan']['currency'].toUpperCase()}/${subscriptionInfo['plan']['interval']}`}</Typography>
                                                    {subscriptionInfo['pause_collection'] !== null && <Typography><i>Scheduled to pause</i></Typography>}
                                                    {subscriptionInfo['status'] === 'active' ? (
                                                        <React.Fragment>
                                                            <Typography sx={{marginTop: 1}}>{`Last billing: ${formatDate(subscriptionInfo['current_period_start'])}`}</Typography>
                                                            {subscriptionInfo['pause_collection'] === null ? (
                                                                <React.Fragment>
                                                                    <Typography>{`Next billing: ${formatDate(subscriptionInfo['current_period_end'])}`}</Typography>
                                                                    <Button style={{marginTop: 32, width: '100%'}} severity='danger' size='small' label='Pause' onClick={cancelHandler} />
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <Typography>{`Active until: ${formatDate(subscriptionInfo['current_period_end'])}`}</Typography>
                                                                    <Button style={{marginTop: 32, width: '100%'}} severity='info' size='small' label='Resume' onClick={resumeHandler} />
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Button style={{marginTop: 32, width: '100%'}} severity='success' size='small' label='Subscribe' onClick={() => window.open('https://buy.stripe.com/4gweXSboy6AIctq4gg')} />
                                                        </React.Fragment>
                                                    )}
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <Typography variant='h3'>Voucher Code</Typography>
                                                    <Typography sx={{marginTop: 2}}>{capitalizeFirstLetter(subscriptionInfo['status'])}</Typography>
                                                    <React.Fragment>
                                                        <Typography sx={{marginTop: 1}}>{`Expires on: ${subscriptionInfo['expirationDate'].split('T')[0]}`}</Typography>
                                                        <p>You are currently subscribed to Ratbot with the creator code program!</p>
                                                    </React.Fragment>
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <Box>
                                            <Typography variant='h3'>Subscription</Typography>
                                            <Typography sx={{marginTop: 2}}>You currently do not subscribe to Ratbot.</Typography>
                                            <Typography sx={{marginTop: 2}}>Why not give it a try? The first month is free!</Typography>
                                            <Typography sx={{marginTop: 2}}>Click button below to subscribe securely via Stripe.</Typography>
                                            <Button style={{marginTop: 32, width: '100%'}} severity='success' size='small' label='Subscribe' onClick={() => window.open('https://buy.stripe.com/4gweXSboy6AIctq4gg')} />
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isMobile && <Grid item xs={1} />} {/* SPACING */}
                        <Grid item xs={isMobile ? 12 : 6}> {/* JOBS AND QUICK ACTIONS */}
                            <Grid container sx={{height: isMobile ? '100%' : '75vh', marginTop: isMobile && 2}}>
                                <Grid item xs={12} sx={{...styles.borderBackground, marginRight: isMobile && '20px'}}> {/* ONLINE STATUS */}
                                    <Box sx={styles.botOnlineStatus}>
                                        <h1 style={{ textAlign: 'center' }}>{Object.keys(botUptime).length > 0 ? 'Online' : 'Offline'}</h1>
                                        <h3 style={{ textAlign: 'center' }}>{botJobs ? getJobsStatus() : ''}</h3>
                                        {botJobs && (<ProgressBar value={Object.keys(botUptime).length > 0 ? getPercentageProgress(botUptime['uptime'], getFullJobsLength()) : 0} displayValueTemplate={(value) => (<></>)} />)}
                                    </Box>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Button
                                                severity={(botJobs && botJobs.length > 0) ? 'info' : 'success'}
                                                style={{ marginTop: '16px', width: '100%' }}
                                                label={(botJobs && botJobs.length > 0) ? 'Extend 60m' : 'Start 60m'}
                                                size='small'
                                                icon={(botJobs && botJobs.length > 0) ? 'pi pi-plus' : 'pi pi-play'}
                                                onClick={() => handleButtonClick(postJob)}
                                                disabled={subscriptionInfo['status'] !== 'active' || (isButtonDisabled || (Object.keys(botUptime).length > 0 && !(botJobs[0] && 'running' in botJobs[0] && botJobs[0]['running'])))}
                                            />
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={3}>
                                            <Button
                                                severity='danger'
                                                style={{ marginTop: '16px', width: '100%' }}
                                                size='small'
                                                icon='pi pi-times'
                                                onClick={() => handleButtonClick(clearJobs)}
                                                disabled={isButtonDisabled || (botJobs && botJobs.length === 0)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{...styles.borderBackground, marginTop: isMobile ? 2 : 4, marginRight: isMobile && '20px'}}>
                                    <Typography variant='h3'>Personality</Typography>
                                    {Object.keys(presets).length > 0 && (<Dropdown value={selectedPersonality} style={{width: '100%', backgroundColor: colorScheme.background25, marginTop: 16}} onChange={(e) => personalityChangeHandler(e.value)}
                                        options={Object.keys(presets).map((p) => ({label: p}))} optionLabel='label' placeholder={selectedPersonality} />)}
                                    {Object.keys(presets).length > 0 && (<Typography variant='body2' sx={{marginTop: 2}}>{presets[selectedPersonality['label']]['description']}</Typography>)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}> {/* SIMULATIONS */}
                    <Grid container sx={{height: !isMobile && '75vh', marginTop: isMobile && 4}}>
                        {!isMobile && <Grid item xs={1} />}
                        <Grid item xs={isMobile ? 12 : 11} sx={{...styles.borderBackground, paddingBottom: 2, marginRight: isMobile && '20px'}}>
                            <Grid container>
                                <Grid item xs={12} sx={{marginBottom: 2}}>
                                    <Dropdown value={selectedSimulations} onChange={(e) => setSelectedSimulations(e.value)} options={[{label: 'Triggers'}, {label: 'Macros'}]} 
                                        optionLabel="label" placeholder={selectedSimulations} style={{width: '100%', backgroundColor: colorScheme.background25}} />
                                </Grid>
                                <Box sx={{width: '100%', maxHeight: isMobile ? '50vh' : '60vh', overflowY: 'auto'}}>
                                    {selectedSimulations['label'] === 'Triggers' ? userSettings['triggers'].map((trigger, index) => (
                                        <Grid key={trigger['id']} item xs={12} sx={{ backgroundColor: colorScheme.background25, padding: 2, marginBottom: 2, width: '100%', borderRadius: 2 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <h3>{trigger['description']}</h3>
                                                    <h4>{trigger['notes']}</h4>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button size='small' label='Run Trigger' icon='pi pi-play' style={{width: '100%', marginTop: 16}}
                                                        onClick={() => handleButtonClick(() => postSimulation(trigger))}
                                                        disabled={isButtonDisabled}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )): selectedSimulations['label'] === 'Macros' && Object.keys(userSettings['macros']).map((macroName, index) => (
                                        <Grid key={index} item xs={12} sx={{ backgroundColor: colorScheme.background25, padding: 2, marginBottom: 2, marginRight: 2 }}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <h3>{macroName}</h3>
                                                    <h4>{`${userSettings['macros'][macroName]['actions'].length} actions`}</h4>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button size='small' label='Run Trigger' icon='pi pi-play' style={{width: '100%', marginTop: 16}}
                                                        onClick={() => handleButtonClick(() => postSimulation(
                                                            {
                                                                'actions': userSettings['macros'][macroName]['actions'],
                                                                'notes': macroName
                                                            }
                                                        ))}
                                                        disabled={isButtonDisabled}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4} sx={!isMobile ? {paddingLeft: 4, paddingRight: 4} : {paddingRight: '20px', marginTop: 4}}> {/* CHAT WINDOW */}
                    <div style={!isMobile ? {height: '100%', width: '100%'} : {height: '50vh'}}>
                        <TwitchChat channel={authCtx.username} width="100%" height="100%" />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Dashboard;