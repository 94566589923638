import { useState } from 'react';

function useSystemCommands() {
    const [systemCommands, setSystemCommands] = useState({
        "Get Clip": {
            "command": "getclip",
            "description": "Gets a random clip from a channel (!getclip RatbotTTV).",
            "admin": false
        },
        "Set Personality": {
            "command": "personality",
            "description": "Sets a new personality preset (!personality Uplifting).",
            "admin": true
        },
        "Trivia": {
            "command": "trivia",
            "description": "Starts a short trivia game in the chat.",
            "admin": false
        },
        "Score": {
            "command": "score",
            "description": "Gets the user's stats for a specified chat game (!score trivia).",
            "admin": false
        },
        "Reset": {
            "command": "reset",
            "description": "Deletes the user's conversation history with Ratbot.",
            "admin": false
        },
        "Alias": {
            "command": "alias",
            "description": "Posts a list of the aliases Ratbot will respond to.",
            "admin": false
        },
        "Story": {
            "command": "story",
            "description": "When used alone, Ratbot provides twist-ending stories (!story). When given text, Ratbot creates short stories using it (!story The fox and the grapes).",
            "admin": true
        },
        "Chat Message Length": {
            "command": "shortreplies",
            "description": "Sets whether Ratbot should attempt to use shorter or longer chat messages (!shortreplies true/false).",
            "admin": true
        },
        "Ignore User": {
            "command": "ignore",
            "description": "Adds a user to Ratbot's ignore list.",
            "admin": true
        },
        "Remove Ignore": {
            "command": "unignore",
            "description": "Removes a user from Ratbot's ignore list.",
            "admin": true
        },
        "Skip to Next Song": {
            "command": "skip",
            "description": "Skips to next song in the Spotify queue, Spotify will auto-start the next song if paused.",
            "admin": true
        },
        "Spotify Volume": {
            "command": "volume",
            "description": "Sets a new volume 0-100 for Spotify (!volume 50).",
            "admin": true
        },
        "Restart Ratbot": {
            "command": "restartratbot",
            "description": "Restarts your Ratbot instance on the server for quickly resolving issues, please report any problems you have that requires this.",
            "admin": true
        }
    });

  return [systemCommands, setSystemCommands];
}

export default useSystemCommands;