import React, { useState, useEffect, useContext, useCallback } from 'react';
import { debounce } from 'lodash';
import DialogComponent from '../layouts/DialogComponent';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Grid } from '@mui/material';
import useIsMobile from '../../utils/useIsMobile';
import { ColorModeContext } from '../../theme';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { useUser } from '../../context/UserContext';

const Editors = ({ backgroundColor = '#5D3B9E', editorsList = [], updateEditors = () => {} }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const { theme } = useContext(ColorModeContext);
    const [selectedEditor, setSelectedEditor] = useState('');
    const [tableData, setTableData] = useState([]);
    const [search, setSearch] = useState(''); // New state for managing input
    const [searchResults, setSearchResults] = useState([]); // New state for managing search results
    const isMobile = useIsMobile();
    const [editors, setEditors] = useState(editorsList);
    const [loading, setLoading] = useState(false);
    const availablePermissions = {
        "read:personalities": false,
        "write:personalities": false,
        "read:commands": false,
        "write:commands": false,
        "read:triggers": false,
        "write:triggers": false,
        "read:spotify": false,
        "write:spotify": false,
        "read:blockedusers": false,
        "write:blockedusers": false,
        "read:aliases": false,
        "write:aliases": false,
        "read:customprompt": false,
        "write:customprompt": false,
        "read:emotefrequency": false,
        "write:emotefrequency": false,
        "read:twitch": false,
        "write:twitch": false
    };

    const fetchTwitchInfo = async (broadcasterid = '', login = '') => {
        const url = broadcasterid !== '' ? `${apiUrl}/user/twitchuser?token=${authCtx.token}&broadcasterid=${broadcasterid}` : 
            `${apiUrl}/user/twitchuser?token=${authCtx.token}&login=${login}`;
        const response = await fetch(url);
        if (response.ok) {
            const res = await response.json();
            return res;
        } else {
            return {
                "id":"",
                "login":"",
                "display_name":"User Not Found",
                "type":"",
                "broadcaster_type":"",
                "description":"",
                "profile_image_url":"https://via.placeholder.com/150",
                "offline_image_url":"https://via.placeholder.com/150",
                "view_count":0,
                "created_at":"2013-05-12T19:53:01Z"
            };
        }
    };
    const debouncedFetchTwitchInfo = useCallback(debounce(async (query) => {
        const result = await fetchTwitchInfo('', query);
        if (result.id !== '') {
            setSearchResults([result]);
        } else {
            setSearchResults([]);
        }
    }, 1000), []);

    useEffect(() => {
        const updateEditorsWithMissingPermissions = () => {
            const updatedEditors = editors.map(editor => {
                const updatedPermissions = { ...editor.permissions };
                Object.keys(availablePermissions).forEach(permission => {
                    if (updatedPermissions[permission] === undefined) {
                        updatedPermissions[permission] = false;
                    }
                });
                return { ...editor, permissions: updatedPermissions };
            });
            return updatedEditors;
        };
        const editorsWithAllPermissions = updateEditorsWithMissingPermissions();
        setEditors(editorsWithAllPermissions);
        prepareDataTable();
    }, []);
    useEffect(() => {
        updateEditors(editors);
        prepareDataTable();
    }, [editors]);
    useEffect(() => {
        if (search.trim()) {
            debouncedFetchTwitchInfo(search);
        } else {
            setSearchResults([]);
        }
        return () => {
            debouncedFetchTwitchInfo.cancel();
        };
    }, [search, debouncedFetchTwitchInfo]);

    const prepareDataTable = async () => {
        const tmpArr = [];
        setLoading(true);
        for (const eObj of editors) {
            const tmpObj = {};
            const twitchInfo = await fetchTwitchInfo(eObj['id']);
            tmpObj['id'] = eObj['id'];
            tmpObj['username'] = twitchInfo['display_name'];
            tmpObj['image'] = twitchInfo['profile_image_url'];
            tmpObj['type'] = twitchInfo['broadcaster_type'];
            tmpObj['created_at'] = twitchInfo['created_at'];
            tmpArr.push(tmpObj);
        };
        setLoading(false);
        setTableData(tmpArr);
    };
    const imageBodyTemplate = (rowData) => {
        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <img src={rowData.image} width={50} style={{ borderRadius: '5px' }} />
            </div>
        );
    };
    const rowClassName = (data) => {
        return {
            'p-highlight': selectedEditor === data.id
        };
    };
    const getPermissionData = (id) => {
        const tmpArr = [];
        for (const eObj of editors) {
            // Find correct editor matching id
            if (id === eObj['id']) {
                // Loop through each key in permissions and add columns into array
                for (let i in Object.keys(eObj['permissions'])) {
                    const tmpObj = {};
                    const key = Object.keys(eObj['permissions'])[i];
                    tmpObj['action'] = key.split(':')[0];
                    tmpObj['permission'] = key.split(':')[1];
                    tmpObj['value'] = eObj['permissions'][key];
                    tmpArr.push(tmpObj);
                }
            }
        };
        return tmpArr;
    };
    const getUsername = (id) => {
        for (let editorIndex in tableData) {
            if (selectedEditor === tableData[editorIndex]['id']) {
                return tableData[editorIndex]['username'];
            }
        };
    };
    const settingTemplate = (permission) => {
        const changeHandler = (v) => {
            const newValue = v.toLowerCase() === 'true';
            const _editors = [...editors];
            for (let editorIndex in _editors) {
                if (selectedEditor === _editors[editorIndex]['id']) {
                    const permissions = _editors[editorIndex]['permissions'];
                    permissions[`${permission['action']}:${permission['permission']}`] = newValue;
                    if (permission['action'] === 'write' && newValue === true) {
                        const readPermissionKey = `read:${permission['permission']}`;
                        if (permissions.hasOwnProperty(readPermissionKey)) {
                            permissions[readPermissionKey] = true;
                        }
                    } else if (permission['action'] === 'read' && newValue === false) {
                        const readPermissionKey = `write:${permission['permission']}`;
                        if (permissions.hasOwnProperty(readPermissionKey)) {
                            permissions[readPermissionKey] = false;
                        }
                    }
                }
            }
            setEditors(_editors);
        };

        return (
            <Dropdown value={permission['value'].toString()} onChange={(e) => changeHandler(e.value)} 
                      options={[{'value': 'true'}, {'value': 'false'}]}
                      optionLabel='value' style={{width: '100%'}} />
        );
    };
    const addEditor = async (editor) => {
        setSearch('');
        const _editors = [...editors];
        const tmpObj = {
            id: editor.id,
            permissions: {...availablePermissions}
        };
        _editors.push(tmpObj);
        setEditors(_editors);
        const tableDataArr = [...tableData];
        const tableTmpObj = {};
        tableTmpObj['id'] = editor['id'];
        tableTmpObj['username'] = editor['display_name'];
        tableTmpObj['image'] = editor['profile_image_url'];
        tableTmpObj['type'] = editor['broadcaster_type'];
        tableTmpObj['created_at'] = editor['created_at'];
        tableDataArr.push(tableTmpObj);
        setTableData(tableDataArr);
    };
    const actionBodyTemplate = (editor) => {
        return <Button size='small' icon='pi pi-fw pi-times' severity='danger' onClick={() => setEditors(currentEditors => currentEditors.filter(e => e.id !== editor.id))} />
    };
    const setAllPermissionsForEditor = (value) => {
        if (selectedEditor === '') return;
        const updatedEditors = editors.map((editor) => {
            if (editor.id === selectedEditor) {
                const updatedPermissions = Object.keys(editor.permissions).reduce((acc, permission) => {
                    acc[permission] = value;
                    return acc;
                }, {});
                return { ...editor, permissions: updatedPermissions };
            }
            return editor;
        });
        setEditors(updatedEditors);
    };
    

    return (
        <DialogComponent footer='Editors are users who can make setting changes on your behalf' maxHeight='85vh' minWidth='65vw'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AutoComplete value={search} suggestions={searchResults} completeMethod={({ query }) => setSearch(query)}
                                field="display_name" dropdown forceSelection itemTemplate={(item) => (
                                    <div onClick={() => addEditor(item)} style={{display: 'flex', alignItems: 'center'}}>
                                        <img src={item.profile_image_url} alt={item.display_name} style={{width: '30px', marginRight: '8px', borderRadius: '50%'}} />
                                        {item.display_name}
                                    </div>
                                )} placeholder='Type to add editor' style={{width: '100%'}} />
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={() => setAllPermissionsForEditor(true)} label='Allow All' size='small' severity='success' style={{width: '100%'}} disabled={selectedEditor === ''} />
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={() => setAllPermissionsForEditor(false)} label='Deny All' size='small' severity='danger' style={{width: '100%'}} disabled={selectedEditor === ''} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6} sx={{height: isMobile ? '50vh' : '60vh'}}>
                    <DataTable value={tableData} size='small' showGridlines stripedRows paginator rows={25} rowsPerPageOptions={[5, 10, 15, 25, 50]}
                        selectionMode='single' selection={selectedEditor} onSelectionChange={(e) => setSelectedEditor(e.value.id)} loading={loading}
                        style={{backgroundColor: theme.palette.neutral.dark}} scrollable scrollHeight='50vh' rowClassName={rowClassName}>
                        <Column header="Profile" body={imageBodyTemplate} style={{width: '50px'}}></Column>
                        <Column field="username" header="Username"></Column>
                        <Column field="type" header="Type" style={{width: '100px'}}></Column>
                        <Column field="created_at" header="Created At"></Column>
                        <Column header="" body={actionBodyTemplate}></Column>
                    </DataTable>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6} sx={{marginTop: isMobile && '64px'}}>
                    {selectedEditor !== '' && (
                        <DataTable value={getPermissionData(selectedEditor)} size='small' stripedRows rows={25} rowsPerPageOptions={[5, 10, 15, 25, 50]} 
                            paginator sortMode='single' sortField='permission' sortOrder={1} scrollable scrollHeight='50vh' loading={loading}>
                            <Column field='permission' header={`Permission (${getUsername(selectedEditor)})`} sortable />
                            <Column field='action' header='Action' sortable style={{width: '200px'}} />
                            <Column header='Setting' sortable body={settingTemplate} style={{width: '100px'}} />
                        </DataTable>
                    )}
                </Grid>
              </Grid>
        </DialogComponent>
    );
};

export default Editors;