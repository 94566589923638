import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import React, { useContext } from 'react';
import useIsMobile from '../../utils/useIsMobile';
import { Collapse } from '@nextui-org/react';
import { ColorModeContext } from '../../theme';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const ReleaseNotes = ({ releaseNotes }) => {
  const isMobile = useIsMobile();
  const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
  const formatTimestamp = (timestamp) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(Number(timestamp)).toLocaleString('en-US', options);
  };
  const textStyle = {
    color: theme.palette.text.primary
  };

  const renderEntry = (entry) => {
    switch (entry['type']) {
        case 'summary':
            return (
                <Typography sx={{...textStyle, marginBottom: '8px'}} variant='h5'>{entry['content']}</Typography>
            );
        case 'list':
            return (
                <List sx={{marginBottom: '8px'}}>
                    {entry['content'].map(txt => (
                        <ListItem>
                            <ListItemIcon><RadioButtonUncheckedIcon sx={{width: '10px', height: '10px'}} /></ListItemIcon>
                            <Typography variant='h4' sx={textStyle}>{txt}</Typography>
                        </ListItem>
                    ))}
                </List>
            );
        case 'image':
            return (
                <img src={entry['url']} alt={entry['altText']} width={entry['width']} height={entry['height']} />
            )
    
        default:
            return (
                <Typography sx={textStyle}>{JSON.stringify(entry)}</Typography>
            );
    }
  };

  return (
    <Collapse.Group>
        {releaseNotes.map(entry => (
            <Collapse key={entry['id']} title={<Typography sx={textStyle} variant='h4'>{`${formatTimestamp(entry['timestamp'])}`}</Typography>}>
                <Typography sx={{...textStyle, marginBottom: '8px'}} variant='h3'>{entry['title']}</Typography>
                {entry['entries'].map(entryObj => (
                    <React.Fragment>
                        {
                            renderEntry(entryObj)
                        }
                    </React.Fragment>
                ))}
            </Collapse>
        ))}
    </Collapse.Group>
  );
};


export default ReleaseNotes;
