import { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';

const apiUrl = process.env.REACT_APP_API_URL;

const useBotUptime = () => {
    const authCtx = useUser();
    const [botUptime, setBotUptime] = useState({});

    useEffect(() => {
        const fetchStreamersData = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/botuptime?token=${authCtx.token}`);
                if (response.status === 200) {
                    const res = await response.json();
                    setBotUptime(res);
                } else {
                    setBotUptime({});
                }
            } catch (e) {
                console.log(e);
            }
        };

        // Fetch data immediately on mount
        fetchStreamersData();

        // Set interval to fetch data every 5 seconds
        const intervalId = setInterval(fetchStreamersData, 5000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [authCtx.token]);

    return botUptime;
};

export default useBotUptime;