import { Box } from '@mui/material';
import React from 'react';
import './PlaybackPanel.css';

const AlbumInfo = ({ album = {images: [{url: ''}]} }) => (
    <Box className="image-container" sx={{ flexShrink: 0 }}>
        <img src={Object.keys(album).length > 0 ? album.images[0]?.url : 'https://i.imgur.com/uijqYCJ.png'} 
            alt="Album cover" className="image-responsive" style={{ width: '100px', height: 'auto' }} />
        <Box className="album-overlay">Album</Box>
    </Box>
);

export default AlbumInfo;