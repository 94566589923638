import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext';

export default function AuthSuccess() {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const authCtx = useUser();

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const username = queryParams.get('username');
      const profile_url = queryParams.get('profile_url');
      const broadcaster_type = queryParams.get('broadcaster_type');
      const editor = queryParams.get('editor') === 'true';

      const loginHandler = async () => {
        await authCtx.login(token, username, profile_url, broadcaster_type, editor);
        console.log('AuthSuccess login state:', authCtx);
        if (authCtx.isLoggedIn) {
          window.location.reload();
        }
        setLoading(false);
      };

      loginHandler();
    }, [location.search, authCtx.login]);

    if (!loading) {
        if (authCtx.editor) {
            return <Navigate to='/editorsettings' />;
        } else {
            return <Navigate to='/settings?p=dashboard' />;
        }
    }

    return <p>Authentication successful! Please wait...</p>;
};