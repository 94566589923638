import React, { useState } from 'react';
import { Grid } from '@mui/material';
import DialogComponent from '../../../beta/layouts/DialogComponent';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import useIngredientTags from '../utils/useIngredientTags';
import { Button } from 'primereact/button';
import { useUser } from '../../../context/UserContext';

const CreatePerfumeIngredient = ({backgroundColor = '#5D3B9E', footer = '', setOpenDialog = () => {}, setIsBlur = () => {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const tags = useIngredientTags();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const [newIngredient, setNewIngredient] = useState(
        {
            title: '',
            description: '',
            image: '',
            note: '',
            tags: [],
            properties: {
              scentFamily: '',
              origin: '',
              extractionMethod: '',
              botanicalName: ''
            },
            blendCompatibility: {
              complements: [],
              avoids: []
            },
            safetyInfo: 'Safe to use in cosmetic applications.'
        }
    );
    const styles = {
        base: {
            width: '100%'
        }
    };

    const handleInputChange = (e) => {
        setNewIngredient({ ...newIngredient, [e.target.name]: e.target.value });
    };
    const handlePropertyChange = (name, value) => {
        setNewIngredient({ ...newIngredient, properties: { ...newIngredient.properties, [name]: value } });
    };
    const handleCompatibilityChange = (name, value) => {
        setNewIngredient({ ...newIngredient, blendCompatibility: { ...newIngredient.blendCompatibility, [name]: value } });
    };
    const areAllFieldsFilled = () => {
        if (!newIngredient.title || !newIngredient.description || !newIngredient.image || !newIngredient.safetyInfo) {
            return false;
        }
        if (!newIngredient.note || newIngredient.tags.length === 0) {
            return false;
        }
        const properties = newIngredient.properties;
        if (!properties.scentFamily || !properties.origin || !properties.extractionMethod || !properties.botanicalName) {
            return false;
        }
        return true;
    };
    const submitHandler = async () => {
        const url = `${apiUrl}/yellowraven/newperfumeingredient?token=${authCtx.token}`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newIngredient)
        };
        const response = await fetch(url, options);
        const res = await response.json();
        if (response.ok) {
            setOpenDialog(false);
            setIsBlur(false);
        }
    };

    const noteOptions = ['Top', 'Middle', 'Base'];
    const oilOptions = [{label: 'Bergamot', value: 'Bergamot'}, {label: 'Cedarwood', value: 'Cedarwood'}, /* ... other oils */];
    const extractionMethodOptions = ['Steam Distillation', 'Cold Press', 'CO2 Extraction', 'Solvent Extraction'];
    const scentFamilyOptions = ['Floral', 'Citrus', 'Woody', 'Spicy', 'Herbal', 'Fruity', 'Gourmand', 'Fresh', 'Earthy', 'Medicinal', 'Resinous'];

    return (
        <DialogComponent footer={footer} maxHeight='60vh' minWidth='500px'>
            <Grid container style={{ height: '100%', marginTop: '8px', paddingLeft: '16px' }} spacing={2}>
                <Grid item xs={12}>
                    <InputText style={{...styles.base}} value={newIngredient.title} onChange={handleInputChange} placeholder="Title" name="title" />
                </Grid>
                <Grid item xs={12}>
                    <InputText style={{...styles.base}} value={newIngredient.description} onChange={handleInputChange} placeholder="Description" name="description" />
                </Grid>
                <Grid item xs={12}>
                    <InputText style={{...styles.base}} value={newIngredient.image} onChange={handleInputChange} placeholder="Image URL" name="image" />
                </Grid>
                <Grid item xs={12}>
                    <Dropdown style={{...styles.base}} value={newIngredient.note} options={noteOptions} onChange={(e) => handleInputChange({ target: { name: 'note', value: e.value } })} 
                        placeholder="Select a Note" name="note" />
                </Grid>
                <Grid item xs={12}>
                    <MultiSelect style={{...styles.base}} value={newIngredient.tags} options={tags} onChange={(e) => handleInputChange({ target: { name: 'tags', value: e.value } })}
                        placeholder="Select Tags" name="tags" />
                </Grid>
                <Grid item xs={12}>
                    <Dropdown style={styles.base} value={newIngredient.properties.scentFamily} options={scentFamilyOptions} 
                        onChange={(e) => handlePropertyChange('scentFamily', e.value)} placeholder="Select Scent Family" />
                </Grid>
                <Grid item xs={12}>
                    <InputText style={styles.base} value={newIngredient.properties.origin} onChange={(e) => handlePropertyChange('origin', e.target.value)} 
                        placeholder="Origin" />
                </Grid>
                <Grid item xs={12}>
                    <Dropdown style={styles.base} value={newIngredient.properties.extractionMethod} options={extractionMethodOptions} 
                        onChange={(e) => handlePropertyChange('extractionMethod', e.value)} placeholder="Select Extraction Method" />
                </Grid>
                <Grid item xs={12}>
                    <InputText style={styles.base} value={newIngredient.properties.botanicalName} onChange={(e) => handlePropertyChange('botanicalName', e.target.value)} 
                        placeholder="Botanical Name" />
                </Grid>

                <Grid item xs={12}>
                    <MultiSelect style={styles.base} value={newIngredient.blendCompatibility.complements} options={oilOptions} 
                        onChange={(e) => handleCompatibilityChange('complements', e.value)} placeholder="Select Complementary Oils" />
                </Grid>
                <Grid item xs={12}>
                    <MultiSelect style={styles.base} value={newIngredient.blendCompatibility.avoids} options={oilOptions} 
                        onChange={(e) => handleCompatibilityChange('avoids', e.value)} placeholder="Select Oils to Avoid" />
                </Grid>
                <Grid item xs={12}>
                    <InputTextarea style={styles.base} value={newIngredient.safetyInfo} onChange={(e) => handleInputChange({ target: { name: 'safetyInfo', value: e.target.value } })} 
                        rows={3} placeholder="Safety Information" autoResize />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!areAllFieldsFilled()} size='small' label='Submit' severity='success' onClick={submitHandler} />
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default CreatePerfumeIngredient;