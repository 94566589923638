import React from 'react';
import { Menu } from 'primereact/menu';
import { PrimeIcons } from 'primereact/api';
import { Avatar } from 'primereact/avatar';
import { Grid, Typography } from '@mui/material';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Card } from 'primereact/card';
import { Badge } from 'primereact/badge';
import { useNavigate } from 'react-router-dom';

const SidePanel = ({ mainPage = 'Home', setMainPage = () => {} }) => {
    const navigate = useNavigate();

    const itemRenderer = (item) => (
        <div className='p-menuitem-content' style={{backgroundColor: item.label === mainPage ? 'rgba(255, 255, 255, 0.1)' : 'transparent'}}>
            <a className='flex align-items-center p-menuitem-link' onClick={item.command} style={{ textAlign: 'left' }}>
                <span className={item.icon} style={{ marginRight: '8px' }} />
                <Typography variant='h4' sx={{ marginLeft: '8px' }}>{item.label}</Typography>
                {item.badge && <Badge className="ml-auto" value={item.badge} />}
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </a>
        </div>
    );

    const items = [
        { label: 'Home', icon: PrimeIcons.HOME, command: () => { setMainPage('Home'); navigate('/spotifyplayer/home'); }, template: itemRenderer },
        { label: 'Playlists', icon: PrimeIcons.LIST, command: () => { setMainPage('Playlists'); navigate('/spotifyplayer/playlists'); }, template: itemRenderer },
        { label: 'Feed', icon: PrimeIcons.BELL, command: () => { setMainPage('Feed'); navigate('/spotifyplayer/feed'); }, template: itemRenderer },
        { label: 'Favorites', icon: PrimeIcons.HEART, command: () => { setMainPage('Favorites'); navigate('/spotifyplayer/favorites'); }, template: itemRenderer },
        { label: 'History', icon: PrimeIcons.BOOK, command: () => { setMainPage('History'); navigate('/spotifyplayer/history'); }, template: itemRenderer }
    ];

    return (
        <React.Fragment>
            <Splitter layout='vertical' gutterSize="0">
                <SplitterPanel size={92} minSize={90}>
                    <Menu model={items} style={{ width: '100%', height: '100%' }} />
                </SplitterPanel>
                <SplitterPanel size={8}>
                    <Card style={{ width: '100%', height: '100%' }}>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={4}>
                                <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" className="mr-2" shape="circle" size="large"
                                    style={{ display: 'block', margin: 'auto' }} />
                            </Grid>
                            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <p>Jane Doe</p>
                            </Grid>
                        </Grid>
                    </Card>
                </SplitterPanel>
            </Splitter>
        </React.Fragment>
    );
};

export default SidePanel;
