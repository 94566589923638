import React, { useEffect, useRef, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { ColorPicker } from 'primereact/colorpicker';
import { Button, Grid, Typography } from '@mui/material';
import { useUser } from '../../context/UserContext';

const CreateSpotifyRewardDialog = ({backgroundColor = '#5D3B9E', footer = ''}) => {
    const [newReward, setNewReward] = useState({
        title: 'Spotify Song Request',
        cost: 1000,
        prompt: 'Adds a song to the Spotify queue. Spotify link or search text can be used.',
        background_color: backgroundColor,
        rewardName: 'spotifySongRequest'
    });
    const [isError, setIsError] = useState(false);
    const sliderRef = useRef(null);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();

    const createSpotifyChannelPointReward = async () => {
        try {
            const response = await fetch(`${apiUrl}/user/createcustomreward?token=${authCtx.token}&type=spotify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newReward)
            });
            if (response.ok) {
                window.location.reload();
            } else {
                setIsError(true);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (sliderRef.current) {
          const sliderElement = sliderRef.current;
          const sliderBar = sliderElement.querySelector('.p-slider');
          const sliderHandle = sliderElement.querySelector('.p-slider-handle');
          if (sliderBar) {
            sliderBar.style.background = backgroundColor;
          }
          if (sliderHandle) {
            sliderHandle.style.background = backgroundColor;
          }
        }
    }, []);

  return (
    <DialogComponent footer={footer} maxHeight='75vh'>
        <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '32px'}} spacing={2}>
            <Typography variant='h5'>Title of reward</Typography>
            <InputText style={{width: '100%', backgroundColor: colorScheme.background50, marginTop: '8px'}} value={newReward['title']}
                onChange={(e) => setNewReward((prev) => ({...prev, title: e.target.value}))} />
            <Typography variant='h5' sx={{marginTop: '16px'}}>{`Channel point cost (${newReward['cost']})`}</Typography>
            <div style={{width: '100%', marginTop: '16px'}}>
                <div ref={sliderRef}>
                    <Slider value={newReward['cost']} onChange={(e) => setNewReward((prev) => ({...prev, cost: e.value}))} step={100} min={100} max={10000} />
                </div>
            </div>
            <Typography variant='h5' sx={{marginTop: '16px'}}>Viewer prompt when redeeming</Typography>
            <InputText style={{width: '100%', backgroundColor: colorScheme.background50, marginTop: '8px'}} value={newReward['prompt']}
                onChange={(e) => setNewReward((prev) => ({...prev, prompt: e.target.value}))} />
            <Typography variant='h5' sx={{marginTop: '16px'}}>Background color</Typography>
            <ColorPicker style={{width: '100%', marginTop: '8px'}} value={newReward['background_color']} 
                onChange={(e) => setNewReward((prev) => ({...prev, 'background_color': `#${e.value}`}))} inline />
            <Button sx={{marginTop: '32px'}} variant='contained' color='secondary' onClick={createSpotifyChannelPointReward}>Create Reward</Button>
        </Grid>
        {isError && (<Typography color='red' sx={{marginTop: '16px', marginLeft: '16px'}}>Error in creating reward - Please check that the title is unique</Typography>)}
    </DialogComponent>
  );
};

export default CreateSpotifyRewardDialog;