import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box, Grid, IconButton, Slider, Typography } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import useActionTemplates from '../../utils/useActionTemplates';
import useIsMobile from '../../utils/useIsMobile';
import { Divider } from 'primereact/divider';
import { Sidebar } from 'primereact/sidebar';
import './editActions.css';  // Import the CSS file
import useCopyArray from '../../utils/useCustomVariableCopyArray';
import useSystemVariables from '../../utils/useSystemVariables';
import { useUserSettings } from '../../store/usersettings-context';
import CustomSnackbar from '../../utils/customsnackbar';
import VariableCopyDropdown from '../../components/commands/VariableCopyDropdown';

const EditActions = ({ actionsArr = [], save = (newActionsArr = []) => {}, backgroundColor, textColor = '#e0e0e0' }) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [actionTemplates, setActionTemplates] = useActionTemplates();
    const [systemVariables, setSystemVariables] = useSystemVariables();
    const [snackAlert, setSnackAlert] = useState({ "open": false, "severity": "success", "text": "", "autoHideDuration": 5000 });
    const [visibleRight, setVisibleRight] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [localActionsArr, setLocalActionsArr] = useState(actionsArr);
    const isMobile = useIsMobile();
    const { getCopyArray } = useCopyArray();
    const sliderStyle = {
        '& .MuiSlider-thumb': { backgroundColor, opacity: 1 },
        '& .MuiSlider-rail': { backgroundColor, opacity: 1 },
        '& .MuiSlider-track': { backgroundColor, opacity: 1, border: 'none' }
    };
    const placeholderTextMap = {
        standard: 'Message',
        enhanced: 'Message',
        variable: 'Command',
        tts: 'Message'
    };
    const operatorsOptions = {
        "Equals": "=",
        "Is less than": "<",
        "Is greater than": ">",
        "Does not equal": "!",
        "Either true": "|",
        "Contains": "&",
        "Not contains": "^"
    };

    useEffect(() => {
        save(localActionsArr);
    }, [localActionsArr]);

    const MyInputText = ({ text = '', save = () => {}, placeholderText = 'Message' }) => {
        const [txtBuffer, setTxtBuffer] = useState(text);
        return (
            <InputText
                style={backgroundColor ? {width: '100%', height: '48px', backgroundColor} : {width: '100%', height: '48px'}}
                placeholder={placeholderText}
                value={txtBuffer}
                onChange={(e) => setTxtBuffer(e.target.value)}
                onBlur={() => save(txtBuffer)}
            />
        );
    };

    const MyAutoComplete = ({ text = '', save = () => {}, placeholderText = 'Command' }) => {
        const [txtBuffer, setTxtBuffer] = useState(text);
        const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    
        const searchSuggestions = (event) => {
            const query = event.query;
            const results = getAutocompleteData().filter((item) => item.toLowerCase().includes(query.toLowerCase()));
            setFilteredSuggestions(results);
        };
    
        const handleBlur = (e) => {
            setTimeout(() => {
                save(e.target.value);
            }, 250);
        };
    
        const handleSelect = (e) => {
            setTxtBuffer(e.value);
        };
    
        return (
            <AutoComplete
                style={{ width: '100%', height: '48px', backgroundColor }}
                placeholder={placeholderText}
                value={txtBuffer}
                suggestions={filteredSuggestions}
                completeMethod={searchSuggestions}
                onChange={(e) => setTxtBuffer(e.value)}
                onSelect={handleSelect}
                onBlur={handleBlur}
                dropdown
            />
        );
    };

    const addAction = () => {
        const actions = [...localActionsArr];
        actions.push({
            type: "Standard",
            message: ""
        });
        setLocalActionsArr([...actions]);
    };

    const moveElement = (array, index, direction) => {
        if (index < 0 || index >= array.length) return array;
        const newIndex = (direction === 'up' ? index - 1 : index + 1);
        if (newIndex < 0 || newIndex >= array.length) return array;
        [array[index], array[newIndex]] = [array[newIndex], array[index]];
        return array;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getAutocompleteData = () => {
        if ('customVariables' in userSettings) {
            const _returnArr = [];
            systemVariables.map((variable, index) => {
                _returnArr.push(variable.copy);
            });
            for (const variableKey of Object.keys(userSettings['customVariables'])) {
                const variable = userSettings['customVariables'][variableKey];
                const copyArray = getCopyArray(variableKey, variable['datatype'], variable['unique'] ?? false);
                _returnArr.push(...copyArray);
            }
            return _returnArr;
        } else {
            return [];
        }
    };

    const MySidebar = React.memo(() => {
        const styles = {
            actionsItem: {
                backgroundColor: 'rgba(0, 0, 0, 0.25)', 
                padding: 16,
                marginTop: 8, 
                marginBottom: 8, 
                marginLeft: 32,
                marginRight: 8,
                width: '100%',
                borderRadius: '8px',
                border: '2px solid rgba(0, 0, 0, 0.25)'
            }
        };

        const MySlider = ({ actionObj, index }) => {
            const [bufferValue, setBufferValue] = useState(actionObj['seconds']);

            return (
                <Slider
                    sx={{...sliderStyle, marginTop: '8px'}}
                    value={bufferValue}
                    min={1}
                    max={60}
                    step={1}
                    valueLabelDisplay='auto'
                    valueLabelFormat={bufferValue.toString()}
                    onChange={(e, v) => {setBufferValue(v)}}
                    onChangeCommitted={(e, v) => {
                        const actions = [...localActionsArr];
                        actions[selectedIndex]['actions'][index]['seconds'] = v;
                        setLocalActionsArr([...actions]);
                    }}
                />
            );
        };

        return (
            <Sidebar style={{ width: '30vw', backgroundColor }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <Grid container spacing={4}>
                    <Grid item xs={12} style={{ marginBottom: 32 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button label='Add Action' icon="pi pi-plus" style={{ width: '100%' }} onClick={() => {
                                    const actions = [...localActionsArr];
                                    actions[selectedIndex]['actions'] = [...actions[selectedIndex]['actions'], { type: 'Standard', message: '' }];
                                    setLocalActionsArr([...actions]);
                                }} />
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{float: 'right'}}>
                                    <VariableCopyDropdown userSettings={userSettings} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        {'actions' in localActionsArr[selectedIndex] && localActionsArr[selectedIndex]['actions'].map((actionObj, index) => (
                            <Grid item xs={12} style={styles.actionsItem} key={index}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Dropdown style={{ backgroundColor, width: '100%' }} options={Object.keys({...actionTemplates, Break: {description: "Stops running any further actions"}}).filter((a, i) => a !== 'Compare').map((a, i) => ({ label: a, value: a }))}
                                            value={capitalizeFirstLetter(actionObj['type'])} onChange={(e) => {
                                                const actions = [...localActionsArr];
                                                actions[selectedIndex]['actions'][index]['type'] = e.value;
                                                if (e.value.toLowerCase() === 'delay') {
                                                    delete actions[selectedIndex]['actions'][index]['message'];
                                                    actions[selectedIndex]['actions'][index]['seconds'] = actionTemplates['Delay']['seconds'];
                                                }
                                                setLocalActionsArr([...actions]);
                                            }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(actionObj['type'].toLowerCase() === 'enhanced' || actionObj['type'].toLowerCase() === 'standard' ||
                                            actionObj['type'].toLowerCase() === 'variable' || actionObj['type'].toLowerCase() === 'tts standard' ||
                                            actionObj['type'].toLowerCase() === 'tts enhanced') && (actionObj['type'].toLowerCase() === 'variable' ? (
                                                <MyAutoComplete
                                                    placeholderText={placeholderTextMap[actionObj['type'].toLowerCase()]}
                                                    text={actionObj['message']}
                                                    save={(newMessage) => {
                                                        const actions = [...localActionsArr];
                                                        actions[selectedIndex]['actions'][index]['message'] = newMessage;
                                                        setLocalActionsArr([...actions]);
                                                    }}
                                                />
                                            ) : (
                                                <MyInputText
                                                    placeholderText={placeholderTextMap[actionObj['type'].toLowerCase()]}
                                                    text={actionObj['message']}
                                                    save={(newMessage) => {
                                                        const actions = [...localActionsArr];
                                                        actions[selectedIndex]['actions'][index]['message'] = newMessage;
                                                        setLocalActionsArr([...actions]);
                                                    }}
                                                />
                                            )
                                        )}
                                        {actionObj['type'].toLowerCase() === 'delay' && (
                                            <MySlider actionObj={actionObj} index={index} />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 8 }}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <Button id='move-subaction-up' onClick={() => setLocalActionsArr((prev) => {
                                                    const actions = [...prev];
                                                    actions[selectedIndex]['actions'] = [...moveElement(actions[selectedIndex]['actions'], index, 'up')];
                                                    return actions;
                                                })} size='small' icon='pi pi-arrow-up' severity='info' />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button id='move-subaction-down' onClick={() => setLocalActionsArr((prev) => {
                                                    const actions = [...prev];
                                                    actions[selectedIndex]['actions'] = [...moveElement(actions[selectedIndex]['actions'], index, 'down')];
                                                    return actions;
                                                })} size='small' icon='pi pi-arrow-down' severity='info' />
                                            </Grid>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button size='small' style={{ width: '100%' }} severity='danger' label='Delete' onClick={() => {
                                                    setLocalActionsArr((prev) => {
                                                        const actions = [...prev];
                                                        actions[selectedIndex]['actions'].splice(index, 1);
                                                        return actions;
                                                    });
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Sidebar>
        );
    });

    return (
        <React.Fragment>
            <CustomSnackbar open={snackAlert.open} autoHideDuration={snackAlert.autoHideDuration} severity={snackAlert.severity} snackText={snackAlert.text} setSnackAlert={setSnackAlert} />
            <Grid container sx={{marginBottom: isMobile && '16px'}}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Actions</Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6} sx={{ marginTop: 2}}>
                    <Button onClick={addAction} size={'small'} style={{ backgroundColor: backgroundColor && backgroundColor, border: 'none', color: textColor && textColor, width: isMobile && '100%' }}>+ Add Action</Button>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6} sx={{ marginTop: 2 }}>
                    <div style={{float: 'right', width: isMobile && '100%'}}>
                        <VariableCopyDropdown userSettings={userSettings} setSnackAlert={setSnackAlert} backgroundColor={backgroundColor} />
                    </div>
                </Grid>
            </Grid>
            {isMobile && <Divider />}
            {localActionsArr.length > 0 && localActionsArr.map((actionObj, index) => (
                <Grid item xs={12} key={index}>
                    <Grid container spacing={isMobile ? 0 : 2} sx={{ height: isMobile ? '100%' : '100px' }} alignContent='center'>
                        <Grid item xs={isMobile ? 4 : 1}>
                            <Box display='flex' alignItems='center'>
                                <Grid container sx={{ width: '40px' }}>
                                    <Grid item xs={12}>
                                        <IconButton size='small' onClick={() => setLocalActionsArr((prev) => ([...moveElement(prev, index, 'up')]))}><ArrowUpward /></IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IconButton size='small' onClick={() => setLocalActionsArr((prev) => ([...moveElement(prev, index, 'down')]))}><ArrowDownward /></IconButton>
                                    </Grid>
                                </Grid>
                                <Typography sx={{ marginLeft: '8px', marginBottom: '8px' }} variant='h4'>{`#${index + 1}`}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 8 : 2}>
                            <Dropdown
                                value={capitalizeFirstLetter(localActionsArr[index]['type'])}
                                options={Object.keys(actionTemplates).map((a, i) => ({ label: a, value: a }))}
                                onChange={(e) => {
                                    const actions = [...localActionsArr];
                                    actions[index]['type'] = e.value;
                                    if (e.value.toLowerCase() === 'delay') {
                                        delete actions[index]['message'];
                                        actions[index]['seconds'] = actionTemplates['Delay']['seconds'];
                                    }
                                    setLocalActionsArr([...actions]);
                                }}
                                placeholder="Select an Action"
                                style={{ width: '100%', backgroundColor }}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8} sx={{marginTop: isMobile && '16px'}}>
                            {(localActionsArr[index]['type'].toLowerCase() === 'enhanced' ||
                                localActionsArr[index]['type'].toLowerCase() === 'standard' ||
                                localActionsArr[index]['type'].toLowerCase() === 'variable' ||
                                localActionsArr[index]['type'].toLowerCase() === 'tts standard' ||
                                localActionsArr[index]['type'].toLowerCase() === 'tts enhanced') && (
                                    localActionsArr[index]['type'].toLowerCase() === 'variable' ? (
                                        <Grid container spacing={isMobile && 1} sx={{width: '100%'}}>
                                            <div style={{width: '100%', paddingLeft: isMobile && '8px'}}>
                                                <MyAutoComplete
                                                    placeholderText={placeholderTextMap[localActionsArr[index]['type'].toLowerCase()]}
                                                    text={localActionsArr[index]['message']}
                                                    save={(newMessage) => {
                                                        const actions = [...localActionsArr];
                                                        actions[index]['message'] = newMessage;
                                                        setLocalActionsArr([...actions]);
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    ) : (
                                        <div style={{width: '100%', paddingRight: isMobile && '8px'}}>
                                            <MyInputText
                                                placeholderText={placeholderTextMap[localActionsArr[index]['type'].toLowerCase()]}
                                                text={localActionsArr[index]['message']}
                                                save={(newMessage) => {
                                                    const actions = [...localActionsArr];
                                                    actions[index]['message'] = newMessage;
                                                    setLocalActionsArr([...actions]);
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            {localActionsArr[index]['type'].toLowerCase() === 'delay' && (
                                <Slider
                                    sx={{ ...sliderStyle, marginTop: '8px' }}
                                    value={localActionsArr[index]['seconds']}
                                    min={1}
                                    max={60}
                                    step={1}
                                    valueLabelDisplay='auto'
                                    valueLabelFormat={localActionsArr[index]['seconds'].toString()}
                                    onChange={(e, v) => {
                                        const actions = [...localActionsArr];
                                        actions[index]['seconds'] = v;
                                        setLocalActionsArr([...actions]);
                                    }}
                                />
                            )}
                            {localActionsArr[index]['type'].toLowerCase() === 'compare' && (
                                <Grid container spacing={isMobile && 1} sx={{width: '100%'}}>
                                    <MySidebar />
                                    <Grid item xs={isMobile ? 12 : 4}>
                                        <div style={{maxWidth: !isMobile && '90%'}}>
                                            <MyAutoComplete text={localActionsArr[index]['value1']} save={(newMessage) => {
                                                    const actions = [...localActionsArr];
                                                    actions[index]['value1'] = newMessage;
                                                    setLocalActionsArr([...actions]);
                                                }} placeholderText='' />
                                        </div>
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 3}>
                                        <Dropdown   style={{ backgroundColor, width: isMobile ? '100%' : '90%' }}
                                                    options={Object.keys(operatorsOptions).map((o, oi) => ({ label: o, value: operatorsOptions[o] }))}
                                                    value={localActionsArr[index]['operator']}
                                                    onChange={(e) => {
                                                        const actions = [...localActionsArr];
                                                        actions[index]['operator'] = e.value;
                                                        setLocalActionsArr([...actions]);
                                                    }}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 3}>
                                        <div style={{maxWidth: !isMobile && '90%'}}>
                                            <MyAutoComplete text={localActionsArr[index]['value2']} save={(newMessage) => {
                                                    const actions = [...localActionsArr];
                                                    actions[index]['value2'] = newMessage;
                                                    setLocalActionsArr([...actions]);
                                                }} placeholderText='' />
                                        </div>
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 2}>
                                        <Button style={{width: '100%'}} label='Then' onClick={() => {
                                            setVisibleRight(true);
                                            setSelectedIndex(index);
                                            const actions = [...localActionsArr];
                                            actions[index]['actions'] = actions[index]['actions'] ? [...actions[index]['actions']] : [];
                                            setLocalActionsArr([...actions]);
                                            }} />
                                    </Grid>
                                </Grid>
                            )}
                            {localActionsArr[index]['type'].toLowerCase() === 'macro' && (
                                <div style={{width: '100%', paddingRight: isMobile && '8px'}}>
                                    <Dropdown style={{ width: '100%', backgroundColor }} value={localActionsArr[index]['macro']} placeholder='Select a macro'
                                        options={Object.keys(userSettings['macros']).map((m, i) => ({ label: m, value: m }))}
                                        onChange={(e) => {
                                            const actions = [...localActionsArr];
                                            actions[index]['macro'] = e.value;
                                            setLocalActionsArr([...actions]);
                                        }} />
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 1} sx={{marginTop: isMobile && '8px', paddingRight: isMobile && '8px'}}>
                            <Button
                                style={{ height: '48px', width: '100%' }}
                                size='small'
                                icon='pi pi-times'
                                onClick={() => setLocalActionsArr((prev) => ([...prev.filter((o, i) => i !== index)]))}
                            />
                        </Grid>
                    </Grid>
                    {isMobile && <Divider />}
                </Grid>
            ))}
        </React.Fragment>
    );
};

export default EditActions;
