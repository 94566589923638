import React from 'react';
import { AppBar, Toolbar, Typography, Button, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../utils/useIsMobile';

const Topbar = ({page, setPage}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handlePageChange = (newPage) => {
    setPage(newPage);
    navigate(`/about?p=${newPage}`);
  };

  const handleSelectChange = (event) => {
    const selectedPage = event.target.value;
    handlePageChange(selectedPage);
  };

  const renderMobileNavigation = () => {
    return (
      <Select variant="standard" color="secondary" value={page} onChange={handleSelectChange} displayEmpty inputProps={{ 'aria-label': 'navigation' }}>
        <MenuItem value="about">About Ratbot</MenuItem>
        <MenuItem value="personalities">Personalities</MenuItem>
        <MenuItem value="streamers">Streamers</MenuItem>
        <MenuItem value="modinfo">For Moderators</MenuItem>
      </Select>
    );
  };

  const renderDesktopNavigation = () => {
    return (
      <>
        <Button color="inherit" onClick={() => handlePageChange('about')}>
          <Typography variant="h5">About Ratbot</Typography>
        </Button>
        <Button color="inherit" onClick={() => handlePageChange('personalities')}>
          <Typography variant="h5">Personalities</Typography>
        </Button>
        <Button color="inherit" onClick={() => handlePageChange('streamers')}>
          <Typography variant="h5">Streamers</Typography>
        </Button>
        <Button color="inherit" onClick={() => handlePageChange('modinfo')}>
          <Typography variant="h5">For Moderators</Typography>
        </Button>
      </>
    );
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', border: 'none', boxShadow: 'none' }}>
      <Toolbar>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          Ratbot on Twitch
        </Typography>
        {isMobile ? renderMobileNavigation() : renderDesktopNavigation()}
        <Button sx={{ marginLeft: isMobile ? '8px' : '48px' }} variant="contained" color="secondary" onClick={() => navigate('/')}>
          <Typography variant={isMobile ? "h5" : "h3"}>Log In</Typography>
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
