import { Button, ButtonGroup, Container, Grid, Typography } from '@mui/material';
import React from 'react';

const Welcome = ({setStepperValue}) => {
  return (
    <Container width='100vw' alignItems='center' justifyContent='center' sx={{marginTop: '25vh'}}>
        <Grid item xs={12} textAlign='center'>
            <Typography variant='h1'>Welcome to Ratbot Setup</Typography>
        </Grid>
        <Grid item xs={12} textAlign='center' sx={{marginTop: '5vh'}}>
            <Typography variant='h2'>Get started with a quick setup and introduction, or skip and go directly to your dashboard</Typography>
        </Grid>
        <Grid item xs={12} textAlign='center' sx={{marginTop: '10vh'}}>
            <ButtonGroup>
                <Button variant='contained' color='error'>Skip</Button>
                <Button variant='contained' color='success' onClick={() => setStepperValue((prev) => prev + 1)}>Setup</Button>
            </ButtonGroup>
        </Grid>
    </Container>
  )
};

export default Welcome;