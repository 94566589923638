import { useState, useEffect, useMemo, useCallback } from 'react';
import { useUser } from '../context/UserContext';

const useLoadStories = () => {
    const authCtx = useUser();
    const [savedStories, setSavedStories] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchData = useCallback(async () => {
        try {
            const savedStoriesResponse = await fetch(`${apiUrl}/game/story/savedgames?token=${authCtx.token}`);
            const savedStoriesData = await savedStoriesResponse.json();
            setSavedStories(savedStoriesData);
        } catch (e) {
            console.log(e);
        }
    }, [apiUrl, authCtx.token]);

    useEffect(() => {
        fetchData();
    }, [apiUrl, fetchTrigger, fetchData]);

    // Memoize the result using useMemo
    const memoizedStories = useMemo(() => savedStories, [savedStories]);

    // Function to trigger re-fetch
    const refreshSavedStories = useCallback(() => {
        setFetchTrigger(prev => !prev);
    }, []);

    return { savedStories: memoizedStories, refreshSavedStories };
};

export default useLoadStories;