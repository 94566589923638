import React, { useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid, Typography } from '@mui/material';
import { useUserSettings } from '../../store/usersettings-context';
import useIsMobile from '../../utils/useIsMobile';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const NewMacro = ({backgroundColor = '#5D3B9E', setOpenDialog = () => {}, setIsBlur = () => {}, editObj = {}}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [macroName, setMacroName] = useState(Object.keys(editObj).length === 0 ? '' : editObj['title']);
    const [originalMacroName, setOriginalMacroName] = useState(macroName);
    const [isEditing, setIsEditing] = useState(Object.keys(editObj).length > 0);
    const [hasChangedMacroName, setHasChangedMacroName] = useState(false);
    const isMobile = useIsMobile();
    const [warning, setWarning] = useState('');
    
    const scrollableContent = {
        maxHeight: '65vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    const updateMacroName = (newMacroName) => {
        if (newMacroName.length <= 255) {
            setMacroName(newMacroName);
            setWarning('');
        } else {
            setMacroName(newMacroName.slice(0, 255));
            setWarning('Macro name cannot exceed 255 characters.');
        }
        if (isEditing && newMacroName !== originalMacroName) {
            setHasChangedMacroName(true);
        } else {
            setHasChangedMacroName(false);
        }
    };

    const saveMacroHandler = () => {
        const _macros = {...userSettings.macros};
        _macros[macroName] = Object.keys(editObj).length === 0 ? {actions: [], title: macroName} : {...editObj, title: macroName};
        update({macros: _macros}, true);
        if (hasChangedMacroName === true) deletekey(['macros', originalMacroName]);
        setOpenDialog(false);
        setIsBlur(false);
    };

    return (
        <DialogComponent footer={isEditing ? 'Edit your existing macro' : 'Create a new macro'} maxHeight='75vh' minWidth='25vw'>
            <Grid container spacing={2} sx={{...scrollableContent, marginLeft: '0px', padding: '8px'}}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Command</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputText style={{width: '100%', backgroundColor}} placeholder='Macro Name' value={macroName} 
                        onChange={(e) => {
                            updateMacroName(e.target.value);
                            if (e.target.value !== originalMacroName) {
                                setHasChangedMacroName(true);
                            }
                        }} />
                    {warning && <Typography variant='body2' color='error'>{warning}</Typography>}
                </Grid>
                <Grid item xs={12}>
                    <Button label='Save' severity='success' size='small' style={{ width: '25%' }} onClick={saveMacroHandler} />
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default NewMacro;