import React, { useContext, useEffect, useState } from 'react';
import MainPage from '../../../utils/mainpage';
import MainPageWrapper from '../../../utils/mainpagewrapper';
import MainPageChild from '../../../utils/mainpagechild';
import { tokens } from '../../../theme';
import { Button, Card, CardContent, FormControl, Grid, MenuItem, Select, Typography, styled, useTheme } from '@mui/material';
import useIsMobile from '../../../utils/useIsMobile';
import AuthContext from "../../../store/auth-context";
import { useUserSettings } from '../../../store/usersettings-context';
import CustomSnackbar from '../../../utils/customsnackbar';

const Spotify = () => {
    const isMobile = useIsMobile();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [backgroundColor, setBackgroundColor] = useState(tokens(theme.palette.mode).primary[400]);
    const [genreSeeds, setGenreSeeds] = useState([]);
    const [knownGames, setKnownGames] = useState({});
    const [snackAlert, setSnackAlert] = useState({open: false, severity: "success", text: "", autoHideDuration: 5000});
    const { userSettings, refresh, update, error } = useUserSettings();
    const authCtx = useContext(AuthContext); 
    const apiUrl = process.env.REACT_APP_API_URL;

    const TransparentCard = styled(Card)(({ theme }) => ({
        backgroundColor: isMobile ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        boxShadow: 'none',
    }));
  
    useEffect(() => {
      if (isMobile) {
        setBackgroundColor(colors.primary[600]);
      } else if (!isMobile) {
        setBackgroundColor(colors.primary[400]);
      }
    }, [isMobile]);

    useEffect(() => {
        const fetchGenreSeeds = async () => {
            try {
                const genreResponse = await fetch(`${apiUrl}/spotifygetgenreseeds?refreshtoken=${userSettings.modData.spotify.refreshToken}`);
                const genreRes = await genreResponse.json();
                setGenreSeeds(genreRes['genres']);
            } catch (e) {
                const alertText = e.message || 'An error occurred.';
                setSnackAlert((prev) => ({...prev, severity: "error", text: alertText, open: true}));
            }
        };
        const fetchKnownGames = async () => {
            try {
                const knownGamesResponse = await fetch(`${apiUrl}/api/admin/knowngames?token=${authCtx.token}`);
                const knownGamesRes = await knownGamesResponse.json();
                setKnownGames(knownGamesRes);
            } catch (e) {
                const alertText = e.message || 'An error occurred.';
                setSnackAlert((prev) => ({...prev, severity: "error", text: alertText, open: true}));
            }
        };

        if (genreSeeds.length === 0) { fetchGenreSeeds(); }
        if (Object.keys(knownGames).length === 0) { fetchKnownGames(); }
    }, []);

    const savehandler = async () => {
        if (Object.keys(knownGames).length > 0) {
            try {
                const saveResponse = await fetch(`${apiUrl}/api/admin/knowngames?token=${authCtx.token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(knownGames)
                });
                const saveRes = await saveResponse.text();
                setSnackAlert((prev) => ({...prev, severity: "success", text: saveRes, open: true}));
            } catch (e) {
                const alertText = e.message || 'An error occurred.';
                setSnackAlert((prev) => ({...prev, severity: "error", text: alertText, open: true}));
            }
        }
    };
    
  return (
    <MainPage backgroundColor={backgroundColor}>
        <CustomSnackbar open={snackAlert.open} autoHideDuration={snackAlert.autoHideDuration} severity={snackAlert.severity}
            setSnackAlert={setSnackAlert} snackText={snackAlert.text} />
        <MainPageWrapper backgroundColor={backgroundColor}>
            <MainPageChild title="Spotify" subtitle="Tool for editing genre seed assignment for GameGroove">
                <React.Fragment>
                    <Grid container spacing={isMobile ? 1 : 2} sx={{display: 'flex'}}>
                        {Object.keys(knownGames).map(game => (
                            <Grid item xs={12} md={4}>
                                <TransparentCard>
                                    <CardContent>
                                        <Typography variant={isMobile ? "h5" : "h3"}>{knownGames[game]['game_name']}</Typography>
                                        <br />
                                        <Typography variant={isMobile ? "h6" : "h4"}>Genres: </Typography>
                                        {knownGames[game]['genres'].map(g => (
                                            <FormControl key={g} sx={{marginTop: '8px'}}>
                                                <Select variant="standard" value={g} color="secondary" sx={{marginRight: '8px'}} onChange={(e) => {
                                                    const tmpIndex = knownGames[game]['genres'].indexOf(g);
                                                    const tmpObj = { ...knownGames };
                                                    tmpObj[game]['genres'][tmpIndex] = e.target.value;
                                                    setKnownGames(tmpObj);
                                                }}>
                                                    {genreSeeds.map(s => (
                                                        <MenuItem key={s} value={s}>{s}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ))}
                                        <Button size={isMobile ? "small" : "medium"} variant="text" color="secondary" sx={{marginTop: '16px'}} onClick={() => {
                                            window.location.href = `https://open.spotify.com/playlist/${knownGames[game]['playlist'].split(':')[2]}`;
                                            }}>{`Open playlist on Spotify`}</Button>
                                    </CardContent>
                                </TransparentCard>
                            </Grid>
                        ))}
                    </Grid>
                    <br />
                    <Button size="large" variant="contained" color="secondary" onClick={savehandler}>Save Changes</Button>
                    <br />
                    <br />
                </React.Fragment>
            </MainPageChild>
        </MainPageWrapper>
    </MainPage>
  )
};

export default Spotify;