import React, { useState } from 'react';
import { Box, keyframes, styled } from '@mui/material';

const AnimatedBackground = ({children, selectedImages}) => {
    const rotate = keyframes`from {transform: rotate(0deg);} to {transform: rotate(360deg);}`;
    const antirotate = keyframes`from {transform: rotate(0deg);} to {transform: rotate(-360deg);}`;
    const BackgroundContainer = styled(Box)({
        backgroundImage: `url(${selectedImages[0]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        filter: 'blur(10px) brightness(0.3)',
        position: 'absolute',
        top: '-100%',
        left: '-100%',
        right: 0,
        bottom: 0,
        zIndex: -2,
        animation: `${rotate} 540s linear infinite`,
    });
    const ForegroundContainer = styled(Box)({
        backgroundImage: `url(${selectedImages[1]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        filter: 'blur(10px) brightness(0.3)',
        position: 'absolute',
        top: '-100%',
        left: '-100%',
        right: 0,
        bottom: 0,
        zIndex: -1,
        animation: `${antirotate} 720s linear infinite`,
        opacity: 0.25,
    });

    return (
        <React.Fragment>
            <BackgroundContainer sx={{height: '300%', width: '300%'}} />
            <ForegroundContainer sx={{height: '300%', width: '300%'}} />
            {children}
        </React.Fragment>
    );
};

export default React.memo(AnimatedBackground);