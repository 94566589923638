import React, { createContext, useContext, useState } from 'react';

const initialState = {
    token: '',
    isLoggedIn: false,
    username: '',
    profile_url: '',
    broadcaster_type: '',
    isSubscribed: false,
    isAdmin: false,
    theme: 'dark',
    editor: false,
    login: async (token, username, profile_url, broadcaster_type, editor) => {},
    logout: () => {},
    checkIfAdmin: () => {},
    checkIfSubscribed: async (token, requestDetails) => {}
};

const UserContext = createContext(initialState);

export const useUser = () => useContext(UserContext);
export default UserContext;