import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const StripeCheckout = () => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [searchParams] = useSearchParams();
    const checkoutId = searchParams.get('checkout_id');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const navigate = useNavigate(); // useNavigate hook for redirection

    const registerSubscription = async () => {
        try {
            const response = await fetch(`${apiUrl}/stripecheckout?checkout_id=${checkoutId}&token=${authCtx.token}`);
            if (response.ok) {
                setSuccessMessage("Checkout completed successfully! You will be redirected shortly.");
                setTimeout(() => {
                    navigate('/'); // Redirect to '/' after a short delay
                }, 3000); // 3-second delay to show the success message
            } else {
                // Handle non-2xx responses
                const errorMessage = `Checkout failed: ${response.statusText}`;
                setError(errorMessage);
                console.error(errorMessage);
            }
        } catch (err) {
            console.error("An error occurred:", err.name);
            console.error("Error message:", err.message);
            console.error("Stack trace:", err.stack);
            setError(err.message);
        }
    };

    useEffect(() => {
        if (checkoutId && authCtx.token) {
            registerSubscription();
        }
    }, [checkoutId, apiUrl, authCtx.token]);

    return (
        <React.Fragment>
            {error && <p>{error}</p>}
            {successMessage && <p>{successMessage}</p>}
        </React.Fragment>
    );
};

export default StripeCheckout;