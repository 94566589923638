import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import DialogComponent from '../../../beta/layouts/DialogComponent';
import { useUser } from '../../../context/UserContext';

const SubscriberSubscription = ({backgroundColor = '#5D3B9E', footer = '', customerObj = {}}) => {
    const [stripeInfo, setStripeInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    useEffect(() => {
        const fetchCustomerData = async () => {
            const response = await fetch(`${apiUrl}/user/subscription/${customerObj['subscription']}?token=${authCtx.token}`);
            const res = await response.json();
            setStripeInfo(res);
            setLoading(false);
        };
        fetchCustomerData();
    }, [customerObj]);

  return (
    <DialogComponent footer={footer} maxHeight='60vh'>
        <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}} spacing={2}>
            {loading ? (
                <Typography>Loading customer data...</Typography>
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography>{`Status: ${stripeInfo['status']}`}</Typography>
                        <Typography>{`Created: ${new Date(stripeInfo['created'] * 1000).toLocaleString()}`}</Typography>
                        <Typography>{`Subscribed At: ${new Date(stripeInfo['start_date'] * 1000).toLocaleString()}`}</Typography>
                        <Typography>{`Last Payment: ${new Date(stripeInfo['current_period_start'] * 1000).toLocaleString()}`}</Typography>
                        <Typography>{`Next Payment: ${new Date(stripeInfo['current_period_end'] * 1000).toLocaleString()}`}</Typography>
                        <Typography>{`Trial Start: ${new Date(stripeInfo['trial_start'] * 1000).toLocaleString()}`}</Typography>
                        <Typography>{`Trial End: ${new Date(stripeInfo['trial_end'] * 1000).toLocaleString()}`}</Typography>
                        <Typography>{`Canceled At: ${new Date(stripeInfo['canceled_at'] * 1000).toLocaleString()}`}</Typography>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    </DialogComponent>
  );
};

export default SubscriberSubscription;