import React, { useState, useContext, useCallback, useMemo } from 'react';
import { useUser } from '../context/UserContext';

const apiUrl = process.env.REACT_APP_API_URL;

const StreamerSettingsContext = React.createContext(null);

const StreamerSettingsProvider = ({ children }) => {
    const [userSettings, setUserSettings] = useState({});
    const [selectedStreamer, setSelectedStreamer] = useState(localStorage.getItem('editingFor'));
    const [error, setError] = useState(null);
    const authCtx = useUser();

    const refresh = useCallback(async (streamer) => {
        if (!streamer) return;
        const response = await fetch(`${apiUrl}/user/getstreamersettings?token=${authCtx.token}&streamer=${streamer}`);
        const res = await response.json();
        setUserSettings(res);
    }, [authCtx]);

    const update = useCallback(async (data, destructively = true) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        const response = await fetch(`${apiUrl}/user/editorupdatesettings?token=${authCtx.token}&streamer=${selectedStreamer}&destructively=${destructively}`, options);
        const res = await response.json();
        await refresh(localStorage.getItem('editingFor'));
        return response.ok;
    }, [authCtx, refresh, selectedStreamer]);

  const deletekey = useCallback(async (keypath = []) => {

  }, [authCtx, refresh]);

  const memoizedValue = useMemo(() => ({ userSettings, refresh, update, error, deletekey, selectedStreamer, setSelectedStreamer }), 
    [userSettings, refresh, update, error, deletekey, selectedStreamer, setSelectedStreamer]);

  return <StreamerSettingsContext.Provider value={memoizedValue}>{children}</StreamerSettingsContext.Provider>;
};

export const useStreamerContext = () => {
  const streamerSettingsCtx = useContext(StreamerSettingsContext);
  if (!streamerSettingsCtx) {
    throw new Error('useStreamerSettings must be used within a StreamerSettingsProvider');
  }
  return streamerSettingsCtx;
};

export default StreamerSettingsProvider;