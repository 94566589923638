import React, { useRef, useState } from 'react';
import Welcome from './welcome';
import { Box, keyframes, styled } from '@mui/material';
import PersonalitySetup from './personality_grid';

const Onboarding = () => {
    const [stepperValue, setStepperValue] = useState(0);
    const animationRef = useRef(null);
    const antiAnimationRef = useRef(null);
    const rotate = keyframes`from {transform: rotate(0deg);} to {transform: rotate(360deg);}`;
    const antirotate = keyframes`from {transform: rotate(0deg);} to {transform: rotate(-360deg);}`;
    const BackgroundContainer = styled(Box)({
        backgroundImage: `url(https://i.imgur.com/evryi6x.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        filter: 'blur(5px) brightness(0.3)',
        position: 'absolute',
        top: '-100%',
        left: '-100%',
        right: 0,
        bottom: 0,
        zIndex: -2, // Behind the foreground
        animation: `${rotate} 540s linear infinite`,
    });
    const ForegroundContainer = styled(Box)({
        backgroundImage: `url(https://i.imgur.com/ApIGuVM.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        filter: 'blur(5px) brightness(0.3)',
        position: 'absolute',
        top: '-100%',
        left: '-100%',
        right: 0,
        bottom: 0,
        zIndex: -1, // In front of the background
        animation: `${antirotate} 720s linear infinite`,
        opacity: 0.25, // 50% transparency
    });
    

    const getPage = () => {
        switch (stepperValue) {
            case 0:
                return (<Welcome setStepperValue={setStepperValue} />);
            case 1:
                return (<PersonalitySetup setStepperValue={setStepperValue} />);
        
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            {stepperValue !== 0 && <BackgroundContainer sx={{height: '300%', width: '300%'}} />}
            {stepperValue !== 0 && <ForegroundContainer sx={{height: '300%', width: '300%'}} />}
            {getPage()}
        </React.Fragment>
    );
};

export default Onboarding;